import ApiService from '../../../../services/api.service';

class ItemUnitService extends ApiService {
  name  = 'itemUnit';
  path  = 'master/unit';
  constructor() {
    super();
    this.init()
  }
}


export default new ItemUnitService();
