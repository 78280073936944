import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import _ from 'lodash';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import RoleService from './Role.service';
import PermissionService from '../Permission/Permission.service';
import MenuService from '../Menu/MenuService';

@reduxForm({form: 'RoleForm', destroyOnUnmount: true, initialValues: {rolePermissions: [], rolePermissionMap: {}, menusMap: {}}})
@connect((state) => ({
  formData: getFormValues('RoleForm')(state)
}))
export default class RoleForm extends FormView {
  service     = RoleService
  viewType    = 2;
  initialData = {rolePermissions: [], rolePermissionMap: {}, menus: [], menusMap: {} }

  formView() {
    var map = this.props.formData.rolePermissionMap;
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.role.name')}
              name          = 'name'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />

            <Field
              label         = {counterpart('entities.role.description')}
              name          = 'description'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />

          </div>
        </Card>
        <br/>

        {/* <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='Menu' />
          <Divider/>

          <DataTable baseId="simple-pagination" plain>
            <TableBody>
            {Object.keys(this.props.formData.menusMap).map((key, i)=> {
                let d = this.props.formData.menusMap[key];
                return this.VChild(d, key)
              })}
            </TableBody>
          </DataTable>
        </Card>
        <br/> */}

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.role.permission').toUpperCase()} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn>{counterpart('entities.role.permission').toUpperCase()}</TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(this.props.formData.rolePermissionMap).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                  {this.props.formData.rolePermissionMap[key].permission.name}
                  </TableColumn>
                  <TableColumn>
                    <Field
                      name          = {'rolePermissionMap.no'+i+'.allow'}
                      className     = 'md-cell md-cell--6'
                      component     = {Switch}
                      // searchField   = {['name', 'aliasName', 'npwp']}
                    />
                  </TableColumn>
                </TableRow>

              })}
            </TableBody>
          </DataTable>
        </Card>
      </div>
    )
  }

  VChild(d, key) {
    return <React.Fragment>
      <TableRow key={key} selectable={false}>
        <TableColumn>
        {Array.from(Array(d.menu.level-1)).map((_, i) => ( <span>&ensp;&ensp;&ensp;</span>)) }
        {d.menu.name}
        </TableColumn>
        <TableColumn>
          <Field
            name          = {d.parentKey+'.'+key+'.allow'}
            className     = 'md-cell md-cell--6'
            component     = {Switch}
            // searchField   = {['name', 'aliasName', 'npwp']}
          />
        </TableColumn>
      </TableRow>
      {d.menu.hasChild &&
        Object.keys(d.childrenMap).map((ckey)=> {
          let c = d.childrenMap[ckey];
          // console.log(c, 'asdasddddddd')
          return this.VChild(c, ckey)
        })
      }
    </React.Fragment>
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      var d = this.initialData;
      let res = await PermissionService.api.find({page: 1, sortBy: 'name', size: 4000, sort: 'ASC'});
      res.data.data.forEach((v, i) => {
        d.rolePermissionMap['no'+i] = {
          permission: v,
          allow: true
        }
      })

      let menuRes = await MenuService.api.lookup();
      let menuRoot = _(menuRes.data).filter({level: 1}).orderBy('seq').map((v, i) => ({menu: v, allow: true, children: [], parentKey: 'menusMap'})).value()
      menuRoot.forEach((v, i)=> this._searchChild(v, menuRes.data))

      let menuRootMap = menuRoot.reduce((pv, cv) => {
        pv[cv.menu.id] = cv
        delete cv.menu.id;
        delete cv.menu.parentId;
        return pv
      }, {})

      // d.menus = menuRoot;
      d.menusMap = menuRootMap;

      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      var rolePermissionMap = {}
      res.data.permissions.forEach((v, i)=> {
        rolePermissionMap['no'+i] = v;
      })

      res.data.rolePermissionMap = rolePermissionMap;

      this.props.initialize(res.data);
    }
  }

  _searchChild(obj, arr, parentKey, del) {
    if(obj.menu.hasChild) {
      obj.children = _(arr).filter({parentId: obj.menu.id}).orderBy('seq').map((v, i) => ({menu: v, allow: true, children: [], parentKey: obj.parentKey+'.'+obj.menu.id+'.childrenMap'})).value()
      obj.children.forEach((child, i) => {
        this._searchChild(child, arr)
      })
      obj.childrenMap = obj.children.reduce((pv, cv) => {
        pv[cv.menu.id] = cv
        if(del) {
          delete cv.menu.id;
          delete cv.menu.parentId;
        }
        return pv
      }, {})
    }
  }

  _serializeMenu(childMap) {
    return Object.keys(childMap).reduce((pv, key)=> {
      var data = childMap[key]
      pv.push(data)
      if(data.menu.hasChild) {
        data.children = this._serializeMenu(data.childrenMap)
      }

      return pv;
    }, [])
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)
    var rolePermissions = [];

    Object.keys(value.rolePermissionMap).forEach((k, i)=> {
      rolePermissions.push(value.rolePermissionMap[k])
    })
    value.permissions = rolePermissions;
    delete value.rolePermissionMap;

    // value.menus = this._serializeMenu(value.menusMap);

    // console.log(JSON.stringify(value), 'value')

    return value;
  }



  handleAddPermission = ()=> {
    var map = this.props.formData.rolePermissionMap;
    var length = Object.keys(this.props.formData.rolePermissionMap).length
    this.props.dispatch(this.props.change('rolePermissionMap.no'+length, {allow: true}))
  }

  handleDeletePermission = (i) => {
    var map = this.props.formData.rolePermissionMap;
    delete map['no'+i]
    this.props.dispatch(this.props.change('rolePermissionMap', map))
  }
}
