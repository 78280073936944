import ApiService from '../../../services/api.service';

class LogSummaryBpEbupotService extends ApiService {
  name  = 'logSummaryBpEbupot';
  path  = 'pph21/process/logSummary-bp-ebupot';
  constructor() {
    super();
    this.init()
  }

  api = {
    download: async (d) => {
      console.log(d)
      var res = await this.http.get(`${this.apiLocation}/${this.path}/download/${d.id}`, {
        responseType: 'blob'
      })

      return res;
    }
  }
}


export default new LogSummaryBpEbupotService();
