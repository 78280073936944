import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import KoreksiFiskalBulanService from './KoreksiFiskalBulan.service';
import ListView from '../../../../components/entity/listView';
import { permission } from '../../../../config/constant';
import DefaultView from '../../../../components/entity/default.view';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import {Field, reduxForm, getFormValues} from 'redux-form';

@connect((state) => ({
  ...KoreksiFiskalBulanService.stateConnectSetting()(state),
  kp: state.auth.currentSPT,
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), KoreksiFiskalBulanService.actionConnectSetting())
export default class KoreksiFiskalBulanView extends DefaultView {
  showDeleteAll = false;
  service     = KoreksiFiskalBulanService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = BiayaPromosiDialog

  approvalSettingName     = 'Koreksi Fiskal'
  checkerPermissionName   = permission.MelakukanCheckKoreksiFiskal;
  approvalPermissionName  = permission.MelakukanApproveKoreksiFiskal;

  beforeFetch(params) {
    params.status=['TEST_STATUS', 'TEST_STATUS_REFERENCE', 'draft', 'approved', 'checked'];
  }

  fetchOption() {
    console.log(this.props.kp)
    return {
      path: 'koreksi-fiskal/'+this.props.kp.data.id+'/koreksi-fiskal'
    }
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.koreksiFiskalBulan.masa",             value: "masa",          isDefaultSort:true},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.koreksiFiskalBulan.tahun",            value: "tahun.tahun"},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.koreksiFiskalBulan.date",             value: "date"}
  ]

  _barActions = () => {
    var arr = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.export',
      iconClassName:'mdi mdi-export',
      onClick:() => this.handleExport(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
  ]

  if(this.showDeleteAll) {

  arr.push({
    label:'word.delete',
    iconClassName:'mdi mdi-delete',
    onClick:() => {
      this.setState({showDialogConfirmDeleteSelected:true})
    },
    disabledFunc:() => this.props.table.selected.length === 0
  })
}

  return arr
}

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Masa'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
      </this.SearchContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id, item.tahun.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  async handleExport(arr) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Export ke Csv',
      width: 500,
      initialValue: {note: '-', delimiter: ';'},
      okText: 'Export',
      text: <div className='md-grid'> <Field
      label         = 'Note'
      name          = 'note'
      className     = 'md-cell md-cell--12'
      component     = {Textfield}
      validate      = {validation.required}
    /></div>
    }));

    if(d) {
      await this.service.api.export(arr.map(v => v.id), d);
      iziToast.success({
        title: 'success',
        message: counterpart('entities.upload.exportSuccess')
      })
    }
  }
}
