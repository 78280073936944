import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import MetodePenyusutanFiskalService from './MetodePenyusutanFiskal.service';
import ListView from '../../../../components/entity/listView';
import MetodePenyusutanFiskalDialog from './MetodePenyusutanFiskal.dialog';

@connect(MetodePenyusutanFiskalService.stateConnectSetting(), MetodePenyusutanFiskalService.actionConnectSetting())
export default class MetodePenyusutanFiskalView extends ListView {
  service     = MetodePenyusutanFiskalService
  FormDialog  = MetodePenyusutanFiskalDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.metodePenyusutanFiskal.code",    value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.metodePenyusutanFiskal.name",    value: "name",  isDefaultSort:true}
    
  ]
}
