import ApiService from '../../../services/api.service';

class DashboardService extends ApiService {
  name= 'dashboard';
  path= 'dashboard';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api={
    test: async() => {
      var res = await this.http.get(`${this.apiLocation}/pph21/master/religion`)
      return res.data;
    },
    flowInfo: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/mainFlow/info/`)
      return res.data;
    },
    calculate: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/calculate`, data)
      return res;
    },
    compare: async (data) => {
      var res = await this.http.get(`${this.apiLocation}/dashboard-comparation`, {
        params: data
      })
      return res;
    },

    detail: async (id, masa, year) => {
      var res = await this.http.get(`${this.apiLocation}/comparation-detail/${id}/${masa}/${year}`)
      return res;
    }
  }

}


export default new DashboardService();
