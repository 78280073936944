import ApiService from '../../../../../services/api.service';

class PPh21DataService extends ApiService {
  name  = 'pph21Data';
  path  = 'pph21/process/pph21Data';
  constructor() {
    super();
    this.init()
  }

  api = {
    delete: async (id) => {
      var data = await this.http.delete(`${this.apiLocation}/pph21/process/pph21-data/${id}`)
      return data
    },
    deleteAll: async (ids) => {
      var data = await this.http.post(`${this.apiLocation}/pph21/process/pph21-data/delete/bulk`, {ids})
      return data
    },
    calculate: async (id) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}/${id}/calculate`, {})
      return data
    },
    calculateBatch: async (data) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}-calculate-batch`, data)
      return data
    },
    sendEppt: async (data) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}-send-eppt`, data)
      return data
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    }
  }
}


export default new PPh21DataService();
