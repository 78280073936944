import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import CoaDaftarNominatifBiayaEntertainService from './CoaDaftarNominatifBiayaEntertain.service';
import CoaService from '../Coa/Coa.service';

@reduxForm({form: 'CoaDaftarNominatifBiayaEntertainForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('CoaDaftarNominatifBiayaEntertainForm')(state)
}))
export default class CoaDaftarNominatifBiayaEntertainDialog extends DialogView {
  service     = CoaDaftarNominatifBiayaEntertainService

  formView() {
    return (
      <div className='md-grid'>

        <Field
          label         = {counterpart('entities.coa.title')}
          name          = 'coa'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          // remoteSearch  = {true}
          searchField2  = 'code'
          itemTemplate  = {function(d) {
            return {
              primaryText: d.name,
              secondaryText: d.code,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          service       = {CoaService}
          valueField    = 'parent'
          />

      </div>
    )
  }
}
