import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab} from 'react-md';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import uploadService from '../upload/upload.service';
import env from 'env';

// import FormDialog from './ItemType.dialog.view';
import CoaService from './Coa.service';
import ListView from '../../../../components/entity/listView';
import CoaDialog from './Coa.dialog';

@connect(CoaService.stateConnectSetting(), CoaService.actionConnectSetting())
export default class CoaView extends ListView {
  showDeleteAll = true;
  service     = CoaService
  FormDialog  = CoaDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.coa.code",       value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.coa.name",       value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.coa.compare",       value: "compare", type: "boolean"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.coa.isPPn",      value: "isPPn",  type: "boolean"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.coa.isPPh21",      value: "isPPh21",  type: "boolean"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.coa.isPPh23",       value: "isPPh23", type: "boolean"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.coa.isPPh25",       value: "isPPh25", type: "boolean"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.coa.isPPh4a2",       value: "isPPh4a2", type: "boolean"}
  ]

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleUpload()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import COA',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/coa_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }
}
