import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, DataTable, TableHeader,
  TableBody,
  TableRow,
  TableColumn} from 'react-md';
  import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';

// import FormDialog from './Perjanjian.dialog.view';
import ReceiptService from './Receipt.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import DocumentTypeService from '../../Admin/DocumentType/DocumentType.service';
import { permission } from '../../../../config/constant';
import DefaultView from '../../../../components/entity/default.view';
import counterpart from 'counterpart';
import {List, ListItem} from 'react-md';
import iziToast from 'izitoast';
import CompanyService from '../../Admin/Company/Company.service';

@connect((state) => ({
  ...ReceiptService.stateConnectSetting()(state),
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), ReceiptService.actionConnectSetting())
export default class ReceiptView extends DefaultView {
  showDeleteAll = true;
  showBatchApproval       = true;

  service     = ReceiptService
  addDialog   = false
  editDialog  = false

  category    = null;

  approvalSettingName     = 'Perjanjian'
  checkerPermissionName   = permission.MelakukanCheckReceipt;
  approvalPermissionName  = permission.MelakukanApproveReceipt;

  async beforeFetch(params) {

    if(!this.category) {
      var res = await DocumentTypeService.api.find({page: 1, size: 1000, sort: -1, category:'Receipt', sortBy: 'name', field: 'category', query: 'Receipt'});
      if(res.data) {
        this.category = res.data.data.map((v) => {
          return v.name
        })
      }
    }

    params.type= 'pembayaran-page-search-common';
    params.category=this.category;
    params.status=["draft", "checked", "approved", "reject", "TEST_STATUS", "TEST_STATUS_REFERENCE"];
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.receipt.statusKirim",                      value: (d)=> {
      return <p style={{width: 150}}>4 (2) : {d.status.statusTax4a2} <br/>23 : {d.status.statusTax23}</p>
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.receipt.no",                          value: "no",                    isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.receipt.category",                    value: "category"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.receipt.reference",                   value: "reference",             className: "mpk-font-size-S"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.receipt.date",                        value: "date"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.receipt.lawanTransaksi.name",         value: "lawanTransaksi.name"},
    {isSortable: false, show: true,   isSearchable:true,  label: "entities.receipt.lawanTransaksi.npwp",         value: "lawanTransaksi.npwp"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.receipt.lawanTransaksi.address",      value: "lawanTransaksi.address"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.receipt.lawanTransaksi.email",        value: "lawanTransaksi.email"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.receipt.lawanTransaksi.phone",        value: "lawanTransaksi.phone"},
    {isSortable: false, show: true,  isSearchable:false,  label: "entities.perjanjian.summary.total",               value: "summary.total" ,  type: 'number'},
    // {isSortable: true,  show: true,     isSearchable:true,  label: "entities.bungaDeposito.statusEppt",          value: "eppt.status"}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
              label         = {counterpart('entities.company.title')}
              name          = 'companyId'
              className     = 'md-cell md-cell--12'
              component     = {this.ig.Searchfield}
              service       = {CompanyService}
              itemTemplate   = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              valueField    = 'id'
            />
        <this.ig.Field
          label='Masa Perolehan'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Tahun Perolehan'
          name='year'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor'
          name='no'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Reference'
          name='reference'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Lawan Transaksi'
          name='namaLk'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Lawan Transaksi'
          name='npwpLk'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />


        <this.ig.Field
          label='Ke Tanggal'
          name='startDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />

        <this.ig.Field
          label='Dari Tanggal'
          name='endDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
      </this.SearchContainer>
    )
  }

  _dialogConfirm() {
    var selected = this.props.table.selected.filter((item) => (item.workflow.status == 'rejected' || item.workflow.status == 'draft') && this.props.user.login == item.createdBy)
    return <DialogConfirm
      title={"Hapus"}
      message={
        <div>
          <p>Apakah anda yakin akan menghapus data ini?</p>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn>Nomor Nota</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selected.map((x, i) => (
                <TableRow key={i}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>{x.no}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </DataTable>
        </div>
      }
      visible={this.state.showDialogConfirmDeleteSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected

        await this.deleteSelected(items, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
      translate={false}
    />
  }

  barActions = () => {

    var arr = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'entities.bungaDeposito.sendToEppt',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleSendToEppt(),
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
  ]

  if(this.showBatchApproval) {
    arr.push({
      label:'word.approval',
      iconClassName:'mdi mdi-clipboard-check-outline',
      onClick:() => this.handleBatchApproval(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    })
  }

  arr.push({
    label:'word.delete',
    iconClassName:'mdi mdi-delete',
    onClick:() => {
      this.setState({showDialogConfirmDeleteSelected:true})
    },
    disabledFunc:() => this.props.table.selected.length === 0
  })

  return arr
}

  tableActions = (d) => {
    var permissionMap = this.props.user.permissionMap;
    var menus = [
      {
        label:"More Info",
        iconClassName:"mdi mdi-bell",
        onClick: (item) => this.editItem(item)
      },
      {
        label:"Cek Status Kirim",
        iconClassName:"mdi mdi-information",
        onClick: (item) => this.handleCheckStatus(item)
      },
      {label:"divider", iconClassName:"-"},
      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick:(item, callback) => this.deleteItem(item, callback),
        confirmation:{
          title:"sentence.custom.deleteItem",
          message:"sentence.custom.deleteItemConfirmation"
        }
      },
      {label:"divider", iconClassName:"-"}
    ]

    if(this.props.approvalSetting[this.approvalSettingName]) {

      if(d.workflow.status == 'draft' && this.props.user.permissionMap[this.checkerPermissionName]) {
        menus.push({
          label:"Proses Check",
          iconClassName:"mdi mdi-format-list-checks",
          onClick: (item) => this.handleCheck(item),
          disabled: true
        })
      }
      if(d.workflow.status == 'checked' && this.props.user.permissionMap[this.approvalPermissionName]) {
        menus.push({
          label:"Proses Approval",
          iconClassName:"mdi mdi-clipboard-check-outline",
          onClick: (item) => this.handleApproval(item)
        })
      }

    } else {
      menus.push({
        label:"Proses Approval",
        iconClassName:"mdi mdi-clipboard-check-outline",
        onClick: (item) => this.handleApproval(item)
      })
    }

    return menus;
  }

  async handleSendToEppt() {
    // console.log(this.props.table)
    var items = this.props.table.selected.filter((t) => t.workflow.status == 'approved')
    var d = await this.context.showDialog((props, res, rej) =>({
      title: counterpart.translate('entities.bungaDeposito.sendToEppt'),
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
          {items.map((v, i)=> {
            if(v.status.statusTax23 == 'sudah dikirim' || v.status.statusTax4a2 == 'sudah dikirim') {
              return <ListItem style={{backgroundColor: '#ffebee'}} primaryText={`${v.lawanTransaksi.name} (${v.lawanTransaksi.npwp}) - SUDAH DIKIRIM`} secondaryText={`${v.no} - ${v.date}`} key={i} />
            } else {
              return <ListItem primaryText={`${v.lawanTransaksi.name} (${v.lawanTransaksi.npwp})`} secondaryText={`${v.no} - ${v.date}`} key={i} />
            }
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: items.filter(t => !(t.status.statusTax23 == 'sudah dikirim' || t.status.statusTax4a2 == 'sudah dikirim')).map((v, i) => v.id)
      }
      await this.service.api.sendToEppt(data)
      iziToast.success({title: 'Success', message: 'Data sedang dikirim, mohon cek status kirim di kolom Status EPPT'})
      this.fetchData()
    }
  }

  async handleCheckStatus(item) {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: counterpart.translate('entities.bungaDeposito.statusEppt'),
      width: 500,
      initialValue: {},
      cancelText: 'Submit',
      actions: [
        {
          children: 'Close',
          onClick: ()=> {res(false)}
        }
      ],
      text: (<div style={{width: '100%'}}>
        <List style={{width: '100%'}}>
          <ListItem primaryText="Status Pasal 4(2)" secondaryText={item.status.statusTax4a2} />
          <ListItem primaryText="Status Pasal 23" secondaryText={item.status.statusTax23} />
        </List>
      </div>)
    }));
  }

  deleteSelected = async (items, callback) => {
    try {
      let ids = []

      items.forEach(item => {
        if((item.workflow.status == 'rejected' || item.workflow.status == 'draft') && this.props.user.login == item.createdBy) ids.push(item.id)
      })

      if(this.service.api.deleteSelected) {
        await this.service.api.deleteSelected(ids)
      } else if(this.service.api.deleteAll) {
        await this.service.api.deleteAll(ids)
      }

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  deleteItem = async (item, callback) => {
    if(!(item.workflow.status == 'rejected' || item.workflow.status == 'draft')) {
      callback("Warning", "Data dengan status Approved/Waiting/Checked tidak dapat di hapus!")
      return item
    }

    if(this.props.user.login != item.createdBy) {
      callback("Warning", "Anda tidak di izinkan untuk menghapus data ini!")
      return item
    }

    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  _approvalListView(v, i) {
    return <ListItem primaryText={`${v.lawanTransaksi.name} (${v.lawanTransaksi.npwp})`}  key={i} secondaryText={`Masa ${v.no} - ${v.date}`} />
  }
}
