import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import LogSummaryBpEbupotService from './LogSummaryBpEbupot.service';
import ListView from '../../../components/entity/listView';
import LogSummaryBpEbupotDialog from './LogSummaryBpEbupot.dialog'
import download from "downloadjs";

@connect(LogSummaryBpEbupotService.stateConnectSetting(), LogSummaryBpEbupotService.actionConnectSetting())
export default class LogSummaryBpEbupotView extends ListView {
  service     = LogSummaryBpEbupotService
  FormDialog  = LogSummaryBpEbupotDialog

  async beforeFetch(params) {

      params.module=["FakturKeluaran", "FakturMasukan", "ReturFakturKeluaran", "ReturFakturMasukan", "Cbs", "Penyusutan Amortisasi", "TEST_MODULE", "Daftar Nominatif Biaya Entertain", "Daftar Nominatif Biaya Promosi", "Invoice", "PPh25", "Export Koreksi Fiskal", "Koreksi Fiskal"]
      params.start = "01/01/2018"
      params.end   = "01/01/5020"
    }

  columns=[
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.orgNama",                  value: "orgNama",                 },
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.orgNpwp",                  value: "orgNpwp",                 },
      // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.origin",                value: "origin"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.sptMasa",                value: "sptMasa", type: "number"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.sptTahun",                  value: "sptTahun", type: "number"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.sptPembetulan",                  value: "sptPembetulan", type: "number"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.bp23Count",                value: "bp23Count", type: "number"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.bp26Count",                  value: "bp26Count", type: "number"},
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.status",                  value: "status",                 },
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.message",                  value: "message",                 },
      {isSortable: true,  show: true,   isSearchable:true,  label: "entities.logSummary.createdDate",                  value: "createdDate", type: "date"}
  ]

  tableActions = (d)=> {
    var menu = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  if(d.status == 'FINISH') {
    menu.push({
      label:"Download",
      iconClassName:"mdi mdi-download",
      onClick: (item) => this.handleDownload(item)
    })
  }

  return menu;
}

  async handleDownload(item) {
    // let res = await this.service.api.download(item);
    // var filename = res.headers['content-disposition']
    // if(!filename) filename = res.headers['authorization']
    // if(filename) filename = filename.split('filename=').pop();
    // if(!filename) filename = "log.csv"
    // console.log(res.headers)
    // download(res.data, filename);
    if(localStorage.getItem('sipbas-eppt').search('com') >= 0) {
      var hostArr = localStorage.getItem('sipbas-eppt').split(":")
      window.open(`${hostArr[0]}:${hostArr[1]}/api/ebupot/export-pdf-bp/download/${item.id}`)
    } else {
      window.open(`${localStorage.getItem('sipbas-eppt')}/api/ebupot/export-pdf-bp/download/${item.id}`)
    }
  }
}
