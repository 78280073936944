import React from 'react';
import { Route, Switch } from 'react-router-dom';

export default (props) => (
  <Switch>
    <Route path={props.match.path+"/biayaPromosi/:id"} component={require('./BiayaPromosi/BiayaPromosi.form').default}/>
    <Route path={props.match.path+"/biayaPromosi"} component={require('./BiayaPromosi/BiayaPromosi.view').default}/>
    <Route path={props.match.path+"/biayaEntertain/:id"} component={require('./BiayaEntertain/BiayaEntertain.form').default}/>
    <Route path={props.match.path+"/biayaEntertain"} component={require('./BiayaEntertain/BiayaEntertain.view').default}/>
    <Route path={props.match.path+"/koreksiFiskalBulan/:id"} component={require('./KoreksiFiskalBulan/KoreksiFiskalBulan.form').default}/>
    <Route path={props.match.path+"/koreksiFiskalBulan"} component={require('./KoreksiFiskalBulan/KoreksiFiskalBulan.view').default}/>
    <Route path={props.match.path+"/pph25/:id"} component={require('./Pph25/Pph25.form').default}/>
    <Route path={props.match.path+"/pph25"} component={require('./Pph25/Pph25.view').default}/>
    <Route path={props.match.path+"/bungaDeposito/:id"} component={require('./BungaDeposito/BungaDeposito.form').default}/>
    <Route path={props.match.path+"/bungaDeposito"} component={require('./BungaDeposito/BungaDeposito.view').default}/>

    <Route path={props.match.path+'/logImport'} exact component={require('../LogImport/LogImport.view').default}/>
    <Route path={props.match.path+'/logProcess'} exact component={require('../LogProcess/LogProcess.view').default}/>
    <Route path={props.match.path+'/logExport'} exact component={require('../LogExport/LogExport.view').default}/>

    <Route path={props.match.path+'/'} component={function() {return <div/>}} />
  </Switch>
)
