import ApiService from '../../../../services/api.service';

class PeriodUnitService extends ApiService {
  name  = 'item';
  path  = 'master/periodUnit';

  constructor() {
    super();
    this.init()
  }
}


export default new PeriodUnitService();
