import ApiService from '../../../../services/api.service';

class AttachmentService extends ApiService {

  name  = 'attachmentService';
  path  = 'archive-spt/attachment';
  constructor() {
    super();
    this.init()
  }

  api = {

    findByModuleAndArchiveId: async (module, archiveId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}-by-module/${module}/${archiveId}`)
      return res
    },

    download: async (id) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}-download/${id}`, {
        responseType: 'blob'
      })

      return res
    },
    retry: async (d) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}-retry/${d.id}`)
      return res;
    }
  }
}


export default new AttachmentService();
