import ApiService from '../../../../services/api.service';

class KoreksiFiskalBulanService extends ApiService {
  name  = 'koreksiFiskalBulan';
  path  = 'koreksi-fiskal/koreksi-fiskal';
  constructor() {
    super();
    this.init()
  }

  api= {
    findOne: async (id, tahunId, dispatch=()=> {})=> {
      let res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/${tahunId}/koreksi-fiskal/${id}`)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/koreksi-fiskal`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/koreksi-fiskal/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (id, tahunId, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/koreksi-fiskal/${tahunId}/koreksi-fiskal/${id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/koreksi-fiskal/${id}/update-workflow`, data);
      return res;
    },
    export: async (d, e) => {
      var res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/export/${d[0]}`, e);
      return res
    },
  }
}


export default new KoreksiFiskalBulanService();
