import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './StatusPtkp.dialog.view';
import StatusPtkpService from './StatusPtkp.service';
import ListView from '../../../../../components/entity/listView';
import StatusPtkpDialog from './StatusPtkp.dialog';

@connect(StatusPtkpService.stateConnectSetting(), StatusPtkpService.actionConnectSetting())
export default class StatusPtkpView extends ListView {
  service     = StatusPtkpService
  FormDialog  = StatusPtkpDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.statusPtkp.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.statusPtkp.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
