import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import SelectField, { Paper, Switch, List, ListItem } from 'react-md';
import counterpart from 'counterpart';
import { reduxForm, Field } from 'redux-form';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';
import { Textfield, Searchfield, Datepicker, Multiselect, Switch as Switch2, Radio, TextfieldMask, Checkbox,Fileinput, validation } from '../form'
import TableSettingCustom from '../TableSettingCustom/TableSettingCustom';
import {deleteSelectedDataItems} from "../../redux/actions/imageActions";
import iziToast from 'izitoast';


class SearchContainer extends Component {
  render() {
    return (
      <div className="mpk-fill mpk-content">
        <div className='md-grid'>
        {this.props.children}
        </div>
      </div>
    )
  }
}

export default class ListView extends Component {
  rootPath;
  service=null;
  editDialog = true;
  addDialog = true;
  translate = true;

  showDeleteAll = false;
  showBatchApproval = false;

  firstCall = true;

  tr = counterpart.translate;

  apiPath = 'find';
  viewType=1;

  ig = {
    Textfield,
    Datepicker,
    Multiselect,
    Switch: Switch2,
    Radio,
    TextfieldMask,
    Checkbox,
    Field,
    Searchfield,
    Fileinput
  }

  SearchContainer=SearchContainer;

  static contextTypes = {
    showDialog: 'React.PropTypes.func',
  };

  buildSearchContainer() {
    var cont = reduxForm({form: this.service.name+'_search', destroyOnUnmount: true})(SearchContainer);
    this.SearchContainer = cont;
  }
  componentWillMount() {
    this.buildSearchContainer();

  }

  componentDidMount() {
    this.firstCall = false;
  }

  titleHeader() {
    return `entities.${this.service.name}.title`;
  }

  header() {
    return <div/>
  }

  FormDialog() {
    return <div/>
  }

  constructor(props){
    super(props);
    this.state = {
      showTableSetting:true,
      showDialogConfirmDeleteSelected:false,
      showDialogConfirmDeleteFinishFailedSelected:false,
      showForm:false,
      formData: {},
      data: []
    }
  }

  // view -------------------------------------------------
  render() {
    if(this.viewType === 1) {
      return this.view1();
    } else {
      return this.view2()
    }
  }

  bottomBar() {
    return null;
  }

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  additionalContent() {
    return null;
  }

  additionalTopContent(){
    return null;
  }

  view2() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-padding-N all mpk-content">
          {this.additionalTopContent()}
          <div style={{minHeight: 450}} className="mpk-layout">
            {this.tableView()}
            {this.tableSetting()}
          </div>

          {this.additionalContent()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  _viewContainer(props) {
    return <div className="mpk-layout column fill">
      {props.children}
    </div>
  }

  viewContainer = (props) => {
    return this._viewContainer(props);
  }

  _barActions = () => {
    var arr = [
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    }
  ]

  if(this.showBatchApproval) {
    arr.push({
      label:'word.approval',
      iconClassName:'mdi mdi-clipboard-check-outline',
      onClick:() => this.handleBatchApproval(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    })
  }

  if(this.showDeleteAll) {

  arr.push({
    label:'word.delete',
    iconClassName:'mdi mdi-delete',
    onClick:() => {
      this.setState({showDialogConfirmDeleteSelected:true})
    },
    disabledFunc:() => this.props.table.selected.length === 0
  })
}

  return arr
}

  _barItem = ()=> {
    return (
      <Switch
        id="switch-table-setting"
        name="Switch Table Setting"
        label=""
        checked={this.state.showTableSetting}
        onClick={(e) => {
          e.preventDefault()
          this.setState({
            showTableSetting:!this.state.showTableSetting
          })
        }}
      />
    )
  }

  barItem() {return this._barItem()}

  _commandBar(props) {
    var barActions = this._barActions();
    if(this.barActions) barActions = this.barActions;
    if(typeof this.barActions == 'function'){
      barActions = this.barActions()
    }

    return <Commandbar
    title={this.titleHeader()}
    translate={this.translate}
    actions={barActions}
    rightCorner={this._barItem()}/>
  }

  commandBar(props) {
    return this._commandBar(props);
  }

  _columns = [
    {label: "word.name",  value: "name", isDefaultSort:true, show:true, isSearchable:true}
  ]

  defaultColumns = [
    {isSortable:false,  label: "word.id",                 value: "id",        show:false,   isSearchable:false},
    {isSortable:true,   label: "word.createdBy",          value: "createdBy", show:true,    isSearchable:false },
    {isSortable:true,   label: "word.createdDate",        value: "createdAt", show:true,    isSearchable:false, isDefaultSort: true},
    {isSortable:true,   label: "word.lastModifiedBy",     value: "updatedBy", show:false,   isSearchable:false},
    {isSortable:true,   label: "word.lastModifiedDate",   value: 'updatedAt', show:false,   isSearchable:false, searchField: 'updatedAt', isDefaultSort: false}
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }
  ]

  footer() {
    return null;
  }

  _tableView(props) {
    var columns = this._columns;
    var tableActions = this._tableActions;

    if(this.columns) {
      columns = this.columns;
      if(typeof this.columns === 'function') columns = this.columns();
    }

    if(this.tableActions) tableActions= this.tableActions;

    var _this = this;
    if(columns[0].addon){
    } else {
      // columns.unshift(
      //   {
      //     addon: true,
      //     label: "word.number",
      //     value: function(d, index){
      //       var current = 0;
      //       if(_this.props){
      //         if(_this.props.table){
      //           if(_this.props.table.params){
      //             current = _this.props.table.params.size * (_this.props.table.params.page-1)
      //           }
      //         }
      //       }
      //       if(index || index == 0){
      //         return (index+1) + current;
      //       } else {
      //         return "-"
      //       }
      //     },
      //     type: "func",
      //     show:true,
      //     isSearchable:true}
      //   )
    }

    columns = [...columns, ...this.defaultColumns]

    return (
    <Paper className="flex mpk-layout column">
      {this.header()}

      <Table
        connect={{
          properties: this.props.table,
          actions: this.props.tableActions
        }}
        isPaging={true}
        translate={true}
        columns={columns}
        itemActions={tableActions}
        fetchData={this.fetchData}
        footer={this.footer()}
      />
    </Paper>
    )
  }

  tableView(props) {return this._tableView(props)}

  // table setting and search
  defaultSearchForm() {

    var columns = [];
    if(this.columns) {
      columns = this.columns;
      if(typeof this.columns === 'function') columns = this.columns();
    }

    var options = columns.filter((v)=> v.isSearchable).map((d, i) => {
      var id = d.value;
      // if(typeof id === 'function') id = id();
      var action = 'equals';
      if(d.searchField) id = d.searchField
      if(d.searchAction) action = d.searchAction;

      return {
        id: id,
        name: counterpart.translate(d.label)
      }
    })

    return (
      <div className='md-grid' style={{margin: 0, padding:0}}>
      <Field
        label='Kolom'
        // name='column'
        name='field'
        className="md-cell md-cell--12"
        component={Searchfield}
        options={options}
      />

      <Field
        label='Kata Kunci'
        // name='searchKey'
        name='query'
        className="md-cell md-cell--12"
        component={Textfield}
      />
      </div>
    )
  }
  searchForm() {
    return <this.SearchContainer>
      {this.defaultSearchForm()}
    </this.SearchContainer>
  }

  tabWidth= 240
  tabs = ["search", "column"];

  tabComponents() {
    return {}
  }


  _tableSetting() {
    if(this.state.showTableSetting) {
      return <div className="flex-none mpk-layout">
        <Empty width={16} className="flex-none"/>
        <Paper className="flex-none">
          <TableSettingCustom
            dispatch={this.props.dispatch}
            service={this.service}
            tableFilter={this.props.tableFilter}
            tableFilter2={this.props.tableFilter2}
            tableActions={this.props.tableActions}
            table={this.props.table}

            width={this.tabWidth}
            tabs={this.tabs}
            tabComponents={this.tabComponents()}

            searchForm={this.searchForm()}

            onSearch={this.fetchData}
            translate={true}
          />
        </Paper>
      </div>
    }
  }

  tableSetting() {return this._tableSetting()}

  _dialogConfirm() {

    return <DialogConfirm
      title={'word.deletes'}
      message={'sentence.custom.deleteSelectedItems'}
      visible={this.state.showDialogConfirmDeleteSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected

        await this.deleteSelected(items, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
      translate={true}
    />
  }

  dialogConfirm() {return this._dialogConfirm()}

  _dialogConfirmDeleteFinishFailedSelected() {
    return <DialogConfirm
      title={'word.deletes'}
      message={'sentence.custom.deleteSelectedItems'}
      visible={this.state.showDialogConfirmDeleteFinishFailedSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected
        let deleteable = []

        items.forEach(data => {
          if(data.status == 'FINISH' || data.status == 'FAILED') {
            deleteable.push(data)
          }
        })

        await this.deleteSelected(deleteable, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteFinishFailedSelected:false})}
      translate={true}
    />
  }

  dialogConfirmDeleteFinishFailedSelected() {return this._dialogConfirmDeleteFinishFailedSelected()}


  // method ----------------------------------------------
  beforeFetch(params) {

  }

  afterFetch(res) {

  }

  fetchOption() {
    return {}
  }

  getJsonFromUrl() {
    var result = {};

    if(this.props.location && this.props.location.search) {
      var query = this.props.location.search.substr(1);
      query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
    }

    return result;
  }

  fetchData = async (params=this.props.table.params, onSuccess, onError) => {
    params = _.cloneDeep(params);

    var search = this.getJsonFromUrl();
    var filterObj = this.props.filterObj;
    if(this.firstCall) filterObj = {}
    delete filterObj.size;
    delete filterObj.page;
    delete filterObj.sort;
    delete filterObj.sortBy;

    params ={
      ...params,
      ...search,
      ...filterObj
    }


    if(!onSuccess) {
      this.props.tableActions.setProperties({
        isLoading: true
      })
    }

    try {
      await this.beforeFetch(params)
      var res = await this.service.api[this.apiPath](params, this.props.dispatch, this.fetchOption());
      var data = res.data.data;
      var total = parseInt(res.data.total);
      if(onSuccess) onSuccess(data, total)

      if(!onSuccess) {
        this.props.tableActions.setProperties({
          isLoading: false,
          data,
          params:{
            total
          }
        })
      }

      if(this.service && this.service.tableService) {
        this.props.dispatch({type: this.service.tableService.tableType.TABLE_SELECT_DATA_ITEM, index: 0, isSelect: false})
      }

      this.setState({data})

      await this.afterFetch(res)

      return {
        data,
        total
      }
    } catch(e) {
      console.log(e)
      if(onError) onError(e);
      if(!onError) {
        this.props.tableActions.setProperties({
          isLoading: false,
          error:{
            isError:true,
            title:e.statusText,
            message:e.message
          }
        });
      }
      throw e;
    }
  };

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push('/'+this.service.name+"/new");
      }

    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push('/'+this.service.name+"/"+item.id);
      }
    }
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  deleteSelected = async (items, callback) => {
    try {
      let ids = []

      items.forEach(item => {
        ids.push(item.id)
      })

      if(this.service.api.deleteSelected) {
        await this.service.api.deleteSelected(ids)
      } else if(this.service.api.deleteAll) {
        await this.service.api.deleteAll(ids)
      }

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  showFormDialog = (data) => {
    this.setState({
      showForm: true,
      formData: _.cloneDeep(data)
    })
  }

  handleBatchApproval = async (arr)=> {

    var p = await this.context.showDialog((props, res, rej) =>({
      title: 'Pilih Proses',
      initialValue: {proses:'Proses Cek'},
      height: 'auto',
      width: 300,
      contentStyle: {overflow: 'inherit'},
      okText: 'Lanjutkan',
      text: <div className="md-grid" >
            <Field
              label         = {'Proses'}
              name          = 'proses'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: 'Proses Cek',
                name: 'Proses Cek'
              }, {
                id: 'Proses Approval',
                name: 'Proses Approval'
              }, {
                id: 'Proses Pembatalan Approval',
                name: 'Proses Pembatalan Approval'
              }]}
              validate      = {validation.required}
            />
      </div>
    }))

    if(p) {
    var filtered = arr;
    var formInput = () => <div/>
    switch (p.proses) {
      case 'Proses Approval':
        filtered = arr.filter((x) => x.workflow.status == 'checked')
        formInput = () => <Field
          label         = {counterpart('entities.workflow.action')}
          name          = 'status'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'approved',
            name: 'Approve'
          }, {
            id: 'rejected',
            name: 'Reject'
          }]}
          validate      = {validation.required}
        />
        break;
      case 'Proses Pembatalan Approval':
        filtered = arr.filter((x) => x.workflow.status == 'approved' ||  x.workflow.status == 'rejected')
        formInput = () => <div/>
        break;
      default:
        filtered = arr.filter((x) => x.workflow.status == 'draft')
        formInput = () => <Field
          label         = {counterpart('entities.workflow.action')}
          name          = 'status'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: 'checked',
            name: 'Approve'
          }, {
            id: 'rejected',
            name: 'Reject'
          }]}
          validate      = {validation.required}
        />
        break;
    }
    var dialog = await this.context.showDialog((props, res, rej) =>({
      initialValue: {status: 'draft'},
      height: 'auto',
      width: 300,
      contentStyle: {padding:0, overflowY: 'hidden'},
      okText: 'Submit',
      text: (
        <div >
          <div className='mpk-commandbar mpk-layout align-center justify-between mpk-border bottom solid dark'>
            <div className='mpk-font-size-M flex mpk-layout align-center'>{p.proses}</div>
          </div>

          <div className="md-grid" >
            {formInput()}
            <Field
              label         = {counterpart('entities.workflow.message')}
              name          = 'message'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}

              />
          </div>

          <div style={{width: '100%', height: 300, overflow: 'auto'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan memproses data dibawah ini?</p>
        <List style={{width: '100%'}}>
        {filtered.map((v, i)=> {
          return this._approvalListView(v, i)
          })}
        </List>
      </div>)
        </div>
      )
    }));

    if(dialog) {
      await this.service.api.updateWorkflowBulk({...dialog,ids: filtered.map(x => x.id)});
      iziToast.success({title: 'Success', message: 'Proses check telah berhasil dilakukan'})
      this.fetchData()
    }
  }
  }

  _approvalListView(v, i) {
    return <ListItem primaryText={`${v.company.name} (${v.company.npwp})`}  key={i} secondaryText={`Masa ${v.month} - ${v.workflow.status}`} />
  }
}
