import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ComponentType.dialog.view';
import ComponentTypeService from './ComponentType.service';
import ListView from '../../../../../components/entity/listView';
import ComponentTypeDialog from './ComponentType.dialog';

@connect(ComponentTypeService.stateConnectSetting(), ComponentTypeService.actionConnectSetting())
export default class ComponentTypeView extends ListView {
  service     = ComponentTypeService
  FormDialog  = ComponentTypeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.componentType.name",                    value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.componentType.code",                    value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.componentType.category",                value: "category"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.componentType.taxType",                 value: "taxType"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.componentType.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
