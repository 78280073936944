import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import KelompokHartaService from './KelompokHarta.service';
import JenisHartaService from '../JenisHarta/JenisHarta.service';

@reduxForm({form: 'KelompokHartaForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('KelompokHartaForm')(state)
}))
export default class KelompokHartaDialog extends DialogView {
  service     = KelompokHartaService


  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.jenisHarta.title')}
          name          = 'jenisHarta'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          remoteSearch  = {true}
          service       = {JenisHartaService}
          valueField    = 'parent'
          // searchField   = {['name', 'aliasName', 'npwp']}
        />

        <Field
          label         = {counterpart('entities.kelompokHarta.code')}
          name          = 'code'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.kelompokHarta.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.kelompokHarta.lamaManfaat')}
          name          = 'lamaManfaat'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
          money         = {","}
        />
        <Field
          label         = {counterpart('entities.kelompokHarta.tarifGL')}
          name          = 'tarifGL'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
          money         = ","
        />
        <Field
          label         = {counterpart('entities.kelompokHarta.tarifSM')}
          name          = 'tarifSM'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
          money         = ","
        />
      </div>
    )
  }

}
