import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PenomoranBp23Service from './PenomoranBp23.service';

@reduxForm({form: 'PenomoranBp23Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
formData: getFormValues('PenomoranBp23Form')(state)
}))
export default class PenomoranBp23Form extends FormView {
service     = PenomoranBp23Service
viewType    = 2;

formView() {
    return (
    <div>
        <Card>
        <div className='md-grid'>
            <Field
            label         = {counterpart('entities.penomoranBp23.prefix')}
            name          = 'prefix'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            validate      = {validation.required}
            />
            <Field
            label         = {counterpart('entities.penomoranBp23.suffix')}
            name          = 'suffix'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            validate      = {validation.required}
            />
            <Field
            label         = {counterpart('entities.penomoranBp23.increment')}
            name          = 'increment'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
            />
            </div>
        </Card>
        </div>
    )
    }

    async initData() {
        let res = await this.service.api.find({});
        this.props.initialize(res.data[0]);
    }

    async handleSave(value) {
      var beforeSave = await this.beforeSave(value);
      var res = null;
      if(beforeSave) {
        if(typeof beforeSave === 'object') value = beforeSave;
        try {
          this.setState({onProgress: true})
          if(value.id) {
            res = await this.service.api.update(value)
          } else {
            res = await this.service.api.save(value)
          }
          this.setState({onProgress: false})
          this.afterSave(res, value)

          var pathArray = this.props.location.pathname.split('/');
          var path = '';
          pathArray.forEach((d, i)=> {
            if(pathArray.length-1 != i) {
              path = path+d;
              if(i != pathArray.length-2) path = path+'/'
            }
          })

          iziToast.success({
            title: 'success',
            message: 'Data Telah tersimpan'
          })

          // this.props.history.push(path)
        } catch(e) {
          var msg = e.message;
          if(e.response) msg = e.response.data.message;
          this.setState({
            isError: true,
            onProgress: false,
            errorMessage: msg
          })
        }
      }
    }
}

