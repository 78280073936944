import React, { Component } from 'react';
import createHistory from 'history/createHashHistory';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Router from 'react-router-dom/Router';
import picturefill from 'picturefill'
import * as globalActions from 'react-mpk/redux/actions/globalActions';
import id from './locale/id.json';
import en from './locale/en.json';

import './App.scss';

import env from 'env';
import { httpService, authService, navService, locale } from 'react-mpk';

import routes from './routes';
import DialogService from './services/dialog.service';
const history = createHistory();

@connect((state) => ({
  setting: state.auth.setting
}))
class App extends Component {
  componentWillMount() {
    //SET HTTP INTERCEPTOR
    httpService.setInterceptors((config)=> {
      let accessToken = authService.getAccessToken();
      if (accessToken) {
        config.headers = {
          Authorization: 'Bearer ' + accessToken
        }
      }
      return config;
    });
    //##

    //SET ERROR HANDLER
    httpService.setErrorHandler( err => {
      err.message = err.response.data ? (err.response.data.message) : err.message;
      return err;
    });
    //##

    //SET ENV
    // authService.setEnv(env);
    navService.init(history);
    //##

    //SET LOCALE
    locale.init('en', en);
    locale.init('id', id)

    this.props.globalActions.setProperties({
      localeCode:'id',
      localeList:[
        {label:'Bahasa', code:'id'},
        {label:'English', code:'en'},
      ]
    });
    //

    picturefill();
  }

  render() {

    return (
      <div className="App" style={{
        zoom: this.props.setting.size
      }}>
        <DialogService>
        <Router history={history}>
          {routes()}
        </Router>
        </DialogService>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  global:state.global
});

const mapDispatchToProps = dispatch => ({
  globalActions:bindActionCreators(globalActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
