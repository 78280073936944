import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import _ from 'lodash';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import Service from './User.service';
import UserRoleService from '../UserRole/UserRole.service';
import RoleService from '../Role/Role.service';
import CompanyService from '../Company/Company.service';
// import counterpart from 'counterpart';


@reduxForm({form: 'UserRoleForm', destroyOnUnmount: true, initialValues:{companyMap: {}}})
@connect((state) => ({
  formData: getFormValues('UserRoleForm')(state)
}))
export default class UserRoleForm extends FormView {
  service     = UserRoleService
  viewType    = 2;
  translate   = false

  titleHeader() {
    if(this.props.formData && this.props.formData.user) return `Role ${this.props.formData.user.name}`;

    return <div/>
  }

  // initialData = {aturCabang: 'companys: [], companyMap: {}}

  componentDidMount() {
    this.initData()
  }

  formView() {
    var map = this.props.formData.companyMap?this.props.formData.companyMap: {}
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.role.title')}
              name          = 'role'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              remoteSearch  = {true}
              service       = {RoleService}
              valueField    = 'parent'
              // searchField   = {['name', 'aliasName', 'npwp']}
              />

            <Field
            label         = {"Atur Cabang"}
            name          = 'hasBranch'
            className     = 'md-cell md-cell--12'
            component     = {Switch}
            // validate      = {validation.required}
            />

          </div>
        </Card>
        <br/>

        {this.props.formData.hasBranch &&

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'Cabang'} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn>{counterpart('entities.company.title').toUpperCase()}</TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(map).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    {map[key].data.name} - {map[key].data.npwp}
                  </TableColumn>
                  <TableColumn>
                    <div className='mpk-layout'>
                      <div className='flex' />
                      <Field component={Switch} name={`companyMap.${key}.enable`} />
                    </div>
                  </TableColumn>
                </TableRow>
              })}
            </TableBody>
          </DataTable>

          {/* <div className='mpk-layout' style={{padding: 8}}>
              <div className='flex'></div>
              <Button flat primary swapTheming onClick={this.handleAddCompany}>Tambah Cabang</Button>
          </div> */}
        </Card>
        }
      </div>
    )
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.save')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.save')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  async initData() {

    if(!this.props.formData.user) {
      this.props.history.push('/user');
    } else {
      var companyRes = await CompanyService.api.find({
        page: 1,
        sortBy: 'name',
        size: 90000000,
        sort: 'ASC'
      })
      var res = {
        data: {...this.props.formData}
      }

      try {
        res = await this.service.api.findOneByUser(this.props.formData.user.id);
        // console.log(res.data, 'asdasd')
        var companyMapDb = {}
        res.data.companies.forEach((v, i)=> {
          companyMapDb[v.id] = v;
        })

        console.log(companyMapDb, 'lalal')



        var companyMap = companyRes.data.data.reduce((pv, cv) => {
          pv[cv.id] = {
            data: cv,
            enable: companyMapDb[cv.id] != undefined
          }

          return pv
        }, {})

        res.data.companyMap = companyMap;
      } catch(e) {
        console.log("errrr", e)
        var companyMap = companyRes.data.data.reduce((pv, cv) => {
          pv[cv.id] = {
            data: cv,
            enable: false
          }

          return pv
        }, {})

        res.data.companyMap = companyMap;
      }

      this.props.initialize(res.data);


    }
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)
    var companys = [];
    Object.keys(value.companyMap).forEach((k, i)=> {
      companys.push(value.companyMap[k])
    })

    companys = companys.filter(d => d.enable)
    companys = companys.map(x => x.data)

    value.companies = companys;
    delete value.companyMap;

    return value;
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          res = await this.service.api.save(value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push('/user')
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  handleAddCompany = ()=> {
    var map = this.props.formData.companyMap;
    var length = Object.keys(this.props.formData.companyMap).length
    this.props.dispatch(this.props.change('companyMap.no'+length, {}))
  }

  handleDeleteCompany = (i) => {
    var map = this.props.formData.companyMap;
    delete map['no'+i]
    this.props.dispatch(this.props.change('companyMap', map))
  }
}
