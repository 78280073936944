/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Main from 'modules/Main/Main';
import Account from './modules/Account/Account'
import ResetPassword from './modules/Account/ResetPassword'

export default actions => (
  <Switch>
    <Route path="/account" component={Account}/>
    <Route path="/reset/finish" component={ResetPassword}/>
    <Route path="/" component={Main}/>
  </Switch>
);
