import ApiService from '../../../../services/api.service';

class ApprovalSettingService extends ApiService {
  name  = 'approvalSetting';
  path  = 'master/approval-setting';
  constructor() {
    super();
    this.init()
  }
}


export default new ApprovalSettingService();
