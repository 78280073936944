import ApiService from '../../../../services/api.service';

class TarifService extends ApiService {
  name  = 'tarif';
  path  = 'master/tarif';
  constructor() {
    super();
    this.init()
  }
}


export default new TarifService();
