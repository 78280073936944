import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import EarningService from './Earning.service';
import ComponentTypeService from '../ComponentType/ComponentType.service';

@reduxForm({form: 'EarningForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('EarningForm')(state)
}))
export default class EarningDialog extends DialogView {
  service     = EarningService
  initialData = {
    hideSlipPrintOut: false,
    isDynamic: false
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.earning.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.earning.alias')}
          name          = 'alias'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.earning.componentType')}
          name          = 'componentType'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          service       = {ComponentTypeService}
          valueField    = "parent"
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.earning.hideSlipPrintOut')}
          name          = 'hideSlipPrintOut'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.earning.isDynamic')}
          name          = 'isDynamic'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.earning.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          // validate      = {validation.required}
        />
      </div>
    )
  }
}
