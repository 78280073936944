import ApiService from '../../../../services/api.service';

class UserService extends ApiService {
  name  = 'user';
  path  = 'master/user';
  constructor() {
    super();
    this.init()
  }

  api = {
    saveExternal: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-external`, data);
      return res;
    },
    changePassword: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/change-password`, data);
      return res;
    },
    getVersion: async () => {
      let res = await this.http.get(`${this.apiLocation}/info`);
      return res;
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    }
  }
}


export default new UserService();
