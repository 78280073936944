import ApiService from '../../../../services/api.service';

class CoaBungaDepositoService extends ApiService {
  name  = 'coaBungaDeposito';
  path  = 'master/coa-bunga-deposito-tabungan';
  constructor() {
    super();
    this.init()
  }
}


export default new CoaBungaDepositoService();
