import ApiService from '../../../../services/api.service';

class PerjanjianItemService extends ApiService {
  name  = 'perjanjianItem';
  path  = 'pembayaran/perjanjian-item';
  constructor() {
    super();
    this.init()
  }

  api= {
    save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/perjanjian/${data.perjanjian.id}/perjanjian-item`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/perjanjian/${data.perjanjian.id}/perjanjian-item/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (id, perjanjianId, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/perjanjian/${perjanjianId}/perjanjian-item/${id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    }
  }
}


export default new PerjanjianItemService();
