import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
List,
ListItem,
TextField
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../components/form';
import DialogView from '../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import LogSummaryService from './LogSummaryBpEbupot.service';

@reduxForm({form: 'LogSummaryForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
data: getFormValues('LogSummaryForm')(state)
}))
export default class LogSummaryDialog extends DialogView {
service     = LogSummaryService
showSubmit  = false
cancelLabel = 'Tutup'
formView() {
    return (
    <div className='md-grid'>
        <Field
        label         = {counterpart('entities.LogSummary.name')}
        name          = 'name'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.origin')}
        name          = 'origin'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.module')}
        name          = 'module'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.status')}
        name          = 'status'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.description')}
        name          = 'description'
        className     = 'md-cell md-cell--12'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.delimiter')}
        name          = 'delimiter'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.source')}
        name          = 'source'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.total')}
        name          = 'total'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.current')}
        name          = 'current'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.error')}
        name          = 'error'
        className     = 'md-cell md-cell--12'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.start')}
        name          = 'start'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.end')}
        name          = 'end'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.isSourceAvailable')}
        name          = 'isSourceAvailable'
        className     = 'md-cell md-cell--6'
        component     = {Switch}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.isErrorAvailable')}
        name          = 'isErrorAvailable'
        className     = 'md-cell md-cell--6'
        component     = {Switch}
        validate      = {validation.required}
        />
        <Field
        label         = {counterpart('entities.LogSummary.message')}
        name          = 'message'
        className     = 'md-cell md-cell--12'
        component     = {Textfield}
        validate      = {validation.required}
        />
    </div>
    )
}
}
