import ApiService from '../../../../services/api.service';

class CoaDaftarNominatifBiayaEntertainService extends ApiService {
  name  = 'coaDaftarNominatifBiayaEntertain';
  path  = 'master/coa-daftar-nominatif-biaya-entertain';
  constructor() {
    super();
    this.init()
  }
}


export default new CoaDaftarNominatifBiayaEntertainService();