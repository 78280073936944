import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import CoaBungaDepositoService from './CoaBungaDeposito.service';
import CoaService from '../Coa/Coa.service';

@reduxForm({form: 'CoaBungaDepositoForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('CoaBungaDepositoForm')(state)
}))
export default class CoaBungaDepositoDialog extends DialogView {
  service     = CoaBungaDepositoService

  formView() {
    return (
      <div className='md-grid'>

        <Field
          label         = {'Jenis Penghasilan'}
          name          = 'jenisPenghasilan'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{
            id: "Bunga Deposito / Tabungan",
            name: "Bunga Deposito / Tabungan"
          }, {
            id: "Jasa Giro",
            name: "Jasa Giro"
          }]}
          />

        <Field
          label         = {counterpart('entities.coa.title')}
          name          = 'coa'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          // remoteSearch  = {true}
          searchField2  = 'code'
          itemTemplate  = {function(d) {
            return {
              primaryText: d.name,
              secondaryText: d.code,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          service       = {CoaService}
          valueField    = 'parent'
          />

      </div>
    )
  }
}
