import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import CbsService from './Cbs.service';

@reduxForm({form: 'CbsForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('CbsForm')(state)
}))
export default class CbsDialog extends DialogView {
  service     = CbsService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.cbs.officeCode')}
          name          = 'officeCode'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.currencyCode')}
          name          = 'currencyCode'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.nomorIA')}
          name          = 'nomorIA'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <Field
          label         = {counterpart('entities.cbs.tahun')}
          name          = 'tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.dk')}
          name          = 'dk'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: 'D', name: 'Debit'},
            {id: 'K', name: 'Kredit'},
          ]}
        />
        <Field
          label         = {counterpart('entities.cbs.jumlahMutasi')}
          name          = 'jumlahMutasi'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.archiveNo')}
          name          = 'archiveNo'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.cbs.description')}
          name          = 'description'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
