import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import counterpart from 'counterpart';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import download from 'downloadjs';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SummaryService from './Summary.service';
import iziToast from 'izitoast';
import { CompanyView } from '../../../Admin/Company/Company.view';
import CompanyService from '../../../Admin/Company/Company.service';
import PeriodeService from './Periode.service'

@connect(CompanyService.stateConnectSetting(), CompanyService.actionConnectSetting())
class OrganizationPicker extends CompanyView {
  viewType=2;

  _barActions = () => [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }

}

@reduxForm({form: 'summary', destroyOnUnmount: true, initialValues: {organizations: []}})
@connect((state) => ({
  ...CompanyService.stateConnectSetting()(state),
  formData    : getFormValues('summary')(state)
}))
export default class PPhSummaryTahunan extends FormView {
  service  = PeriodeService
  viewType = 2;

  translate = false

  titleHeader() {
    return "Summary Tahunan";
  }

  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      tahunOptions: [],

      showOrganizationPicker: false
    }
  }

  async handleSave(type) {
    let formData = this.props.formData

    let fromYear = formData.fromYear
    let toYear = formData.toYear
    let year = formData.year
    let fromMonth = formData.fromMonth
    let toMonth = formData.toMonth
    let calculate = formData.calculate

    let organizationIds =[];

    formData.organizations.forEach(x => {
      // console.log(x, 'asd')
      organizationIds.push(x.id);
    })

    let pasal = this.props.match.params.pasal

    if(pasal === '4a2') {
      pasal = '4A2'
    }

    let statuses = []

    if(formData.process) {
      statuses.push('PROCESS')
    }

    if(formData.waiting) {
      statuses.push('WAITING')
    }

    if(formData.finish) {
      statuses.push('FINISH')
    }

    let data = {
      ids:organizationIds,
      type,
      pasal,
      fromYear,
      toYear,
      year,
      fromMonth,
      toMonth,
      statuses,
      calculate
    }

    try {
      var summary = await this.service.api.tahunan(data)

      var filename = summary.headers['content-disposition']
      if(!filename) filename = res.headers['authorization']
      if(filename) filename = filename.split('filename=').pop();
      if(!filename) filename = "report.xlsx"

      download(summary.data, filename)
    } catch (e) {
      let message = 'File summary gagal dibuat atau tidak ada data.'

      iziToast.warning({
        title: 'Gagal',
        message: message
      })
    }
  }

  async initData() {
    this.props.initialize({organizations:[]});
  }

  tahunOptions = [];

  masaOptions = [
    {id:1, name: 'Januari'}, {id:2, name: 'Februari'}, {id:3, name: 'Maret'}, {id:4, name: 'April'}, {id:5, name: 'Mei'}, {id:6, name: 'Juni'},
    {id:7, name: 'Juli'}, {id:8, name: 'Agustus'}, {id:9, name: 'September'}, {id:10, name: 'Oktober'}, {id:11, name: 'November'}, {id:12, name: 'Desember'}
  ]

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    let selectedOrganizations = this.props.formData.organizations

    return (
      <div>

        <Button secondary raised primary onClick={() => this.handleSave('UNIT_21')} style={{marginRight: 16}} disabled={selectedOrganizations.length === 0}>Download</Button>

      </div>
    )
  }

  async handleMultiSelectOrganization() {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    this.props.table.selected.forEach((d, i)=> {
      var check = _.find(organizations, {id: d.id});
      if(!check) {
        organizations.push(d);
      }
    })

    this.props.change('organizations', organizations);
  }

  async handleDelete(i) {
    var formData = this.props.formData;
    var organizations = formData.organizations;
    organizations.splice(i, 1);
    this.props.change('organizations', organizations);
  }

  formView() {
    var tahun = new Date().getFullYear();
    var tahunOptions = [];

    for (let index = 0; index < 11; index++) {
      tahunOptions.push({
        id: tahun - index,
        name: tahun - index
      })
    }

    var formData                      = {};
    if (this.props.formData) formData = this.props.formData.values
    return (
      <div>

        <Card>
          <div className='md-grid'>
            {/* <Field
              className="md-cell md-cell--6"
              label     = 'Dari Masa'
              name      = 'fromMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            />
            <Field
              className="md-cell md-cell--6"
              label     = 'Sampai Masa'
              name      = 'toMonth'
              component = {Searchfield}
              options   = {this.masaOptions}
            /> */}

            <Field
              className="md-cell md-cell--12"
              label     = 'Tahun'
              name      = 'year'
              component = {Searchfield}
              options   = {tahunOptions}
            />

          </div>



          <div className="md-grid">
            <Button raised primary onClick={()=> {
              this.setState({showOrganizationPicker: true})
            }} >Multi Select Organization</Button>

            <DialogContainer
              id="simple-action-dialog"
              width={800}
              // height={586}
              contentStyle={{padding:0}}
              visible={this.state.showOrganizationPicker}
              onHide={()=> {
                this.setState({showOrganizationPicker: false})
              }}
              actions={[
                <Button flat onClick={async ()=> {
                  this.setState({showOrganizationPicker: false})
                }}>Close</Button>,
                <Button raised primary onClick={async ()=> {
                  await this.handleMultiSelectOrganization();
                  this.setState({showOrganizationPicker: false})
                }}>Confirm</Button>

              ]}
              // title="Change something?"
            >
              <OrganizationPicker />
            </DialogContainer>
            <List className="md-cell--12 md-paper md-paper--1">
              <Subheader primaryText={<div>Selected </div>} ></Subheader>
              {this.props.formData.organizations.map((d, i)=> {

                return <ListItem
                  key={i}
                  rightIcon={<FontIcon onClick={()=> this.handleDelete(i)}>delete</FontIcon>}
                  primaryText={d.name}
                  secondaryText={d.npwp}
                />

              })}
            </List>
          </div>
        </Card>

      </div>
    )
  }

}
