import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import IncomeTypeService from './IncomeType.service';

@reduxForm({form: 'IncomeTypeForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('IncomeTypeForm')(state)
}))
export default class IncomeTypeDialog extends DialogView {
  service     = IncomeTypeService

  constructor(prop) {
    super(prop);

    this.state = {
      ...this.state,
      showBp: true,
      showCode: true
    }
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.incomeType.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />

        <Field
          label         = {counterpart('entities.incomeType.alias')}
          name          = 'alias'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />

        <Field
          label         = {counterpart('entities.incomeType.kodeKop')}
          name          = 'kodeKop'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />

        <Field
          label         = {counterpart('entities.incomeType.pasal')}
          name          = 'pasal'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[
            {id: 'Pasal-21', name: 'Pasal 21'},
            {id: 'Pasal-23', name: 'Pasal 23'},
            {id: 'Pasal-23-Ebupot', name: 'Pasal 23 Ebupot'},
            {id: 'Pasal-4-Ayat-2', name: 'Pasal 4 (2)'}
          ]}
          validate      = {validation.required}
          onChange      = {async ()=> {
            await this.setState({showBp: false})
            // setTimeout(()=> {
            this.setState({showBp: true})
            // }, 800)

          }}
        />

        {this.state.showBp &&
        <Field
          label         = {counterpart('entities.incomeType.buktiPotong')}
          name          = 'buktiPotong'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {this.getOption('bpMap')}
          // validate      = {validation.required}
          onChange      = {async ()=> {
            await this.setState({showCode: false})
            // setTimeout(()=> {
            this.setState({showCode: true})
            // }, 800)

          }}
        />}

        {this.state.showCode &&
        <Field
          label         = {counterpart('entities.incomeType.code')}
          name          = 'code'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {this.getCodeOption()}
        />
        }
        <Field
          label         = {counterpart('entities.incomeType.kap')}
          name          = 'kap'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.incomeType.kjs')}
          name          = 'kjs'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.incomeType.rate')}
          name          = 'rate'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
          money         = {','}
        />
      </div>
    )
  }

  getOption() {
    var data = this.props.data;
    if(data && data.pasal) {
      if(data.pasal != '') return this.bpMap[data.pasal]
    }

    return []
  }

  getCodeOption() {
    var data = this.props.data;
    if(data && data.buktiPotong) {
      if(data.buktiPotong != '') return this.codeMap[data.buktiPotong]
    }

    return []
  }

  bpMap = {
    'Pasal-21': [{id: 'BP_21_F', name: 'BP 21 F'}, {id: 'BP_21_TF', name: 'BP 21 TF'}],
    'Pasal-23': [{id: 'BP_23', name: 'BP 23'}, {id: 'BP_26', name: 'BP 26'}],
    'Pasal-23-Ebupot': [{id: 'Pasal-23-Ebupot', name: 'BP 23 Ebupot'}],
    'Pasal-4-Ayat-2': [
      {id: 'HADIAH_UNDIAN', name: 'HADIAH UNDIAN'},
      {id: 'JASA_KONSTRUKSI', name: 'JASA KONSTRUKSI'},
      {id: 'PERSEWAAN_TANAH', name: 'PERSEWAAN TANAH'},
      {id: 'WP_PEREDARAN_TERTENTU', name: 'WP PEREDARAN TERTENTU'},
      {id: 'BUNGA_DEPOSITO', name: 'BUNGA DEPOSITO'},
      {id: 'JASA_KONSTRUKSI_SETOR_SENDIRI', name: 'JASA KONSTRUKSI SETOR SENDIRI'},
      {id: 'SEWA_TANAH_BANGUNAN_SETOR_SENDIRI', name: 'SEWA TANAH BANGUNAN SETOR SENDIRI'},
      {id: 'WP_MELAKUKAN_PENGALIHAN_HAK_ATAS_TANAH_BANGUNAN', name: 'WP MELAKUKAN PENGALIHAN HAK ATAS TANAH BANGUNAN'}
    ]
  }

  codeMap = {
    "BP_21_F": [{
      id: 'bp-21-f',
      name: 'bp-21-f'
    }],
    "BP_21_TF": [{
      id: 'bp-21-tf',
      name: 'bp-21-tf'
    }],
    "Pasal-23-Ebupot": [{
      id: 'Pasal-23-Ebupot',
      name: 'Pasal-23-Ebupot'
    }],
    'BP_23': [
      {id: "bp-23-01", name: "bp-23-01"},
      {id: "bp-23-02", name: "bp-23-02"},
      {id: "bp-23-03", name: "bp-23-03"},
      {id: "bp-23-04", name: "bp-23-04"},
      {id: "bp-23-05", name: "bp-23-05"},
      {id: "bp-23-06a", name: "bp-23-06a"},
      {id: "bp-23-06b", name: "bp-23-06b"},
      {id: "bp-23-06c", name: "bp-23-06c"},
      {id: "bp-23-06d", name: "bp-23-06d"},
    ],
    'BP_26': [{id: "bp-23-26-01", name: "bp-23-26-01"},
    {id: "bp-23-26-02", name: "bp-23-26-02"},
    {id: "bp-23-26-03", name: "bp-23-26-03"},
    {id: "bp-23-26-04", name: "bp-23-26-04"},
    {id: "bp-23-26-05", name: "bp-23-26-05"},
    {id: "bp-23-26-06", name: "bp-23-26-06"},
    {id: "bp-23-26-07", name: "bp-23-26-07"},
    {id: "bp-23-26-08", name: "bp-23-26-08"},
    {id: "bp-23-26-09", name: "bp-23-26-09"},
    {id: "bp-23-26-10", name: "bp-23-26-10"},
    {id: "bp-23-26-11", name: "bp-23-26-11"},
    {id: "bp-23-26-12", name: "bp-23-26-12"},
    {id: "bp-23-26-13", name: "bp-23-26-13"},
    ],
    'HADIAH_UNDIAN': [
      {id: "bp-4a2-hadiah-undian-01", name: "bp-4a2-hadiah-undian-01"},
      {id: "bp-4a2-hadiah-undian-02", name: "bp-4a2-hadiah-undian-02"},
      {id: "bp-4a2-hadiah-undian-03", name: "bp-4a2-hadiah-undian-03"},
      {id: "bp-4a2-hadiah-undian-04", name: "bp-4a2-hadiah-undian-04"},
      {id: "bp-4a2-hadiah-undian-05", name: "bp-4a2-hadiah-undian-05"},
      {id: "bp-4a2-hadiah-undian-06", name: "bp-4a2-hadiah-undian-06"},
    ],
    "JASA_KONSTRUKSI": [
      {id: "bp-4a2-jasa-konstruksi-01", name: "bp-4a2-jasa-konstruksi-01"},
      {id: "bp-4a2-jasa-konstruksi-02", name: "bp-4a2-jasa-konstruksi-02"},
      {id: "bp-4a2-jasa-konstruksi-03", name: "bp-4a2-jasa-konstruksi-03"},
      {id: "bp-4a2-jasa-konstruksi-04", name: "bp-4a2-jasa-konstruksi-04"},
      {id: "bp-4a2-jasa-konstruksi-04-01", name: "bp-4a2-jasa-konstruksi-04-01"},
      {id: "bp-4a2-jasa-konstruksi-04-02", name: "bp-4a2-jasa-konstruksi-04-02"},
      {id: "bp-4a2-jasa-konstruksi-05-01", name: "bp-4a2-jasa-konstruksi-05-01"},
      {id: "bp-4a2-jasa-konstruksi-05-02", name: "bp-4a2-jasa-konstruksi-05-02"},
      {id: "bp-4a2-persewaan-tanah", name: "bp-4a2-persewaan-tanah"},
      {id: "bp-4a2-wp-peredaran-tertentu", name: "bp-4a2-wp-peredaran-tertentu"}
    ],
    "PERSEWAAN_TANAH": [{id: "bp-4a2-persewaan-tanah-01", name: "bp-4a2-persewaan-tanah-01"}],
    "WP_PEREDARAN_TERTENTU": [{id: "bp-4a2-bruto-tertentu-01", name: "bp-4a2-bruto-tertentu-01"}],
    "BUNGA_DEPOSITO": [
      {id: "bp-4a2-bunga-deposito-01a", name: "bp-4a2-bunga-deposito-01a"},
      {id: "bp-4a2-bunga-deposito-01b", name: "bp-4a2-bunga-deposito-01b"},
      {id: "bp-4a2-bunga-deposito-01c", name: "bp-4a2-bunga-deposito-01c"},
      {id: "bp-4a2-bunga-deposito-02", name: "bp-4a2-bunga-deposito-02"},
      {id: "bp-4a2-bunga-deposito-03", name: "bp-4a2-bunga-deposito-03"},
      {id: "bp-4a2-bunga-deposito-04", name: "bp-4a2-bunga-deposito-04"},
      {id: "bp-4a2-bunga-deposito-05", name: "bp-4a2-bunga-deposito-05"},
      {id: "bp-4a2-bunga-deposito-06", name: "bp-4a2-bunga-deposito-06"}
    ],
    "JASA_KONSTRUKSI_SETOR_SENDIRI": [
      {id: "bp-4a2-jasa-konstruksi-setor-sendiri-01", name: "bp-4a2-jasa-konstruksi-setor-sendiri-01"},
      {id: "bp-4a2-jasa-konstruksi-setor-sendiri-02", name: "bp-4a2-jasa-konstruksi-setor-sendiri-02"},
      {id: "bp-4a2-jasa-konstruksi-setor-sendiri-03", name: "bp-4a2-jasa-konstruksi-setor-sendiri-03"},
    ],
    "SEWA_TANAH_BANGUNAN_SETOR_SENDIRI": [{id: "bp-4a2-sewa-tanah-bangunan-setor-sendiri-01", name: "bp-4a2-sewa-tanah-bangunan-setor-sendiri-01"}],

    "WP_MELAKUKAN_PENGALIHAN_HAK_ATAS_TANAH_BANGUNAN": [{id: "bp-4a2-wp-melakukan-hak-atas-tanah-bangunan", name: "bp-4a2-wp-melakukan-hak-atas-tanah-bangunan"}],
  }
}

