import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import LawanTransaksiService from './LawanTransaksi.service';

@reduxForm({form: 'LawanTransaksiForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('LawanTransaksiForm')(state)
}))
export default class LawanTransaksiDialog extends DialogView {
  service     = LawanTransaksiService

  initialData= {
    source: "Web"
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.lawanTransaksi.name')}
          name          = 'name'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.lawanTransaksi.npwp')}
          name          = 'npwp'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
          validate      = {validation.required}
        />
        <Field
          label         = 'NIK'
          name          = 'nik'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.lawanTransaksi.address')}
          name          = 'address'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.lawanTransaksi.phone')}
          name          = 'phone'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.lawanTransaksi.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />

        <Field
          label         = 'Provinsi'
          name          = 'provinsi'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = 'Kabupaten'
          name          = 'kabupaten'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = 'Kecamatan'
          name          = 'kecamatan'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = 'Kelurahan'
          name          = 'kelurahan'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
      </div>
    )
  }
}
