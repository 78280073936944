import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Earning.dialog.view';
import EarningService from './Earning.service';
import ListView from '../../../../../components/entity/listView';
import EarningDialog from './Earning.dialog';

@connect(EarningService.stateConnectSetting(), EarningService.actionConnectSetting())
export default class EarningView extends ListView {
  service     = EarningService
  FormDialog  = EarningDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.earning.name",                    value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.earning.alias",                   value: "alias"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.earning.componentType",           value: "componentType.name"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.earning.isDynamic",           value: "isDynamic", type: 'boolean'},
    {isSortable: false,  show: false,   isSearchable:true,  label: "entities.earning.hideSlipPrintOut",           value: "hideSlipPrintOut", type: 'boolean'},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.earning.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
