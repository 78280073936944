import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import SalarySlipService from './SalarySlip.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import _ from 'lodash'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import EmployeeService from '../Employee/Employee.service';

@reduxForm({form: 'SalarySlipForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('SalarySlipForm')(state),
  periode: state.auth.currentSPT
}))
export default class SalarySlipForm extends FormView {
  service     = SalarySlipService
  viewType    = 2;

  initialData = {
    salaryType: 'NORMAL',
    paymentStatus: 'PAID',
    "totalEarning": 0,
    "totalDeduction": 0,
    "total": 0,
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.earningMap = res.data.earnings.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.deductionMap = res.data.deductions.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.title')}
            name          = 'employee'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'parent'
            service       = {EmployeeService}
            viewField     = 'id'
            itemTemplate  = {function(d) {
              return {
                primaryText: d.basicInfo? d.basicInfo.name: '',
                secondaryText: d.basicInfo? d.basicInfo.npwp: '',
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />
          <Field
            label         = {counterpart('entities.salarySlip.salaryType')}
            name          = 'salaryType'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: 'NORMAL',
              name: 'NORMAL'
            }, {
              id: 'NORMAL',
              name: 'NORMAL'
            }]}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.salarySlip.paymentStatus')}
            name          = 'paymentStatus'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'PAID',
              name: 'PAID'
            }, {
              id: 'HOLD',
              name: 'HOLD'
            }]}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.salarySlip.paymentDate')}
            name          = 'paymentDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />
          <Field
            label         = {counterpart('entities.salarySlip.note')}
            name          = 'note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.salarySlip.earningDeduction.title')} />
        <Divider/>
        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.earning')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.salarySlip.earningDeduction.amount')}</TableColumn>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.note')}</TableColumn>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.delete')}</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>

          {Object.keys(this.props.data.earningMap).map((k, i) => {
            var d = this.props.data.earningMap[k];
            return <TableRow key={d.id}>
              <TableColumn>
                <Field
                  label         = {'_'}
                  name          = {'earningMap.'+d.id+'.earning'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  valueField    = 'parent'
                  options       = {this.state.earningType}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  label         = {'_'}
                  name          = {'earningMap.'+d.id+'.amount'}
                  className     = 'md-cell md-cell--12 md-text-right'
                  component     = {TextfieldMask}
                  money         = ','
                />
              </TableColumn>
              <TableColumn className='md-text-right'>
                <Field
                  label         = {'_'}
                  name          = {'earningMap.'+d.id+'.note'}
                  className     = 'md-cell md-cell--12'
                  component     = {Textfield}
                />
              </TableColumn>
              <TableColumn className='md-text-center'>
                <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteEarning(d.id)}>{counterpart('entities.salarySlip.earningDeduction.delete')}</Button>
              </TableColumn>
            </TableRow>
          })}

          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
            <TableColumn className='md-text-center'>
              <Button primary flat onClick={this.addEarning}>{counterpart('entities.salarySlip.earningDeduction.add')}</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
        </DataTable>

        <Divider/>
        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.deduction')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.salarySlip.earningDeduction.amount')}</TableColumn>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.note')}</TableColumn>
            <TableColumn className=' md-text-left'>{counterpart('entities.salarySlip.earningDeduction.delete')}</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.deductionMap).map((k, i) => {
            var d = this.props.data.deductionMap[k];
            return <TableRow key={d.id}>
              <TableColumn>
                <Field
                  label         = {'_'}
                  name          = {'deductionMap.'+d.id+'.deduction'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  valueField    = 'parent'
                  options       = {this.state.deductionType}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  label         = {'_'}
                  name          = {'deductionMap.'+d.id+'.amount'}
                  className     = 'md-cell md-cell--12 md-text-right'
                  component     = {TextfieldMask}
                  money         = ','
                />
              </TableColumn>
              <TableColumn className='md-text-right'>
                <Field
                  label         = {'_'}
                  name          = {'deductionMap.'+d.id+'.note'}
                  className     = 'md-cell md-cell--12'
                  component     = {Textfield}
                />
              </TableColumn>
              <TableColumn className='md-text-center'>
                <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteDeduction(d.id)}>{counterpart('entities.salarySlip.earningDeduction.delete')}</Button>
              </TableColumn>
            </TableRow>
          })}

          <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
            <TableColumn className='md-text-center'>
              <Button primary flat onClick={this.addDeduction}>{counterpart('entities.salarySlip.earningDeduction.add')}</Button>
            </TableColumn>
          </TableRow>
        </TableBody>
        </DataTable>
      </Card>
    </div>)
  }

  async beforeSave(value) {

    value.periode = this.props.periode.data

    value.earnings = Object.keys(value.earningMap).map((k, i) => {
      if(value.earningMap[k].id.search('NEW_DATA') >= 0) {
        delete value.earningMap[k].id
      }
      return value.earningMap[k]
    })

    value.deductions = Object.keys(value.deductionMap).map((k, i) => {
      if(value.deductionMap[k].id.search('NEW_DATA') >= 0) {
        delete value.deductionMap[k].id
      }
      return value.deductionMap[k]
    })

    return true;
  }

  addEarning = (e)=> {
    e.preventDefault()
    var earningMap = _.cloneDeep(this.props.data.earningMap)
    earningMap['NEW_DATA_'+Object.keys(earningMap).length] = {
      id: 'NEW_DATA_'+Object.keys(earningMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  deleteEarning = (id)=> {
    var earningMap = _.cloneDeep(this.props.data.earningMap)
    delete earningMap[id]

    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  addDeduction = (e)=> {
    e.preventDefault()
    var deductionMap = _.cloneDeep(this.props.data.deductionMap)
    deductionMap['NEW_DATA_'+Object.keys(deductionMap).length] = {
      id: 'NEW_DATA_'+Object.keys(deductionMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  deleteDeduction = (id)=> {
    var deductionMap = _.cloneDeep(this.props.data.deductionMap)
    delete deductionMap[id]

    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }
}
