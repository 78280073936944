import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import KelompokHartaService from './KelompokHarta.service';
import ListView from '../../../../components/entity/listView';
import KelompokHartaDialog from './KelompokHarta.dialog';

@connect(KelompokHartaService.stateConnectSetting(), KelompokHartaService.actionConnectSetting())
export default class KelompokHartaView extends ListView {
  service     = KelompokHartaService
  FormDialog  = KelompokHartaDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.kelompokHarta.code",          value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisHarta.title",            value: "jenisHarta.name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.kelompokHarta.name",          value: "name",   isDefaultSort:true},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.kelompokHarta.lamaManfaat",   value: "lamaManfaat",  type: "number"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.kelompokHarta.tarifGL",       value: "tarifGL",      type: "number"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.kelompokHarta.tarifSM",       value: "tarifSM",      type: "number"}
  ]
}
