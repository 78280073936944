import ApiService from '../../../../services/api.service';

class PenomoranBp4a2Service extends ApiService {
  name  = 'penomoranBp4a2';
  path  = 'master/penomoran-bp4a2';
  constructor() {
    super();
    this.init()
  }
}


export default new PenomoranBp4a2Service();
