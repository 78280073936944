import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import EmployeeService from './Employee.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import _ from 'lodash'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';

@reduxForm({form: 'EmployeeForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('EmployeeForm')(state)
}))
export default class EmployeeForm extends FormView {
  service     = EmployeeService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false
    },
    jobInfo: {
      "isMutation": false,
      "isJoining": false,
      "isRetiring": false,
    },
    bankInfo: {},
    otherInfo: {},
    earnings: [],
    deductions: [],
    earningMap: {},
    deductionMap: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.earningMap = res.data.earnings.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.deductionMap = res.data.deductions.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.company.title')}
            name          = 'companyInfo'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            valueField    = 'parent'
            service       = {CompanyService}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
          />
          <Field
            label         = {"Jenis Kelamin"}
            name          = 'basicInfo.gender'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            options       = {[{
              id: "MALE",
              name: "LAKI-LAKI"
            },{
              id: "FEMALE",
              name: "PEREMPUAN"
            }]}
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"Info Pajak"} />
        <Divider/>
        <div className='md-grid'>
        <Field
            label         = {"Status Pindahan"}
            name          = 'otherInfo.statusPindahan'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: '0',
              name: 'Normal'
            }, {
              id: '1',
              name: 'Dipindahkan ke cabang lain'
            }, {
              id: '2',
              name: 'Berhenti ke perusahaan lain/pensiun'
            }, {
              id: '3',
              name: 'Berhenti meninggal/pindah ke LN/dari LN'
            }, {
              id: '4',
              name: 'Pegawai baru'
            }, {
              id: '5',
              name: 'Pindahan'
            }]}
            onChange      = {(e, d)=> {
              switch (d) {
                case '0':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '1':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '2':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '3':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', false)
                  this.props.change('jobInfo.isRetiring', true)
                  break;
                case '4':
                  this.props.change('jobInfo.isMutation', false)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                case '5':
                  this.props.change('jobInfo.isMutation', true)
                  this.props.change('jobInfo.isJoining', true)
                  this.props.change('jobInfo.isRetiring', false)
                  break;
                default:
                  break;
              }
            }}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.taxType')}
            name          = 'otherInfo.taxType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'GROSS',
              name: 'GROSS'
            }, {
              id: 'GROSS UP',
              name: 'GROSS UP'

            }]}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'otherInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'code'
            service       = {ObjekPajakService}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.status')}
            name          = 'jobInfo.status'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'ACTIVE',
              name: 'ACTIVE'
            }, {
              id: 'LEFT',
              name: 'LEFT'
            }]}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.isMutation')}
            name          = 'jobInfo.isMutation'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.mutationDate')}
            name          = 'jobInfo.mutationDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.isJoining')}
            name          = 'jobInfo.isJoining'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.joinedDate')}
            name          = 'jobInfo.joinedDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.isRetiring')}
            name          = 'jobInfo.isRetiring'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.retireDate')}
            name          = 'jobInfo.retireDate'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
          />

          <Field
            label         = {counterpart('entities.employee.jobInfo.note')}
            name          = 'jobInfo.note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />

        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.bankInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.bankInfo.name')}
            name          = 'bankInfo.name'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.bankInfo.no')}
            name          = 'bankInfo.no'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
        </div>
      </Card>



      <br/>
    </div>)
  }

  earningDeductionView() {
    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.earningDeduction.title')} />
      <Divider/>
      <DataTable plain>
      <TableHeader>
        <TableRow>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.earning')}</TableColumn>
          <TableColumn className=' md-text-right'>{counterpart('entities.employee.earningDeduction.amount')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.note')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.delete')}</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>

        {Object.keys(this.props.data.earningMap).map((k, i) => {
          var d = this.props.data.earningMap[k];
          return <TableRow key={d.id}>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.earning'}
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'parent'
                options       = {this.state.earningType}
              />
            </TableColumn>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.amount'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                money         = ','
              />
            </TableColumn>
            <TableColumn className='md-text-right'>
              <Field
                label         = {'_'}
                name          = {'earningMap.'+d.id+'.note'}
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
            </TableColumn>
            <TableColumn className='md-text-center'>
              <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteEarning(d.id)}>{counterpart('entities.employee.earningDeduction.delete')}</Button>
            </TableColumn>
          </TableRow>
        })}

        <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn className='md-text-center'>
            <Button primary flat onClick={this.addEarning}>{counterpart('entities.employee.earningDeduction.add')}</Button>
          </TableColumn>
        </TableRow>
      </TableBody>
      </DataTable>

      <Divider/>
      <DataTable plain>
      <TableHeader>
        <TableRow>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.deduction')}</TableColumn>
          <TableColumn className=' md-text-right'>{counterpart('entities.employee.earningDeduction.amount')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.note')}</TableColumn>
          <TableColumn className=' md-text-left'>{counterpart('entities.employee.earningDeduction.delete')}</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.keys(this.props.data.deductionMap).map((k, i) => {
          var d = this.props.data.deductionMap[k];
          return <TableRow key={d.id}>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.deduction'}
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                valueField    = 'parent'
                options       = {this.state.deductionType}
              />
            </TableColumn>
            <TableColumn>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.amount'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                money         = ','
              />
            </TableColumn>
            <TableColumn className='md-text-right'>
              <Field
                label         = {'_'}
                name          = {'deductionMap.'+d.id+'.note'}
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
              />
            </TableColumn>
            <TableColumn className='md-text-center'>
              <Button style={{margin: 0}} secondary flat onClick={()=> this.deleteDeduction(d.id)}>{counterpart('entities.employee.earningDeduction.delete')}</Button>
            </TableColumn>
          </TableRow>
        })}

        <TableRow key={'d'} style={{backgroundColor: '#EEE'}}>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn className='md-text-center'>
            <Button primary flat onClick={this.addDeduction}>{counterpart('entities.employee.earningDeduction.add')}</Button>
          </TableColumn>
        </TableRow>
      </TableBody>
      </DataTable>
    </Card>
  }

  async beforeSave(value) {

    value.earnings = Object.keys(value.earningMap).map((k, i) => {
      if(value.earningMap[k].id.search('NEW_DATA') >= 0) {
        delete value.earningMap[k].id
      }
      return value.earningMap[k]
    })

    value.deductions = Object.keys(value.deductionMap).map((k, i) => {
      if(value.deductionMap[k].id.search('NEW_DATA') >= 0) {
        delete value.deductionMap[k].id
      }
      return value.deductionMap[k]
    })

    return true;
  }

  addEarning = (e)=> {
    e.preventDefault()
    var earningMap = _.cloneDeep(this.props.data.earningMap)
    earningMap['NEW_DATA_'+Object.keys(earningMap).length] = {
      id: 'NEW_DATA_'+Object.keys(earningMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  deleteEarning = (id)=> {
    var earningMap = _.cloneDeep(this.props.data.earningMap)
    delete earningMap[id]

    this.props.dispatch(this.props.change('earningMap', earningMap))
  }

  addDeduction = (e)=> {
    e.preventDefault()
    var deductionMap = _.cloneDeep(this.props.data.deductionMap)
    deductionMap['NEW_DATA_'+Object.keys(deductionMap).length] = {
      id: 'NEW_DATA_'+Object.keys(deductionMap).length,
      amount: 0,
      isEncrypted: false
    }
    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }

  deleteDeduction = (id)=> {
    var deductionMap = _.cloneDeep(this.props.data.deductionMap)
    delete deductionMap[id]

    this.props.dispatch(this.props.change('deductionMap', deductionMap))
  }
}
