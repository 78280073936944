import React, { Component } from 'react';
import { connect } from 'react-redux';
import env from 'env';
// import FormDialog from './ItemType.dialog.view';
import CbsService from './Cbs.service';
import ListView from '../../../../components/entity/listView';
import CbsDialog from './Cbs.dialog';
import {CbsImportDialog} from './Cbs.import.form';
import DefaultView from '../../../../components/entity/default.view';
import { permission } from '../../../../config/constant';

@connect((state) => ({
  ...CbsService.stateConnectSetting()(state),
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), CbsService.actionConnectSetting())
export default class CbsView extends DefaultView {
  service     = CbsService
  FormDialog  = CbsDialog

  approvalSettingName     = 'Cbs'
  checkerPermissionName   = permission.MelakukanCheckCbs;
  approvalPermissionName  = permission.MelakukanApproveCbs;

  async beforeFetch(params) {
    params.status=["draft", "checked", "approved", "reject", "TEST_STATUS", "TEST_STATUS_REFERENCE"];
  }

  columns=[
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.officeCode",       value: "officeCode"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.currencyCode",     value: "currencyCode"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.nomorIA",          value: "nomorIA"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.date",             value: "date",          },
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.cbs.masa",             value: "masa"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.cbs.tahun",            value: "tahun"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.dk",               value: "dk"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.jumlahMutasi",     value: "jumlahMutasi",  type: "number"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.archiveNo",        value: "archiveNo"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.cbs.description",      value: "description"}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Masa'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Tahun'
          name='year'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.setState({showImportDialog: true})
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
  ]


  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          <CbsImportDialog
            height={400}
            visible={this.state.showImportDialog}
            add={this.props.tableActions.addDataItem}
            formData={{}}
            onSuccess={()=> {}}
            onClose={() => this.setState({showImportDialog:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showImportDialog: false

    }
  }
}
