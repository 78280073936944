import ApiService from '../../../../services/api.service';

class JenisHartaService extends ApiService {
  name  = 'jenisHarta';
  path  = 'master/jenis-harta';
  constructor() {
    super();
    this.init()
  }
}


export default new JenisHartaService();
