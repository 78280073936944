import ApiService from '../../../../services/api.service';

class CoaDaftarNominatifBiayaPromosiService extends ApiService {
  name  = 'coaDaftarNominatifBiayaPromosi';
  path  = 'master/coa-daftar-nominatif-biaya-promosi';
  constructor() {
    super();
    this.init()
  }
}


export default new CoaDaftarNominatifBiayaPromosiService();
