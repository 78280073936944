import ApiService from '../../../../services/api.service';

class CurrencyService extends ApiService {
  name  = 'currency';
  path  = 'master/currency';
  constructor() {
    super();
    this.init()
  }
}


export default new CurrencyService();
