import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemUnit.dialog.view';
import ItemUnitService from './ItemUnit.service';
import ListView from '../../../../components/entity/listView';
import ItemUnitDialog from './ItemUnit.dialog';

@connect(ItemUnitService.stateConnectSetting(), ItemUnitService.actionConnectSetting())
export default class ItemUnitView extends ListView {
  service     = ItemUnitService
  FormDialog  = ItemUnitDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.itemUnit.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.itemUnit.symbol",                  value: "symbol",                  className: "mpk-font-size-S"}
  ]
}
