import ApiService from '../../../../services/api.service';

class KoreksiFiskalSettingService extends ApiService {
  name  = 'koreksiFiskalSetting';
  path  = 'master/koreksi-fiskal-setting';
  constructor() {
    super();
    this.init()
  }

  api= {
    update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/${this.path}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },
  }
}


export default new KoreksiFiskalSettingService();
