import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import Pph25Service from './Pph25.service';
import CoaService from '../../Admin/Coa/Coa.service';

@reduxForm({form: 'Pph25Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('Pph25Form')(state),
  kp: state.auth.currentSPT
}))
export default class Pph25Form extends FormView {
  service     = Pph25Service

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.pph25.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <Field
          label         = {counterpart('entities.pph25.tahun')}
          name          = 'tahun.tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          disabled
        />
        <Field
          label         = {counterpart('entities.pph25.penghasilanNetto')}
          name          = 'penghasilanNetto'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
        />
        <Field
          label         = {"Penghasilan Netto sejak awal tahun pajak sampai dengan masa pajak yang dilaporkan"}
          name          = 'netoSampaiLapor'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
          disabled
        />
        <Field
          label         = {counterpart('entities.pph25.pph')}
          name          = 'pph'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          money         = {','}
          disabled
        />
        <Field
          label         = {counterpart('entities.pph25.pphPasal22')}
          name          = 'pphPasal22'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
        />
        <Field
          label         = {"PPh Pasal 22 sejak awal Tahun Pajak sampai dengan Masa Pajak yang dilaporkan "}
          name          = 'pph22SampaiLapor'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
          disabled
        />
        <Field
          label         = {counterpart('entities.pph25.pphPasal25Angsuran')}
          name          = 'pphPasal25Angsuran'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
          disabled
        />
        <Field
          label         = {counterpart('entities.pph25.angsuran')}
          name          = 'angsuran'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money         = {','}
          disabled
        />

        <Field
          label         = {counterpart('entities.pph25.source')}
          name          = 'source'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          money         = {','}
          disabled
        />


      </div>
    )
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord ='Hitung dan Simpan';
    if(this.props.match.params.sptId == 'new') saveWord = 'Hitung dan Simpan';

    return (
      <div>
        <Button secondary flat onClick={()=> {this.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      var initialData = {
        tahun: this.props.kp.data,
        penghasilanNetto: 0,
        pphPasal22: 0
      }
      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id, this.props.kp.data.id);
      this.props.initialize(res.data);
    }
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          res = await this.service.api.save(value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        if(value.id) {
          iziToast.success({
            title: 'Success',
            message: 'Data telah di hitung dan disimpan'
          })
          this.props.initData()
          // this.props.history.push(path);

        } else {
          this.props.history.push(path+"/"+res.data.id)
          iziToast.success({
            title: 'Success',
            message: 'Data telah di hitung dan disimpan'
          })

          this.initData()
        }


      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  goBack() {
    var pathArray = this.props.location.pathname.split('/');
    var path = '';
    pathArray.forEach((d, i)=> {
      if(pathArray.length-1 != i) {
        path = path+d;
        if(i != pathArray.length-2) path = path+'/'
      }
    })

    this.props.history.push(path)
  }

}
