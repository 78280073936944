import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import KoreksiFiskalBulanService from './KoreksiFiskalBulan.service';
import CoaService from '../../Admin/Coa/Coa.service';
import KoreksiFiskalSettingService from '../../Admin/KoreksiFiskalSetting/KoreksiFiskalSetting.service';
import { KoreksiFiskalValue } from '../../../../config/constant';
import utilsService from '../../../../services/utils.service';

@reduxForm({form: 'KoreksiFiskalBulanForm', destroyOnUnmount: true, initialValues: {
  content: [],
  contentMap: {}
}})
@connect((state) => ({
  formData: getFormValues('KoreksiFiskalBulanForm')(state),
  kp: state.auth.currentSPT
}))
export default class KoreksiFiskalBulanForm extends FormView {
  service     = KoreksiFiskalBulanService
  viewType    = 2

  formView() {
    return (
      <div>
         <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.koreksiFiskalBulan.masa')}
              name          = 'masa'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              validate      = {validation.required}
              options       = {[
                {id: '1', name: 'Januari'},
                {id: '2', name: 'Februari'},
                {id: '3', name: 'Maret'},
                {id: '4', name: 'April'},
                {id: '5', name: 'Mei'},
                {id: '6', name: 'Juni'},
                {id: '7', name: 'Juli'},
                {id: '8', name: 'Agustus'},
                {id: '9', name: 'September'},
                {id: '10', name: 'Oktober'},
                {id: '11', name: 'November'},
                {id: '12', name: 'Desember'}
              ]}
            />
            <Field
              label         = {counterpart('entities.koreksiFiskalBulan.tahun')}
              name          = 'tahun.tahun'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
              disabled
            />
          </div>
        </Card>

        <br/>
        {this.props.match.params.id != 'new' &&
        <Card>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>Nama Akun</TableColumn>
                <TableColumn>Laba Rugi Komersial</TableColumn>
                <TableColumn>Koreksi Positif</TableColumn>
                <TableColumn>Koreksi Negatif</TableColumn>
                <TableColumn>Laba Rugi Fiskal</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(this.props.formData.contentMap).map((key, i) => {
                return this.VRow(this.props.formData.contentMap[key], i)
              })}

              {(this.props.formData.masa === 12 || this.props.formData.masa === '12') &&
                <React.Fragment>
                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.totalLabaRugiFiskal')}</TableColumn>
                    <TableColumn className='md-text-right'>{utilsService.number.format2(this.props.formData.footer.totalLabaRugiFiskal)}</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.pembulatanLabaRugiFiskal')}</TableColumn>
                    <TableColumn className='md-text-right'>{utilsService.number.format2(this.props.formData.footer.pembulatanLabaRugiFiskal)}</TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>Perhitungan PPh Badan Terutang (Income Tax)</TableColumn>
                    <TableColumn className='md-text-right'>{utilsService.number.format2(this.props.formData.footer.perhitunganPPhBadanJanSampaiDes)}</TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>Pengembalian/Pengurangan Kredit pajak LN (psl 24) yang telah di perhitungkan tahun lalu </TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.pengembalianKreditPajakTahunLalu'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('pengembalianKreditPajakTahunLalu', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>PPh ditanggung Pemerintah (DTP)</TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.dtp'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('dtp', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>Kredi pajak dalam negeri</TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.kreditPajakDN'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('kreditPajakDN', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>Kredit pajak luar negeri</TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.kreditPajakLN'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('kreditPajakLN', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>PPh pasal 25</TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.pphPasal25'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('pphPasal25', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>STP PPh pasal 25 (hanya Pokok saja)</TableColumn>
                    <TableColumn className='md-text-right'>
                      <Field
                        name          = 'footer.stpPasal25'
                        component     = {TextfieldMask}
                        className     = 'md-text-right'
                        money         = {','}
                        onChange      = {(e, v)=> {this.handleTotalAngsuranChange('stpPasal25', v)}}
                      />
                    </TableColumn>
                  </TableRow>

                  {/* <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.angsuranPPhBadanJanSampaiSept')}</TableColumn>
                    <TableColumn className='md-text-right'><Field
                      label         = {''}
                      name          = 'footer.angsuranPPhBadanJanSampaiSept'
                      className     = 'md-text-right'
                      component     = {TextfieldMask}
                      money         = ","
                      onChange      = {(e, v)=> {this.handleTotalAngsuranChange('angsuranPPhBadanJanSampaiSept', v)}}
                      // validate      = {validation.required}
                      // disabled
                    /></TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.angsuranPPhBadanOktSampaiDes')}</TableColumn>
                    <TableColumn className='md-text-right'><Field
                      label         = {''}
                      name          = 'footer.angsuranPPhBadanOktSampaiDes'
                      className     = 'md-text-right'
                      component     = {TextfieldMask}
                      money         = ","
                      onChange      = {(e, v)=> {this.handleTotalAngsuranChange('angsuranPPhBadanOktSampaiDes', v)}}
                      // validate      = {validation.required}
                      // disabled
                    /></TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.totalAngsuran')}</TableColumn>
                    <TableColumn className='md-text-right'>{utilsService.number.format2(this.props.formData.footer.totalAngsuran)}</TableColumn>
                  </TableRow> */}
                  <TableRow>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn className='md-text-right'>{counterpart.translate('entities.koreksiFiskalBulan.lbAtauKb')}</TableColumn>
                    <TableColumn className='md-text-right'>{utilsService.number.format2(this.props.formData.footer.lbAtauKb)}</TableColumn>
                  </TableRow>
                </React.Fragment>
              }
            </TableBody>
          </DataTable>
        </Card>
        }
      </div>
    )
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: []
    }
  }

  VRow(v, i, parent) {
    return <React.Fragment>
      <TableRow key={i}>
        <TableColumn>{Array.from(Array(v.level-1)).map((_, i) => ( <span>&ensp;&ensp;&ensp;</span>)) } {v.name}</TableColumn>
        <TableColumn>
          {v.isLabaRugiKomersialEditable && <Field
            // label         = {counterpart('entities.koreksiFiskalSetting.valueLabaRugiKomersial')}
            name          = {v.pids+''+v.id+'.labaRugiKomersial'}
            component     = {TextfieldMask}
            // validate      = {validation.required}
            className     = 'md-text-right'
            disabled      = {!v.isLabaRugiKomersialEditable}
            money         = {','}
            onChange      = {(e, nilai) => this.handleInputChange('labaRugiKomersial', nilai, v, parent)}
          />}
          {(!v.isLabaRugiKomersialEditable && v.valueLabaRugiKomersial != KoreksiFiskalValue.TidakAdaNilai) && <div className='md-text-right'>{utilsService.number.format2(v.labaRugiKomersial)}</div>}
        </TableColumn>
        <TableColumn>
          {v.isKoreksiPositifEditable && <Field
            // label         = {counterpart('entities.koreksiFiskalSetting.valueKoreksiPositif')}
            name          = {v.pids+''+v.id+'.koreksiPositif'}
            component     = {TextfieldMask}
            // validate      = {validation.required}
            className     = 'md-text-right'
            disabled      = {!v.isKoreksiPositifEditable}
            money         = {','}
            onChange      = {(e, nilai) => this.handleInputChange('koreksiPositif', nilai, v, parent)}
          />}
          {(!v.isKoreksiPositifEditable && v.valueKoreksiPositif != KoreksiFiskalValue.TidakAdaNilai)  && <div className='md-text-right'>{utilsService.number.format2(v.koreksiPositif)}</div>}
        </TableColumn>
        <TableColumn>
          {v.isKoreksiNegatifEditable && <Field
            // label         = {counterpart('entities.koreksiFiskalSetting.valueKoreksiNegatif')}
            name          = {v.pids+''+v.id+'.koreksiNegatif'}
            component     = {TextfieldMask}
            // validate      = {validation.required}
            className     = 'md-text-right'
            disabled      = {!v.isKoreksiNegatifEditable}
            money         = {','}
            onChange      = {(e, nilai) => this.handleInputChange('koreksiNegatif', nilai, v, parent)}
          />}
          {(!v.isKoreksiNegatifEditable && v.valueKoreksiNegatif != KoreksiFiskalValue.TidakAdaNilai) && <div className='md-text-right'>{utilsService.number.format2(v.koreksiNegatif)}</div>}
        </TableColumn>
        <TableColumn>
          {v.isLabaRugiFiskalEditable && <Field
            // label         = {counterpart('entities.koreksiFiskalSetting.valueLabaRugiFiskal')}
            name          = {v.pids+''+v.id+'.labaRugiFiskal'}
            component     = {TextfieldMask}
            // validate      = {validation.required}
            className     = 'md-text-right'
            disabled      = {!v.isLabaRugiFiskalEditable}
            money         = {','}
            onChange      = {(e, nilai) => this.handleInputChange('labaRugiFiskal', nilai, v, parent)}
            disabled
          />}

          {(!v.isLabaRugiFiskalEditable && v.valueLabaRugiFiskal != KoreksiFiskalValue.TidakAdaNilai) && <div className='md-text-right'>{utilsService.number.format2(v.labaRugiFiskal)}</div>}
        </TableColumn>
      </TableRow>
      {Object.keys(v.childrenMap).length > 0 &&
        Object.keys(v.childrenMap).map((key, i) => (this.VRow(v.childrenMap[key], i, v)))
      }
    </React.Fragment>
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      var initialData = {
        tahun: this.props.kp.data,
        content: []
      }

      var res = await KoreksiFiskalSettingService.api.find({
        page: 1,
        size: 10000,
        sortBy: 'seq',
        sort: 1
      }, this.props.dispatch);
      var data = res.data.data;
      initialData.content = data;
      initialData.contentMap = this.deSerialize(data, 'contentMap.');

      console.log(initialData, 'sadasd')

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id, this.props.kp.data.id);
      res.data.contentMap = this.deSerialize(res.data.content, 'contentMap.')
      this.props.initialize(res.data);
    }
  }


  @utilsService.Debounce(1000)
  async handleInputChange(field, value, o, parent) {
    o = _.cloneDeep(o);
    if(parent) {
      if(parent['value'+this.capitalizeFirstLetter(field)] === "Penjumlahan") {

        var total = Object.keys(parent.childrenMap).reduce((pv, key) => {
          var nv = parent.childrenMap[key][field];
          if(o.id == parent.childrenMap[key].id) nv = value;

          pv += nv;
          return pv

        }, 0)
        this.props.change(parent.pids+''+parent.id+'.'+field, total)
      }
    }

    if(o.valueLabaRugiFiskal == 'Penjumlahan Koreksi Positif dan Negatif') {
      o[field] = value;
      var labaRugiFiskal = o.koreksiPositif-o.koreksiNegatif;
      await this.props.change(o.pids+''+o.id+'.labaRugiFiskal', labaRugiFiskal)

      this.handleCalculateTotal()
    } else if(o.valueLabaRugiFiskal == 'Laba Rugi Komersial Ditambah Koreksi Positif Dikurangi Koreksi Negatif') {
      o[field] = value;
      var labaRugiFiskal = o.labaRugiKomersial+o.koreksiPositif-o.koreksiNegatif;
      await this.props.change(o.pids+''+o.id+'.labaRugiFiskal', labaRugiFiskal)
    }

    // if(field == 'koreksiPositif' || field == 'koreksiNegatif') {
    //   o[field] = value;
    //   var labaRugiFiskal = o.koreksiPositif-o.koreksiNegatif;
    //   await this.props.change(o.pids+''+o.id+'.labaRugiFiskal', labaRugiFiskal)

    //   this.handleCalculateTotal()
    // } else {
    //   await this.props.change(o.pids+''+o.id+'.labaRugiFiskal', value)
    //   this.handleCalculateTotal()
    // }
  }

  @utilsService.Debounce(1000)
  async handleTotalAngsuranChange(field, value) {
    var footer = this.props.formData.footer;
    if(!footer) footer = {}
    footer[field] = value;

    var angsuranPPhBadanJanSampaiSept = utilsService.number.get(footer.angsuranPPhBadanJanSampaiSept);
    var angsuranPPhBadanOktSampaiDes = utilsService.number.get(footer.angsuranPPhBadanOktSampaiDes);

    await this.props.change('footer.totalAngsuran', angsuranPPhBadanOktSampaiDes+angsuranPPhBadanJanSampaiSept);
    await this.props.change('footer.'+field, value)
    this.handleCalculateTotal()

  }

  handleCalculateTotal() {
    var ct = this.props.formData.contentMap;
    var total = this.childCalculate(ct, 0);
    var pembulatanLabaRugiFiskal = Math.floor(total/1000)*1000;
    var perhitunganPPhBadanJanSampaiDes = 25/100*pembulatanLabaRugiFiskal;
    var totalAngsuran = this.props.formData.footer.totalAngsuran;

    var perhitunganPPhBadanTerutang            = utilsService.number.get(this.props.formData.footer.perhitunganPPhBadanTerutang);
    var pengembalianKreditPajakTahunLalu       = utilsService.number.get(this.props.formData.footer.pengembalianKreditPajakTahunLalu);
    var dtp                                    = utilsService.number.get(this.props.formData.footer.dtp);
    var kreditPajakDN                          = utilsService.number.get(this.props.formData.footer.kreditPajakDN);
    var kreditPajakLN                          = utilsService.number.get(this.props.formData.footer.kreditPajakLN);
    var pphPasal25                             = utilsService.number.get(this.props.formData.footer.pphPasal25);
    var stpPasal25                             = utilsService.number.get(this.props.formData.footer.stpPasal25);

    if(!totalAngsuran) totalAngsuran = 0;
    var lbAtauKb = perhitunganPPhBadanJanSampaiDes+pengembalianKreditPajakTahunLalu-dtp-kreditPajakDN-kreditPajakLN-pphPasal25-stpPasal25;

    this.props.change('footer.totalLabaRugiFiskal', total)
    this.props.change('footer.pembulatanLabaRugiFiskal', pembulatanLabaRugiFiskal)
    this.props.change('footer.perhitunganPPhBadanJanSampaiDes', perhitunganPPhBadanJanSampaiDes)
    this.props.change('footer.lbAtauKb', lbAtauKb)

  }

  childCalculate(childrenMap, total) {
    Object.keys(childrenMap).forEach((k, i) => {
      var d = childrenMap[k];
      if(!d.hasChild) {
        total += parseInt(d.labaRugiFiskal)
      } else {
        total += this.childCalculate(d.childrenMap, 0)
      }
    })

    return total;
  }

  async beforeSave(value) {
    var v = _.cloneDeep(value);
    if(this.props.match.params.id != 'new') {
      var content = [];
      v.content = this.serialize(v.contentMap, !v.id)
    } else {
      delete v.content
    }

    console.log(v, 'asdasd')
    return v;
  }

  deSerialize(array, parentIds) {

    array.sort((a, b) => a.seq - b.seq)
    var map = array.reduce((pv, cv) => {
      cv.childrenMap = {};
      cv.pids = parentIds;

      if(cv.children.length > 0) {
        cv.childrenMap = this.deSerialize(cv.children, parentIds+''+cv.id+'.childrenMap.');
      }
      pv[cv.id] = cv;

      return pv
    }, {})

    return map
  }

  serialize(arrayMap, deleteId) {
    var contents = [];
    Object.keys(arrayMap).forEach((k, i)=> {
      var v = arrayMap[k];
      if(deleteId) delete v.id

      if(v.childrenMap) {
        v.children = this.serialize(v.childrenMap, deleteId)
      }

      contents.push(v);
    })

    return contents;
  }


  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


}
