import ApiService from '../../../../services/api.service';

class ItemService extends ApiService {
  name  = 'item';
  path  = 'master/item';
  constructor() {
    super();
    this.init()
  }

  api = {
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    }
  }
}


export default new ItemService();
