/**
 * Created by dwiargo on 11/29/17.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EditProfileForm from './EditProfile/EditProfile.form';
import EditPasswordForm from './EditPassword/EditPassword.form';
import PPhSummary from './Payrol/Process/Periode/PPh.summary';

export default (actions, global) => (
  <Switch>
    {/* Admin */}
    <Route path="/company/:id" component={require('./Admin/Company/Company.form').default}/>
    <Route path="/company" component={require('./Admin/Company/Company.view').default}/>
    <Route path="/lawanTransaksi" component={require('./Admin/LawanTransaksi/LawanTransaksi.view').default}/>
    <Route path="/unit" component={require('./Admin/ItemUnit/ItemUnit.view').default}/>
    <Route path="/itemType" component={require('./Admin/ItemType/ItemType.view').default}/>
    <Route path="/coa" component={require('./Admin/Coa/Coa.view').default}/>
    <Route path="/incomeType" component={require('./Admin/IncomeType/IncomeType.view').default}/>
    <Route path="/periodUnit" component={require('./Admin/PeriodUnit/PeriodUnit.view').default}/>
    <Route path="/currency" component={require('./Admin/Currency/Currency.view').default}/>
    <Route path="/user/:id" component={require('./Admin/User/User.form').default}/>
    <Route path="/user-role/:id" component={require('./Admin/User/UserRole.form').default}/>
    <Route path="/user" component={require('./Admin/User/User.view').default}/>
    <Route path="/item/:id" component={require('./Admin/Item/Item.form').default}/>
    <Route path="/item" component={require('./Admin/Item/Item.view').default}/>
    <Route path="/approvalSetting" component={require('./Admin/ApprovalSetting/ApprovalSetting.view').default}/>
    <Route path="/jenisHarta" component={require('./Admin/JenisHarta/JenisHarta.view').default}/>
    <Route path="/kelompokHarta" component={require('./Admin/KelompokHarta/KelompokHarta.view').default}/>
    <Route path="/harta" component={require('./Admin/Harta/Harta.view').default}/>
    <Route path="/jenisUsaha" component={require('./Admin/JenisUsaha/JenisUsaha.view').default}/>
    <Route path="/metodePenyusutanKomersial" component={require('./Admin/MetodePenyusutanKomersial/MetodePenyusutanKomersial.view').default}/>
    <Route path="/metodePenyusutanFiskal" component={require('./Admin/MetodePenyusutanFiskal/MetodePenyusutanFiskal.view').default}/>
    <Route path="/role/:id" component={require('./Admin/Role/Role.form').default}/>
    <Route path="/role" component={require('./Admin/Role/Role.view').default}/>
    <Route path="/penyusutanAmortisasi/:id" component={require('./Admin/PenyusutanAmortisasi/PenyusutanAmortisasi.form').default}/>
    <Route path="/penyusutanAmortisasi" component={require('./Admin/PenyusutanAmortisasi/PenyusutanAmortisasi.main.view').default}/>
    <Route path="/import-penyusutanAmortisasi" component={require('./Admin/PenyusutanAmortisasi/PenyusutanAmortisasi.import.form').default}/>
    <Route path="/coaDaftarNominatifBiayaPromosi" component={require('./Admin/CoaDaftarNominatifBiayaPromosi/CoaDaftarNominatifBiayaPromosi.view').default}/>
    <Route path="/coaDaftarNominatifBiayaEntertain" component={require('./Admin/CoaDaftarNominatifBiayaEntertain/CoaDaftarNominatifBiayaEntertain.view').default}/>
    <Route path="/coa-bunga-deposito" component={require('./Admin/CoaBungaDeposito/CoaBungaDeposito.view').default}/>
    <Route path="/perjanjian/:id" component={require('./Pembayaran/Perjanjian/Perjanjian.form').default}/>
    <Route path="/perjanjian" component={require('./Pembayaran/Perjanjian/Perjanjian.view').default}/>
    <Route path="/receipt/:id" component={require('./Pembayaran/Receipt/Receipt.form').default}/>
    <Route path="/receipt" component={require('./Pembayaran/Receipt/Receipt.view').default}/>
    <Route path="/cbs" component={require('./Cbs/Cbs/Cbs.view').default}/>
    <Route path="/import-cbs" component={require('./Cbs/Cbs/Cbs.import.form').default}/>
    <Route path="/invoice/:id" component={require('./Pembayaran/Invoice/Invoice.form').default}/>
    <Route path="/invoice" component={require('./Pembayaran/Invoice/Invoice.view').default}/>
    <Route path="/biayaPromosi/:id" component={require('./KoreksiFiskal/BiayaPromosi/BiayaPromosi.form').default}/>
    <Route path="/biayaPromosi" component={require('./KoreksiFiskal/BiayaPromosi/BiayaPromosi.view').default}/>
    <Route path="/biayaEntertain/:id" component={require('./KoreksiFiskal/BiayaEntertain/BiayaEntertain.form').default}/>
    <Route path="/biayaEntertain" component={require('./KoreksiFiskal/BiayaEntertain/BiayaEntertain.view').default}/>
    <Route path="/archiveSPT23/:id" component={require('./Archive/ArchiveBp23/ArchiveBp23.form').default}/>
    <Route path="/archiveSPT23" component={require('./Archive/ArchiveBp23/ArchiveBp23.view').default}/>
    <Route path="/archiveSPT21/:id" component={require('./Archive/ArchiveBp21/ArchiveBp21.form').default}/>
    <Route path="/archiveSPT21" component={require('./Archive/ArchiveBp21/ArchiveBp21.view').default}/>
    <Route path="/archiveSPT4a2/:id" component={require('./Archive/ArchiveBp4a2/ArchiveBp4a2.form').default}/>
    <Route path="/archiveSPT4a2" component={require('./Archive/ArchiveBp4a2/ArchiveBp4a2.view').default}/>
    <Route path="/archiveSPT15" component={require('./Archive/ArchiveBp15/ArchiveBp15.view').default}/>
    <Route path="/tarif" component={require('./Admin/Tarif/Tarif.view').default}/>
    <Route path="/documentType" component={require('./Admin/DocumentType/DocumentType.view').default}/>
    <Route path="/logImport" component={require('./LogImport/LogImport.view').default}/>
    <Route path="/logExport" component={require('./LogExport/LogExport.view').default}/>
    <Route path="/logProcess" component={require('./LogProcess/LogProcess.view').default}/>
    <Route path="/logSummary" component={require('./LogSummary/LogSummary.view').default}/>
    <Route path="/logSummary-bp-ebupot" component={require('./LogSummaryBpEbupot/LogSummaryBpEbupot.view').default}/>
    <Route path="/penomoranBp4a2" component={require('./Admin/PenomoranBp4a2/PenomoranBp4a2.form').default}/>
    <Route path="/penomoranBp23" component={require('./Admin/PenomoranBp23/PenomoranBp23.form').default}/>
    <Route path="/appSchedule" component={require('./Admin/AppSchedule/AppSchedule.view').default}/>

    {/* Koreksi Fiskal */}
    <Route path="/koreksiFiskalSetting" exact component={require('./Admin/KoreksiFiskalSetting/KoreksiFiskalSetting.form').default}/>
    <Route path="/koreksiFiskal" exact component={require('./KoreksiFiskal/KoreksiFiskalTahun/KoreksiFiskalTahun.view').default}/>
    <Route path="/koreksiFiskal/:koreksiFiskalId/open" component={require('./KoreksiFiskal/koreksiFiskal.main').default}/>
    {/* END 21_26 */}

    {/* PAYROLL ----------------------------------------------------------- */}
    <Route path="/religion" exact component={require('./Payrol/Master/Religion/Religion.view').default}/>
    <Route path="/designation" exact component={require('./Payrol/Master/Designation/Designation.view').default}/>
    <Route path="/bank" exact component={require('./Payrol/Master/Bank/Bank.view').default}/>
    <Route path="/objekPajak" exact component={require('./Payrol/Master/ObjekPajak/ObjekPajak.view').default}/>
    <Route path="/componentType" exact component={require('./Payrol/Master/ComponentType/ComponentType.view').default}/>
    <Route path="/earning" exact component={require('./Payrol/Master/Earning/Earning.view').default}/>
    <Route path="/deduction" exact component={require('./Payrol/Master/Deduction/Deduction.view').default}/>
    <Route path="/pph21Setting/:id" exact component={require('./Payrol/Master/PPh21Setting/PPh21Setting.form').default}/>
    <Route path="/pph21Setting" exact component={require('./Payrol/Master/PPh21Setting/PPh21Setting.view').default}/>
    <Route path="/statusPTKP" exact component={require('./Payrol/Master/StatusPtkp/StatusPtkp.view').default}/>
    <Route path="/employee/:id" exact component={require('./Payrol/Process/Employee/Employee.form').default}/>
    <Route path="/employee" exact component={require('./Payrol/Process/Employee/Employee.view').default}/>
    <Route path="/nonEmployee/:id" exact component={require('./Payrol/Process/NonEmployee/NonEmployee.form').default}/>
    <Route path="/nonEmployee" exact component={require('./Payrol/Process/NonEmployee/NonEmployee.view').default}/>
    <Route path="/salarySlip/:id" exact component={require('./Payrol/Process/SalarySlip/SalarySlip.form').default}/>
    <Route path="/salarySlip" exact component={require('./Payrol/Process/SalarySlip/SalarySlip.view').default}/>

    <Route path="/periode" exact component={require('./Payrol/Process/Periode/Periode.view').default}/>
    <Route path="/periode/:periodeId/open" component={require('./Payrol/Process/Peiode.main').default}/>
    {/* END OF PAYROLL */}

    <Route path={"/summary/:pasal"} render={(props) => (
      <PPhSummary key={props.match.params.pasal} {...props} />
    )} />
    <Route path="/summary-bulanan" exact component={require('./Payrol/Process/Periode/PPh.summaryBulanan').default}/>
    <Route path="/summary-tahunan" exact component={require('./Payrol/Process/Periode/PPh.summaryTahunan').default}/>
    <Route path="/" component={require('./Dashboard/Dashboard.view').default}/>
  </Switch>
)





