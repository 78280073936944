import React, { Component } from 'react';
import { Button, DialogContainer, TextField } from 'react-md';
import jsqr from 'jsqr';


export default class ScanCam extends Component {
  interval = 200;
  canvasEl;
  canvas;
  video = {}

  constructor(props) {
    super(props);
    this.state = {
      loadingMessage: "🎥 Unable to access video stream (please make sure you have a webcam enabled)",
      canvasElement: true,
      outputMessage: false,
      outputData: false,
      qrData: 'No QR code detected.'
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!this.props.visible && nextProps.visible) {
      this.setState({
        loadingMessage: "🎥 Unable to access video stream (please make sure you have a webcam enabled)",
        qrData: 'No QR code detected.'
      })
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((stream) => {
        this.video.srcObject = stream;
        this.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        this.video.play();
        requestAnimationFrame(this.tick.bind(this));
      });
    }
  }

  componentWillMount() {
    this.video = document.createElement("video");
  }

  componentDidMount() {
    // this.canvas = this.canvas.getContext('2d')

  }

  setRef(ctx) {
    this.canvasEl = ctx;
    if(ctx) this.canvas = ctx.getContext('2d')
    //console.log(this.canvas)
  }

  render() {
    return <DialogContainer
      id="cam-action-dialog"
      width={540}

      visible={this.props.visible}
      onHide={()=> {
        this.props.onHide()
        this.video.srcObject.getVideoTracks()[0].stop();
      }}
      actions={[
        <Button flat primary onClick={()=> {
          this.video.srcObject.getVideoTracks()[0].stop();
          this.props.onAdd(this.state.qrData)
          this.props.onHide()
        }}>Add</Button>,
        <Button flat primary onClick={()=> {
          this.video.srcObject.getVideoTracks()[0].stop();
          this.props.onHide();
        }}>Cancel</Button>
      ]}
      title="Cam Scan"
    >

      <div id="loadingMessage">{this.state.loadingMessage}</div>
      <canvas style={{width: 480, height: 400}} ref={ctx => {

       this.setRef(ctx)
      }}></canvas>

      <div id="output">
        <div id="outputMessage" className=''>
          <br/>
          <div primary flat style={{
            display: 'block',
            padding: '10px',
            textAlign: 'center',
            border: '2px dashed black',
            wordWrap: 'break-word'
          }}>Scanned QRCODE: {this.state.qrData}</div>
        </div>
      </div>

    </DialogContainer>

  }

  // canvas method
  drawLine(begin, end, color) {
    this.canvas.beginPath();
    this.canvas.moveTo(begin.x, begin.y);
    this.canvas.lineTo(end.x, end.y);
    this.canvas.lineWidth = 4;
    this.canvas.strokeStyle = color;
    this.canvas.stroke();
  }

  tick() {

    if(this.canvas && this.canvasEl) {
      this.setState({loadingMessage: '⌛ Loading video...'})
      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        this.setState({loadingMessage: ''})
        //canvasElement.hidden = false;
        //outputContainer.hidden = false;
        this.canvasEl.height = this.video.videoHeight;
        this.canvasEl.width = this.video.videoWidth;
        this.canvas.drawImage(this.video, 0, 0, this.canvasEl.width, this.canvasEl.height);
        var imageData = this.canvas.getImageData(0, 0, this.canvasEl.width, this.canvasEl.height);
        var code = jsqr(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          this.setState({
            qrData: code.data
          })
        } else {
          //outputMessage.hidden = false;
          //outputData.parentElement.hidden = true;
        }
      }
    }

    setTimeout(() => {
      requestAnimationFrame(this.tick.bind(this));
    }, this.interval);

  }
}
