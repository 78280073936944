import ApiService from '../../../../services/api.service';

class AmortisasiService extends ApiService {
  name  = 'amortisasi';
  path  = 'koreksi-fiskal/penyusutan-amortisasi';
  constructor() {
    super();
    this.init()
  }

  api = {
    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    }
  }
}


export default new AmortisasiService();
