import ApiService from '../../../../services/api.service';

class InvoiceItemService extends ApiService {
  name  = 'invoiceItem';
  path  = 'pembayaran/invoice-item';
  constructor() {
    super();
    this.init()
  }

  api= {
    save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/invoice/${data.invoice.id}/invoice-item`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/invoice/${data.invoice.id}/invoice-item/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (id, invoiceId, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/invoice/${invoiceId}/invoice-item/${id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    }
  }
}


export default new InvoiceItemService();
