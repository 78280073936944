import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ArchiveBp21Service from './ArchiveBp21.service';
import UploadDialog from '../../Admin/upload/upload.dialog';
import AttachmentService from './Attachment.service';


@reduxForm({form: 'ArchiveBp21Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('ArchiveBp21Form')(state)
}))
export default class ArchiveBp21Form extends FormView {
  service     = ArchiveBp21Service
  viewType    = 2;
  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      showDialog: false,
      formData: {},
      showUpDialog: false,
      attachments: []
    }
  }

  formView() {
    return (
      <div>
      <Card>
      <div className='md-grid'>
        {/* <Field
          label         = {counterpart('entities.archiveSpt21.category')}
          name          = 'category'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        /> */}
        <Field
          label         = {counterpart('entities.archiveSpt21.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.tahun')}
          name          = 'tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.pembetulan')}
          name          = 'pembetulan'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.npwp')}
          name          = 'npwp'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        {/* <Field
          label         = {counterpart('entities.archiveSpt21.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.bruto')}
          name          = 'bruto'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.pph')}
          name          = 'pph'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.nomorBp')}
          name          = 'nomorBp'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.detail')}
          name          = 'detail'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.archiveSpt21.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        /> */}

        <Field
          label         = 'Pesan'
          name          = 'message'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
      </Card>

      <br/>
      {this.attachmentView()}
      </div>
    )
  }

  attachmentView() {
    // if(!this.props.formData.id) return <div/>

    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.attachment.title').toUpperCase()} />
      <Divider/>

      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={()=> {this.setState({showUpDialog: true})}}>{counterpart('entities.attachment.add')}</Button>
      </div>

      <List>
        {this.state.attachments.map((v, i) => {
          return <ListItem primaryText={v.description} secondaryText={v.path} key={i} onClick={()=> this.handleDownloadAttachment(v.id)}
            rightIcon={<FontIcon onClick={(e)=> {e.stopPropagation(); this.handleDeleteAttachment(v.id, i)}}>close</FontIcon>}
          />
        })}
      </List>

      <UploadDialog
          height={400}
          visible={this.state.showUpDialog}
          formData={{}}
          onSuccess={(e, value)=> {this.handleAttachment(e, value)}}
          onClose={() => this.setState({showUpDialog:false})}
          match={this.props.match}
        />
    </Card>
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {/* <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button> */}
      </div>
    )
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }

    var res = await AttachmentService.api.findByModuleAndArchiveId('21', this.props.match.params.id)
    if(res.data) {
      this.setState({attachments: res.data})
    }
  }

  async handleAttachment(value) {

    value.path = value.name;
    value.archiveId = this.props.match.params.id ;
    value.module = "21"
    value.description = value.origin;
    console.log('asdasd', value)
    await AttachmentService.api.save(value);

    iziToast.success({
        title: 'success',
        message: counterpart('entities.attachment.attachmentSuccess')
    })
    await this.initData()
  }

  async handleDownloadAttachment(attachmentId) {
    let res = await AttachmentService.api.download(attachmentId);
    var filename = res.headers['content-disposition']
    if(!filename) filename = res.headers['authorization']
    if(filename) filename = filename.split('filename=').pop();
    if(!filename) filename = "report.xlsx"
    download(res.data, filename);
  }

  async handleDeleteAttachment(attachmentId, i) {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      text: 'Apakah anda yakin akan menghapus attachment ini?'
    }))
    if(dialog) {
        await AttachmentService.api.delete(attachmentId);

        iziToast.success({title: 'Success', message: 'Attachment telah berhasil di hapus'})
        this.initData()
    }
  }
}
