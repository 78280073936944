import React, { Component } from 'react';
import { connect } from 'react-redux';
import env from 'env';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import counterpart from 'counterpart';

// import FormDialog from './LawanTransaksi.dialog.view';
import LawanTransaksiService from './LawanTransaksi.service';
import ListView from '../../../../components/entity/listView';
import LawanTransaksiDialog from './LawanTransaksi.dialog';
import iziToast from 'izitoast';
import uploadService from '../upload/upload.service';

@connect(LawanTransaksiService.stateConnectSetting(), LawanTransaksiService.actionConnectSetting())
export default class LawanTransaksiView extends ListView {
  showDeleteAll = true;
  service     = LawanTransaksiService
  FormDialog  = LawanTransaksiDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.lawanTransaksi.name",                    value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.lawanTransaksi.npwp",                    value: "npwp"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.lawanTransaksi.nik",                     value: "nik"},
    {isSortable: false, show: false,  isSearchable:false, label: "entities.lawanTransaksi.address",                 value: "address",                 className: "mpk-font-size-S"},
    {isSortable: false, show: false,  isSearchable:false, label: "entities.lawanTransaksi.postal",                  value: "postal",                  type:'boolean'},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.phone",                   value: "phone"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.email",                   value: "email"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.provinsi",                   value: "provinsi"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.kabupaten",                   value: "kabupaten"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.kecamatan",                   value: "kecamatan"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.lawanTransaksi.kelurahan",                   value: "kelurahan"}
  ]

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleUpload()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Lawan Transaksi',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/lawan_transaksi_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }
}
