import ApiService from '../../../../services/api.service';

class BiayaEntertainService extends ApiService {
  name  = 'biayaEntertain';
  path  = 'koreksi-fiskal/daftar-nominatif-biaya-entertain';
  constructor() {
    super();
    this.init()
  }

  api= {
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    findOne: async (id, tahunId, dispatch=()=> {})=> {
      let res = {}
      if(tahunId) {
        res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/${tahunId}/daftar-nominatif-biaya-entertain/${id}`)
      } else {
        res = await this.http.get(`${this.apiLocation}/daftar-nominatif-biaya-entertain/${id}`)
      }
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

    pull: async (tahunId, data) => {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${tahunId}/daftar-nominatif-biaya-entertain-pull`, data);

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = {}
      if(data.tahun && data.tahun.id) {
        res=await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-entertain`, data);
      } else {
        res=await this.http.post(`${this.apiLocation}/daftar-nominatif-biaya-entertain`, data);
      }
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = {}
      if(data.tahun && data.tahun.id) {
        res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-entertain/${data.id}`, data);
      } else {
        res = await this.http.put(`${this.apiLocation}/daftar-nominatif-biaya-entertain/${data.id}`, data)
      }
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (data, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-entertain/${data.id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/daftar-nominatif-biaya-entertain/${id}/update-workflow`, data);
      return res;
    },

    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },


    export: async (d) => {
      var res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${d.tahun.id}/daftar-nominatif-biaya-entertain/export-all`, d);
      return res
    },
  }
}


export default new BiayaEntertainService();
