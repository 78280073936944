import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toolbar, List, ListItem, Avatar, DropdownMenu, FontIcon, TextField, Subheader, Divider} from 'react-md';
import {navService} from 'react-mpk';
import authService from '../../services/auth.service';

@connect(state => ({
  isLoginIn: state.auth.isLoginIn,
  user: state.auth.user
}))
export default class UserBar extends Component {
  render() {
    let user = {};
    if(this.props.user) user = this.props.user;

    return (
    <ul className="md-list md-dark-theme" style={{background: 'none'}}>
      <li>
        <div style={{margin: '10px 0'}} role="button" className="md-fake-btn md-pointer--hover md-fake-btn--no-outline md-list-tile  md-text">
          <div className="md-ink-container">
          </div>
          {user.firstName &&
            <div className="md-tile-addon md-tile-addon--avatar">
              <div className="md-inline-block md-avatar md-avatar--green">
                <div className="md-avatar-content">{user.firstName.charAt(0)}</div>
              </div>
            </div>
          }

          <div className="md-tile-content md-tile-content--left-avatar md-tile-content--right-padding">
            <div className="md-tile-text--primary md-text">{`${user.name}`}</div>
            <div className="md-tile-text--secondary md-tile-text--three-lines md-text--secondary">
              {user.email}
            </div>
          </div>
        </div>
      </li>
    </ul>
    )
  }
}
