import ApiService from '../../../../services/api.service';
import qs from 'qs';

class PerjanjianService extends ApiService {
  name  = 'perjanjian';
  path  = 'pembayaran/perjanjian';
  constructor() {
    super();
    this.init()
  }

  api= {
    saveAttachment: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/attachment-add`, data);
      return res;
    },
    downloadAttachment: async (id, attachmentId)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/download-attachment/${attachmentId}`,  {
        responseType: 'blob'
      });
      return res;
    },
    deletedAttachment: async (id, attachmentId)=> {
      let res = await this.http.delete(`${this.apiLocation}/${this.path}/attachment/${attachmentId}/attachment-delete`);
      return res;
    },
    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    },
    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },

    findAllReceiptOutstanding: async (param) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-receipt-outstanding`, {params:param, paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }});

      return res;
    },
    findAllInvoiceOutstanding: async (param) => {
      let res = await this.http.get(`${this.apiLocation}/${this.path}-invoice-outstanding`, {params:param, paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }});

      return res;
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    }
  }
}


export default new PerjanjianService();
