import ApiService from '../../../../services/api.service';

class PermissionService extends ApiService {
  name  = 'permission';
  path  = 'master/permission';
  constructor() {
    super();
    this.init()
  }
}


export default new PermissionService();
