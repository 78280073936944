import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import _ from 'lodash';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import Service from './User.service';
import UserRoleService from '../UserRole/UserRole.service';
import RoleService from '../Role/Role.service';
import CompanyService from '../Company/Company.service';
import UserService from './User.service';

@reduxForm({form: 'UserForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('UserForm')(state)
}))
export default class UserForm extends FormView {
  service     = UserService
  // viewType    = 2;
  initialData = {
    "login": '',
    "name": "",
    "email": "",
    "kdUnitKerja": "",
    "unitKerja": "",
    "active": true,
    "role": ""
}

  formView() {
    var map = this.props.formData.companyMap;
    return (

      <div className='md-grid'>
        { !this.props.formData.id &&
        <Field
          label         = {'Lokal/Eksternal'}
          name          = 'userType'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[
            {id: 'local', name: 'Local'},
            {id: 'external', name: 'External'}
          ]}
          />
        }
        <Field
          label         = {counterpart('entities.user.username')}
          name          = 'login'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('word.password')}
          name          = 'password'
          className     = 'md-cell md-cell--12'
          type          = 'password'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.email')}
          name          = 'email'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.kdUnitKerja')}
          name          = 'kdUnitKerja'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.unitKerja')}
          name          = 'unitKerja'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          />
        <Field
          label         = {counterpart('entities.user.active')}
          name          = 'active'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          />
        <Field
          label         = {counterpart('entities.user.role')}
          name          = 'role'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {[{
            id: 'user',
            name: 'User'
          },{
            id: 'admin',
            name: 'Admin'
          }]}
          />

      </div>

    )
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          if(value.userType == 'external') {
            res = await this.service.api.saveExternal(value)
          } else {
            res = await this.service.api.save(value)
          }

        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }
}
