import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
//style
import '../node_modules/storm-react-diagrams/dist/style.min.css';
import '../node_modules/izitoast/dist/css/iziToast.css';
import './index.scss';

// configure
import configureStore from './redux/store/configure-store';
import {configureHttp} from './services/http.service';

configureHttp();
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
