import ApiService from '../../../../services/api.service';

class MetodePenyusutanKomersialService extends ApiService {
  name  = 'metodePenyusutanKomersial';
  path  = 'master/metode-penyusutan-komersial';
  constructor() {
    super();
    this.init()
  }
}


export default new MetodePenyusutanKomersialService();
