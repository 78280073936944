import ApiService from '../../../../../services/api.service';

class PPh21TidakFinalService extends ApiService {
  name  = 'pph21TidakFinal';
  path  = 'pph21/process/pph21-data-tidak-final';

  constructor() {
    super();
    this.init()
  }

  api = {
    deleteAll: async (ids) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}/delete/bulk`, {ids})
      return data
    },
    calculate: async (data) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}/${data.id}/calculate`, data)
      return data
    },
    calculateBatch: async (data) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}-calculate-batch`, data)
      return data
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    sendEppt: async (data) => {
      var data = await this.http.post(`${this.apiLocation}/${this.path}-send-eppt`, data)
      return data
    }
  }
}


export default new PPh21TidakFinalService();
