import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import Pph25Service from './Pph25.service';
import ListView from '../../../../components/entity/listView';
import { permission } from '../../../../config/constant';
import DefaultView from '../../../../components/entity/default.view';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import {List, ListItem} from 'react-md'
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import {Field, reduxForm, getFormValues} from 'redux-form';

@connect((state) => ({
  ...Pph25Service.stateConnectSetting()(state),
  kp: state.auth.currentSPT,
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), Pph25Service.actionConnectSetting())
export default class Pph25View extends DefaultView {
  service     = Pph25Service
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = Pph25Dialog

  approvalSettingName     = 'PPh25'
  checkerPermissionName   = permission.MelakukanCheckPPh25;
  approvalPermissionName  = permission.MelakukanApprovePPh25;

  beforeFetch(params) {

  params.status=['TEST_STATUS', 'TEST_STATUS_REFERENCE', "draft", "checked", "approved", "reject",];
  }

  fetchOption() {
    console.log(this.props.kp)
    return {
      path: 'koreksi-fiskal/'+this.props.kp.data.id+'/pph25'
    }
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.bungaDeposito.statusEppt",    value: "eppt.status"},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.pph25.masa",                value: "masa", isDefaultSort:true},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.pph25.tahun",               value: "tahun.tahun"},
    {isSortable: true,  show: false,     isSearchable:true,  label: "entities.pph25.penghasilanNetto",    value: "penghasilanNetto",             type: 'number'},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.pph25.pph",                 value: "pph",                          type: 'number'},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.pph25.pphPasal22",          value: "pphPasal22",                   type: 'number'},
    {isSortable: true,  show: false,     isSearchable:true,  label: "entities.pph25.pphPasal25Angsuran",  value: "pphPasal25Angsuran",           type: 'number'},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.pph25.angsuran2",            value: "angsuran",                     type: 'number'},

  ]

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Masa'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
      </this.SearchContainer>
    )
  }

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.export',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.handleExport()
    },
    ,
    {
      label:'entities.bungaDeposito.sendToEppt',
      iconClassName:'mdi mdi-export',
      onClick:() => this.handleSendToEppt(),
      disabledFunc:() => this.props.table.selected.length === 0
    },

    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    }
  ]

  async handleSendToEppt() {
    // console.log(this.props.table)
    var d = await this.context.showDialog((props, res, rej) =>({
      title: counterpart.translate('entities.bungaDeposito.sendToEppt'),
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: (<div style={{width: '100%'}}>
        <p style={{padding: '0px 24px'}}>Apakah anda yakin akan mengirim data dibawah ini?</p>
        <List style={{width: '100%'}}>
          {this.props.table.selected.map((v, i)=> {
            return <ListItem primaryText={`${v.tahun.tahun} - ${v.masa}`} key={i} />
          })}
        </List>
      </div>)
    }));

    if(d) {
      var data = {
        ids: this.props.table.selected.map((v, i) => v.id)
      }
      await this.service.api.sendToEppt(this.props.kp.data.id, data)
      iziToast.success({title: 'Success', message: 'Data sedang dikirim, mohon cek status kirim di kolom Status EPPT'})
      this.fetchData()
    }
  }

  async handleExport() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Export ke Excel',
      width: 500,
      initialValue: {delimiter: ';', note: '-'},
      okText: 'Export',
      text: (<div className='md-grid'>
        {/* <Field
            label         = 'Tahun Pajak'
            name          = 'year'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          /> */}
        {/* <Field
            label         = {counterpart('entities.upload.delimiter')}
            name          = 'delimiter'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[
              {id: ',', name: 'Koma'},
              {id: ';', name: 'Titik Koma'}
            ]}
            validate      = {validation.required}
          /> */}
        <Field
            label         = 'Note'
            name          = 'note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
      </div>)
    }));

    if(d) {
      d.tahunId = this.props.kp.data.id;
      await this.service.api.export(d);
      iziToast.success({
        title: 'success',
        message: counterpart('entities.upload.exportSuccess')
      })
    }
  }

}
