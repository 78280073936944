import ApiService from '../../../../services/api.service';

class KoreksiFiskalService extends ApiService {
  name  = 'koreksiFiskal';
  path  = 'koreksi-fiskal/koreksi-fiskal-tahun';
  constructor() {
    super();
    this.init()
  }
}


export default new KoreksiFiskalService();
