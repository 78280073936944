import ApiService from '../../../../services/api.service';

class BungaDepositoService extends ApiService {
  name  = 'bungaDeposito';
  path  = 'koreksi-fiskal/bunga-deposito-tabungan';
  constructor() {
    super();
    this.init()
  }

  api= {
    findOne: async (id, tahunId, dispatch=()=> {})=> {
      let res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/${tahunId}/bunga-deposito-tabungan/${id}`)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/bunga-deposito-tabungan/${id}/update-workflow`, data);
      return res;
    },

    pull: async (data, dispatch=()=> {})=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/bunga-deposito-tabungan/${data.id}/pull`, data)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },


    sendToEppt: async (tahunId, data) => {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${tahunId}/bunga-deposito-tabungan-send-to-eppt`, data);

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/bunga-deposito-tabungan`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/bunga-deposito-tabungan/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (id, tahunId, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/koreksi-fiskal/${tahunId}/bunga-deposito-tabungan/${id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/bunga-deposito-tabungan/${id}/update-workflow`, data);
      return res;
    }
  }
}


export default new BungaDepositoService();
