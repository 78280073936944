import ApiService from '../../../../../services/api.service';

class PeriodeService extends ApiService {
  name  = 'periode';
  path  = 'pph21/process/pph21Periode';
  constructor() {
    super();
    this.init()
  }
  api = {
    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    },

    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },

    validateAppoved: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/validate-approved`, data);
      return res;
    },

    generate: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary`, data, {
        responseType: 'blob'
      })
      return res;
    },

    generateBpEbupot: async (data, npwp) => {
      var newData = {
        masa1: data.fromMonth,
        masa2: data.toMonth,
        tahun1: data.fromYear.toString(),
        tahun2: data.toYear.toString(),
        allNpwp: false,
        merge: false,
        mergeAll: false,
        orgNpwps: npwp,
        orgIds: []
      }
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-bp-ebupot`, newData, {
        responseType: 'blob'
      })
      return res;
    },

    generateSptEbupot: async (data, npwp) => {
      var newData = {
        masa1: data.fromMonth,
        masa2: data.toMonth,
        tahun1: data.fromYear.toString(),
        tahun2: data.toYear.toString(),
        allNpwp: false,
        merge: false,
        mergeAll: false,
        orgNpwps: npwp,
        orgIds: []
      }
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-spt-ebupot`, newData, {
        responseType: 'blob'
      })
      return res;
    },

    bulanan: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-bulanan/${data.fromMonth}/${data.toMonth}/${data.year}`, data, {
        responseType: 'blob'
      })
      return res;
    },

    tahunan: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/pph21/process/pph21Periode/summary-tahunan/${data.year}`, data, {
        responseType: 'blob'
      })
      return res;
    },

  }
}


export default new PeriodeService();
