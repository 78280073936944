import ApiService from '../../../../services/api.service';

class UploadService extends ApiService {
  name  = 'upload';
  path  = 'upload';
  constructor() {
    super();
    this.init()
  }

  api= {
    upload: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return res;
    }
  }
}


export default new UploadService();
