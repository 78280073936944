import ApiService from '../../../../services/api.service';

class InvoiceService extends ApiService {
  name  = 'invoice';
  path  = 'pembayaran/invoice';
  constructor() {
    super();
    this.init()
  }

  api= {
    sendToEppt: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-send-to-eppt`, data);

      return res;
    },
    export: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-export-all`, data);

      return res;
    },
    saveAttachment: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/attachment-add`, data);
      return res;
    },
    deletedAttachment: async (id, attachmentId)=> {
      let res = await this.http.delete(`${this.apiLocation}/${this.path}/attachment/${attachmentId}/delete`);
      return res;
    },
    downloadAttachment: async (id, attachmentId)=> {
      let res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/download-attachment/${attachmentId}`,  {
        responseType: 'blob'
      });
      return res;
    },

    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    },

    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },

    getFaktur: async (url)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-faktur`, {url});
      return res;
    }
  }
}


export default new InvoiceService();
