import React, { Component } from 'react';
import { connect } from 'react-redux';
import PPh21SettingService from './PPh21Setting.service';
import ListView from '../../../../../components/entity/listView';

@connect(PPh21SettingService.stateConnectSetting(), PPh21SettingService.actionConnectSetting())
export default class PPh21SettingView extends ListView {
  service     = PPh21SettingService
  editDialog  = false;
  addDialog   = false;

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.pph21Setting.name",                    value: "name"},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.pph21Setting.active",                    value: "active", type: 'boolean'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.pph21Setting.fromDate",                    value: "from"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.pph21Setting.toDate",                    value: "to"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.pph21Setting.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
