import ApiService from '../../../../services/api.service';

class JenisUsahaService extends ApiService {
  name  = 'jenisUsaha';
  path  = 'master/jenis-usaha';
  constructor() {
    super();
    this.init()
  }
}


export default new JenisUsahaService();
