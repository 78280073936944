import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './SalarySlip.dialog.view';
import SalarySlipService from './SalarySlip.service';
import ListView from '../../../../../components/entity/listView';

@connect(SalarySlipService.stateConnectSetting(), SalarySlipService.actionConnectSetting())
export default class SalarySlipView extends ListView {
  service     = SalarySlipService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = SalarySlipDialog

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  columns=[
    {isSortable: true, show: true,   isSearchable:false, label: "entities.employee.basicInfo.name",          value: "employee.basicInfo.name"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.employee.basicInfo.npwp",          value: "employee.basicInfo.npwp"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.salaryType",            value: "salaryType"},
    {isSortable: true, show: true,   isSearchable:false, label: "entities.salarySlip.total",                 value: "total", type: 'number'}
  ]
}
