import ApiService from '../../../../services/api.service';

class ItemTypeService extends ApiService {
  name  = 'itemType';
  path  = 'master/itemType';
  constructor() {
    super();
    this.init()
  }
}


export default new ItemTypeService();
