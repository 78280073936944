import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import InvoiceService from './Invoice.service';
import CompanyService from '../../Admin/Company/Company.service';
import LawanTransaksiService from '../../Admin/LawanTransaksi/LawanTransaksi.service';
import InvoiceItemDialog from './InvoiceItem.dialog';
import utilsService from '../../../../services/utils.service';
import terbilang from '../../../../services/terbilang.service';
import ItemTypeService from '../../Admin/ItemType/ItemType.service';
import DocumentTypeService from '../../Admin/DocumentType/DocumentType.service';
import PerjanjianService from '../Perjanjian/Perjanjian.service';
import ReceiptService from '../Receipt/Receipt.service';
import UploadDialog from '../../Admin/upload/upload.dialog';
import InvoiceItemService from './InvoiceItem.service';
import ScanCam from './ScanCam.view';
import FakturPreview from './Faktur.preview';
import jsqr from 'jsqr';
import jpeg from 'jpeg-js';
import  bluebird from 'bluebird';
import IncomeTypeService from '../../Admin/IncomeType/IncomeType.service';
import CoaService from '../../Admin/Coa/Coa.service';
import uploadService from '../../Admin/upload/upload.service';

@reduxForm({form: 'InvoiceForm', destroyOnUnmount: true, initialValues: {
  items: [], itemMap: {}, faktur: {}, summary: {}
}})
@connect((state) => ({
  formData: getFormValues('InvoiceForm')(state)
}))
export default class InvoiceForm extends FormView {
  service     = InvoiceService
  viewType    = 2;
  initialData = {
    items: [],
    // faktur: {},
    workflow: {
      status: 'draft'
    },
    itemMap: {},
    locationOnRoot: false,
    itemTypeOnRoot: false,
    attachments: [],
    "summary": {
      "totalItemPrice": 0,
      "taxPercentage": 0,
      "taxAmount": 0,
      "discountPercentage": 0,
      "discountAmount": 0,
      "priceBeforeTax": 0,
      "priceAfterTax": 0,
      "other1Name": "",
      "other1Amount": 0,
      "other2Name": "",
      "other2Amount": 0,
      "total": 0,
      "spelledOut": "",
      "isPPnCalculatePerItem": true,
      "isDiscountCalculatePerItem": true,
      "isPpnIncluded": false
    }
  }

  fileElement = {click: ()=>{}}

  formView() {
    if(this.state.warmUp) return <div/>
    return (
      <div>
        <InvoiceItemDialog
          height={400}
          companies={this.state.companies}
          visible={this.state.showDialog}
          incomeTypes={this.state.incomeTypes}
          coas={this.state.coas}
          parentData={this.props.formData}
          // add={this.props.tableActions.addDataItem}
          formData={this.state.formData}
          onSuccess={(value)=> {this.handleAddItemSuccess(value)}}
          onClose={() => this.setState({showDialog:false})}
          match={this.props.match}
        />

        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.invoice.category')}
              name          = 'category'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {this.state.documentTypes}
              validate      = {validation.required}
              apiPath       = 'lookup'
              valueField    = 'name'
            />
            <Field
              label         = {counterpart('entities.invoice.no')}
              name          = 'no'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.date')}
              name          = 'date'
              className     = 'md-cell md-cell--6'
              component     = {Datepicker}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.reference')}
              name          = 'reference'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.lawanTransaksi.title').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.title')}
              name          = 'lawanTransaksiId'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              service       = {LawanTransaksiService}
              onChange      = {(data) => {
                if(data.npwp && data.npwp.length > 0) this.handleNpwpChange(data.npwp)
                this.props.change('lawanTransaksi', data)
              }}
              valueField    = 'parent'
            />

            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.name')}
              name          = 'lawanTransaksi.name'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.npwp')}
              name          = 'lawanTransaksi.npwp'
              className     = 'md-cell md-cell--6'
              component     = {TextfieldMask}
              maskFormat    = "##.###.###.#-###-###"
              mask          = "_"
              length        = {15}
              validate      = {validation.required}
              onChange      = {(e, v) => this.handleNpwpChange(v)}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.nik')}
              name          = 'lawanTransaksi.nik'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.address')}
              name          = 'lawanTransaksi.address'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.postal')}
              name          = 'lawanTransaksi.postal'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.phone')}
              name          = 'lawanTransaksi.phone'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = 'Provinsi'
              name          = 'lawanTransaksi.provinsi'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kabupaten'
              name          = 'lawanTransaksi.kabupaten'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kecamatan'
              name          = 'lawanTransaksi.kecamatan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kelurahan'
              name          = 'lawanTransaksi.kelurahan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.invoice.lawanTransaksi.email')}
              name          = 'lawanTransaksi.email'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.source').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
          {this.state.showPerjanjianForm &&
            <Field
              label         = {counterpart('entities.perjanjian.title')}
              name          = 'perjanjian'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              // service       = {PerjanjianService}
              // // validate      = {validation.required}
              // params        = {{
              //   page: 1, sortBy: 'no', size: 20, sort: 'ASC',
              //   type: 'pembayaran-page-search-common',
              //   category: this.state.poParam,
              //   status: ['TEST_STATUS', 'approved']
              // }}
              options       = {this.state.perjanjianList}
              viewField     = {'no'}
              itemTemplate  = {function(d) {
                var primary = ''
                if(d.lawanTransaksi) primary = d.lawanTransaksi.npwp+" - "+d.lawanTransaksi.name;
                return {
                  primaryText: primary,
                  secondaryText: d.no,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}

              // apiPath       = 'lookup'
              valueField    = 'parent'

              onChange      = {(d)=> {
                this.handlePerjanjianChange(d)
              }}
            />
          }
          {this.state.showPerjanjianForm &&
            <Field
              label         = {counterpart('entities.receipt.title')}
              name          = 'receipt'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              // service       = {ReceiptService}
              // // validate      = {validation.required}
              // params        = {{
              //   page: 1, sortBy: 'no', size: 20, sort: 'ASC',
              //   type: 'pembayaran-page-search-common',
              //   category:this.state.receiptParam,
              //   status: ['TEST_STATUS', 'approved']
              // }}
              options       = {this.state.receiptList}
              viewField     = {'no'}
              itemTemplate  = {function(d) {
                var primary = ''
                if(d.lawanTransaksi) primary = d.lawanTransaksi.npwp+" - "+d.lawanTransaksi.name;
                return {
                  primaryText: primary,
                  secondaryText: d.no,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}

              // apiPath       = 'lookup'
              valueField    = 'parent'

              onChange      = {(d)=> {
                this.handleReceiptChange(d)
              }}
            />
          }
            </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.setting').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.invoice.locationOnRoot')}
              name          = 'locationOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.locationOnRoot) &&
            <Field
              label         = {counterpart('entities.company.title')}
              name          = 'companyId'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              options       = {this.state.companies}
              valueField    = 'id'
              itemTemplate   = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              // apiPath       = {'lookup'}
            />
            }
          </div>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.invoice.itemTypeOnRoot')}
              name          = 'itemTypeOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.itemTypeOnRoot) &&
            <Field
              label         = {counterpart('entities.invoice.itemType')}
              name          = 'itemType'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              service       = {ItemTypeService}
              valueField    = 'name'
            />
            }
          </div>
          <div className='md-grid'>
            {/* <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isDiscountCalculatePerItem')}
              name          = 'summary.isDiscountCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />

            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isPPnCalculatePerItem')}
              name          = 'summary.isPPnCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}

            {/* <Field
              label         = {counterpart('entities.perjanjian.summary.isPpnIncluded')}
              name          = 'summary.isPpnIncluded'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.item.title').toUpperCase()} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>Perusahaan/Cabang</TableColumn>
                <TableColumn>{counterpart('entities.invoice.item.code').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.invoice.item.name').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.invoice.item.description').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.price').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.invoice.item.qty').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.ppn').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.invoice.item.total').toUpperCase()}</TableColumn>

              </TableRow>
            </TableHeader>
            <TableBody>
            {Object.keys(this.props.formData.itemMap).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    <div className="buttons__group">
                      <Button flat iconChildren="edit_outline" forceIconSize={20} onClick={()=> this.handleEditItem(this.props.formData.itemMap[key], i)}>Edit</Button>
                      <Button flat primary iconChildren="delete_outline" forceIconSize={20} onClick={()=> this.handleDeleteItem(i, this.props.formData.itemMap[key])}>Delete</Button>
                    </div>
                  </TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].info.company.name +" - "+ this.props.formData.itemMap[key].info.company.npwp}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].code}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].name}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].description}</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].price)}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].qty}</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].ppn)}%</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].total)}</TableColumn>

                  </TableRow>
            })}
            </TableBody>
          </DataTable>
          <div className='mpk-layout' style={{padding: 8}}>
              <div className='flex'></div>
              <Button style={{marginRight: 10}} flat swapTheming onClick={() => {this.handleUpload()}}>Import</Button>
              <Button flat primary swapTheming onClick={this.handleAddItem}>{counterpart('entities.invoice.action.addItem')}</Button>
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.summary.title').toUpperCase()} />
          <Divider/>

          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.summary.total')}
              name          = 'summary.totalItemPrice'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              disabled
              money={','}
            />

            {!this.props.formData.summary.isDiscountCalculatePerItem && <React.Fragment>
            <Field
              label         = {counterpart('entities.invoice.summary.discountPercentage')}
              name          = 'summary.discountPercentage'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'discountPercentage')}}
            />
            <Field
              label         = {counterpart('entities.invoice.summary.discountAmount')}
              name          = 'summary.discountAmount'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            </React.Fragment>
            }

            {!this.props.formData.summary.isPPnCalculatePerItem &&
            <React.Fragment>
              <Field
              label         = {counterpart('entities.receipt.summary.priceBeforeTax')}
              name          = 'summary.priceBeforeTax'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.receipt.summary.taxPercentage')+' (%)'}
              name          = 'summary.taxPercentage'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'taxPercentage')}}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.taxAmount')}
              name          = 'summary.taxAmount'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.receipt.summary.priceAfterTax')}
              name          = 'summary.priceAfterTax'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            </React.Fragment>
            }
            <Field
              label         = {counterpart('entities.invoice.summary.other1Name')}
              name          = 'summary.other1Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.invoice.summary.other1Amount')}
              name          = 'summary.other1Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other1Amount')}}
            />
            <Field
              label         = {counterpart('entities.invoice.summary.other2Name')}
              name          = 'summary.other2Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.invoice.summary.other2Amount')}
              name          = 'summary.other2Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other2Amount')}}
            />
            <Field
              label         = {counterpart('entities.invoice.summary.total')}
              name          = 'summary.total'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.invoice.summary.spelledOut')}
              name          = 'summary.spelledOut'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              disabled
            />
          </div>

        </Card>
        <br/>
        {this.attachmentView()}
        <br/>
        {this.fakturView()}

      </div>
    )
  }

  attachmentView() {
    // if(!this.props.formData.id) return <div/>
    if(!this.props.formData.attachments) return <div/>

    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.attachment.title').toUpperCase()} />
      <Divider/>

      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={()=> {this.setState({showUpDialog: true})}}>{counterpart('entities.attachment.add')}</Button>
      </div>

      <List>
        {this.props.formData.attachments.map((v, i) => {
          return <ListItem primaryText={v.description} secondaryText={v.path} key={i} onClick={()=> this.handleDownloadAttachment(v.id)}
            rightIcon={<FontIcon onClick={(e)=> {e.stopPropagation(); this.handleDeleteAttachment(v.id, i)}}>close</FontIcon>}
          />
        })}
      </List>

      <UploadDialog
          height={400}
          visible={this.state.showUpDialog}
          formData={{}}
          onSuccess={(e, value)=> {this.handleAttachment(e, value)}}
          onClose={() => this.setState({showUpDialog:false})}
          match={this.props.match}
        />
    </Card>
  }

  fakturView() {
    if(!this.props.formData.id) return <div/>

    var data = this.props.formData;
    var faktur = data.faktur? data.faktur: {detail: [], npwpPenjual: '000000000000000', npwpLawanTransaksi: '000000000000000'}

    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.faktur.title').toUpperCase()} />
      <Divider/>
      <div className='mpk-layout' style={{padding: 8}}>
        <div className='flex'>
          <Field
            label         = {counterpart('entities.faktur.url')}
            name          = 'faktur.url'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
        </div>
        <div>
          <Button onClick={() => this.handleGetFaktur()} style={{marginTop: 30}} flat primary swapTheming >{counterpart('entities.faktur.get')}</Button>

          <Button onClick={() => this.setState({showCam: true})} style={{marginTop: 30, marginLeft: 10}} flat secondary swapTheming >Webcam</Button>
          <Button onClick={() => this.fileElement.click()} style={{marginTop: 30, marginLeft: 10}} flat secondary swapTheming >Image</Button>
        </div>
      </div>

      <Divider />

      <div className='md-grid'>
        <List className="md-cell md-cell--12">
          <ListItem primaryText="Nomor Faktur" secondaryText={faktur.nomorFaktur} />
          <ListItem primaryText="Nama Penjual" secondaryText={faktur.namaPenjual} />
          <ListItem primaryText="NPWP Penjual" secondaryText={faktur.npwpPenjual} />
          <ListItem primaryText="Jumlah DPP" secondaryText={utilsService.number.format(faktur.jumlahDPP)} />
          <ListItem primaryText="Jumlah PPN" secondaryText={utilsService.number.format(faktur.jumlahPPN)} />
        </List>
      </div>
      <div className='mpk-layout' style={{padding: '0 8px'}}>
        <div className='flex' />
        <Button onClick={() => {this.setState({fkPreview: true});}} style={{ marginLeft: 10, marginBottom: 8}} flat secondary swapTheming >Detail</Button>
      </div>

      <input
          style={{display: 'none'}}
          type='file'
          accept='image/jpeg'
          label={''}
          multiple={true}
          ref={input => {this.fileElement = input}}
          onChange={(e)=> {this.handleImagesFiles(e.target.files)}}
          name='files'
          className="flext"
        />
      <ScanCam visible={this.state.showCam}
          onHide={()=> {this.setState({showCam: false})}}
          onAdd={(d)=> {
            this.handleUpdateData({
              file: {
                name: 'Camera'
              },
              qr: {
                data: d
              }
            })
          }}
          />

      {this.state.fkPreview &&
      <FakturPreview
        fk={faktur}
        visible={this.state.fkPreview}
        onHide={()=> this.setState({fkPreview: false}) }
        />
      }
    </Card>
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      showDialog: false,
      formData: {},
      documentTypes: [],
      documentTypeParam: [],
      receiptParam: [],
      poParam: [],
      warmUp: true,
      showUpDialog: false,

      perjanjianList: [],
      showPerjanjianForm: false,
      receiptList: [],
      showCam: false,
      fkPreview: false,
      incomeTypes: [],
      coas: [],
      companies: [],
      companyMap: {}
    }
  }

  async initData() {

    await this.getDocumentType()

    let companyRes = await CompanyService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(companyRes.data) {
      var companyMap = companyRes.data.data.reduce((pv, cv) => {
        pv[cv.id] = cv
        return pv;
      }, {})
      this.setState({companies: companyRes.data.data, companyMap})
    }

     // get income types
     let incomeTypeRes = await IncomeTypeService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    if(incomeTypeRes.data) this.setState({incomeTypes: incomeTypeRes.data.data})
    // get coa
    let coaRes = await CoaService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    });
    if(coaRes.data) this.setState({coas: coaRes.data.data})

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      var itemMap = {}
      var totalItemPrice = 0;
      res.data.items.forEach((v, i)=> {
        if(v.ppn == 10) v.adaPPN = true;
        itemMap['no'+i] = v;
        if(v.total) totalItemPrice += parseFloat(v.total)
      })

      res.data.itemMap = itemMap;
      res.data.summary.totalItemPrice = totalItemPrice;

      this.props.initialize(res.data);
    }
  }

  async getDocumentType() {
    var res = await DocumentTypeService.api.find({page: 1, size: 1000, category: 'Invoice', sort: -1, sortBy: 'name'});
    var po = [];
    var invoice = [];
    var receipt = [];

    if(res.data) {
      var categoryParam = res.data.data.forEach((v) => {
        if(v.category == 'PO') po.push(v);
        if(v.category == 'Receipt') receipt.push(v);
        if(v.category == 'Invoice') invoice.push(v);
      })

      var documentTypeParam = invoice.map((v) => {
        return v.name
      })
      var poParam = po.map((v) => {return v.name});
      var receiptParam = receipt.map(v => v.name);

      this.setState({documentTypes: invoice, documentTypeParam: documentTypeParam, warmUp: false, receiptParam, poParam})
    }
  }

  @utilsService.Debounce(500)
  async handlePerjanjianChange(param) {
    // console.log(param, 'sadas')
    if(!param.id) return true;
    var res = await PerjanjianService.api.findOne(param.id);
    var data = _.cloneDeep(this.props.formData);

    var itemMap = {}
    res.data.items.forEach((v, i)=> {
      v.info = {
        company: v.location,
        perjanjianId: param.id,
        perjanjianNo: param.no,
        perjanjianItemId: v.id
      }
      delete v.id;

      v.incomeTypes.forEach((x, i) => {
        delete x.id;
        delete x.itemId
        x.pph = v.priceNett*x.incomeType.rate/100
      })

      v.companyId = v.locationId;
      v.perjanjianId = param.id;
      v.perjanjianNo = param.no;
      // if(v.periode && v.periode.total) {
      //   if(v.periode.total > 1) {
      //     v.price = v.price/v.periode.total;
      //     v.priceNett = (v.qty*v.price)-(v.discount*v.qty*v.price/100);
      //     v.total = v.priceNett+(v.ppn*v.priceNett/100)
      //   }
      // }
      v.periode = 0;
      if(v.ppn == 10) v.adaPPN = true;
      delete v.receipts;
      itemMap['no'+i] = v;
    })

    delete res.data.lawanTransaksi.id;
    delete res.data.summary.id;

    data.itemType = res.data.itemType;
    data.itemTypeOnRoot = res.data.itemTypeOnRoot;
    data.locationOnRoot = res.data.locationOnRoot;
    data.companyId = res.data.companyId;
    data.items = res.data.items;
    data.itemMap = itemMap;
    data.lawanTransaksi = res.data.lawanTransaksi;
    data.summary = res.data.summary;
    data.summary.isDiscountCalculatePerItem = res.data.termOfCondition.isDiscountCalculatePerItem;
    data.summary.isPPnCalculatePerItem = res.data.termOfCondition.isPPnCalculatePerItem;

    this.props.initialize(data)

    this.calculate()
  }

  @utilsService.Debounce(500)
  async handleReceiptChange(param) {
    // console.log(param, 'sadas')
    if(!param.id) return true;
    var res = await ReceiptService.api.findOne(param.id);
    var data = _.cloneDeep(this.props.formData);

    var itemMap = {}
    res.data.items.forEach((v, i)=> {

      if(!v.info) v.info = {}
      var info = {
        company: v.info.company,
        perjanjianId: v.info.perjanjianId,
        perjanjianNo: v.info.perjanjianNo,
        receiptNo: param.no,
        receiptId: param.id,
        perjanjianItemId: v.info.perjanjianItemId
      }
      v.info = info;


      delete v.id;

      v.incomeTypes.forEach((v, i) => {
        delete v.id;
        delete v.itemId
        x.pph = v.priceNett*x.incomeType.rate/100
      })

      v.companyId = v.companyId;
      if(v.locationId) c.companyId = c.locationId;
      v.perjanjianId = v.perjanjianId;
      v.perjanjianNo = v.perjanjianId;
      v.receiptId = param.id;
      v.receiptNo = param.no;

      v.periode = 0;
      if(v.ppn == 10) v.adaPPN = true;
      delete v.receipts;
      itemMap['no'+i] = v;
    })

    delete res.data.lawanTransaksi.id;
    delete res.data.summary.id;

    data.itemType = res.data.itemType;
    data.itemTypeOnRoot = res.data.itemTypeOnRoot;
    data.locationOnRoot = res.data.locationOnRoot;
    data.companyId = res.data.companyId;
    data.items = res.data.items;
    data.itemMap = itemMap;
    data.lawanTransaksi = res.data.lawanTransaksi;
    data.summary = res.data.summary;

    this.props.initialize(data)
    this.calculate()
  }

  @utilsService.Debounce(500)
  async handleNpwpChange(lawanTransaksiNpwp) {
    this.setState({showPerjanjianForm: false})
    var res = await PerjanjianService.api.findAllInvoiceOutstanding({lawanTransaksiNpwp});
    var receipt = await ReceiptService.api.findAllInvoiceOutstanding({lawanTransaksiNpwp});
    this.setState({ perjanjianList: res.data, receiptList: receipt.data})
    this.setState({
      showPerjanjianForm: true
    })
  }


  @utilsService.Debounce(500)
  async calculate(v, field) {
    var summary = this.props.formData.summary;
    // console.log(summary, 'asdasdasd', this.props.formData)
    var totalItemPrice = 0;
    Object.keys(this.props.formData.itemMap).forEach((v, i)=> {
      if(this.props.formData.itemMap[v].total) totalItemPrice += parseFloat(this.props.formData.itemMap[v].total)
    })
    var c = {
      totalItemPrice      : utilsService.number.get(totalItemPrice),
      discountPercentage  : utilsService.number.get(summary.discountPercentage),
      discountAmount      : utilsService.number.get(summary.discountAmount),
      priceBeforeTax      : utilsService.number.get(summary.priceBeforeTax),
      taxPercentage       : utilsService.number.get(summary.taxPercentage),
      taxAmount           : utilsService.number.get(summary.taxAmount),
      priceAfterTax       : utilsService.number.get(summary.priceAfterTax),
      other1Amount        : utilsService.number.get(summary.other1Amount),
      other2Amount        : utilsService.number.get(summary.other2Amount),
      total               : utilsService.number.get(summary.total),
      spelledOut          : ""
    }
    if(field) c[field] = utilsService.number.get(v);

    c.discountAmount = c.discountPercentage*c.totalItemPrice/100;
    if(summary.isPpnIncluded) {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.priceBeforeTax = 100/(100+c.taxPercentage)*c.priceBeforeTax;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    } else {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    }

    c.total = c.priceAfterTax+c.other1Amount+c.other2Amount;
    // console.log(c.total, 'asdas')
    c.spelledOut = terbilang(parseInt(c.total));

    this.props.change('summary.totalItemPrice', c.totalItemPrice)
    this.props.change('summary.discountAmount', c.discountAmount)
    this.props.change('summary.priceBeforeTax', c.priceBeforeTax)
    this.props.change('summary.taxAmount', c.taxAmount)
    this.props.change('summary.priceAfterTax', c.priceAfterTax)
    this.props.change('summary.total', c.total)
    this.props.change('summary.spelledOut', c.spelledOut)
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)
    if(value.attachments.length <= 0) {
      iziToast.error({title: 'Error', message: 'Attachment harus di isi!'})

      return false
    }
    var items = [];
    delete value.lawanTransaksi.id;
    Object.keys(value.itemMap).forEach((k, i)=> {
      value.itemMap[k].date = value.date;
      items.push(value.itemMap[k])
    })

    value.items = items;
    delete value.itemMap;

    return value;
  }

  async handleAttachment(value) {
    value.path = value.name;
    value.invoiceId = this.props.formData.id;
    value.description = value.origin;
    if(this.props.formData.id) {
      await this.service.api.saveAttachment(this.props.formData.id, {attachments: [value]});

      iziToast.success({
        title: 'success',
        message: counterpart('entities.attachment.attachmentSuccess')
      })
      await this.initData()
    } else {
      await this.service.api.saveAttachment('11111111-1111-1111-1111-111111111111', {attachments: [value]});
      var attachments = [...this.props.formData.attachments]
      attachments.push(value)

      this.props.change('attachments', attachments)
    }
  }

  async handleDownloadAttachment(attachmentId) {
    let res = await this.service.api.downloadAttachment(this.props.formData.id, attachmentId);
    var filename = res.headers['content-disposition']
    if(!filename) filename = res.headers['authorization']
    if(filename) filename = filename.split('filename=').pop();
    if(!filename) filename = "report.xlsx"
    download(res.data, filename);
  }

  async handleDeleteAttachment(attachmentId, i) {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      text: 'Apakah anda yakin akan menghapus attachment ini?'
    }))
    if(dialog) {
      if(this.props.formData.id) {
        await this.service.api.deletedAttachment(this.props.formData.id, attachmentId);

        iziToast.success({title: 'Success', message: 'Attachment telah berhasil di hapus'})
        this.initData()
      } else {
        var attachments = [...this.props.formData.attachments]
        attachments.splice(i, 1)
        this.props.change('attachments', attachments)
      }
    }
  }


  handleAddItem = (value)=> {
    // var map = this.props.formData.itemMap;
    // var length = Object.keys(this.props.formData.itemMap).length
    // this.props.dispatch(this.props.change('itemMap.no'+length, {}))
    var data = {currency: 'Rupiah',incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, info: {company: {}, percentDppPpn: 100, dppPpn: 0}, ppn: 0, discount: 0, date: this.props.formData.date}
    if(this.props.formData && this.props.formData.locationOnRoot && this.props.formData.companyId) {
      data.info.companyId = this.props.formData.companyId
      data.info.company = this.state.companyMap[this.props.formData.companyId]

    }

    if(this.props.formData && this.props.formData.itemTypeOnRoot && this.props.formData.itemType) {
      data.itemType = this.props.formData.itemType
    }
    this.props.dispatch(initialize('InvoiceItemForm', data))
    this.setState({showDialog:true})
  }

  handleAddItemSuccess = (value)=> {
    // if(this.props.formData.id) {
    //   this.calculate();
    //   this.initData()
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    var length = Object.keys(this.props.formData.itemMap).length
    // console.log(value.itemIndex, 'asdasd')
    if(value.itemIndex || value.itemIndex == 0) length = value.itemIndex;
    this.props.dispatch(this.props.change('itemMap.no'+length, value))

    this.calculate()
  }

  handleDeleteItem = async (i) => {

    // if(this.props.formData.id) {
    //   var dialog = await this.context.showDialog((props, res, rej) =>({
    //     text: 'Apakah anda yakin akan menghapus item ini?'
    //   }))
    //   if(dialog) {
    //     await InvoiceItemService.api.delete(this.props.formData.itemMap['no'+i].id, this.props.formData.id);

    //     iziToast.success({title: 'Success', message: 'Item telah berhasil di hapus'})
    //     this.initData()
    //   }

    //   this.calculate();
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('itemMap', newMap))
    this.calculate()
  }

  handleEditItem = (data, i) => {
    let newData = _.cloneDeep(data);
    var incomeTypeMap = {};
    var receiptMap = {};
    if(newData.incomeTypes) {
      newData.incomeTypes.forEach((v, i)=> {
        incomeTypeMap['no'+i] = v;
      })
    }
    // if(newData.receipts) {
    //   newData.receipts.forEach((v, i)=> {
    //     receiptMap['no'+i] = v;
    //   })
    // }
    newData.itemIndex = i;
    newData.incomeTypeMap = incomeTypeMap;
    // newData.receiptMap = receiptMap;

    this.props.dispatch(initialize('InvoiceItemForm', newData))
    this.setState({showDialog:true})
  }

  async handleGetFaktur() {
    var data = _.cloneDeep(this.props.formData)
    var url = '';
    if(this.props.formData && this.props.formData.faktur && this.props.formData.faktur.url) url = this.props.formData.faktur.url
    var res = await this.service.api.getFaktur(url)
    if(res.data) {
      delete res.data.id;
      res.data.detail.forEach((v, i) => {
        delete v.id
      })

      data.faktur = {
        ...data.faktur,
        ...res.data
      }

      this.props.initialize(data)

      iziToast.success({
        title: 'Sukses',
        message: 'Data Faktur telah terambil, klick tombol simpan/save untuk menyimpan data tersebut.'
      })
    } else {
      console.log('error faktur')
    }
  }

  async handleUpdateData(d) {
    if(d.qr) {
      if(d.qr.data.search('http://svc.efaktur.pajak.go.id/validasi/faktur/') == -1) {
        iziToast.warning({
          title: 'Warning',
          message: 'Invalid scanned QRcode: '+d.qr.data
        })
      } else {
        await this.props.change('faktur.url', d.qr.data);
        this.handleGetFaktur()
      }
    } else {
      iziToast.warning({
        title: 'Warning',
        message: 'No QRcode Scanned : '+d.file.name
      })
    }
  }

  async handleImagesFiles(files) {
    if(files[0]) {
      var f = files[0];
      var res = await new Promise((resolve, reject)=> {
        var reader = new FileReader();
        reader.onload = (e)=> {
          var img = jpeg.decode(e.target.result, true);
          var qr = jsqr(img.data, img.width, img.height);
          resolve({qr: qr, file: f})
        }
        reader.readAsArrayBuffer(f)
      })

      this.handleUpdateData(res)

    }
  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Barang/Jasa',
      width: 500,
      initialValue: {ambil: true},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/pitem_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
          <Field
            label         = {'Ambil data lainnya dari barang/jasa pertama'}
            name          = 'ambil'
            className     = 'md-cell md-cell--12'
            component     = {Switch}
            // twoLines
            // validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = await PerjanjianService.api.import(value)
        if(uploadRes.data) {
          this.generateXlsData(uploadRes.data, value)
        }
      }
    }
  }

  generateXlsData(arr, opt) {
    var keys = Object.keys(this.props.formData.itemMap)
    var sample = {}
    if(opt.ambil ) {
      if(keys.length < 1) {
        iziToast.error({title: 'Failed', message: 'Data barang/item tidak ditemukan'})
        return false
      }
      sample = _.cloneDeep(this.props.formData.itemMap[keys[0]])
    }

    console.log(this.state.companies)

    bluebird.map(arr, async (v, i) => {
      var data = {}
      if(!opt.ambil) {
        data = this.buildData(v)
      } else {
        var x =
        console.log(sample, 'asdasd', keys, this.props.formData.itemMap)
        data = {..._.cloneDeep(sample), ...v}

      }
      var location = _.filter(this.state.companies, (d)=> d.code.includes(data.kodeCabang))[0]
      data.info.company = location?location:{name: data.kodeCabang+' Tidak Ditemukan', npwp: 'Tidak Ditemukan'}

      data.priceNett = (data.qty*data.price)-(data.discount*data.qty*data.price/100);
      data.total = data.priceNett+(data.ppn*data.priceNett/100)

      data.incomeTypes.forEach((k, i)=> {
        k.pph = data.priceNett*k.incomeType.rate/100
      })

      await this.handleAddItemSuccess(data)
    })
  }

  buildData(v) {
    var data = {location: {}, incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, discount: 0, ppn: 0, periode: {totalPerSatuan: 0, diBayarPerSatuan: 0, total:0}, info: {totalReceipted: 0, totalInvoiced: 0}}

    if(this.props.formData && this.props.formData.locationOnRoot && this.props.formData.companyId) {
      console.log( this.state.companyMap[this.props.formData.companyId], 'test')
      data.location = this.state.companyMap[this.props.formData.companyId]
    }

    if(this.props.formData && this.props.formData.itemTypeOnRoot && this.props.formData.itemType) {
      data.itemType = this.props.formData.itemType
    }

    if(this.props.formData && this.props.formData.termOfCondition && !this.props.formData.termOfCondition.isReceiptMultiLevelPartial) {
      data.isReceiptMultiLevel = this.props.formData.termOfCondition.isReceiptMultiLevel
    }

    return {...data, ...v}
  }
}
