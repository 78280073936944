import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Employee.dialog.view';
import EmployeeService from './Employee.service';
import ListView from '../../../../../components/entity/listView';

@connect(EmployeeService.stateConnectSetting(), EmployeeService.actionConnectSetting())
export default class EmployeeView extends ListView {
  showDeleteAll = true;
  service     = EmployeeService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = EmployeeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.company.title",                    value: "companyInfo.name"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.name",          value: "basicInfo.name"},
    {isSortable: true, show: true,   isSearchable:true, label: "entities.employee.basicInfo.npwp",          value: "basicInfo.npwp"},
    {isSortable: false, show: false,   isSearchable:true, label: "entities.employee.basicInfo.identityNo",    value: "basicInfo.identityNo"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.employee.jobInfo.employeeNo",    value: "jobInfo.employeeNo"}
  ]
}
