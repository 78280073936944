import React, { Component } from "react";
import { connect } from "react-redux";

// import FormDialog from './ItemType.dialog.view';
import BungaDepositoService from "./BungaDeposito.service";
import ListView from "../../../../components/entity/listView";
import { permission } from "../../../../config/constant";
import DefaultView from "../../../../components/entity/default.view";
import {
  Textfield,
  TextfieldMask,
  validation,
  Datepicker,
  Switch,
  Searchfield
} from "../../../../components/form";
import { Field, reduxForm, getFormValues } from "redux-form";
import counterpart from "counterpart";
import iziToast from "izitoast";
import { List, ListItem } from "react-md";
import utilsService from "../../../../services/utils.service";

@connect(
  state => ({
    ...BungaDepositoService.stateConnectSetting()(state),
    kp: state.auth.currentSPT,
    approvalSetting: state.auth.approvalSetting,
    user: state.auth.user
  }),
  BungaDepositoService.actionConnectSetting()
)
export default class BungaDepositoView extends DefaultView {
  service = BungaDepositoService;
  editDialog = false;
  addDialog = false;
  // FormDialog  = BungaDepositoDialog

  approvalSettingName = "Daftar Nominatif Biaya Promosi";
  checkerPermissionName = permission.MelakukanCheckKoreksiFiskal;
  approvalPermissionName = permission.MelakukanApproveKoreksiFiskal;

  beforeFetch(params) {
    params.status = [
      "TEST_STATUS",
      "TEST_STATUS_REFERENCE",
      "draft",
      "approved",
      "rejected",
      "checked"
    ];
  }

  fetchOption() {
    console.log(this.props.kp);
    return {
      path:
        "koreksi-fiskal/" + this.props.kp.data.id + "/bunga-deposito-tabungan"
    };
  }

  columns = [
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.perjanjian.statusWorkflow",
      value: d => {
        if (d.workflow.status == "draft")
          return (
            <span
              style={{
                backgroundColor: "#AAA",
                borderRadius: 4,
                padding: "4px 8px"
              }}
            >
              {d.workflow.status.toUpperCase()}
            </span>
          );
        if (d.workflow.status == "rejected")
          return (
            <span
              style={{
                backgroundColor: "#ffcdd2",
                borderRadius: 4,
                padding: "4px 8px"
              }}
            >
              {d.workflow.status.toUpperCase()}
            </span>
          );
        if (d.workflow.status == "approved")
          return (
            <span
              style={{
                backgroundColor: "#c8e6c9",
                borderRadius: 4,
                padding: "4px 8px"
              }}
            >
              {d.workflow.status.toUpperCase()}
            </span>
          );
        if (d.workflow.status == "checked")
          return (
            <span
              style={{
                backgroundColor: "#ffe0b2",
                borderRadius: 4,
                padding: "4px 8px"
              }}
            >
              {d.workflow.status.toUpperCase()}
            </span>
          );
      },
      type: "func"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.company.npwp",
      value: "company.npwp"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.company.name",
      value: "company.name"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.masa",
      value: "masa",
      isDefaultSort: true
    },
    // {
    //   isSortable: true,
    //   show: true,
    //   isSearchable: true,
    //   label: "entities.bungaDeposito.jumlahNasabah",
    //   value: "jumlahNasabah",
    //   type: "number"
    // },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.nilaiObjekPajak",
      value: (d)=> utilsService.number.format(d.nilaiObjekPajak+d.transaksiNilaiObjekPajak),
      type: "func"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.pphDiPotong",
      type: "func",
      value: (d)=> utilsService.number.format(d.pphDiPotong+d.transaksiPphDiPotong),
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.nilaiObjekPajak2",
      value: (d)=> utilsService.number.format(d.nilaiObjekPajak3+d.transaksiNilaiObjekPajak3),
      type: "func"
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.pphDiPotong2",
      type: "func",
      value: (d)=> utilsService.number.format(d.pphDiPotong3+d.transaksiPphDiPotong3),
    },
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.bungaDeposito.statusEppt",
      value: "eppt.status"
    }
  ];

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label="Masa"
          name="month"
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options={[
            { id: "1", name: "Januari" },
            { id: "2", name: "Februari" },
            { id: "3", name: "Maret" },
            { id: "4", name: "April" },
            { id: "5", name: "Mei" },
            { id: "6", name: "Juni" },
            { id: "7", name: "Juli" },
            { id: "8", name: "Agustus" },
            { id: "9", name: "September" },
            { id: "10", name: "Oktober" },
            { id: "11", name: "November" },
            { id: "12", name: "Desember" }
          ]}
        />
      </this.SearchContainer>
    );
  }

  tableActions = d => {
    var permissionMap = this.props.user.permissionMap;
    var menus = [
      {
        label: "More Info",
        iconClassName: "mdi mdi-bell",
        onClick: item => this.editItem(item)
      },
      {
        label: "Cek Status Kirim",
        iconClassName: "mdi mdi-information",
        onClick: item => this.handleCheckStatus(item)
      },
      { label: "divider", iconClassName: "-" },
      {
        label: "Delete",
        iconClassName: "mdi mdi-delete",
        onClick: (item, callback) => this.deleteItem(item, callback),
        confirmation: {
          title: "sentence.custom.deleteItem",
          message: "sentence.custom.deleteItemConfirmation"
        }
      },
      { label: "divider", iconClassName: "-" }
    ];

    if (this.props.approvalSetting[this.approvalSettingName]) {
      if (
        d.workflow.status == "draft" &&
        this.props.user.permissionMap[this.checkerPermissionName]
      ) {
        menus.push({
          label: "Proses Check",
          iconClassName: "mdi mdi-format-list-checks",
          onClick: item => this.handleCheck(item),
          disabled: true
        });
      }
      if (
        d.workflow.status == "checked" &&
        this.props.user.permissionMap[this.approvalPermissionName]
      ) {
        menus.push({
          label: "Proses Approval",
          iconClassName: "mdi mdi-clipboard-check-outline",
          onClick: item => this.handleApproval(item)
        });
      }

      if (
        (d.workflow.status == "approved" || d.workflow.status == "rejected") &&
        this.props.user.permissionMap[this.approvalPermissionName]
      ) {
        menus.push({
          label: "Batalkan Approval",
          iconClassName: "mdi mdi-undo",
          onClick: item => this.unApprove(item)
        });
      }
    } else {
      menus.push({
        label: "Proses Approval",
        iconClassName: "mdi mdi-clipboard-check-outline",
        onClick: item => this.handleApproval(item)
      });
    }

    return menus;
  };

  barActions = [
    {
      label: "word.create",
      iconClassName: "mdi mdi-plus",
      onClick: () => this.addItem()
    },
    // {
    //   label:'entities.biayaPromosi.import',
    //   iconClassName:'mdi mdi-import',
    //   onClick:() => this.handleImport()
    // },
    {
      label: "entities.bungaDeposito.sendToEppt",
      iconClassName: "mdi mdi-export",
      onClick: () => this.handleSendToEppt(),
      disabledFunc: () => this.props.table.selected.length === 0
    },
    {
      label: "word.refresh",
      iconClassName: "mdi mdi-refresh",
      onClick: () => {
        window.location.hash = window.location.hash.split("?")[0];
        this.fetchData();
      }
    },
    {
      label: "word.delete",
      iconClassName: "mdi mdi-delete",
      onClick: () => {
        this.setState({ showDialogConfirmDeleteSelected: true });
      },
      disabledFunc: () => this.props.table.selected.length === 0
    }
  ];

  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item.id, item.tahun.id);
      callback();
      await this.fetchData();
    } catch (e) {
      callback(e, e);
    }
  };

  async handleSendToEppt() {
    console.log(this.props.table);
    var d = await this.context.showDialog((props, res, rej) => ({
      title: counterpart.translate("entities.bungaDeposito.sendToEppt"),
      width: 500,
      initialValue: {},
      okText: "Submit",
      text: (
        <div style={{ width: "100%" }}>
          <p style={{ padding: "0px 24px" }}>
            Apakah anda yakin akan mengirim data dibawah ini?
          </p>
          <List style={{ width: "100%" }}>
            {this.props.table.selected.map((v, i) => {
              return (
                <ListItem
                  primaryText={`${v.company.name} (${v.company.npwp})`}
                  secondaryText={`${v.tahun.tahun} - ${v.masa}`}
                  key={i}
                />
              );
            })}
          </List>
        </div>
      )
    }));

    if (d) {
      var data = {
        ids: this.props.table.selected.map((v, i) => v.id)
      };
      await this.service.api.sendToEppt(this.props.kp.data.id, data);
      iziToast.success({
        title: "Success",
        message:
          "Data sedang dikirim, mohon cek status kirim di kolom Status EPPT"
      });
      this.fetchData();
    }
  }

  async handleCheckStatus(item) {
    var d = await this.context.showDialog((props, res, rej) => ({
      title: counterpart.translate("entities.bungaDeposito.statusEppt"),
      width: 500,
      initialValue: {},
      cancelText: "Submit",
      actions: [
        {
          children: "Close",
          onClick: () => {
            res(false);
          }
        }
      ],
      text: (
        <div style={{ width: "100%" }}>
          <List style={{ width: "100%" }}>
            <ListItem primaryText="Status" secondaryText={item.eppt.status} />
            <ListItem primaryText="Message" secondaryText={item.eppt.message} />
          </List>
        </div>
      )
    }));
  }

  async handleImport() {
    var d = await this.context.showDialog((props, res, rej) => ({
      title: counterpart.translate("entities.biayaPromosi.import"),
      initialValue: {},
      okText: "Submit",
      contentStyle: { overflowY: "inherit", overflow: "inherit" },
      text: (
        <div className="md-grid">
          <Field
            label={counterpart("entities.biayaPromosi.masa")}
            name="masa"
            className="md-cell md-cell--12"
            component={Searchfield}
            validate={validation.required}
            options={[
              { id: "1", name: "Januari" },
              { id: "2", name: "Februari" },
              { id: "3", name: "Maret" },
              { id: "4", name: "April" },
              { id: "5", name: "Mei" },
              { id: "6", name: "Juni" },
              { id: "7", name: "Juli" },
              { id: "8", name: "Agustus" },
              { id: "9", name: "September" },
              { id: "10", name: "Oktober" },
              { id: "11", name: "November" },
              { id: "12", name: "Desember" }
            ]}
          />
        </div>
      )
    }));

    if (d) {
      await this.service.api.pull(this.props.kp.data.id, d);
      iziToast.success({
        title: "Success",
        message: "Data telah berhasil di impor"
      });

      this.fetchData();
    }
  }
}
