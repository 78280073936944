import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import MetodePenyusutanFiskalService from './MetodePenyusutanFiskal.service';

@reduxForm({form: 'MetodePenyusutanFiskalForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('MetodePenyusutanFiskalForm')(state)
}))
export default class MetodePenyusutanFiskalDialog extends DialogView {
  service     = MetodePenyusutanFiskalService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.metodePenyusutanFiskal.code')}
          name          = 'code'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.metodePenyusutanFiskal.name')}
          name          = 'name'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
