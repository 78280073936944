import ApiService from '../../../../services/api.service';

class PenomoranBp23Service extends ApiService {
  name  = 'penomoranBp23';
  path  = 'master/penomoran-bp23';
  constructor() {
    super();
    this.init()
  }
}


export default new PenomoranBp23Service();
