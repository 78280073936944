import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import UserService from './User.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import counterpart from 'counterpart';
import uploadService from '../upload/upload.service';
import iziToast from 'izitoast';

@connect(UserService.stateConnectSetting(), UserService.actionConnectSetting())
export default class UserView extends ListView {
  service     = UserService
  addDialog  = false
  editDialog = false

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.login",     value: "login",   isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.name",      value: "name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.user.email",     value: "email"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.user.active",    value: "active",   type: "boolean"},
    {isSortable: true,  show: true,   isSearchable:false, label: "entities.user.role",      value: "role"}

  ]


  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleUpload()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    {
      label:"More Info",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.editItem(item)
    },
    {
      label:"Change Role",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.handleChangeRole(item)
    },
    {label:"divider", iconClassName:"-"},
    {
      label:"Delete",
      iconClassName:"mdi mdi-delete",
      onClick:(item, callback) => this.deleteItem(item, callback),
      confirmation:{
        title:"sentence.custom.deleteItem",
        message:"sentence.custom.deleteItemConfirmation"
      }
    }


  ]

  async handleChangeRole(item) {
    await this.props.dispatch(initialize('UserRoleForm', {companys: [], companyMap: {}, menus: [], user: item, hasBranch: false}))
    setTimeout(()=> {
      this.props.history.push('/'+this.service.name+"-role/change");
    }, 500)

  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import User',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/user.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }

}
