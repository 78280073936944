import ApiService from '../../../../services/api.service';

class LawanTransaksiService extends ApiService {
  name  = 'lawanTransaksi';
  path  = 'master/lawanTransaksi';
  constructor() {
    super();
    this.init()
  }

  api = {
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    }
  }
}


export default new LawanTransaksiService();
