import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import _ from 'lodash';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PerjanjianService from './Perjanjian.service';
import CompanyService from '../../Admin/Company/Company.service';
import LawanTransaksiService from '../../Admin/LawanTransaksi/LawanTransaksi.service';
import PerjanjianItemDialog from './PerjanjianItem.dialog';
import utilsService from '../../../../services/utils.service';
import terbilang, { doTerbilang } from '../../../../services/terbilang.service';
import DocumentTypeService from '../../Admin/DocumentType/DocumentType.service';
import ItemTypeService from '../../Admin/ItemType/ItemType.service';
import UploadDialog from '../../Admin/upload/upload.dialog';
import PerjanjianItemService from './PerjanjianItem.service';
import IncomeTypeService from '../../Admin/IncomeType/IncomeType.service';
import CoaService from '../../Admin/Coa/Coa.service';
import uploadService from '../../Admin/upload/upload.service';
import bluebird from 'bluebird'

@reduxForm({form: 'PerjanjianForm', destroyOnUnmount: true, initialValues: {
  items: [], itemMap: {}, termOfCondition: {}
}})
@connect((state) => ({
  formData: getFormValues('PerjanjianForm')(state)
}))
export default class PerjanjianForm extends FormView {
  service     = PerjanjianService
  viewType    = 2;
  initialData = {
    items: [],
    workflow: {
      status: 'draft'
    },
    itemMap: {},
    receipts: [],
    receiptMap: {},
    locationOnRoot: false,
    itemTypeOnRoot: false,
    attachments: [],
    "summary": {
      "totalItemPrice": 0,
      "totalItemPrice": 0,
      "taxPercentage": 0,
      "taxAmount": 0,
      "discountPercentage": 0,
      "discountAmount": 0,
      "priceBeforeTax": 0,
      "priceAfterTax": 0,
      "other1Name": "",
      "other1Amount": 0,
      "other2Name": "",
      "other2Amount": 0,
      "total": 0,
      "spelledOut": "",
      "isPpnIncluded": false
    },
    "termOfCondition": {
      "isReceiptNeeded": false,
      "isReceiptGenerateTax": false,
      "isReceiptPartial": false,
      "isReceiptShowPrice": false,
      "isReceiptMultiLevelPartial": false,
      "isReceiptMultiLevel": false,
      "isPPnCalculatePerItem": true,
      "isDiscountCalculatePerItem": true
    }
  }

  formView() {
    if(this.state.warmUp) return <div/>
    var data =  this.props.formData
    return (
      <div>
        <PerjanjianItemDialog
          height={400}
          visible={this.state.showDialog}
          // add={this.props.tableActions.addDataItem}
          incomeTypes={this.state.incomeTypes}
          coas={this.state.coas}
          companies={this.state.companies}
          parentData={this.props.formData}
          formData={this.state.formData}
          onSuccess={(value)=> {this.handleAddItemSuccess(value)}}
          onClose={() => this.setState({showDialog:false})}
          match={this.props.match}
        />
        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.category')}
              name          = 'category'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {this.state.documentTypes}
              validate      = {validation.required}
              params        = {{page: 1, size: 1000, category: 'PO', sort: -1, sortBy: 'name', field: 'category', query: 'PO'}}
              apiPath       = 'lookup'
              valueField    = 'name'
            />
            <Field
              label         = {counterpart('entities.perjanjian.no')}
              name          = 'no'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.date')}
              name          = 'date'
              className     = 'md-cell md-cell--6'
              component     = {Datepicker}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.reference')}
              name          = 'reference'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.perjanjian.lawanTransaksi.title').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.title')}
              name          = 'lawanTransaksiId'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              service       = {LawanTransaksiService}
              onChange      = {(data) => {
                this.props.change('lawanTransaksi', data)
              }}
              valueField    = 'parent'
            />

            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.name')}
              name          = 'lawanTransaksi.name'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.npwp')}
              name          = 'lawanTransaksi.npwp'
              className     = 'md-cell md-cell--6'
              component     = {TextfieldMask}
              maskFormat    = "##.###.###.#-###-###"
              mask          = "_"
              length        = {15}
              validate      = {validation.required}
            />
            <Field
              label         = 'Nik'
              name          = 'lawanTransaksi.nik'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.address')}
              name          = 'lawanTransaksi.address'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.postal')}
              name          = 'lawanTransaksi.postal'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.phone')}
              name          = 'lawanTransaksi.phone'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = 'Provinsi'
              name          = 'lawanTransaksi.provinsi'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kabupaten'
              name          = 'lawanTransaksi.kabupaten'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kecamatan'
              name          = 'lawanTransaksi.kecamatan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kelurahan'
              name          = 'lawanTransaksi.kelurahan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.perjanjian.lawanTransaksi.email')}
              name          = 'lawanTransaksi.email'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.perjanjian.setting').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.locationOnRoot')}
              name          = 'locationOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.locationOnRoot) &&
            <Field
              label         = {counterpart('entities.company.title')}
              name          = 'companyId'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              // service       = {CompanyService}
              options       = {this.state.companies}
              itemTemplate   = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              valueField    = 'id'
            />
            }
          </div>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.itemTypeOnRoot')}
              name          = 'itemTypeOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.itemTypeOnRoot) &&
            <Field
              label         = {counterpart('entities.perjanjian.itemType')}
              name          = 'itemType'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              service       = {ItemTypeService}
              valueField    = 'name'
            />
            }

            {/* <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isDiscountCalculatePerItem')}
              name          = 'termOfCondition.isDiscountCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />

            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isPPnCalculatePerItem')}
              name          = 'termOfCondition.isPPnCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}

            {/* <Field
              label         = {counterpart('entities.perjanjian.summary.isPpnIncluded')}
              name          = 'summary.isPpnIncluded'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}

            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptNeeded')}
              name          = 'termOfCondition.isReceiptNeeded'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.termOfCondition && this.props.formData.termOfCondition.isReceiptNeeded) &&
            <div>
            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptGenerateTax')}
              name          = 'termOfCondition.isReceiptGenerateTax'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />
            {/* <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptPartial')}
              name          = 'termOfCondition.isReceiptPartial'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />
            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptShowPrice')}
              name          = 'termOfCondition.isReceiptShowPrice'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />
            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptMultiLevelPartial')}
              name          = 'termOfCondition.isReceiptMultiLevelPartial'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />
            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isReceiptMultiLevel')}
              name          = 'termOfCondition.isReceiptMultiLevel'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}
            </div>
            }
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.perjanjian.item.title').toUpperCase()} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>Perusahaan/Cabang</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.code').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.name').toUpperCase()}</TableColumn>
                {/* <TableColumn>{counterpart('entities.perjanjian.item.description').toUpperCase()}</TableColumn> */}
                <TableColumn>{counterpart('entities.perjanjian.item.price').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.qty').toUpperCase()}</TableColumn>
                {(this.props.formData.workflow && this.props.formData.workflow.status == 'approved') &&
                    <React.Fragment>
                      <th className='md-table-column md-text-left md-table-column--header md-table-column--adjusted md-text--secondary'>{counterpart('entities.perjanjian.item.totalReceipted').toUpperCase()}</th>
                      <th className='md-table-column md-text-left md-table-column--header md-table-column--adjusted md-text--secondary'>{counterpart('entities.perjanjian.item.totalInvoiced').toUpperCase()}</th>
                    </React.Fragment>
                  }
                <TableColumn>{counterpart('entities.perjanjian.item.ppn').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.total').toUpperCase()}</TableColumn>

              </TableRow>
            </TableHeader>
            <TableBody>
            {Object.keys(this.props.formData.itemMap).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    <div className="buttons__group">
                      <Button flat iconChildren="edit_outline" forceIconSize={20} onClick={()=> this.handleEditItem(this.props.formData.itemMap[key], i)}>Edit</Button>
                      <Button flat primary iconChildren="delete_outline" forceIconSize={20} onClick={()=> this.handleDeleteItem(i, this.props.formData.itemMap[key])}>Delete</Button>
                    </div>
                  </TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].location.name +" - "+ this.props.formData.itemMap[key].location.npwp}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].code}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].name}</TableColumn>
                  {/* <TableColumn>{this.props.formData.itemMap[key].description}</TableColumn> */}
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].price)}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].qty}</TableColumn>
                  {(this.props.formData.workflow && this.props.formData.workflow.status == 'approved') &&
                    <React.Fragment>
                      <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].info.totalReceipted)}</TableColumn>
                      <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].info.totalInvoiced)}</TableColumn>
                    </React.Fragment>
                  }
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].ppn)}%</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].total)}</TableColumn>
                  </TableRow>
            })}
            </TableBody>
          </DataTable>
          <div className='mpk-layout' style={{padding: 8}}>
              <div className='flex'></div>
              <Button style={{marginRight: 10}} flat swapTheming onClick={() => {this.handleUpload()}}>Import</Button>
              <Button flat primary swapTheming onClick={this.handleAddItem}>{counterpart('entities.perjanjian.action.addItem')}</Button>
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.perjanjian.summary.title').toUpperCase()} />
          <Divider/>

          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.summary.total')}
              name          = 'summary.totalItemPrice'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              disabled
              money={','}
            />
            {!data.termOfCondition.isDiscountCalculatePerItem &&
            <React.Fragment>
            <Field
              label         = {counterpart('entities.perjanjian.summary.discountPercentage')}
              name          = 'summary.discountPercentage'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled      = {this.props.formData.termOfCondition.isDiscountCalculatePerItem}
              onChange      = {(e, v)=> {this.calculate(v, 'discountPercentage')}}
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.discountAmount')}
              name          = 'summary.discountAmount'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            </React.Fragment>
            }

            {!data.termOfCondition.isPPnCalculatePerItem &&
              <React.Fragment>
                <Field
                  label         = {counterpart('entities.perjanjian.summary.priceBeforeTax')}
                  name          = 'summary.priceBeforeTax'
                  className     = 'md-cell md-cell--12 '
                  component     = {TextfieldMask}
                  money={','}
                  disabled
                />
                <Field
                  label         = {counterpart('entities.perjanjian.summary.taxPercentage')+' (%)'}
                  name          = 'summary.taxPercentage'
                  className     = 'md-cell md-cell--6 '
                  component     = {TextfieldMask}
                  money={','}
                  disabled      = {this.props.formData.termOfCondition.isPPnCalculatePerItem}
                  onChange      = {(e, v)=> {this.calculate(v, 'taxPercentage')}}
                />
                <Field
                  label         = {counterpart('entities.perjanjian.summary.taxAmount')}
                  name          = 'summary.taxAmount'
                  className     = 'md-cell md-cell--6 '
                  component     = {TextfieldMask}
                  money={','}
                  disabled
                />
                <Field
                  label         = {counterpart('entities.perjanjian.summary.priceAfterTax')}
                  name          = 'summary.priceAfterTax'
                  className     = 'md-cell md-cell--12 '
                  component     = {TextfieldMask}
                  money={','}
                  disabled
                />
              </React.Fragment>
            }

            <Field
              label         = {counterpart('entities.perjanjian.summary.other1Name')}
              name          = 'summary.other1Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.other1Amount')}
              name          = 'summary.other1Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other1Amount')}}
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.other2Name')}
              name          = 'summary.other2Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.other2Amount')}
              name          = 'summary.other2Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other2Amount')}}
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.total')}
              name          = 'summary.total'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.perjanjian.summary.spelledOut')}
              name          = 'summary.spelledOut'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              disabled
            />
          </div>

        </Card>
        <br/>
        <Card>
          {this.props.formData.termOfCondition.isReceiptMultiLevel &&
            <div>
            <DataTable baseId="simple-pagination" plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No. </TableColumn>
                  <TableColumn>{counterpart('entities.perjanjian.item.receipt.no').toUpperCase()}</TableColumn>
                  <TableColumn>{counterpart('entities.perjanjian.item.receipt.name').toUpperCase()}</TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Object.keys(this.props.formData.receiptMap).map((key, i)=> {
                  return <TableRow key={i} selectable={false}>
                    <TableColumn>{i+1}</TableColumn>
                    <TableColumn>
                      <Field
                        label         = {counterpart('entities.perjanjian.item.receipt.no')}
                        name          = {'receiptMap.no'+i+'.no'}
                        className     = 'md-cell md-cell--6'
                        component     = {TextfieldMask}
                        money         = {","}
                      />
                    </TableColumn>
                    <TableColumn>
                      <Field
                        label         = {counterpart('entities.perjanjian.item.receipt.name')}
                        name          = {'receiptMap.no'+i+'.name'}
                        className     = 'md-cell md-cell--6'
                        component     = {Textfield}
                      />
                    </TableColumn>
                    <TableColumn>
                      <Field
                        label         = {counterpart('entities.perjanjian.item.receipt.isLast')}
                        name          = {'receiptMap.no'+i+'.isLast'}
                        className     = 'md-cell md-cell--6'
                        component     = {Switch}
                      />
                    </TableColumn>
                    <TableColumn>
                      <div className='mpk-layout'>
                        <div className='flex' />
                        <Button flat primary swapTheming onClick={() => this.handleDeleteReceipt(i)}>Delete</Button>
                      </div>
                    </TableColumn>
                  </TableRow>
                })}
              </TableBody>
            </DataTable>
            <div className='mpk-layout' style={{padding: 8}}>
                <div className='flex'></div>

                <Button flat primary swapTheming onClick={() => {this.handleAddReceipt()}}>{counterpart('entities.perjanjian.add')}</Button>
            </div>
          </div>}
        </Card>
        <br/>
        {this.attachmentView()}
      </div>
    )
  }

  attachmentView() {
    // if(!this.props.formData.id) return <div/>
    if(!this.props.formData.attachments) return <div/>

    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.attachment.title').toUpperCase()} />
      <Divider/>

      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={()=> {this.setState({showUpDialog: true})}}>{counterpart('entities.attachment.add')}</Button>
      </div>

      <List>
        {this.props.formData.attachments.map((v, i) => {
          return <ListItem primaryText={v.description} secondaryText={v.path} key={i} onClick={()=> this.handleDownloadAttachment(v.id)}
            rightIcon={<FontIcon onClick={(e)=> {e.stopPropagation(); this.handleDeleteAttachment(v.id, i)}}>close</FontIcon>}
          />
        })}
      </List>

      <UploadDialog
          height={400}
          visible={this.state.showUpDialog}
          formData={{}}
          onSuccess={(e, value)=> {this.handleAttachment(e, value)}}
          onClose={() => this.setState({showUpDialog:false})}
          match={this.props.match}
        />
    </Card>
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      showDialog: false,
      formData: {},
      showUpDialog: false,
      documentTypes: [],
      documentTypeParam: [],
      warmUp: true,
      incomeTypes: [],
      coas: [],
      companies: [],
      companyMap: {}
    }
  }

  async initData() {

    await this.getDocumentType()

    let companyRes = await CompanyService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(companyRes.data) {
      var companyMap = companyRes.data.data.reduce((pv, cv) => {
        pv[cv.id] = cv
        return pv;
      }, {})
      this.setState({companies: companyRes.data.data, companyMap})
    }

    // get income types
    let incomeTypeRes = await IncomeTypeService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    if(incomeTypeRes.data) this.setState({incomeTypes: incomeTypeRes.data.data})
    // get coa
    let coaRes = await CoaService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    });
    if(coaRes.data) this.setState({coas: coaRes.data.data})

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      var itemMap = {}
      var receiptMap = {}
      var totalItemPrice = 0;
      res.data.items.forEach((v, i)=> {
        itemMap['no'+i] = v;
        if(v.ppn == 10) v.adaPPN = true;
        if(v.total) totalItemPrice += parseFloat(v.total)
      })

      if(res.data.receipts) {
        res.data.receipts.forEach((v, i)=> {
          receiptMap['no'+i] = v;
        })
      }

      res.data.receiptMap = receiptMap;
      res.data.itemMap = itemMap;
      res.data.summary.totalItemPrice = totalItemPrice;

      this.props.initialize(res.data);
    }
  }

  async getDocumentType() {
    var res = await DocumentTypeService.api.find({page: 1, size: 1000, category: 'PO', sort: -1, sortBy: 'name', field: 'category', query: 'PO'});
    if(res.data) {
      var categoryParam = res.data.data.map((v) => {
        return v.name
      })

      this.setState({documentTypes: res.data.data, documentTypeParam: categoryParam, warmUp: false})
    }
  }

  @utilsService.Debounce(500)
  async calculate(v, field) {
    var summary = this.props.formData.summary;

    var totalItemPrice = 0;
    Object.keys(this.props.formData.itemMap).forEach((v, i)=> {
      if(this.props.formData.itemMap[v].total) totalItemPrice += parseFloat(this.props.formData.itemMap[v].total)
    })

    var c = {
      totalItemPrice      : utilsService.number.get(totalItemPrice),
      discountPercentage  : utilsService.number.get(summary.discountPercentage),
      discountAmount      : utilsService.number.get(summary.discountAmount),
      priceBeforeTax      : utilsService.number.get(summary.priceBeforeTax),
      taxPercentage       : utilsService.number.get(summary.taxPercentage),
      taxAmount           : utilsService.number.get(summary.taxAmount),
      priceAfterTax       : utilsService.number.get(summary.priceAfterTax),
      other1Amount        : utilsService.number.get(summary.other1Amount),
      other2Amount        : utilsService.number.get(summary.other2Amount),
      total               : utilsService.number.get(summary.total),
      spelledOut          : ""
    }
    if(field) c[field] = utilsService.number.get(v);

    c.discountAmount = c.discountPercentage*c.totalItemPrice/100;
    if(summary.isPpnIncluded) {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.priceBeforeTax = 100/(100+c.taxPercentage)*c.priceBeforeTax;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    } else {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    }

    c.total = c.priceAfterTax+c.other1Amount+c.other2Amount;
    // console.log(c.total, 'asdas')
    c.spelledOut = terbilang(parseInt(c.total));

    this.props.change('summary.totalItemPrice', c.totalItemPrice)
    this.props.change('summary.discountAmount', c.discountAmount)
    this.props.change('summary.priceBeforeTax', c.priceBeforeTax)
    this.props.change('summary.taxAmount', c.taxAmount)
    this.props.change('summary.priceAfterTax', c.priceAfterTax)
    this.props.change('summary.total', c.total)
    this.props.change('summary.spelledOut', c.spelledOut)
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)

    if(value.attachments.length <= 0) {
      iziToast.error({title: 'Error', message: 'Attachment harus di isi!'})

      return false
    }

    var items = [];
    delete value.lawanTransaksi.id;
    Object.keys(value.itemMap).forEach((k, i)=> {
      items.push(value.itemMap[k])
    })

    value.items = items;
    delete value.itemMap;

    var receipts = [];
      Object.keys(value.receiptMap).forEach((k, i)=> {
        receipts.push(value.receiptMap[k])
      })

      value.receipts = receipts;
      delete value.receiptMap;

    return value;
  }

  async handleAttachment(value) {
    value.path = value.name;
    value.perjanjianId = this.props.formData.id;
    value.description = value.origin;

    // console.log(value, 'asdasd')
    if(this.props.formData.id) {
      await this.service.api.saveAttachment(this.props.formData.id, {attachments: [value]});

      iziToast.success({
        title: 'success',
        message: counterpart('entities.attachment.attachmentSuccess')
      })
      await this.initData()
    } else {
      await this.service.api.saveAttachment('11111111-1111-1111-1111-111111111111', {attachments: [value]});
      var attachments = [...this.props.formData.attachments]
      attachments.push(value)

      this.props.change('attachments', attachments)
    }
  }

  async handleDownloadAttachment(attachmentId) {
    let res = await this.service.api.downloadAttachment(this.props.formData.id, attachmentId);
    var filename = res.headers['content-disposition']
    if(!filename) filename = res.headers['authorization']
    if(filename) filename = filename.split('filename=').pop();
    if(!filename) filename = "report.xlsx"
    download(res.data, filename);
  }

  async handleDeleteAttachment(attachmentId, i) {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      text: 'Apakah anda yakin akan menghapus attachment ini?'
    }))
    if(dialog) {
      if(this.props.formData.id) {
        await this.service.api.deletedAttachment(this.props.formData.id, attachmentId);

        iziToast.success({title: 'Success', message: 'Attachment telah berhasil di hapus'})
        this.initData()
      } else {
        var attachments = [...this.props.formData.attachments]
        attachments.splice(i, 1)
        this.props.change('attachments', attachments)
      }
    }
  }

  handleAddItem = (value)=> {
    // var map = this.props.formData.itemMap;
    // var length = Object.keys(this.props.formData.itemMap).length
    // this.props.dispatch(this.props.change('itemMap.no'+length, {}))
    var data = {currency: 'Rupiah',location: {},incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, discount: 0, ppn: 0, periode: {totalPerSatuan: 0, diBayarPerSatuan: 0, total:0}, info: {totalReceipted: 0, totalInvoiced: 0,percentDppPpn: 100, dppPpn: 0}}

    if(this.props.formData && this.props.formData.locationOnRoot && this.props.formData.companyId) {
      console.log( this.state.companyMap[this.props.formData.companyId], 'test')
      data.location = this.state.companyMap[this.props.formData.companyId]
    }

    if(this.props.formData && this.props.formData.itemTypeOnRoot && this.props.formData.itemType) {
      data.itemType = this.props.formData.itemType
    }

    if(this.props.formData && this.props.formData.termOfCondition && !this.props.formData.termOfCondition.isReceiptMultiLevelPartial) {
      data.isReceiptMultiLevel = this.props.formData.termOfCondition.isReceiptMultiLevel
    }


    this.props.dispatch(initialize('PerjanjianItemForm', data))
    this.setState({showDialog:true})
  }

  handleAddItemSuccess = async (value)=> {
    // if(this.props.formData.id) {
    //   this.calculate();
    //   this.initData()
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    var length = Object.keys(this.props.formData.itemMap).length
    // console.log(value.itemIndex, 'asdasd')
    if(value.itemIndex || value.itemIndex == 0) length = value.itemIndex;
    await this.props.dispatch(this.props.change('itemMap.no'+length, value))

    this.calculate()
  }

  handleDeleteItem = async (i) => {
    // if(this.props.formData.id) {
    //   var dialog = await this.context.showDialog((props, res, rej) =>({
    //     text: 'Apakah anda yakin akan menghapus item ini?'
    //   }))
    //   if(dialog) {
    //     await PerjanjianItemService.api.delete(this.props.formData.itemMap['no'+i].id, this.props.formData.id);

    //     iziToast.success({title: 'Success', message: 'Item telah berhasil di hapus'})
    //     this.initData()
    //   }

    //   this.calculate();
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    await this.props.dispatch(this.props.change('itemMap', newMap))

    this.calculate()
  }

  handleEditItem = (data, i) => {
    let newData = _.cloneDeep(data);
    var incomeTypeMap = {};
    var receiptMap = {};
    if(newData.incomeTypes) {
      newData.incomeTypes.forEach((v, i)=> {
        incomeTypeMap['no'+i] = v;
      })
    }
    if(newData.receipts) {
      newData.receipts.forEach((v, i)=> {
        receiptMap['no'+i] = v;
      })
    }
    newData.itemIndex = i;
    newData.incomeTypeMap = incomeTypeMap;
    newData.receiptMap = receiptMap;

    this.props.dispatch(initialize('PerjanjianItemForm', newData))
    this.setState({showDialog:true})
  }

  handleAddReceipt = ()=> {
    var map = this.props.formData.receiptMap;
    var length = Object.keys(this.props.formData.receiptMap).length
    this.props.dispatch(this.props.change('receiptMap.no'+length, {isLast: false}))
  }

  handleDeleteReceipt = (i) => {
    var map = this.props.formData.receiptMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('receiptMap', newMap))
  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Barang/Jasa',
      width: 500,
      initialValue: {ambil: true},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/pitem_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
          <Field
            label         = {'Ambil data lainnya dari barang/jasa pertama'}
            name          = 'ambil'
            className     = 'md-cell md-cell--12'
            component     = {Switch}
            // twoLines
            // validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = await this.service.api.import(value)
        if(uploadRes.data) {
          this.generateXlsData(uploadRes.data, value)
        }
      }
    }
  }

  generateXlsData(arr, opt) {
    var keys = Object.keys(this.props.formData.itemMap)
    var sample = {}
    if(opt.ambil ) {
      if(keys.length < 1) {
        iziToast.error({title: 'Failed', message: 'Data barang/item tidak ditemukan'})
        return false
      }
      sample = _.cloneDeep(this.props.formData.itemMap[keys[0]])
    }

    console.log(this.state.companies)

    bluebird.map(arr, async (v, i) => {
      var data = {}
      if(!opt.ambil) {
        data = this.buildData(v)
      } else {
        var x =
        console.log(sample, 'asdasd', keys, this.props.formData.itemMap)
        data = {..._.cloneDeep(sample), ...v}

      }
      var location = _.filter(this.state.companies, (d)=> d.code.includes(data.kodeCabang))[0]
      data.location = location?location:{name: data.kodeCabang+' Tidak Ditemukan', npwp: 'Tidak Ditemukan'}

      data.priceNett = (data.qty*data.price)-(data.discount*data.qty*data.price/100);
      data.total = data.priceNett+(data.ppn*data.priceNett/100)


      await this.handleAddItemSuccess(data)
    })
  }

  buildData(v) {
    var data = {location: {}, incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, discount: 0, ppn: 0, periode: {totalPerSatuan: 0, diBayarPerSatuan: 0, total:0}, info: {totalReceipted: 0, totalInvoiced: 0}}

    if(this.props.formData && this.props.formData.locationOnRoot && this.props.formData.companyId) {
      console.log( this.state.companyMap[this.props.formData.companyId], 'test')
      data.location = this.state.companyMap[this.props.formData.companyId]
    }

    if(this.props.formData && this.props.formData.itemTypeOnRoot && this.props.formData.itemType) {
      data.itemType = this.props.formData.itemType
    }

    if(this.props.formData && this.props.formData.termOfCondition && !this.props.formData.termOfCondition.isReceiptMultiLevelPartial) {
      data.isReceiptMultiLevel = this.props.formData.termOfCondition.isReceiptMultiLevel
    }

    return {...data, ...v}
  }

}
