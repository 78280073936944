import ApiService from '../../../../services/api.service';

class BiayaPromosiService extends ApiService {
  name  = 'biayaPromosi';
  path  = 'koreksi-fiskal/daftar-nominatif-biaya-promosi';
  constructor() {
    super();
    this.init()
  }

  api= {
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    getBp: async(tahun, masa, npwp) => {
      let res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/daftar-nominatif-biaya-promosi-bp/${masa}/${tahun}/${npwp}`)
      return res
    },
    findOne: async (id, tahunId, dispatch=()=> {})=> {
      let res = {}
      if(tahunId) {
        res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/${tahunId}/daftar-nominatif-biaya-promosi/${id}`)
      } else {
        res = await this.http.get(`${this.apiLocation}/daftar-nominatif-biaya-promosi/${id}`)
      }
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

    pull: async (tahunId, data) => {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${tahunId}/daftar-nominatif-biaya-promosi-pull`, data);

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = {}
      if(data.tahun && data.tahun.id) {
        res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-promosi`, data);
      } else {
        res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/11111111-1111-1111-1111-111111111111/daftar-nominatif-biaya-promosi`, data);
      }
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = {}
      if(data.tahun && data.tahun.id) {
        res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-promosi/${data.id}`, data);
      } else {
        res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/11111111-1111-1111-1111-111111111111/daftar-nominatif-biaya-promosi/${data.id}`, data);
      }
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (data, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/daftar-nominatif-biaya-promosi/${data.id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/daftar-nominatif-biaya-promosi/${id}/update-workflow`, data);
      return res;
    },

    updateWorkflowBulk: async (data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/workflow-bulk`, data);
      return res;
    },

    export: async (d) => {
      var res = {}
      if(d.tahun) {
        res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${d.tahun.id}/daftar-nominatif-biaya-promosi/export-all`, d);
      } else {
        await this.http.post(`${this.apiLocation}/koreksi-fiskal/${d.tahunFiskal}/daftar-nominatif-biaya-promosi/export-all-v2`, d);
      }
      return res
    },
  }
}


export default new BiayaPromosiService();
