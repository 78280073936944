import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Fileinput} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import UploadService from './upload.service';

@reduxForm({form: 'UploadForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('UploadForm')(state)
}))
export default class UploadDialog extends DialogView {
  service     = UploadService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.upload.file')}
          name          = 'file'
          className     = 'md-cell md-cell--12'
          component     = {Fileinput}
          // twoLines
          validate      = {validation.required}
        />

        <Field
          label         = {counterpart('entities.upload.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
      </div>
    )
  }

  async handleSave(callback, value) {
    var newVal = this.transform(value);
    try {
      var formData = new FormData();
      formData.append('file', value.file)
      var res = await UploadService.api.upload(formData);

      if(res.data) res.data.description = value.description;
      callback()
      this.props.reset()
      this.props.onSuccess(res.data)
      this.props.onClose()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }
}
