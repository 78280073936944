import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  List,
  ListItem,
  FontIcon,
  DialogContainer
} from 'react-md';
import FormView from '../../../components/entity/form.view';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../components/form/index';
import DashboardService from './Dashboard.service';
import iziToast from 'izitoast';
import utilsService from '../../../services/utils.service';

@reduxForm({form: 'DashboardForm', destroyOnUnmount: true})
@connect((state) => ({
  // access      : state.entity.access.api.find,
  formData    : getFormValues('DashboardForm')(state)
}))
class DashboardView extends FormView {
  translate = false
  titleHeader = () =>  'Dashboard'
  service = DashboardService
  viewType=2

  initialData={
  }

  formView() {
    var formData                      = {};

    if (this.props.formData) formData = this.props.formData.values

    return (
      <div>
        <Card>
          <div className='md-grid'>
            {/* <div > */}
            <Field
              label         = {'Masa'}
              name          = 'masa'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              validate      = {validation.required}
              options       = {[
                {id: '1', name: 'Januari'},
                {id: '2', name: 'Februari'},
                {id: '3', name: 'Maret'},
                {id: '4', name: 'April'},
                {id: '5', name: 'Mei'},
                {id: '6', name: 'Juni'},
                {id: '7', name: 'Juli'},
                {id: '8', name: 'Agustus'},
                {id: '9', name: 'September'},
                {id: '10', name: 'Oktober'},
                {id: '11', name: 'November'},
                {id: '12', name: 'Desember'}
              ]}
              validate={validation.required}
            />

              <Field
                label='Tahun'
                name='tahun'
                className="md-cell md-cell--6"
                component={Textfield}
                validate={validation.required}
              />


          </div>
        </Card>

        <br/>

        <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"PPh 21"} />
        <Divider/>
        <DataTable
          baseId="air-quality-data"
          className="data-tables__fixed-table"
          fixedHeader
          fixedFooter
          plain
          fixedHeight={300}
        >
          <TableHeader>
            <TableRow>
              <TableColumn tooltipLabel='No.'>No.</TableColumn>
              <TableColumn tooltipLabel=''></TableColumn>
              <TableColumn tooltipLabel='COA'>COA</TableColumn>
              <TableColumn tooltipLabel='Nama'>Nama</TableColumn>
              <TableColumn tooltipLabel='D/K'>D/K</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai CBS' className='md-text-right'>Nilai CBS</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai Pencatatan' className='md-text-right'>Nilai Pencatatan</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.data.filter(x => x.coa.isPPh21).map((d, i) => {
              return <TableRow key={i}>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn><Button primary flat onClick={()=> this.handleShowDetail(d.coa.id)}>Detail</Button></TableColumn>
                <TableColumn>{d.coa.code}</TableColumn>
                <TableColumn>{d.coa.name}</TableColumn>
                <TableColumn>{d.coa.dk}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.cbs)}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.pembayaran)}</TableColumn>
              </TableRow>
            })}
          </TableBody>
          </DataTable>
        </Card>

        <br/>

        <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"PPh 23"} />
        <Divider/>
        <DataTable
          baseId="air-quality-data"
          className="data-tables__fixed-table"
          fixedHeader
          fixedFooter
          plain
          fixedHeight={300}
        >
          <TableHeader>
            <TableRow>
              <TableColumn tooltipLabel='No.'>No.</TableColumn>
              <TableColumn tooltipLabel=''></TableColumn>
              <TableColumn tooltipLabel='COA'>COA</TableColumn>
              <TableColumn tooltipLabel='Nama'>Nama</TableColumn>
              <TableColumn tooltipLabel='D/K'>D/K</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai CBS' className='md-text-right'>Nilai CBS</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai Pencatatan' className='md-text-right'>Nilai Pencatatan</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.data.filter(x => x.coa.isPPh23).map((d, i) => {
              return <TableRow key={i}>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn><Button primary flat onClick={()=> this.handleShowDetail(d.coa.id)}>Detail</Button></TableColumn>
                <TableColumn>{d.coa.code}</TableColumn>
                <TableColumn>{d.coa.name}</TableColumn>
                <TableColumn>{d.coa.dk}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.cbs)}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.pembayaran)}</TableColumn>
              </TableRow>
            })}
          </TableBody>
          </DataTable>
        </Card>

        <br/>

        <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"PPh 4 ayat 2"} />
        <Divider/>
        <DataTable
          baseId="air-quality-data"
          className="data-tables__fixed-table"
          fixedHeader
          fixedFooter
          plain
          fixedHeight={300}
        >
          <TableHeader>
            <TableRow>
              <TableColumn tooltipLabel='No.'>No.</TableColumn>
              <TableColumn tooltipLabel=''></TableColumn>
              <TableColumn tooltipLabel='COA'>COA</TableColumn>
              <TableColumn tooltipLabel='Nama'>Nama</TableColumn>
              <TableColumn tooltipLabel='D/K'>D/K</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai CBS' className='md-text-right'>Nilai CBS</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai Pencatatan' className='md-text-right'>Nilai Pencatatan</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.data.filter(x => x.coa.isPPh4a2).map((d, i) => {
              return <TableRow key={i}>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn><Button primary flat onClick={()=> this.handleShowDetail(d.coa.id)}>Detail</Button></TableColumn>
                <TableColumn>{d.coa.code}</TableColumn>
                <TableColumn>{d.coa.name}</TableColumn>
                <TableColumn>{d.coa.dk}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.cbs)}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.pembayaran)}</TableColumn>
              </TableRow>
            })}
          </TableBody>
          </DataTable>
        </Card>

        <br/>

        <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"PPh 25"} />
        <Divider/>
        <DataTable
          baseId="air-quality-data"
          className="data-tables__fixed-table"
          fixedHeader
          fixedFooter
          plain
          fixedHeight={300}
        >
          <TableHeader>
            <TableRow>
              <TableColumn tooltipLabel='No.'>No.</TableColumn>
              <TableColumn tooltipLabel=''></TableColumn>
              <TableColumn tooltipLabel='COA'>COA</TableColumn>
              <TableColumn tooltipLabel='Nama'>Nama</TableColumn>
              <TableColumn tooltipLabel='D/K'>D/K</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai CBS' className='md-text-right'>Nilai CBS</TableColumn>
              <TableColumn className='md-text-right' tooltipLabel='Nilai Pencatatan' className='md-text-right'>Nilai Pencatatan</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.data.filter(x => x.coa.isPPh25).map((d, i) => {
              return <TableRow key={i}>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn><Button primary flat onClick={()=> this.handleShowDetail(d.coa.id)}>Detail</Button></TableColumn>
                <TableColumn>{d.coa.code}</TableColumn>
                <TableColumn>{d.coa.name}</TableColumn>
                <TableColumn>{d.coa.dk}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.cbs)}</TableColumn>
                <TableColumn className='md-text-right'>{utilsService.number.format2(d.pembayaran)}</TableColumn>
              </TableRow>
            })}
          </TableBody>
          </DataTable>
        </Card>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      data: [],
      data21: [],
      data23: [],
      data4a2: [],
      data25: [],

      detail: {
        cbsDebit  : [],
        cbsCredit : [],
        receipts  : [],
        invoice   : []
      }
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div>
        <Button disabled={!valid} raised primary onClick={()=> this.handleCompare()}>Compare</Button>
      </div>
    )
  }

  async initData() {
    // var res = await this.service.api.test(this.props.formData)
  }

  async handleCompare() {
    var res = await this.service.api.compare(this.props.formData)
    this.setState({data: res.data})
  }

  async handleShowDetail(id) {
    var res = await this.service.api.detail(id, this.props.formData.masa, this.props.formData.tahun)

    if(res.data) {

      var data = {
        cbsDebit  : [],
        cbsCredit : [],
        receipts  : [],
        invoice   : [],
        ...res.data
      }
      var d = await this.context.showDialog((props, res, rej) =>({
        title: 'Detail',
        width: 1200,
        initialValue: {},
        okText: 'Submit',
        actions: [{
          secondary: true,
          children: 'Tutup',
          onClick: ()=> {
            var result = props.formValue;
            if(!result) result = {}
            res(result)
          }
        }],
        text: (<div className="md-grid">
          <div className="md-cell md-cell--6">
          <h4>CBS</h4>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>Date</TableColumn>
                  <TableColumn>D/K</TableColumn>
                  <TableColumn className="md-text-right">Jumlah Mutasi</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.cbsDebit.map((d, i) => (
                  <TableRow key={i}>
                    <TableColumn>{d.date}</TableColumn>
                    <TableColumn>{d.dk}</TableColumn>
                    <TableColumn className="md-text-right">{utilsService.number.format2(d.jumlahMutasi)}</TableColumn>
                  </TableRow>
                ))}
                {data.cbsCredit.map((d, i) => (
                  <TableRow key={i}>
                    <TableColumn>{d.date}</TableColumn>
                    <TableColumn>{d.dk}</TableColumn>
                    <TableColumn className="md-text-right">{utilsService.number.format2(d.jumlahMutasi)}</TableColumn>
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
          </div>
          <div className="md-cell md-cell--6">
            <h4>INVOICE/RECEIPT</h4>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                <TableColumn>No.</TableColumn>
                    <TableColumn>Jenis</TableColumn>
                    <TableColumn>NPWP LK</TableColumn>
                    <TableColumn>Nama LK</TableColumn>
                    <TableColumn className="md-text-right">Jumlah</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.invoice.map((d, i) => (
                  <TableRow key={i}>
                    <TableColumn>{d.no}</TableColumn>
                    <TableColumn>INVOICE</TableColumn>
                    <TableColumn>{d.npwpLk}</TableColumn>
                    <TableColumn>{d.namaLk}</TableColumn>
                    <TableColumn className="md-text-right">{utilsService.number.format2(d.total)}</TableColumn>
                  </TableRow>
                ))}
                {data.receipts.map((d, i) => (
                  <TableRow key={i}>
                    <TableColumn>{d.no}</TableColumn>
                    <TableColumn>RECEIPT</TableColumn>
                    <TableColumn>{d.npwpLk}</TableColumn>
                    <TableColumn>{d.namaLk}</TableColumn>
                    <TableColumn className="md-text-right">{utilsService.number.format2(d.total)}</TableColumn>
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
          </div>
        </div>)
      }));
    }


  }
}

export default DashboardView
