import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21SettingService from './PPh21Setting.service';
import EarningService from '../Earning/Earning.service';
import DeductionService from '../Deduction/Deduction.service';
import _ from 'lodash';
import StatusPtkpService from '../StatusPtkp/StatusPtkp.service';
import utils from '../../../../../services/utils.service'

@reduxForm({form: 'PPh21SettingForm', destroyOnUnmount: true, initialValues: {
  ptkpMap: {},
  tarifPenghasilanMap: {},
  tarifPesangonMap: {}
}})
@connect((state) => ({
  data: getFormValues('PPh21SettingForm')(state)
}))
export default class PPh21SettingDialog extends FormView {
  service     = PPh21SettingService
  viewType    = 2

  initialData = {
    active: false,
    biayaJabatan: 0,
    maxPenghasilanBruto: 0,
    ptkp: [],
    tarifPenghasilanMap: {},
    tarifPesangonMap: {},
    tarifHonorPns: {},
    "tarifBiayaPensiun": {
      "tarif": 0,
      "jumlah": 0
    },
    "tarifPTKP": {
      "pribadi": 0,
      "tanggungan": 0
    },
  }

  defaultPtkp  = {
    'K/0'       : 58500000,
    'K/1'       : 63000000,
    'K/2'       : 67500000,
    'K/3'       : 72000000,
    'TK/0'      : 54000000,
    'TK/1'      : 58500000,
    'TK/2'      : 63000000,
    'TK/3'      : 67500000
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state
    }
  }

  async initData() {
    var statusPtkp  = await StatusPtkpService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(this.props.match.params.id == 'new') {
      var initialData = this.initialData;
      var ptkp        = statusPtkp.data.data.map((v, i) => ({
        id: "data_"+i,
        status: v.name,
        amount: this.defaultPtkp[v.name]? this.defaultPtkp[v.name] : 0
      }))

      var ptkpMap     = ptkp.reduce((pv, cv) => {
        // console.log(cv)
        pv[cv.id] = cv;
        return pv;
      }, {})

      var tarifPenghasilan        = [{
        id: 'data_0',
        from: 0,
        tarif: 5,
        tarifNonNPWP: 6,
        to: 50000000
      }, {
        id: 'data_1',
        from: 50000000,
        tarif: 15,
        tarifNonNPWP: 18,
        to: 250000000
      }, {
        id:'data_2',
        from: 250000000,
        tarif: 25,
        tarifNonNPWP: 30,
        to: 500000000
      }, {
        id:'data_3',
        from: 500000000,
        tarif: 30,
        tarifNonNPWP: 36,
        to: 90000000000
      }]

      var tarifPenghasilanMap        = tarifPenghasilan.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      var tarifPesangon        = [{
        id: 'data_0',
        from: 0,
        tarif: 5,
        tarifNonNPWP: 6,
        to: 50000000
      }, {
        id: 'data_1',
        from: 50000000,
        tarif: 15,
        tarifNonNPWP: 18,
        to: 250000000
      }, {
        id:'data_2',
        from: 250000000,
        tarif: 25,
        tarifNonNPWP: 30,
        to: 500000000
      }, {
        id:'data_3',
        from: 500000000,
        tarif: 30,
        tarifNonNPWP: 36,
        to: 90000000000
      }]

      var tarifPesangonMap        = tarifPesangon.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      initialData     = {
        ...initialData,
        ptkp: ptkp,
        ptkpMap: ptkpMap,
        tarifPenghasilan,
        tarifPenghasilanMap: tarifPenghasilanMap,
        tarifPesangonMap: tarifPesangonMap,
        tarifPesangon: tarifPesangon
      }

      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.ptkpMap = res.data.ptkp.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
      res.data.tarifPenghasilanMap = res.data.tarifPenghasilan.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      res.data.tarifPesangonMap = res.data.tarifPesangon.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})

      this.props.initialize(res.data);
    }
  }

  async beforeSave(value) {

    value.tarifPenghasilan = Object.keys(value.tarifPenghasilanMap).map((k, i) => {
      if(this.props.match.params.id == 'new') {
        delete value.tarifPenghasilanMap[k].id
      }
      return value.tarifPenghasilanMap[k]
    })

    value.ptkp = Object.keys(value.ptkpMap).map((k, i) => {
      if(this.props.match.params.id == 'new') {
        delete value.ptkpMap[k].id
      }
      return value.ptkpMap[k]
    })

    value.tarifPesangon = Object.keys(value.tarifPesangonMap).map((k, i) => {
      if(this.props.match.params.id == 'new') {
        delete value.tarifPesangonMap[k].id
      }
      return value.tarifPesangonMap[k]
    })

    return true;
  }

  formView() {
    return (<div>
      <Card>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.pph21Setting.name')}
            name          = 'name'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.fromDate')}
            name          = 'from'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.toDate')}
            name          = 'to'
            className     = 'md-cell md-cell--6'
            component     = {Datepicker}
            validate      = {validation.required}
          />
          {/* <Field
            label         = {counterpart('entities.pph21Setting.active')}
            name          = 'active'
            className     = 'md-cell md-cell--12'
            component     = {Switch}
            // validate      = {validation.required}
          /> */}

          {/* <Field
            label         = {counterpart('entities.pph21Setting.earningType')}
            name          = 'earningType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            service       = {EarningService}
            valueField    = 'parent'
            // validate      = {validation.required}
          />

          <Field
            label         = {counterpart('entities.pph21Setting.deductionType')}
            name          = 'deductionType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            service       = {DeductionService}
            valueField    = 'parent'
            // validate      = {validation.required}
          /> */}

          <Field
            label         = {counterpart('entities.pph21Setting.description')}
            name          = 'description'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            // validate      = {validation.required}
          />
        </div>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[SATU MASA] PENGATURAN TARIF JABATAN DAN PENSIUN'} />
        <Divider/>
        <div className='md-grid'>
        <Field
            label         = {"Tarif "+counterpart('entities.pph21Setting.biayaJabatan')}
            name          = 'biayaJabatan'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.maxPenghasilanBruto')}
            name          = 'maxBiayaJabatan'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = "Tarif Biaya Pensiun"
            name          = 'biayaPensiun'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {"Maksimum biaya pensiun"}
            name          = 'maxBiayaPensiun'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
        </div>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[SATU MASA] PENGATURAN PTKP'} />
        <Divider/>
        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn>Status</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.amount')}</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.ptkpMap).map((key, i) => {
            let d = this.props.data.ptkpMap[key]
            return <TableRow key={i}>
              <TableColumn>{d.status}</TableColumn>
              <TableColumn className=''><Field
                label         = {''}
                name          = {'ptkpMap.'+d.id+'.amount'}
                className     = 'md-cell md-cell--12  md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              /></TableColumn>
            </TableRow>
          })
        }
        </TableBody>
        </DataTable>

      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[SATU MASA] PENGATURAN TARIF'} />
        <Divider/>
        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.from')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.to')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.tarif')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.tarifNonNPWP')}</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.tarifPenghasilanMap).map((key, i) => {
            let d = this.props.data.tarifPenghasilanMap[key]
            return <TableRow key={i}>
              <TableColumn className=' md-text-right'>{utils.number.format2(d.from)}</TableColumn>
              <TableColumn className=' md-text-right'>{utils.number.format2(d.to)}</TableColumn>
              <TableColumn><Field
                label         = {''}
                name          = {'tarifPenghasilanMap.'+d.id+'.tarif'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              /></TableColumn>
              <TableColumn><Field
                label         = {''}
                name          = {'tarifPenghasilanMap.'+d.id+'.tarifNonNPWP'}
                className     = 'md-cell md-cell--12  md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              /></TableColumn>
            </TableRow>
          })
        }
        </TableBody>
        </DataTable>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[TIDAK FINAL] TARIF PTKP'} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.pph21Setting.tarifPTPK.pribadi')}
            name          = 'tarifPTKP.pribadi'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifPTPK.tanggungan')}
            name          = 'tarifPTKP.tanggungan'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
        </div>
      </Card>

       <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[TIDAK FINAL] TARIF LAINNYA'} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.pph21Setting.tarifUpahHarian')}
            name          = 'tarifUpahHarian.jumlah'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifUpahKumulatifSetahun')}
            name          = 'tarifUpahKumulatifSetahun.jumlah'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
        </div>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[FINAL] PENGATURAN TARIF PESANGON'} />
        <Divider/>
        <DataTable plain>
        <TableHeader>
          <TableRow>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.from')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.to')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.tarif')}</TableColumn>
            <TableColumn className=' md-text-right'>{counterpart('entities.pph21Setting.tarifNonNPWP')}</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.tarifPesangonMap).map((key, i) => {
            let d = this.props.data.tarifPesangonMap[key]
            return <TableRow key={i}>
              <TableColumn className=' md-text-right'>
              <Field
                label         = {''}
                name          = {'tarifPesangonMap.'+d.id+'.from'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              />
              </TableColumn>
              <TableColumn className=' md-text-right'>
              <Field
                label         = {''}
                name          = {'tarifPesangonMap.'+d.id+'.to'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              />
              </TableColumn>
              <TableColumn><Field
                label         = {''}
                name          = {'tarifPesangonMap.'+d.id+'.tarif'}
                className     = 'md-cell md-cell--12 md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              /></TableColumn>
              <TableColumn><Field
                label         = {''}
                name          = {'tarifPesangonMap.'+d.id+'.tarifNonNPWP'}
                className     = 'md-cell md-cell--12  md-text-right'
                component     = {TextfieldMask}
                // validate      = {validation.required}
                money         = {","}
              /></TableColumn>
            </TableRow>
          })
        }
        </TableBody>
        </DataTable>
      </Card>


      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[FINAL] MAMFAAT PENSIUN'} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.pph21Setting.tarifBiayaPensiun.tarif')}
            name          = 'tarifBiayaPensiun.tarif'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifBiayaPensiun.jumlah')}
            name          = 'tarifBiayaPensiun.jumlah'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
        </div>
      </Card>

      <br/>

      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={'[FINAL] TARIF HONOR PNS'} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.pph21Setting.tarifHonorPns.gol1')}
            name          = 'tarifHonorPns.gol1'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifHonorPns.gol2')}
            name          = 'tarifHonorPns.gol2'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifHonorPns.gol3')}
            name          = 'tarifHonorPns.gol3'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
          <Field
            label         = {counterpart('entities.pph21Setting.tarifHonorPns.gol4')}
            name          = 'tarifHonorPns.gol4'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            // validate      = {validation.required}
            money         = {","}
          />
        </div>
      </Card>


    </div>)
  }
}
