import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import CoaBungaDepositoService from './CoaBungaDeposito.service';
import ListView from '../../../../components/entity/listView';
import CoaBungaDepositoDialog from './CoaBungaDeposito.dialog';

@connect(CoaBungaDepositoService.stateConnectSetting(), CoaBungaDepositoService.actionConnectSetting())
export default class CoaBungaDepositoView extends ListView {
  service     = CoaBungaDepositoService
  FormDialog  = CoaBungaDepositoDialog

  columns=[
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coaBungaDeposito.jenisPenghasilan",  value: "jenisPenghasilan", searchField: 'code'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.code",              value: "coa.code", searchField: 'code'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.name",              value: "coa.name", searchField: 'name'}
  ]
}
