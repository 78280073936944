import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import MetodePenyusutanKomersialService from './MetodePenyusutanKomersial.service';
import ListView from '../../../../components/entity/listView';
import MetodePenyusutanKomersialDialog from './MetodePenyusutanKomersial.dialog';

@connect(MetodePenyusutanKomersialService.stateConnectSetting(), MetodePenyusutanKomersialService.actionConnectSetting())
export default class MetodePenyusutanKomersialView extends ListView {
  service     = MetodePenyusutanKomersialService
  FormDialog  = MetodePenyusutanKomersialDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.metodePenyusutanKomersial.code",    value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.metodePenyusutanKomersial.name",    value: "name",  isDefaultSort:true}
    
  ]
}
