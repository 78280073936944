import ApiService from '../../../../services/api.service';

class HartaService extends ApiService {
  name  = 'harta';
  path  = 'master/harta';
  constructor() {
    super();
    this.init()
  }
}


export default new HartaService();
