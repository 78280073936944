import React, { Component } from "react";
import { connect } from "react-redux";

// import FormDialog from './ItemType.dialog.view';
import KoreksiFiskalService from "./KoreksiFiskalTahun.service";
import ListView from "../../../../components/entity/listView";
import KoreksiFiskalDialog from "./KoreksiFiskalTahun.dialog";

@connect(
  state => ({
    ...KoreksiFiskalService.stateConnectSetting()(state),
    approvalSetting: state.auth.approvalSetting,
    user: state.auth.user
  }),
  KoreksiFiskalService.actionConnectSetting()
)
export default class KoreksiFiskalView extends ListView {
  service = KoreksiFiskalService;
  FormDialog = KoreksiFiskalDialog;
  urlPath = "koreksiFiskal";
  approvalSettingName = "Koreksi Fiskal";

  columns = [
    {
      isSortable: true,
      show: true,
      isSearchable: true,
      label: "entities.koreksiFiskal.tahun",
      value: v => v.tahun,
      type: "func"
    }
  ];

  tableActions = d => {
    var menus = [
      {
        label: "Buka Koreksi Fiskal",
        iconClassName: "mdi mdi-book-open",
        onClick: item =>
          this.props.history.push(`/${this.urlPath}/${item.id}/open`)
      },
      {
        label: "More Info",
        iconClassName: "mdi mdi-bell",
        onClick: item => this.editItem(item)
      }
    ];

    if (this.props.approvalSetting[this.approvalSettingName]) {
      if (this.props.user.permissionMap["MenghapusKoreksiFiskal"]) {
        menus.push({ label: "divider", iconClassName: "-" });
        menus.push({
          label: "Delete",
          iconClassName: "mdi mdi-delete",
          onClick: (item, callback) => this.deleteItem(item, callback),
          confirmation: {
            title: "sentence.custom.deleteItem",
            message: "sentence.custom.deleteItemConfirmation"
          }
        });
      }
    }
    console.log(this.props.approvalSetting, "this.props.approvalSetting");
    return menus;
  };
}
