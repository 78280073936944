import {
  auth, table, global
} from 'react-mpk/redux/reducers';
import { reducer as formReducer } from 'redux-form'
import image from './imageReducer';

import {combineReducers} from 'redux';

const entityReducer = combineReducers({
  company                          : require('../../modules/Main/Admin/Company/Company.service').default.reducer,
  lawanTransaksi                   : require('../../modules/Main/Admin/LawanTransaksi/LawanTransaksi.service').default.reducer,
  itemUnit                         : require('../../modules/Main/Admin/ItemUnit/ItemUnit.service').default.reducer,
  itemType                         : require('../../modules/Main/Admin/ItemType/ItemType.service').default.reducer,
  periodUnit                       : require('../../modules/Main/Admin/PeriodUnit/PeriodUnit.service').default.reducer,
  coa                              : require('../../modules/Main/Admin/Coa/Coa.service').default.reducer,
  incomeType                       : require('../../modules/Main/Admin/IncomeType/IncomeType.service').default.reducer,
  currency                         : require('../../modules/Main/Admin/Currency/Currency.service').default.reducer,
  user                             : require('../../modules/Main/Admin/User/User.service').default.reducer,
  item                             : require('../../modules/Main/Admin/Item/Item.service').default.reducer,
  approvalSetting                  : require('../../modules/Main/Admin/ApprovalSetting/ApprovalSetting.service').default.reducer,
  item                             : require('../../modules/Main/Admin/Item/Item.service').default.reducer,
  jenisHarta                       : require('../../modules/Main/Admin/JenisHarta/JenisHarta.service').default.reducer,
  kelompokHarta                    : require('../../modules/Main/Admin/KelompokHarta/KelompokHarta.service').default.reducer,
  harta                            : require('../../modules/Main/Admin/Harta/Harta.service').default.reducer,
  jenisUsaha                       : require('../../modules/Main/Admin/JenisUsaha/JenisUsaha.service').default.reducer,
  metodePenyusutanKomersial        : require('../../modules/Main/Admin/MetodePenyusutanKomersial/MetodePenyusutanKomersial.service').default.reducer,
  metodePenyusutanFiskal           : require('../../modules/Main/Admin/MetodePenyusutanFiskal/MetodePenyusutanFiskal.service').default.reducer,
  role                             : require('../../modules/Main/Admin/Role/Role.service').default.reducer,
  penyusutanAmortisasi             : require('../../modules/Main/Admin/PenyusutanAmortisasi/PenyusutanAmortisasi.service').default.reducer,
  amortisasi                       : require('../../modules/Main/Admin/PenyusutanAmortisasi/Amortisasi.service').default.reducer,
  coaDaftarNominatifBiayaPromosi   : require('../../modules/Main/Admin/CoaDaftarNominatifBiayaPromosi/CoaDaftarNominatifBiayaPromosi.service').default.reducer,
  coaDaftarNominatifBiayaEntertain : require('../../modules/Main/Admin/CoaDaftarNominatifBiayaEntertain/CoaDaftarNominatifBiayaEntertain.service').default.reducer,
  coaBungaDeposito                 : require('../../modules/Main/Admin/CoaBungaDeposito/CoaBungaDeposito.service').default.reducer,
  perjanjian                       : require('../../modules/Main/Pembayaran/Perjanjian/Perjanjian.service').default.reducer,
  receipt                          : require('../../modules/Main/Pembayaran/Receipt/Receipt.service').default.reducer,
  cbs                              : require('../../modules/Main/Cbs/Cbs/Cbs.service').default.reducer,
  invoice                          : require('../../modules/Main/Pembayaran/Invoice/Invoice.service').default.reducer,
  biayaPromosi                     : require('../../modules/Main/KoreksiFiskal/BiayaPromosi/BiayaPromosi.service').default.reducer,
  biayaEntertain                   : require('../../modules/Main/KoreksiFiskal/BiayaEntertain/BiayaEntertain.service').default.reducer,
  koreksiFiskal                    : require('../../modules/Main/KoreksiFiskal/KoreksiFiskalTahun/KoreksiFiskalTahun.service').default.reducer,
  archiveSpt23                     : require('../../modules/Main/Archive/ArchiveBp23/ArchiveBp23.service').default.reducer,
  archiveSpt21                     : require('../../modules/Main/Archive/ArchiveBp21/ArchiveBp21.service').default.reducer,
  archiveSpt4a2                    : require('../../modules/Main/Archive/ArchiveBp4a2/ArchiveBp4a2.service').default.reducer,
  archiveSpt15                     : require('../../modules/Main/Archive/ArchiveBp15/ArchiveBp15.service').default.reducer,
  tarif                            : require('../../modules/Main/Admin/Tarif/Tarif.service').default.reducer,
  documentType                     : require('../../modules/Main/Admin/DocumentType/DocumentType.service').default.reducer,
  logImport                        : require('../../modules/Main/LogImport/LogImport.service').default.reducer,
  logExport                        : require('../../modules/Main/LogExport/LogExport.service').default.reducer,
  logProcess                       : require('../../modules/Main/LogProcess/LogProcess.service').default.reducer,
  logSummary                       : require('../../modules/Main/LogSummary/LogSummary.service').default.reducer,
  logSummaryBpEbupot               : require('../../modules/Main/LogSummaryBpEbupot/LogSummaryBpEbupot.service').default.reducer,
  koreksiFiskalBulan               : require('../../modules/Main/KoreksiFiskal/KoreksiFiskalBulan/KoreksiFiskalBulan.service').default.reducer,
  pph25                            : require('../../modules/Main/KoreksiFiskal/Pph25/Pph25.service').default.reducer,
  penomoranBp4a2                   : require('../../modules/Main/Admin/PenomoranBp4a2/PenomoranBp4a2.service').default.reducer,
  penomoranBp23                    : require('../../modules/Main/Admin/PenomoranBp23/PenomoranBp23.service').default.reducer,
  appSchedule                      : require('../../modules/Main/Admin/AppSchedule/AppSchedule.service').default.reducer,
  bungaDeposito                    : require('../../modules/Main/KoreksiFiskal/BungaDeposito/BungaDeposito.service').default.reducer,
  // PAYROLL -----------------------------------------------------------------

  religion                          : require('../../modules/Main/Payrol/Master/Religion/Religion.service').default.reducer,
  designation                       : require('../../modules/Main/Payrol/Master/Designation/Designation.service').default.reducer,
  bank                              : require('../../modules/Main/Payrol/Master/Bank/Bank.service').default.reducer,
  objekPajak                        : require('../../modules/Main/Payrol/Master/ObjekPajak/ObjekPajak.service').default.reducer,
  componentType                     : require('../../modules/Main/Payrol/Master/ComponentType/ComponentType.service').default.reducer,
  earning                           : require('../../modules/Main/Payrol/Master/Earning/Earning.service').default.reducer,
  deduction                         : require('../../modules/Main/Payrol/Master/Deduction/Deduction.service').default.reducer,
  pph21Setting                      : require('../../modules/Main/Payrol/Master/PPh21Setting/PPh21Setting.service').default.reducer,
  statusPtkp                        : require('../../modules/Main/Payrol/Master/StatusPtkp/StatusPtkp.service').default.reducer,
  employee                          : require('../../modules/Main/Payrol/Process/Employee/Employee.service').default.reducer,
  nonEmployee                       : require('../../modules/Main/Payrol/Process/NonEmployee/NonEmployee.service').default.reducer,
  salarySlip                        : require('../../modules/Main/Payrol/Process/SalarySlip/SalarySlip.service').default.reducer,
  periode                           : require('../../modules/Main/Payrol/Process/Periode/Periode.service').default.reducer,
  pph21Data                         : require('../../modules/Main/Payrol/Process/PPh21Data/PPh21Data.service').default.reducer,
  pph21Final                        : require('../../modules/Main/Payrol/Process/PPh21Final/PPh21Final.service').default.reducer,
  pph21TidakFinal                   : require('../../modules/Main/Payrol/Process/PPh21TidakFinal/PPh21TidakFinal.service').default.reducer,
  a1                           : require('../../modules/Main/Payrol/Process/PPh21A1/PPh21A1.service').default.reducer,
  // END OF PAYROLL ----------------------------------------------------------
})

const rootReducer = combineReducers({
  table, global,image,
  form      : formReducer,
  auth      : require('../../services/auth.service').default.reducer,
  entity    : entityReducer
});

export default rootReducer;
