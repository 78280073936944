import ApiService from '../../../../../services/api.service';

class SalarySlipService extends ApiService {
  name  = 'salarySlip';
  path  = 'pph21/process/salarySlip';
  constructor() {
    super();
    this.init()
  }
}


export default new SalarySlipService();
