import ApiService from '../../../../../services/api.service';

class EmployeeService extends ApiService {
  name  = 'employee';
  path  = 'pph21/master/employee';
  constructor() {
    super();
    this.init()
  }
}


export default new EmployeeService();
