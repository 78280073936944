import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import _ from 'lodash';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ItemService from './Item.service';
import ItemUnitService from '../ItemUnit/ItemUnit.service'
import CoaService from '../Coa/Coa.service';
import IncomeTypeService from '../IncomeType/IncomeType.service';
import ItemTypeService from '../ItemType/ItemType.service';

@reduxForm({form: 'ItemForm', destroyOnUnmount: true, initialValues: {itemIncomeTypes: [], itemIncomeTypeMap: {}}})
@connect((state) => ({
  formData: getFormValues('ItemForm')(state)
}))
export default class ItemForm extends FormView {
  service     = ItemService
  viewType    = 2;
  initialData = {itemIncomeTypes: [], itemIncomeTypeMap: {}}

  formView() {
    if(this.state.coas.length <= 0 || this.state.incomeTypes.length <= 0) return <div/>
    var map = this.props.formData.itemIncomeTypeMap;
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.item.name')}
              name          = 'name'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />

            <Field
              label         = {counterpart('entities.item.code')}
              name          = 'code'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.item.description')}
              name          = 'description'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.item.category')}
              name          = 'category'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              remoteSearch  = {true}
              service       = {ItemTypeService}
              valueField    = 'name'
              />
            <Field
              label         = {counterpart('entities.item.unit')}
              name          = 'unit'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              remoteSearch  = {true}
              service       = {ItemUnitService}
              valueField    = 'name'
              // searchField   = {['name', 'aliasName', 'npwp']}
              />

            <Field
              label         = {'Coa Bruto'}
              name          = 'coa'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              options       = {this.state.coas}
              // remoteSearch  = {true}
              // service       = {CoaService}
              // params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
              valueField    = 'parent'
              // searchField   = {['name', 'aliasName', 'npwp']}
              />

          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.incomeType.title').toUpperCase()} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn style={{width: '40%'}}>{counterpart('entities.incomeType.title').toUpperCase()}</TableColumn>
                <TableColumn style={{width: '40%'}}>{'Coa Pajak'}</TableColumn>
                <TableColumn></TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(this.props.formData.itemIncomeTypeMap).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    <Field
                      label         = {''}
                      name          = {'itemIncomeTypeMap.no'+i+'.incomeType'}
                      className     = 'md-cell md-cell--12'
                      component     = {Searchfield}
                      options       = {this.state.incomeTypes}
                      // remoteSearch  = {true}
                      // service       = {IncomeTypeService}
                      valueField    = 'parent'
                      itemTemplate  = {function(d) {
                        return {
                          primaryText: d.name,
                          secondaryText: d.pasal+" - "+d.code,
                          onClick: () => {
                            if(!this.props.disabled){
                              this.handleItemClick(d); this.hide()
                            }
                          }
                        }
                      }}
                      // searchField   = {['name', 'aliasName', 'npwp']}
                    />
                  </TableColumn>
                  <TableColumn>
                    <Field
                      label         = {''}
                      name          = {'itemIncomeTypeMap.no'+i+'.coaId'}
                      className     = 'md-cell md-cell--12'
                      component     = {Searchfield}
                      options       = {this.state.coas}
                      // remoteSearch  = {true}
                      // service       = {IncomeTypeService}
                      valueField    = 'id'
                      // searchField   = {['name', 'aliasName', 'npwp']}
                    />
                  </TableColumn>
                  <TableColumn>
                    <div className='mpk-layout'>
                      <div className='flex' />
                      <Button flat primary swapTheming onClick={() => this.handleDeleteIncomeType(i)}>Hapus</Button>
                    </div>
                  </TableColumn>
                </TableRow>
              })}
            </TableBody>
          </DataTable>

          <div className='mpk-layout' style={{padding: 8}}>
              <div className='flex'></div>
              <Button flat primary swapTheming onClick={this.handleAddIncomeType}>Tambah Jenis Penghasilan</Button>
          </div>
        </Card>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      coas: [],
      incomeTypes: []
    }
  }

  async initData() {

    // get income types
    let incomeTypeRes = await IncomeTypeService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    if(incomeTypeRes.data) this.setState({incomeTypes: incomeTypeRes.data.data})
    // get coa
    let coaRes = await CoaService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    });
    if(coaRes.data) this.setState({coas: coaRes.data.data})


    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      var itemIncomeTypeMap = {}
      res.data.itemIncomeTypes.forEach((v, i)=> {
        itemIncomeTypeMap['no'+i] = v;
      })

      res.data.itemIncomeTypeMap = itemIncomeTypeMap;

      this.props.initialize(res.data);
    }
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)
    var itemIncomeTypes = [];
    Object.keys(value.itemIncomeTypeMap).forEach((k, i)=> {
      itemIncomeTypes.push(value.itemIncomeTypeMap[k])
    })

    value.itemIncomeTypes = itemIncomeTypes;
    delete value.itemIncomeTypeMap;

    return value;
  }

  handleAddIncomeType = ()=> {
    var map = this.props.formData.itemIncomeTypeMap;
    var length = Object.keys(this.props.formData.itemIncomeTypeMap).length
    this.props.dispatch(this.props.change('itemIncomeTypeMap.no'+length, {}))
  }

  handleDeleteIncomeType = (i) => {
    var map = this.props.formData.itemIncomeTypeMap;
    delete map['no'+i]
    this.props.dispatch(this.props.change('itemIncomeTypeMap', map))
  }
}
