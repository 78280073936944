import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import BiayaEntertainService from './BiayaEntertain.service';
import ListView from '../../../../components/entity/listView';
import { permission } from '../../../../config/constant';
import DefaultView from '../../../../components/entity/default.view';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import {Field, reduxForm, getFormValues} from 'redux-form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import { ListItem } from 'react-md';
import uploadService from '../../Admin/upload/upload.service';


@connect((state) => ({
  ...BiayaEntertainService.stateConnectSetting()(state),
  kp: state.auth.currentSPT,
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), BiayaEntertainService.actionConnectSetting())
export default class BiayaEntertainView extends DefaultView {
  service     = BiayaEntertainService
  editDialog  = false;
  addDialog   = false;
  // FormDialog  = BiayaPromosiDialog

  approvalSettingName     = 'Daftar Nominatif Biaya Entertain'
  checkerPermissionName   = permission.MelakukanCheckDaftarNominatifBiayaEntertain;
  approvalPermissionName  = permission.MelakukanApproveDaftarNominatifBiayaEntertain;

  beforeFetch(params) {

    params.status=['TEST_STATUS', 'TEST_STATUS_REFERENCE', 'draft', 'approved', 'rejected', 'checked'];
  }

  fetchOption() {
    var id = ''
    if(this.props.kp && this.props.kp.data) {
      id = this.props.kp.data.id
    } else {
      id = '11111111-1111-1111-1111-111111111111'
    }
    console.log(id, 'id')
    return {
      path: 'koreksi-fiskal/'+id+'/daftar-nominatif-biaya-entertain'
    }
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.biayaEntertain.masa",             value: "masa"},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.biayaEntertain.tahun",            value: "tahun.tahun"},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.biayaEntertain.date",             value: "date"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.place",            value: "place"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.address",          value: "address"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.coa",              value: "coa.name"},
    {isSortable: true,  show: true,     isSearchable:true,  label: "entities.biayaEntertain.total",            value: "total",         type: 'number'},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.name",             value: "name"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.position",         value: "position"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.companyName",      value: "companyName"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.companyType",      value: "companyType"},
    {isSortable: true,  show: false,    isSearchable:true,  label: "entities.biayaEntertain.description",      value: "description"}
  ]

  componentDidMount() {
    this.firstCall = false;
    this.rootPath = this.props.match.url;
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Masa'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Nama'
          name='nama'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  barActions = () => {
    var arr = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleUpload()
    },
    {
      label:'entities.biayaPromosi.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.handleImport()
    },
    {
      label:'word.export',
      iconClassName:'mdi mdi-export',
      onClick:() => this.handleExport()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    }
  ]
  // if(this.showBatchApproval) {
    arr.push({
      label:'word.approval',
      iconClassName:'mdi mdi-clipboard-check-outline',
      onClick:() => this.handleBatchApproval(this.props.table.selected),
      disabledFunc:() => this.props.table.selected.length === 0
    })
  // }

  return arr
  }

  async handleImport() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: counterpart.translate('entities.biayaPromosi.import'),
      initialValue: {},
      okText: 'Submit',
      contentStyle: {overflowY: 'inherit', overflow: 'inherit'},
      text: (<div className='md-grid'>
        <Field
          label         = {counterpart('entities.biayaPromosi.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
      </div>)
    }));

    if(d) {
    await this.service.api.pull(this.props.kp.data.id, d)
    iziToast.success({title: 'Success', message: 'Data telah berhasil di impor'})

    this.fetchData()
    }

  }


  deleteItem = async (item, callback) => {
    try {
      await this.service.api.delete(item);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  async handleExport() {
    var d = {}
    if(this.props.kp && this.props.kp.data) {
      d=await this.context.showDialog((props, res, rej) =>({
        title: 'Export to excel',
        width: 500,
        initialValue: {delimiter: ';', note: ''},
        okText: 'Export',
        text: 'Apakah anda yakin akan meng-ekspor data ini?'
      }));
    } else {
      d=await this.context.showDialog((props, res, rej) =>({
        title: 'Export to excel',
        width: 500,
        initialValue: {delimiter: ';', note: ''},
        okText: 'Export',
        text: <div>
            {/* <p>Apakah anda yakin akan meng-ekspor data ini?</p> */}
            <div className='md-grid'>
            <Field
              label         = 'Tahun Fiskal'
              name          = 'tahunFiskal'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              validate      = {validation.required}
              // options       = {[
              //   {id: '1', name: 'Januari'},
              //   {id: '2', name: 'Februari'},
              //   {id: '3', name: 'Maret'},
              //   {id: '4', name: 'April'},
              //   {id: '5', name: 'Mei'},
              //   {id: '6', name: 'Juni'},
              //   {id: '7', name: 'Juli'},
              //   {id: '8', name: 'Agustus'},
              //   {id: '9', name: 'September'},
              //   {id: '10', name: 'Oktober'},
              //   {id: '11', name: 'November'},
              //   {id: '12', name: 'Desember'}
              // ]}
            />
            </div>
          </div>
      }));
    }


    if(d) {
      if(this.props.kp && this.props.kp.data) d.tahun = this.props.kp.data;

      await this.service.api.export(d);
      iziToast.success({
        title: 'success',
        message: counterpart('entities.upload.exportSuccess')
      })
    }
  }

  _approvalListView(v, i) {
    return <ListItem primaryText={`${v.name}`}  key={i} secondaryText={`Masa ${v.masa} - ${v.tahun.tahun}`} />
  }

  async handleUpload() {
    var value = await this.context.showDialog((props, res, rej) =>({
      title: 'Import Biaya Entertain',
      width: 500,
      initialValue: {},
      actions: [{
        children: 'Download Template',
        onClick: ()=> {window.open(`/images/templates/biaya_entertain_import.xlsx`,'_blank'); res(false)}
      },{
        children: 'Cancel',
        onClick: ()=> {res(false)}
      },{
        secondary: true,
        children: 'Import',
        onClick: ()=> {
          var result = props.props.formValue;
          if(!result) result = {}
          res(result)
        }
      }],
      okText: 'Import',
      text:
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
      </div>
    }));

    if(value) {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }
    }
  }
}
