import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21FinalService from './PPh21Final.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import _ from 'lodash'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import NonEmployeeService from '../NonEmployee/NonEmployee.service';

@reduxForm({form: 'PPh21FinalForm', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('PPh21FinalForm')(state),
  periode: state.auth.currentSPT
}))
export default class PPh21FinalForm extends FormView {
  service     = PPh21FinalService
  viewType    = 2;

  initialData = {
    basicInfo: {
      npwp: '000000000000000',
      isForeign: false,
      religion: '-'
    },
    "calculation": {
      "totalBruto":  0,
      "totalPPh":  0,
      tarif: 0
    },
    bankInfo: {},
    otherInfo: {},
    taxInfo: {
      statusKirim: "belum dikirim"
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    var earningRes  = await EarningService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    var deductionRes  = await DeductionService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    this.setState({
      earningType: earningRes.data.data,
      deductionType: deductionRes.data.data
    })

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {this.props.periode && this.props.periode.data && this.props.periode.data.workflow && this.props.periode.data.workflow.status == 'draft' && this.props.match.params.id != 'new' && <Button primary flat onClick={()=> {this.handleCalculate()}} style={{marginRight: 16}}>{counterpart.translate('word.calculate')}</Button>}
        {this.props.periode && this.props.periode.data && this.props.periode.data.workflow && this.props.periode.data.workflow.status == 'draft' &&<Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.basicInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.title')}
            name          = 'employee'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            params        = {{page: 1, sortBy: 'id', size: 90000000, sort: 'ASC'}}
            valueField    = 'parent'
            service       = {NonEmployeeService}
            viewField     = 'id'
            onChange      = {(d) => {
              var data = _.cloneDeep(this.props.data);
              delete d.id;
              delete d.basicInfo.id;
              delete d.jobInfo.id;
              delete d.finalInfo.id;
              delete d.tidakFinalInfo.id;
              // delete d.otherInfo.id;
              // delete d.bankInfo;

              var data = {
                ...data,
                ...d,
                taxInfo: d.finalInfo
              }

              this.props.initialize(data)
            }}
            itemTemplate  = {function(d) {
              return {
                primaryText: d.basicInfo? d.basicInfo.name: '',
                secondaryText: d.basicInfo? d.basicInfo.npwp: '',
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />

          <Field
            label         = {counterpart('entities.employee.basicInfo.name')}
            name          = 'basicInfo.name'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.npwp')}
            name          = 'basicInfo.npwp'
            className     = 'md-cell md-cell--6'
            component     = {TextfieldMask}
            maskFormat    = "##.###.###.#-###-###"
            mask          = "_"
            length        = {15}
            validate      = {validation.required}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.identityNo')}
            name          = 'basicInfo.identityNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.address')}
            name          = 'basicInfo.address'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.email')}
            name          = 'basicInfo.email'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.isForeign')}
            name          = 'basicInfo.isForeign'
            className     = 'md-cell md-cell--6'
            component     = {Switch}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.country')}
            name          = 'basicInfo.country'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.religion')}
            name          = 'basicInfo.religion'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.basicInfo.ptkp')}
            name          = 'basicInfo.ptkp'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'name'
            service       = {StatusPtkpService}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.jobInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.jobInfo.employeeNo')}
            name          = 'jobInfo.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.employee.jobInfo.designation')}
            name          = 'jobInfo.designation'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            service       = {DesignationService}
            valueField    = 'name'
          />
        </div>
      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.nonEmployee.finalInfo.title')} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = {counterpart('entities.employee.otherInfo.objekPajak')}
            name          = 'taxInfo.objekPajak'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            valueField    = 'id'
            // service       = {ObjekPajakService}
            options = {[
              {id: '21-401-01', detail:'21-401-01', name: 'Uang Pesangon yang Dibayarkan Sekaligus'},
              {id: '21-401-02', detail:'21-401-02', name: 'Uang Manfaat Pensiun, Tunjangan Hari Tua, atau Jaminan Hari Tua yang Dibayarkan Sekaligus'},
              {id: '21-402-01', detail:'21-402-01', name: 'Honor dan Imbalan Lain yang Dibebankan kepada APBN atau APBD yang Diterima oleh PNS, Anggota TNI/POLRI, Pejabat Negara dan Pensiunannya'},
              {id: '21-499-99', detail:'21-499-99', name: 'Objek PPh Pasal 21 Final Lainnya'}
            ]}
            itemTemplate  = {function(d) {
              return {
                primaryText: `${d.name}`,
                secondaryText: d.id,
                onClick: () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />
          <Field
            label         = {counterpart('entities.employee.otherInfo.taxType')}
            name          = 'taxInfo.taxType'
            className     = 'md-cell md-cell--6'
            component     = {Searchfield}
            options       = {[{
              id: 'GROSS',
              name: 'GROSS'
            }, {
              id: 'GROSS UP',
              name: 'GROSS UP'

            }]}
          />
          {/* <Field
            label         = {counterpart('entities.nonEmployee.finalInfo.golonganPns')}
            name          = 'taxInfo.golonganPns'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[{
              id: "I",
              name: "I"
            },{
              id: "II",
              name: "II"
            },{
              id: "III",
              name: "III"
            },{
              id: "IV",
              name: "IV"
            }]}
          /> */}
          <Field
            label         = {'Bruto'}
            name          = 'calculation.bruto'
            className     = 'md-cell md-cell--12  md-text-right'
            component     = {TextfieldMask}
            // disabled
            money         = ","
          />
        </div>


      </Card>

      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.employee.calculation.title')} />
        <Divider/>

        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalBruto')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalBruto'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  // disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn >{counterpart('entities.employee.calculation.tarif')}</TableColumn>
              <TableColumn >
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.tarif'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.employee.calculation.totalPPh')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'calculation.totalPPh'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  // disabled
                  money         = ","
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </Card>
    </div>)
  }

  async beforeSave(value) {
    value.periode = this.props.periode.data
    value.taxInfo.namaPenghasilan= "NORMAL"
    value.taxInfo.statusKirim = "belum dikirim"
    return true
  }

  async handleCalculate() {
    // await this.service.api.update(this.props.data)
    await this.service.api.calculate(this.props.data)
    this.initData()
  }
}
