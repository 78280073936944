import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import DialogView from '../../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PeriodeService from './Periode.service';
import CompanyService from '../../../Admin/Company/Company.service';

@reduxForm({form: 'PeriodeForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('PeriodeForm')(state)
}))
export default class PeriodeDialog extends DialogView {
  service     = PeriodeService

  transform(value) {
    value.month = parseInt(value.month)
    value.year = parseInt(value.year)
    return value;
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.company.title')}
          name          = 'company'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          validate      = {validation.required}
          service       = {CompanyService}
          valueField    = 'parent'
        />
        <Field
          label         = {counterpart('entities.periode.month')}
          name          = 'month'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.periode.year')}
          name          = 'year'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
         <Field
          label         = {counterpart('entities.periode.note')}
          name          = 'note'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          // validate      = {validation.required}
        />
      </div>
    )
  }
}
