import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import BungaDepositoService from './BungaDeposito.service';
import CoaService from '../../Admin/Coa/Coa.service';
import CompanyService from '../../Admin/Company/Company.service';
import utilsService from '../../../../services/utils.service';

@reduxForm({form: 'BungaDepositoForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('BungaDepositoForm')(state),
  kp: state.auth.currentSPT
}))
export default class BungaDepositoForm extends FormView {
  service     = BungaDepositoService

  formView() {
    if(!this.state.load) return <div/>
    return (<div>
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.company.title')}
          name          = 'company'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          validate      = {validation.required}
          // service       = {CompanyService}
          options       = {this.state.companies}
          itemTemplate   = {function(d) {
            return {
              primaryText: d[this.props.viewField],
              secondaryText: d.npwp,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          valueField    = 'parent'
          apiPath       = {'lookup'}
        />
        <Field
          label         = {counterpart('entities.bungaDeposito.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <Field
          label         = {counterpart('entities.koreksiFiskalBulan.tahun')}
          name          = 'tahun.tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          disabled
        />
      </div>
      <Divider/>
<DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>Nama</TableColumn>
              <TableColumn>Jumlah Nasabah</TableColumn>
              <TableColumn>Nilai Objek Pajak</TableColumn>
              <TableColumn>PPh di Potong</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableColumn>Bunga Deposito / Tabungan</TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.jumlahNasabah')}
                  name          = 'jumlahNasabah'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.nilaiObjekPajak')}
                  name          = 'nilaiObjekPajak'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.pphDiPotong')}
                  name          = 'pphDiPotong'
                  className     = 'md-cell md-cell--12'
                  component     = {TextfieldMask}
                  money={','}
                  onChange      = {(e, v)=> {
                    this.props.change('nilaiObjekPajak', utilsService.number.get(v)*100/20)
                  }}
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Bunga Deposito / Tabungan (Transaksi)</TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.jumlahNasabah')}
                  name          = 'transaksiJumlahNasabah'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.nilaiObjekPajak')}
                  name          = 'transaksiNilaiObjekPajak'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.pphDiPotong')}
                  name          = 'transaksiPphDiPotong'
                  className     = 'md-cell md-cell--12'
                  component     = {TextfieldMask}
                  money={','}
                  onChange      = {(e, v)=> {
                    this.props.change('transaksiNilaiObjekPajak', utilsService.number.get(v)*100/20)
                  }}
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Jasa Giro</TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.jumlahNasabah')}
                  name          = 'jumlahNasabah3'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.nilaiObjekPajak')}
                  name          = 'nilaiObjekPajak3'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.pphDiPotong')}
                  name          = 'pphDiPotong3'
                  className     = 'md-cell md-cell--12'
                  component     = {TextfieldMask}
                  money={','}
                  onChange      = {(e, v)=> {
                    this.props.change('nilaiObjekPajak3', utilsService.number.get(v)*100/20)
                  }}
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>Jasa Giro (Transaksi)</TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.jumlahNasabah')}
                  name          = 'transaksiJumlahNasabah3'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.nilaiObjekPajak')}
                  name          = 'transaksiNilaiObjekPajak3'
                  className     = 'md-cell md-cell--6'
                  component     = {TextfieldMask}
                  money={','}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  // label         = {counterpart('entities.bungaDeposito.pphDiPotong')}
                  name          = 'transaksiPphDiPotong3'
                  className     = 'md-cell md-cell--12'
                  component     = {TextfieldMask}
                  money={','}
                  onChange      = {(e, v)=> {
                    this.props.change('transaksiNilaiObjekPajak3', utilsService.number.get(v)*100/20)
                  }}
                />
              </TableColumn>
            </TableRow>

          </TableBody>
        </DataTable>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      companies: [],
      load: false
    }
  }

  async initData() {

    let companyRes = await CompanyService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(companyRes.data) {
      this.setState({companies: companyRes.data.data})
    }

    if(this.props.match.params.id == 'new') {
      var initialData = {
        tahun: this.props.kp.data,
        // jumlahNasabah: 0,
        // nilaiObjekPajak: 0,
        // pphDiPotong: 0,

        jumlahNasabah: 0,
        jumlahNasabah3: 0,
        nilaiObjekPajak: 0,
        nilaiObjekPajak3: 0,
        pphDiPotong: 0,
        pphDiPotong3: 0,

        transaksiJumlahNasabah: 0,
        transaksiJumlahNasabah3: 0,
        transaksiNilaiObjekPajak: 0,
        transaksiNilaiObjekPajak3: 0,
        transaksiPphDiPotong: 0,
        transaksiPphDiPotong3: 0
      }
      this.props.initialize(initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id, this.props.kp.data.id);
      this.props.initialize(res.data);
    }

    this.setState({load: true})
  }

  barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>

        {this.props.formData.id && <Button raised secondary onClick={handleSubmit(this.handleCbs.bind(this))} style={{marginRight: 8}}>Sinkronisasi</Button>}
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  async handleCbs() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: "Ambil data Objek Pajak dari CBS/Transaksi",
      initialValue: {d: 'asd'},
      width: 400,
      okText: 'Ya',
      cancelText: 'tidak',
      text: 'Apakah anda yakin akan mengambil data "Nilai Objek Pajak" dari CBS?'
    }));

    // console.log(d, 'asd')
    if(d) {
      var res = await this.service.api.pull(this.props.formData)
      await this.initData()
      iziToast.success({title: 'Success', message: 'Nilai Objek Pajak telah berhasil diambil dari data CBS'})
    }
  }

}
