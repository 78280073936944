import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21A1Service from './PPh21A1.service';
import StatusPtkpService from '../../Master/StatusPtkp/StatusPtkp.service';
import DesignationService from '../../Master/Designation/Designation.service';
import _ from 'lodash'
import EarningService from '../../Master/Earning/Earning.service';
import DeductionService from '../../Master/Deduction/Deduction.service';
import CompanyService from '../../../Admin/Company/Company.service';
import ObjekPajakService from '../../Master/ObjekPajak/ObjekPajak.service';
import EmployeeService from '../Employee/Employee.service';

@reduxForm({form: 'PPh21A1Form', destroyOnUnmount: true, initialValues: {
  earnings: [],
  deductions: [],
  earningMap: {},
  deductionMap: {}
}})
@connect((state) => ({
  data: getFormValues('PPh21A1Form')(state),
  periode: state.auth.currentSPT
}))
export default class PPh21A1Form extends FormView {
  service     = PPh21A1Service
  viewType    = 2;

  initialData = {
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      earningType: [],
      deductionType: []
    }
  }

  async initData() {

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.props.initialize(res.data);
    }
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.props.history.goBack()}} style={{marginRight: 16}}>{counterpart.translate('word.back')}</Button>
        {this.props.periode && this.props.periode.data && this.props.periode.data.workflow && this.props.periode.data.workflow.status == 'draft' &&<Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>}
      </div>
    )
  }

  formView() {
    return (<div>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG"} />
        <Divider/>
        <div className='md-grid'>
          <Field
            label         = 'Nomor'
            name          = 'header.h01'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.a1.sectionA.a01')}
            name          = 'sectionA.a01'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.a1.sectionA.a02')}
            name          = 'sectionA.a02'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.a1.sectionA.employeeNo')}
            name          = 'sectionA.employeeNo'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
          <Field
            label         = {counterpart('entities.a1.sectionA.a03')}
            name          = 'sectionA.a03'
            className     = 'md-cell md-cell--6'
            component     = {Textfield}
          />
        </div>
      </Card>
      <br/>
      <Card>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle={"B. RINCIAN  PENGHASILAN DAN PENGHITUNGAN PPh PASAL 21"} />
        <Divider/>

        <DataTable plain>
          <TableBody>
            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b01')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b01'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b02')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b02'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b03')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b03'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b04')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b04'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b05')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b05'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b06')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b06'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b07')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b07'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b08')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b08'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b09')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b09'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
              </TableRow>

              <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b10')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b10'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b11')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b11'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn style={{borderTop: '3px solid black'}}>{counterpart('entities.a1.sectionB.b12')}</TableColumn>
              <TableColumn style={{borderTop: '3px solid black'}}>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b12'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b13')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b13'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b14')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b14'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b15')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b15'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b16')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b16'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b17')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b17'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b18')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b18'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b19')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b19'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>

            <TableRow>
              <TableColumn>{counterpart('entities.a1.sectionB.b20')}</TableColumn>
              <TableColumn>
                <Field
                  label         = {'\u00A0'}
                  name          = 'sectionB.b20'
                  className     = 'md-cell md-cell--12  md-text-right'
                  component     = {TextfieldMask}
                  money         = ","
                />
              </TableColumn>
            </TableRow>



          </TableBody>
        </DataTable>
      </Card>
    </div>)
  }

  async beforeSave(value) {
    value.periode = this.props.periode.data
    return true
  }

  async handleCalculate() {
    await this.service.api.update(this.props.data)
    await this.service.api.calculate(this.props.data.id)
    this.initData()
  }
}
