import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import CoaDaftarNominatifBiayaEntertainService from './CoaDaftarNominatifBiayaEntertain.service';
import ListView from '../../../../components/entity/listView';
import CoaDaftarNominatifBiayaEntertainDialog from './CoaDaftarNominatifBiayaEntertain.dialog';

@connect(CoaDaftarNominatifBiayaEntertainService.stateConnectSetting(), CoaDaftarNominatifBiayaEntertainService.actionConnectSetting())
export default class CoaDaftarNominatifBiayaEntertainView extends ListView {
  service     = CoaDaftarNominatifBiayaEntertainService
  FormDialog  = CoaDaftarNominatifBiayaEntertainDialog

  columns=[
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.code",       value: "coa.code", searchField: 'code'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.name",       value: "coa.name", searchField: 'name'}
  ]
}
