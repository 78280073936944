import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import BiayaEntertainService from './BiayaEntertain.service';
import CoaService from '../../Admin/Coa/Coa.service';
import CoaDaftarNominatifBiayaEntertainService from '../../Admin/CoaDaftarNominatifBiayaEntertain/CoaDaftarNominatifBiayaEntertain.service';

@reduxForm({form: 'BiayaEntertainForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('BiayaEntertainForm')(state),
  kp: state.auth.currentSPT
}))
export default class BiayaEntertainForm extends FormView {
  service     = BiayaEntertainService

  formView() {
    if(!this.state.show) return <div/>
    return (
      <div className='md-grid'>
        {(this.props.kp && this.props.kp.data) && <Field
          label         = {counterpart('entities.biayaPromosi.tahun')}
          name          = 'tahun.tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          disabled
        />}
        {!(this.props.kp && this.props.kp.data) && <Field
          label         = {counterpart('entities.biayaPromosi.tahun')}
          name          = 'tahunFiskal'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          // disabled
        />}
        <Field
          label         = {counterpart('entities.biayaEntertain.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          // validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />

        <Field
          label         = {counterpart('entities.biayaEntertain.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.place')}
          name          = 'place'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.position')}
          name          = 'position'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.companyName')}
          name          = 'companyName'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.companyType')}
          name          = 'companyType'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.address')}
          name          = 'address'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.coa.title')}
          name          = 'coa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          // service       = {CoaService}
          options       = {this.state.coas}
          valueField    = 'parent'
        />
        <Field
          label         = {counterpart('entities.biayaEntertain.total')}
          name          = 'total'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
        />

        <Field
          label         = {counterpart('entities.biayaEntertain.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
      </div>
    )

  }
  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      coas: [],
      show: false
    }
  }

  async initData() {
    let coaRes = await CoaDaftarNominatifBiayaEntertainService.api.find({
      page: 1,
      sortBy: 'createdAt',
      size: 90000000,
      sort: 'ASC'
    });


    if(coaRes.data) {
      this.setState({coas: coaRes.data.data.map(x => x.coa), show: true})
    }


    if(this.props.match.params.id == 'new') {
      var initialData = {
        tahun: this.props.kp.data
      }
      this.props.initialize(initialData);
    } else {
      let res = {}
      if(this.props.kp && this.props.kp.data) {
        res = await this.service.api.findOne(this.props.match.params.id, this.props.kp.data.id);
      } else {
        res = await this.service.api.findOne(this.props.match.params.id, null);
        res.data.tahunFiskal = res.data.tahun.tahun
      }
      this.props.initialize(res.data);
    }
  }

}
