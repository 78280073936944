import React, { Component } from 'react';
import moment from 'moment';
import { Button, DialogContainer, TextField } from 'react-md';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import {connect} from 'react-redux';

import './Faktur.preview.scss';
import utilsService from '../../../../services/utils.service';

@reduxForm({form: 'FAKTUR_KELUARAN_FORM_PREVIEW', destroyOnUnmount: true})
@connect((state) => ({
  formData: state.form.FAKTUR_KELUARAN_FORM_PREVIEW
}))
export default class FakturPreview extends Component {

  constructor(props){
    super(props)
    this.state = {
      data: null
    }
  }

  async componentDidUpdate(prevProps, prevState){
    // let res = await this.props.service.api.findOne({
    //   id: this.props.id,
    //   wpId: this.props.wpId
    // });
    // console.log(res, "SADSD")
    // if(this.props.service){
    //   if(this.props.id && this.props.wpId){
    //     let res = await this.props.service.api.findOne({
    //       id: this.props.id,
    //       wpId: this.props.wpId
    //     });
    //     if(res && res.data){
    //       var data = res.data
    //       if(this.state.data == null){
    //         this.setState({ data: data })
    //       } else {
    //         if(this.state.data && this.state.data.id){
    //           if(this.state.data.id != res.data.id){
    //             this.setState({ data: data })
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  render() {
    // if((this.props.fk && this.props.fk.id)) return <div/>
    var data = this.props.fk
    // console.log(data, "ORIGIN FROM PREVIEW")
    // console.log(data2, "DUPLICATE FROM PREVIEW")
    if(true) {
      return <DialogContainer
        id="simple-action-dialog"
        width={800}
        visible={this.props.visible}
        onHide={this.props.onHide}
        actions={[
          <Button flat primary onClick={()=> {
            this.props.onHide();
          }}>Close</Button>
        ]}
        title="Preview Faktur"
      >
        <div className="fk-preview">
          <h3 className="fk-header">Faktur Pajak</h3>

          {/* bag a */}
          <table>
            <tr><td colSpan={3}>Kode dan Nomor Seri Faktur Pajak : {data.nomorFaktur}</td></tr>

            <tr><td colSpan={3}>Pengusaha Kena Pajak </td></tr>
            <tr className="fk-clear"><td style={{width: 60}}>Nama </td><td colSpan={2}>: {data.namaPenjual}</td></tr>
            <tr className="fk-clear"><td>Alamat </td><td colSpan={2}>: {data.alamatPenjual}</td></tr>
            <tr className="fk-clear"><td>Npwp </td><td colSpan={2}>:  {utilsService.number.strFormat(data.npwpPenjual, '##.###.###.#-###.###')} </td></tr>

            <tr><td colSpan={3}>Pembeli Barang Kena Pajak / Penerima Jasa Kena Pajak </td></tr>
            <tr className="fk-clear"><td>Nama </td><td>: {data.namaLawanTransaksi}</td><td>Nik/Paspor     :    {''}</td></tr>
            <tr className="fk-clear"><td>Alamat </td><td colSpan={2}>: {data.alamatLawanTransaksi}</td></tr>
            <tr className="fk-clear"><td>Npwp </td><td colSpan={2}>:  {utilsService.number.strFormat(data.npwpLawanTransaksi, '##.###.###.#-###.###')} </td></tr>

            {/* bag b */}
            <tr>
              <td className="center">NO.</td>
              <td className="center">Nama Barang Kena Pajak / Jasa Kena Pajak</td>
              <td className="center">Harga Jual/Penggantian/Uang <br/> Muka/Termin </td>
            </tr>

            {data.detail.map((d, i)=> {
              return <tr>
                <td className="center">1.</td>
                <td>{d.nama} <br/> Rp { utilsService.number.format(d.hargaSatuan, { precision: 2, thousand: ".", decimal: ',' })} x {d.jumlahBarang}</td>
                <td className="right">{utilsService.number.format(d.hargaTotal, { precision: 2, thousand: ".", decimal: ',' })}</td>
              </tr>
            })}

            {/* bag c */}
             <tr>
              <td colSpan={2} style={{borderTop: '3px solid black'}}>   {data.fgUangMuka == 1 &&
                  "Uang Muka / Termin"
              }

                {data.fgUangMuka != 1 &&
                  "Harga Jual / Penggantian"
                }</td>
              <td className="right" style={{borderTop: '3px solid black'}}>
                {data.fgUangMuka == 1 &&
                  utilsService.number.format(data.uangMukaDPP, { precision: 2, thousand: ".", decimal: ',' })
                }

                {data.fgUangMuka != 1 &&
                  utilsService.number.format(data.jumlahDPP, { precision: 2, thousand: ".", decimal: ',' })
                }
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Dikurangi Potongan Harga</td>
              <td className="right">
                {(data.fgUangMuka == 0 || data.fgUangMuka == 2) &&

                  utilsService.number.format(this.total('diskon'), { precision: 2, thousand: ".", decimal: ',' })
                }

                {data.fgUangMuka == 1 &&
                  "0,00"
                }
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Dikurangi Uang Muka</td>
              <td className="right">
              {(data.fgUangMuka == 2) &&
                utilsService.number.format(data.jumlahDPP-data.uangMukaDPP, { precision: 2, thousand: ".", decimal: ',' })

              }
              {(data.fgUangMuka != 2) &&
                "0,00"
              }

              </td>
            </tr>
            <tr>
              <td colSpan={2}>Dasar Pengenaan Pajak </td>
              <td className="right">
                {(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.uangMukaDPP, { precision: 2, thousand: ".", decimal: ',' })
                }

                {!(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.jumlahDPP, { precision: 2, thousand: ".", decimal: ',' })
                }
              </td>
            </tr>
            <tr>
              <td colSpan={2}>PPN = 10% x Dasar Pengenaan Pajak </td>
              <td className="right">
                {(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.uangMukaPPN, { precision: 2, thousand: ".", decimal: ',' })
                }
                {!(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.jumlahPPN, { precision: 2, thousand: ".", decimal: ',' })
                }
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Total PPnBM (Pajak Penjualan Barang Mewah) </td>
              <td className="right">
                {(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.uangMukaPPnBM, { precision: 2, thousand: ".", decimal: ',' })
                }

                {!(data.fgUangMuka == "2" || data.fgUangMuka == 2) &&
                  utilsService.number.format(data.jumlahPPnBM, { precision: 2, thousand: ".", decimal: ',' })
                }


              </td>
            </tr>
          </table>
          {/* <p style={{fontSize: 12}}>Sesuai dengan ketentuan yang berlaku, Direktorat Jenderal Pajak mengatur bahwa Faktur Pajak ini telah ditandatangani secara elektronik sehingga tidakdiperlukan tanda tangan basah pada Faktur Pajak ini.</p>

          {this.getKeteranganTambahan(data)}

          {data.penandatangan &&

            <div className="penanda-tangan">
              <p>{data.penandatangan.tempat}, {this.parseDate(data.tanggalFaktur)}</p>
              <br/><br/><br/><br/>

              <p>{data.penandatangan.nama}</p>
            </div>
          } */}


        </div>

      </DialogContainer>
    }

    return <div/>

  }

  getKeteranganTambahan(data) {
    var out = "";
    if(data.kdTransaksi == "07") {
      switch (data.idKeteranganTambahan) {
        case "1":
          out = "PAJAK PERTAMBAHAN NILAI TIDAK DIPUNGUT BERDASARKAN PP NOMOR 10 TAHUN 2012"
          break;
        case "2":
          out = "Pajak Pertambahan Nilai atau Pajak Pertambahan Nilai dan Pajak Penjualan atas Barang Mewah tidak dipungut"
          break;
        case "3":
          out = "PAJAK PERTAMBAHAN NILAI DAN PAJAK PENJUALAN ATAS BARANG MEWAH TIDAK DIPUNGUT"
          break;
        case "4":
          out = "PAJAK PERTAMBAHAN NILAI TIDAK DIPUNGUT SESUAI PP NOMOR 71 TAHUN 2012"
          break;
        case "5":
          out = ""
          break;
        case "6":
          out = "PPN dan/atau PPnBM tidak dipungut berdasarkan PMK No.194/PMK.03/2012"
          break;
        case "7":
          out = "PPN TIDAK DIPUNGUT BERDASARKAN PP NOMOR 15 TAHUN 2015"
          break;
        case "8":
          out="PPN TIDAK DIPUNGUT BERDASARKAN PP NOMOR 69 TAHUN 2015"
          break;
        case "9":
          out = "PPN TIDAK DIPUNGUT BERDASARKAN PP NOMOR 96 TAHUN 2015"
          break;
        case "10":
          out = "PPN TIDAK DIPUNGUT BERDASARKAN PP NOMOR 106 TAHUN 2015"
          break;
        default:
          break;
      }
    } else if(data.kdTransaksi == "08") {
      switch (data.idKeteranganTambahan) {
        case "1":
          out = "PPN DIBEBASKAN SESUAI PP NOMOR 146 TAHUN 2000 SEBAGAIMANA TELAH DIUBAH DENGAN PP NOMOR 38 TAHUN 2003"
          break;
        case "2":
          out = "PPN DIBEBASKAN SESUAI PP NOMOR 12 TAHUN 2001 SEBAGAIMANA TELAH BEBERAPA KALI DIUBAH TERAKHIR DENGAN PP NOMOR 31 TAHUN 2007"
          break;
        case "3":
          out = "PPN Dibebaskan berdasarkan Peraturan Pemerintah Nomor 28 Tahun 2009"
          break;
        case "4":
          out = ""
          break;
        case "5":
          out = "PPN DIBEBASKAN SESUAI DENGAN PP NOMOR 81 TAHUN 2015"
          break;
        case "6":
          out = "PPN DIBEBASKAN BERDASARKAN PP NOMOR 74 TAHUN 2015"
          break;
        default:
          break;
      }
    }

    if(out == "") {
      return null
    }  else {
      return <div className="ket-tambahan">
              <p>{out}</p>
            </div>
    }
  }

  parseDate(date) {
    return moment(date, "DD/MM/YYYY").format("DD MMMM YYYY")
  }

  nomorFaktur(d) {
    return d.content.kdTransaksi + d.content.fgPengganti + "." + d.content.nomorFaktur.slice(0,3) + "-" + d.content.nomorFaktur.slice(3,5) + "." +  d.content.nomorFaktur.slice(5,d.content.nomorFaktur.length)
  }

  total(type='diskon') {
    var data = this.props.fk;
    if(!data) {
      data = {content: {}, objekFakturs: []}
    }
    if(!data) {data = {}}
    if(!data.objekFakturs) {data.objekFakturs = []}

    var total = 0;
    data.objekFakturs.forEach((d, i)=> {
      if(d[type]) total += d[type];
    })

    return total;
  }
}
