import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import BiayaPromosiService from './BiayaPromosi.service';
import CoaService from '../../Admin/Coa/Coa.service';
import CoaDaftarNominatifBiayaPromosiService from '../../Admin/CoaDaftarNominatifBiayaPromosi/CoaDaftarNominatifBiayaPromosi.service';
import CompanyService from '../../Admin/Company/Company.service';

@reduxForm({form: 'BiayaPromosiForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('BiayaPromosiForm')(state),
  kp: state.auth.currentSPT
}))
export default class BiayaPromosiForm extends FormView {
  service     = BiayaPromosiService

  formView() {
    if(this.state.coas.length == 0) return <div/>
    return (
      <div className='md-grid'>


        {this.state.companyList.length > 0 && <Field
              label         = {'Perusahaan/Cabang'}
              name          = 'companyId'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              // service       = {CompanyService}
              options       = {this.state.companyList}
              itemTemplate   = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              valueField    = 'id'
            />}

        {(this.props.kp && this.props.kp.data) && <Field
          label         = {counterpart('entities.biayaPromosi.tahun')}
          name          = 'tahun.tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          disabled
        />}
        {!(this.props.kp && this.props.kp.data) && <Field
          label         = {counterpart('entities.biayaPromosi.tahun')}
          name          = 'tahunFiskal'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          // validate      = {validation.required}
          // disabled
        />}

    <Field
          label         = {counterpart('entities.biayaPromosi.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
          onChange      = {(e, v)=> this.getBp(v)}
        />

      {this.props.kp && this.props.kp.data && this.state.loadList &&
        <Field
          label         = {'Data ePPT'}
          name          = 'dataEppt'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {this.state.bpList}
          itemTemplate   = {function(d) {
            return {
              primaryText: d.noBuktiPotong,
              secondaryText: d.name+" - "+d.npwp+" (Masa "+d.masa+", sumber "+d.source+")",
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          valueField    = 'parent'
          onChange      = {(v) => {
            this.props.change('name', v.name)
            this.props.change('npwp', v.npwp)
            this.props.change('address', v.address)
            this.props.change('noBuktiPotong', v.noBuktiPotong)
            this.props.change('totalPph', v.totalPph)
          }}
        />}

        <Field
          label         = {counterpart('entities.company.npwp')}
          name          = 'npwp'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.address')}
          name          = 'address'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.date')}
          name          = 'date'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
        />
        <Field
          label         = {counterpart('entities.coa.title')}
          name          = 'coa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          // service       = {CoaService}
          options       = {this.state.coas}
          valueField    = 'parent'
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.total')}
          name          = 'total'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          money={','}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.description')}
          name          = 'description'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.totalPph')}
          name          = 'totalPph'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
        />
        <Field
          label         = {counterpart('entities.biayaPromosi.noBuktiPotong')}
          name          = 'noBuktiPotong'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
        />
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      coas: [],
      bpList: [],
      companyList: [],
      companyMap: {},
      loadList: true
    }
  }

  async initData() {

    let coaRes = await CoaDaftarNominatifBiayaPromosiService.api.find({
      page: 1,
      sortBy: 'createdAt',
      size: 90000000,
      sort: 'ASC'
    });
    if(coaRes.data) this.setState({coas: coaRes.data.data.map(x => x.coa)})

    let comRes = await CompanyService.api.find({
      page: 1,
      sortBy: 'createdAt',
      size: 90000000,
      sort: 'ASC'
    });



    if(comRes.data) {
      this.setState({companyList: comRes.data.data, companyMap: comRes.data.data.reduce((pv, cv) => {
        pv[cv.id] = cv
        return pv
       }, {})})
    }


    if(this.props.match.params.id == 'new') {
      var initialData = {
        tahun: this.props.kp.data
      }
      this.props.initialize(initialData);

    } else {
      let res = {}
      if(this.props.kp && this.props.kp.data) {
        res = await this.service.api.findOne(this.props.match.params.id, this.props.kp.data.id);
      } else {
        res = await this.service.api.findOne(this.props.match.params.id, null);
        res.data.tahunFiskal = res.data.tahun.tahun
      }

      await this.props.initialize(res.data);

      this.getBp()
    }
  }

  async getBp(masa) {
    this.setState({loadList: false})

    if(!masa) masa = this.props.formData.masa
    var npwp = ''
    if(this.state.companyMap[this.props.formData.companyId]) npwp = this.state.companyMap[this.props.formData.companyId].npwp

    let bpRes = await BiayaPromosiService.api.getBp(this.props.formData.tahun? this.props.formData.tahun.tahun:this.props.formData.tahunFiskal, masa, npwp)
    // console.log(bpRes, 'asd')
    if(bpRes.data) this.setState({bpList: bpRes.data})

    this.setState({loadList: true})
  }

}
