import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import { Button, Card, CardText, CardTitle, divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  MenuButton,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import KoreksiFiskalSettingService from './KoreksiFiskalSetting.service';
import { Treebeard, decorators } from 'react-treebeard'
import KoreksiFiskalSettingDialog from './KoreksiFiskalSetting.dialog';
import CoaService from '../Coa/Coa.service';

// decorators.Loading = (props) => {
//   return (
//       <div style={props.style}>
//           bahahhahah
//       </div>
//   );
// }

decorators.Container = (props) => {
  var {style, onClick, node} = props;
  var Toggle = decorators.Toggle
  return (
    <div style={style.base} onClick={onClick}>
        {/* <Toggle/> */}
        <div style={style.title}>
          <ListItem
            primaryText={node.name}
            // secondaryText="Jan 9, 2014"
            rightIcon={
              <MenuButton icon menuItems={[{
                primaryText: 'Add Child',
                onClick: (e) => {node.action.onAdd()}
              },{
                primaryText: 'Edit',
                onClick: (e) => {node.action.onEdit()}
              }, {
                primaryText: 'Delete'
              }]}>more_vert</MenuButton>
            }/>
        </div>
    </div>
  );
}


@reduxForm({form: 'KoreksiFiskalSettingForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  formData: getFormValues('KoreksiFiskalSettingForm')(state)
}))
export default class KoreksiFiskalSettingForm extends FormView {
  service     = KoreksiFiskalSettingService
  viewType    = 2;

  formView() {
    return (
      <div>
        <Card style={{overflow: 'auto'}}>
          <div className="" style={{paddingTop: 20, paddingBottom: 20}}>
            <KoreksiFiskalSettingDialog
              height={400}
              visible={this.state.showDialog}
              coaList={this.state.coaList}
              // add={this.props.tableActions.addDataItem}
              formData={this.state.formData}
              onSuccess={(value)=> {this.handleAddItemSuccess(value)}}
              onClose={() => this.setState({showDialog:false})}
              match={this.props.match}
            />
            {/* <Treebeard
              className="mpk-full width"
              data={this.state.data}
              onToggle={this.handleToggle}
              decorators={decorators}
              /> */}

            {/* <Card> */}
              <DataTable plain>
                <TableHeader>
                  <TableRow>
                    <TableColumn>Nama Akun</TableColumn>
                    <TableColumn>Laba Rugi Komersial</TableColumn>
                    <TableColumn>Koresksi Positif</TableColumn>
                    <TableColumn>Koreksi Negatif</TableColumn>
                    <TableColumn>Laba Rugi Fiskal</TableColumn>
                    <TableColumn>Aksi</TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {this.state.data.map((v, i) => {
                    return this.VRow(v, i)
                  })}
                </TableBody>
              </DataTable>
            {/* </Card> */}

          </div>
        </Card>
      </div>
    )
  }

  VRow(v, i) {
    return <React.Fragment>
      <TableRow key={i}>
        <TableColumn>{Array.from(Array(v.level-1)).map((_, i) => ( <span>&ensp;&ensp;&ensp;</span>)) } {v.name}</TableColumn>

        <TableColumn>
          {!v.hasChild && <span style={{color: '#CCC'}}>{v.valueLabaRugiKomersial}</span>}
        </TableColumn>
        <TableColumn>
          {!v.hasChild && <span style={{color: '#CCC'}}>{v.valueKoreksiPositif}</span>}
        </TableColumn>
        <TableColumn>
          {!v.hasChild && <span style={{color: '#CCC'}}>{v.valueKoreksiNegatif}</span>}
        </TableColumn>
        <TableColumn>
          {!v.hasChild && <span style={{color: '#CCC'}}>{v.valueLabaRugiFiskal}</span>}
        </TableColumn>
        <TableColumn>
          <MenuButton icon menuItems={[{
            primaryText: 'Add Child',
            onClick: (e) => {v.action.onAdd()}
          },{
            primaryText: 'Edit',
            onClick: (e) => {v.action.onEdit()}
          }, {
            primaryText: 'Delete',
            onClick: (e) => {v.action.onDelete()}
          }]}>more_vert</MenuButton>
        </TableColumn>
      </TableRow>
      {v.children.length > 0 &&
        v.children.map((k, i) => (this.VRow(k, i)))
      }
    </React.Fragment>
  }

  _barItem() {
    const { handleSubmit, submitting, valid, pristine } = this.props;

    var saveWord = counterpart.translate('word.save');
    if(this.props.match.params.id == 'new') saveWord = counterpart.translate('word.create')
    if(this.props.match.params.sptId == 'new') saveWord = counterpart.translate('word.create')

    return (
      <div>
        <Button secondary flat onClick={()=> {this.handleAdd()}} style={{marginRight: 16}}>{counterpart.translate('entities.koreksiFiskalSetting.add')}</Button>
        <Button raised primary onClick={handleSubmit(this.handleSave.bind(this))}>{saveWord}</Button>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      cursor: {},
      data: [],
      formData: {},
      showDialog: false,
      test: 'asdasd',
      coaList: []
    }
  }

  handleToggle= (node, toggled) => {
    if(this.state.cursor){this.state.cursor.active = false;}
    node.active = true;
    if(node.children){ node.toggled = toggled; }
    this.setState({ cursor: node });
  }

  async initData() {

    var res = await this.service.api.find({
      page: 1,
      size: 10000,
      sortBy: 'seq',
      sort: 1
    }, this.props.dispatch);

    var data = res.data.data.sort((a, b) => a.seq - b.seq).map((x, i)=> {
      if(x.children) {
        x.children = x.children.sort((a, b) => a.seq - b.seq)
      }
      return x
    })

    console.log(res.data.data, 'asdsad')
    this.bindAction(data);

    var resCoa = await CoaService.api.find({
      sortBy: 'name',
      sort: 1,
      page: 1,
      size: 90000000
    });

    this.setState({
      data,
      coaList: resCoa.data.data
    })
  }

  async handleSave(value) {
    try {
      var res = await this.service.api.update({
        settings: this.state.data
      })

      iziToast.success({title: 'Success', message: 'Data telah berhasil di update'})
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  handleAddItemSuccess(value) {
    var accs = _.cloneDeep(this.state.data);

    if(value._position) {
      var parent = null;

      value._position.forEach((v, i) => {
        if(i != value._position.length-1) {
          if(i == 0) {
            parent = accs[v]
          } else {
            parent = parent.children[v]
          }
        }
      })

      if(parent) {
        if(value._action == 'edit') {
          parent.children[value._position[value._position.length-1]] = value;
        } else {
          parent.hasChild = true
          parent.children.push(value)
        }
      } else {
        if(value._action == 'edit') {
          accs[value._position[value._position.length-1]] = value;
        } else {
          accs.push(value)
        }
      }


      this.bindAction(accs);
      console.log(accs)
      this.setState({data: accs});
    }
  }

  handleAdd() {
    var data = {
      active: true,
      _action: 'add',
      children: [],
      isLabaRugiKomersialEditable: false,
      isKoreksiPositifEditable: false,
      isKoreksiNegatifEditable: false,
      isLabaRugiFiskalEditable: false,
      valueLabaRugiKomersial: "",
      valueKoreksiPositif: "",
      valueKoreksiNegatif: "",
      valueLabaRugiFiskal: "",
      coaLabaRugiKomersial: [], coaLabaRugiKomersialMap: {},
      coaKoreksiPositif: [], coaKoreksiPositifMap: {},
      coaKoreksiNegatif: [], coaKoreksiNegatifMap: {},
      coaLabaRugiFiskal: [], coaLabaRugiFiskalMap: {},
      parentId: null,
      seq: 1,
      hasChild: false,
      _position: [0],
      level: 1
    }

    this.props.dispatch(initialize('KoreksiFiskalSettingDialog', data))
    this.setState({showDialog: true})
  }

  bindAction(array, parent) {
    array.forEach((v, i) => {

      if(!parent) {
        v._position = [i]
      } else {
        var _position = _.cloneDeep(parent._position);
        _position.push(i);

        v._position = _position;
      }

      v.action = {
        onAdd: () => {
          var data = {
            active: true,
            _action: 'add',
            children: [],
            isLabaRugiKomersialEditable: false,
            isKoreksiPositifEditable: false,
            isKoreksiNegatifEditable: false,
            isLabaRugiFiskalEditable: false,
            valueLabaRugiKomersial: "",
            valueKoreksiPositif: "",
            valueKoreksiNegatif: "",
            valueLabaRugiFiskal: "",
            coaLabaRugiKomersial: [], coaLabaRugiKomersialMap: {},
            coaKoreksiPositif: [], coaKoreksiPositifMap: {},
            coaKoreksiNegatif: [], coaKoreksiNegatifMap: {},
            coaLabaRugiFiskal: [], coaLabaRugiFiskalMap: {},
            parentId: v.id,
            seq: 1,
            hasChild: false
          }

          if(!v) {
            v._position = [i]
          } else {
            var _position = _.cloneDeep(v._position);
            _position.push(v.children.length);
            data._position = _position;
          }

          data.level = data._position.length;

          this.props.dispatch(initialize('KoreksiFiskalSettingDialog', data))
          this.setState({showDialog: true})
        },
        onEdit: () => {
          var data = v;
          data._action = 'edit';

          var coaLabaRugiKomersialMap = {}
            if(data.coaLabaRugiKomersial) {
            data.coaLabaRugiKomersial.forEach((v, i)=> {
              coaLabaRugiKomersialMap['no'+i] = {coa: v};
            })
          }
          data.coaLabaRugiKomersialMap = coaLabaRugiKomersialMap;

          var coaLabaRugiFiskalMap = {}
            if(data.coaLabaRugiFiskal) {
            data.coaLabaRugiFiskal.forEach((v, i)=> {
              coaLabaRugiFiskalMap['no'+i] = {coa: v};
            })
          }
          data.coaLabaRugiFiskalMap = coaLabaRugiFiskalMap;

          var coaKoreksiPositifMap = {}
            if(data.coaKoreksiPositif) {
            data.coaKoreksiPositif.forEach((v, i)=> {
              coaKoreksiPositifMap['no'+i] = {coa: v};
            })
          }

          data.coaKoreksiPositifMap = coaKoreksiPositifMap;

          var coaKoreksiNegatifMap = {}
            if(data.coaKoreksiNegatif) {
            data.coaKoreksiNegatif.forEach((v, i)=> {
              coaKoreksiNegatifMap['no'+i] = {coa: v};
            })
          }

          data.coaKoreksiNegatifMap = coaKoreksiNegatifMap;


          this.props.dispatch(initialize('KoreksiFiskalSettingDialog',
              data
          ))
          this.setState({showDialog: true})
        },
        onDelete: ()=> {
          var accs = _.cloneDeep(this.state.data);
          var value = v;
          if(value._position) {
            var parent = null;

            value._position.forEach((v, i) => {
              if(i != value._position.length-1) {
                if(i == 0) {
                  parent = accs[v]
                } else {
                  parent = parent.children[v]
                }
              }
            })

            if(parent) {
              parent.children.splice(value._position[value._position.length-1], 1);
            } else {
              accs.splice(value._position[value._position.length-1], 1)
            }

            this.bindAction(accs)

            this.setState({data: accs})
          }
        }
      }

      if(v.children) this.bindAction(v.children, v)
    })
  }
}
