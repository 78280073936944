import ApiService from '../../../../services/api.service';

class Pph25Service extends ApiService {
  name  = 'pph25';
  path  = 'koreksi-fiskal/pph25';
  constructor() {
    super();
    this.init()
  }

  api= {
    findOne: async (id, tahunId, dispatch=()=> {})=> {
      let res = await this.http.get(`${this.apiLocation}/koreksi-fiskal/${tahunId}/pph25/${id}`)
      dispatch({
        type: `${this.name.toUpperCase()}_FIND_ONE`,
        data: res.data
      })

      return res;
    },

		save: async (data, dispatch=()=>{})=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/pph25`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_SAVE`,
        data: res.data
      })

      return res;
    },

		update: async (data, dispatch=()=>{})=> {
      let res = await this.http.put(`${this.apiLocation}/koreksi-fiskal/${data.tahun.id}/pph25/${data.id}`, data);
      dispatch({
        type: `${this.name.toUpperCase()}_UPDATE`,
        data: res.data
      })

      return res;
    },

		delete: async (item, dispatch=()=>{})=> {
      let res = await this.http.delete(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/pph25/${item.id}`);
      dispatch({
        type: `${this.name.toUpperCase()}_DELETE`,
        data: res.data
      })

      return res;
    },

    updateWorkflow: async (id, data, item)=> {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${item.tahun.id}/pph25/${id}/update-workflow`, data);
      return res;
    },
    sendToEppt: async (tahunId, data) => {
      let res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${tahunId}/pph25-send-to-eppt`, data);

      return res;
    },
    export: async (d) => {
      var res = await this.http.post(`${this.apiLocation}/koreksi-fiskal/${d.tahunId}/pph25/export`, d);
      return res
    }
  }
}


export default new Pph25Service();
