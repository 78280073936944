import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PenyusutanAmortisasiService from './PenyusutanAmortisasi.service';
import HartaService from '../Harta/Harta.service';
import MetodePenyusutanFiskalService from '../MetodePenyusutanFiskal/MetodePenyusutanFiskal.service';
import MetodePenyusutanKomersialService from '../MetodePenyusutanKomersial/MetodePenyusutanKomersial.service';
import utilsService from '../../../../services/utils.service';
import JenisHartaService from '../JenisHarta/JenisHarta.service';
import KelompokHartaService from '../KelompokHarta/KelompokHarta.service';
import JenisUsahaService from '../JenisUsaha/JenisUsaha.service';

@reduxForm({form: 'PenyusutanAmortisasiForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('PenyusutanAmortisasiForm')(state)
}))
export default class PenyusutanAmortisasiForm extends FormView {
  service     = PenyusutanAmortisasiService
  viewType    = 2

  initialData={
    catatan: '-'
  };
  formView() {
    return (
      <div>
      <Card>
      <div className='md-grid'>
        <Field
              label         = {"Tanggal Perolehan"}
              name          = 'tanggalPerolehan'
              className     = 'md-cell md-cell--12'
              component     = {Datepicker}
              validate      = {validation.required}
              onDateChange  = {(d)=> {
                this.props.change('masa', String(d.getMonth()+1))
                this.props.change('tahun', String(d.getFullYear()))
              }}
        />

        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.masa')}
          name          = 'masa'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          validate      = {validation.required}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.tahun')}
          name          = 'tahun'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        {/* <Field
          label         = {counterpart('entities.penyusutanAmortisasi.start')}
          name          = 'start'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.end')}
          name          = 'end'
          className     = 'md-cell md-cell--6'
          component     = {Datepicker}
          validate      = {validation.required}
        /> */}

        {this.state.jenisHartaList.length > 0 && <Field
          label         = {counterpart('entities.jenisHarta.title')}
          name          = 'jenisHarta'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {this.state.jenisHartaList}
          valueField    = 'parent'
          onChange      = {(e, v)=> this.handleJenisHartaChange(v)}
          // searchField   = {['name', 'aliasName', 'npwp']}
          />}
      {this.state.showKelompokHarta && <Field
          label         = {counterpart('entities.kelompokHarta.title')}
          name          = 'kelompokHarta'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {this.state.kelompokHartaList}
          valueField    = 'parent'
          // searchField   = {['name', 'aliasName', 'npwp']}
          onChange      = {(e, v)=> this.handleKelompokHartaChange(v)}

          />}
       {this.state.showJenisUsaha && <Field
          label         = {counterpart('entities.jenisUsaha.title')}
          name          = 'jenisUsaha'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {this.state.jenisUsahaList}
          valueField    = 'parent'
          validate      = {validation.required}
          // searchField   = {['name', 'aliasName', 'npwp']}
          onChange      = {(e, v)=> this.handleJenisUsahaChange(v)}
          />}

        {this.state.showHarta && <Field
          label         = {counterpart('entities.harta.title')}
          name          = 'harta'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {this.state.hartaList}
          valueField    = 'parent'
        />}

        <Field
          label         = {counterpart('entities.metodePenyusutanFiskal.title')}
          name          = 'metodePenyusutanFiskal'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          remoteSearch  = {true}
          service       = {MetodePenyusutanFiskalService}
          valueField    = 'parent'
        />

        <Field
          label         = {counterpart('entities.metodePenyusutanKomersial.title')}
          name          = 'metodePenyusutanKomersial'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          remoteSearch  = {true}
          service       = {MetodePenyusutanKomersialService}
          valueField    = 'parent'
        />

        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.hargaPerolehan')}
          name          = 'hargaPerolehan'
          className     = 'md-cell md-cell--12'
          component     = {TextfieldMask}
          validate      = {validation.required}
          money         = ','
        />
        {/* <Field
          label         = {counterpart('entities.penyusutanAmortisasi.nilaiBuku')}
          name          = 'nilaiBuku'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.penyusutanTahunIni')}
          name          = 'penyusutanTahunIni'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        /> */}
        <Field
          label         = {counterpart('entities.penyusutanAmortisasi.catatan')}
          name          = 'catatan'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          // validate      = {validation.required}
        />
      </div>
      </Card>
        <br/>

      {this.VList()}

      </div>
    )
  }

  VList() {
    if(this.props.data && this.props.data.id) {
      return <Card>
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>No.</TableColumn>
              <TableColumn>Bulan</TableColumn>
              <TableColumn>Tahun</TableColumn>
              <TableColumn className="md-text-right">Nilai Penyusutan</TableColumn>
              <TableColumn className="md-text-right">Akumulasi Pertahun</TableColumn>
              <TableColumn className="md-text-right">Nilai Sisa Buku </TableColumn>
              <TableColumn className="md-text-right">Akumulasi Total</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.state.penyusutanList.map((v, i) => {
              return <TableRow key={i}>
                <TableColumn>{i+1}</TableColumn>
                <TableColumn>{v.masa}</TableColumn>
                <TableColumn>{v.tahun}</TableColumn>
                <TableColumn className="md-text-right">{utilsService.number.format(v.penyusutanPerBulan)}</TableColumn>
                <TableColumn className="md-text-right">{utilsService.number.format(v.akumulasiPertahun)}</TableColumn>
                <TableColumn className="md-text-right">{utilsService.number.format(v.nilaiSisaBuku)}</TableColumn>
                <TableColumn className="md-text-right">{utilsService.number.format(v.akumulasiTotal)}</TableColumn>
              </TableRow>
            })}
          </TableBody>
        </DataTable>
      </Card>
    }

    return <div/>
  }

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      jenisHartaList: [],
      kelompokHartaList: [],
      jenisUsahaList: [],
      hartaList: [],
      penyusutanList: [],

      kelompokHartaMap: {},
      jenisUsahaMap: {},
      hartaMap: {},
      showKelompokHarta: true,
      showJenisUsaha: true,
      showHarta: true,
    };
  }

  async componentDidMount() {
    this.initData(this.props);

    var jenisHartaList = await JenisHartaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 'ASC'});
    var kelompokHartaList = await KelompokHartaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 'ASC'});
    var jenisUsahaList = await JenisUsahaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 'ASC'});
    var hartaList = await HartaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 'ASC'});

    var kelompokHartaMap = {}
    var jenisUsahaMap = {}
    var hartaMap = {}

    if(kelompokHartaList.data) {
      kelompokHartaMap = kelompokHartaList.data.data.reduce((pv, cv) => {
        if(!pv[cv.jenisHarta.id]) pv[cv.jenisHarta.id] = []
        pv[cv.jenisHarta.id].push(cv)
        return pv
      }, {})
    }

    if(jenisUsahaList.data) {
      jenisUsahaMap = jenisUsahaList.data.data.reduce((pv, cv) => {
        if(!pv[cv.kelompokHarta.id]) pv[cv.kelompokHarta.id] = []
        pv[cv.kelompokHarta.id].push(cv)
        return pv
      }, {})
    }

    if(hartaList.data) {
      hartaMap = hartaList.data.data.reduce((pv, cv) => {
        if(!pv[cv.jenisUsaha.id]) pv[cv.jenisUsaha.id] = []
        pv[cv.jenisUsaha.id].push(cv)
        return pv
      }, {})
    }

    this.setState({jenisHartaList: jenisHartaList.data.data, kelompokHartaMap, jenisUsahaMap, hartaMap})

    if(this.props.data.id) {
      var data = this.props.data;
      var lamaManfaat = data.harta.jenisUsaha.kelompokHarta.lamaManfaat;
      var penyusutanPerTahun  = data.hargaPerolehan / lamaManfaat;
      var penyusutanPerBulan  = penyusutanPerTahun / 12;

      var counter = 0;
      var tahun = parseInt(data.tahun);
      var masa = parseInt(data.masa)-1;
      var nilaiSisaBuku = data.hargaPerolehan;

      var penyusutanList = Array.from(Array(lamaManfaat*12)).map((v, i) => {
        counter ++;
        masa++;
        if(masa == 13) {
          tahun = tahun+1;
          masa = 1;

          nilaiSisaBuku = nilaiSisaBuku - ((counter-1) * penyusutanPerBulan)
          counter = 1;
        }

        var akumulasiTotal = (i+1) * penyusutanPerBulan;
        var akumulasiPertahun = (counter) * penyusutanPerBulan;

        return {tahun, masa, penyusutanPerBulan, akumulasiPertahun, akumulasiTotal, nilaiSisaBuku}

      })

      this.setState({penyusutanList})
    }
  }

  @utilsService.Debounce(1000)
  handleJenisHartaChange(v) {
    var kelompokHartaList = [];
    if(this.state.kelompokHartaMap[v.id]) kelompokHartaList = this.state.kelompokHartaMap[v.id]

    this.setState({showKelompokHarta: false})
    this.setState({kelompokHartaList})
    this.setState({showKelompokHarta: true})
  }

  @utilsService.Debounce(1000)
  handleKelompokHartaChange(v) {
    var jenisUsahaList = [];
    if(this.state.jenisUsahaMap[v.id]) jenisUsahaList = this.state.jenisUsahaMap[v.id]

    this.setState({showJenisUsaha: false})
    this.setState({jenisUsahaList})
    this.setState({showJenisUsaha: true})
  }

  @utilsService.Debounce(1000)
  handleJenisUsahaChange(v) {
    var hartaList = [];
    if(this.state.hartaMap[v.id]) hartaList = this.state.hartaMap[v.id]

    this.setState({showHarta: false})
    this.setState({hartaList})
    this.setState({showHarta: true})
  }
}
