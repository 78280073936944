import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, ListItem, DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn} from 'react-md';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';

// import FormDialog from './Perjanjian.dialog.view';
import PerjanjianService from './Perjanjian.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import DocumentTypeService from '../../Admin/DocumentType/DocumentType.service';
import DefaultView from '../../../../components/entity/default.view';
import { permission } from '../../../../config/constant';
import CompanyService from '../../Admin/Company/Company.service';
import counterpart from 'counterpart';

@connect((state) => ({
  ...PerjanjianService.stateConnectSetting()(state),
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), PerjanjianService.actionConnectSetting())
export default class PerjanjianView extends DefaultView {
  showDeleteAll = true;
  showBatchApproval       = true;
  service                 = PerjanjianService
  addDialog               = false
  editDialog              = false
  category                = null;

  approvalSettingName     = 'Perjanjian'
  checkerPermissionName   = permission.MelakukanCheckPerjanjian;
  approvalPermissionName  = permission.MelakukanApprovePerjanjian;

  async beforeFetch(params) {

    if(!this.category) {
      var res = await DocumentTypeService.api.find({page: 1, size: 1000, category: 'PO', sort: -1, sortBy: 'name', field: 'category', query: 'PO'});
      if(res.data) {
        this.category = res.data.data.map((v) => {
          return v.name
        })
      }
    }

    params.type= 'pembayaran-page-search-common';
    params.category=this.category;
    params.status=["draft", "checked", "approved", "reject", "TEST_STATUS", "TEST_STATUS_REFERENCE"];
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.no",                          value: "no",                    isDefaultSort:true},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.perjanjian.category",                    value: "category"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.reference",                   value: "reference",             className: "mpk-font-size-S"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.date",                        value: "date"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.lawanTransaksi.name",         value: "lawanTransaksi.name"},
    {isSortable: false, show: true,   isSearchable:true,  label: "entities.perjanjian.lawanTransaksi.npwp",         value: "lawanTransaksi.npwp"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.perjanjian.lawanTransaksi.address",      value: "lawanTransaksi.address"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.perjanjian.lawanTransaksi.email",        value: "lawanTransaksi.email"},
    {isSortable: false, show: false,  isSearchable:true,  label: "entities.perjanjian.lawanTransaksi.phone",        value: "lawanTransaksi.phone"},

    {isSortable: false, show: true,  isSearchable:false,  label: "entities.perjanjian.summary.total",               value: "summary.total" ,  type: 'number'},

  ]

  _dialogConfirm() {
    var selected = this.props.table.selected.filter((item) => (item.workflow.status == 'rejected' || item.workflow.status == 'draft') && this.props.user.login == item.createdBy)
    return <DialogConfirm
      title={"Hapus"}
      message={
        <div>
          <p>Apakah anda yakin akan menghapus data ini?</p>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn>Nomor PO</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selected.map((x, i) => (
                <TableRow key={i}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>{x.no}</TableColumn>
                </TableRow>
              ))}
            </TableBody>
          </DataTable>
        </div>
      }
      visible={this.state.showDialogConfirmDeleteSelected}
      onSubmit={async (callback) => {
        let items = this.props.table.selected

        await this.deleteSelected(items, callback)
      }}
      onCancel={() => this.setState({showDialogConfirmDeleteSelected:false})}
      translate={false}
    />
  }

  deleteSelected = async (items, callback) => {
    try {
      let ids = []

      items.forEach(item => {
        if((item.workflow.status == 'rejected' || item.workflow.status == 'draft') && this.props.user.login == item.createdBy) ids.push(item.id)
      })

      if(this.service.api.deleteSelected) {
        await this.service.api.deleteSelected(ids)
      } else if(this.service.api.deleteAll) {
        await this.service.api.deleteAll(ids)
      }

      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  deleteItem = async (item, callback) => {
    if(!(item.workflow.status == 'rejected' || item.workflow.status == 'draft')) {
      callback("Warning", "Data dengan status Approved/Waiting/Checked tidak dapat di hapus!")
      return item
    }

    if(this.props.user.login != item.createdBy) {
      callback("Warning", "Anda tidak di izinkan untuk menghapus data ini!")
      return item
    }

    try {
      await this.service.api.delete(item.id);
      callback()
      await this.fetchData()
    } catch(e) {
      callback(e, e)
    }
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
              label         = {counterpart('entities.company.title')}
              name          = 'companyId'
              className     = 'md-cell md-cell--12'
              component     = {this.ig.Searchfield}
              service       = {CompanyService}
              itemTemplate  = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              valueField    = 'id'
            />

        <this.ig.Field
          label='Masa Perolehan'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Tahun Perolehan'
          name='year'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nomor'
          name='no'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Reference'
          name='reference'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Lawan Transaksi'
          name='namaLk'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP Lawan Transaksi'
          name='npwpLk'
          className="md-cell md-cell--12"
          component={this.ig.TextfieldMask}
          maskFormat    = "##.###.###.#-###-###"
          mask          = "_"
          length        = {15}
        />

        <this.ig.Field
          label='Ke Tanggal'
          name='startDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />

        <this.ig.Field
          label='Dari Tanggal'
          name='endDate'
          className="md-cell md-cell--12"
          component={this.ig.Datepicker}
        />
      </this.SearchContainer>
    )
  }

  _approvalListView(v, i) {
    return <ListItem primaryText={`${v.lawanTransaksi.name} (${v.lawanTransaksi.npwp})`}  key={i} secondaryText={`Masa ${v.no} - ${v.date}`} />
  }
}
