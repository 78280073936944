import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import CoaService from './Coa.service';

@reduxForm({form: 'CoaForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('CoaForm')(state)
}))
export default class CoaDialog extends DialogView {
  service     = CoaService
  initialData = {
    compare: false,
    isPPn: false,
    isPPh21: false,
    isPPh23: false,
    isPPh4a2: false,
    isPPh25: false
  }

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.coa.code')}
          name          = 'code'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.name')}
          name          = 'name'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.dk')}
          name          = 'dk'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          options       = {[{id: 'D', name: 'Debit'}, {id: 'K', name: 'Kredit'}]}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.compare')}
          name          = 'compare'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.isPPn')}
          name          = 'isPPn'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.isPPh21')}
          name          = 'isPPh21'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.isPPh23')}
          name          = 'isPPh23'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.isPPh25')}
          name          = 'isPPh25'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          // validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.coa.isPPh4a2')}
          name          = 'isPPh4a2'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          // validate      = {validation.required}
        />
      </div>
    )
  }
}
