import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Field, reduxForm, getFormValues, initialize} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import ReceiptService from './Receipt.service';
import CompanyService from '../../Admin/Company/Company.service';
import LawanTransaksiService from '../../Admin/LawanTransaksi/LawanTransaksi.service';
import ReceiptItemDialog from './ReceiptItem.dialog';
import utilsService from '../../../../services/utils.service';
import terbilang from '../../../../services/terbilang.service';
import ItemTypeService from '../../Admin/ItemType/ItemType.service';
import DocumentTypeService from '../../Admin/DocumentType/DocumentType.service';
import { PerjanjianView } from '../Perjanjian/Perjanjian.view';
import PerjanjianService from '../Perjanjian/Perjanjian.service';
import UploadDialog from '../../Admin/upload/upload.dialog';
import ReceiptItemService from './ReceiptItem.service';
import IncomeTypeService from '../../Admin/IncomeType/IncomeType.service';
import CoaService from '../../Admin/Coa/Coa.service';

@reduxForm({form: 'ReceiptForm', destroyOnUnmount: true, initialValues: {
  items: [], itemMap: {}, summary: {}
}})
@connect((state) => ({
  formData: getFormValues('ReceiptForm')(state)
}))
export default class ReceiptForm extends FormView {
  service     = ReceiptService
  viewType    = 2;
  initialData = {
    items: [],
    workflow: {
      status: 'draft'
    },
    itemMap: {},
    locationOnRoot: false,
    itemTypeOnRoot: false,
    attachments: [],
    "summary": {
      "taxPercentage": 0,
      "taxAmount": 0,
      "discountPercentage": 0,
      "discountAmount": 0,
      "priceBeforeTax": 0,
      "priceAfterTax": 0,
      "other1Name": "",
      "other1Amount": 0,
      "other2Name": "",
      "other2Amount": 0,
      "total": 0,
      "spelledOut": "",
      "isPPnCalculatePerItem": true,
      "isDiscountCalculatePerItem": true,
      "isPpnIncluded": false
    },
    status: {
      receiptType: '',
      isLast: true,
      status: ''
    }
  }

  formView() {
    if(this.state.warmUp) return <div/>
    return (
      <div>
        <ReceiptItemDialog
          height={400}
          companies={this.state.companies}
          visible={this.state.showDialog}
          incomeTypes={this.state.incomeTypes}
          coas={this.state.coas}
          parentData={this.props.formData}
          // add={this.props.tableActions.addDataItem}
          formData={this.state.formData}
          onSuccess={(value)=> {this.handleAddItemSuccess(value)}}
          onClose={() => this.setState({showDialog:false})}
          match={this.props.match}
        />
        <Card>

          <div className='md-grid'>
            <br/>
            <Field
              label         = {counterpart('entities.receipt.category')}
              name          = 'category'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {this.state.documentTypes}
              validate      = {validation.required}
              valueField    = 'name'
            />
            <Field
              label         = {counterpart('entities.receipt.no')}
              name          = 'no'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.date')}
              name          = 'date'
              className     = 'md-cell md-cell--6'
              component     = {Datepicker}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.reference')}
              name          = 'reference'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.lawanTransaksi.title').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.title')}
              name          = 'lawanTransaksiId'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              service       = {LawanTransaksiService}
              onChange      = {(data) => {
                if(data.npwp && data.npwp.length > 0) this.handleNpwpChange(data.npwp)
                this.props.change('lawanTransaksi', data)
              }}
              valueField    = 'parent'
            />

            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.name')}
              name          = 'lawanTransaksi.name'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.npwp')}
              name          = 'lawanTransaksi.npwp'
              className     = 'md-cell md-cell--6'
              component     = {TextfieldMask}
              maskFormat    = "##.###.###.#-###-###"
              mask          = "_"
              length        = {15}
              validate      = {validation.required}
              onChange      = {(e, v)=> this.handleNpwpChange(v)}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.nik')}
              name          = 'lawanTransaksi.nik'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.address')}
              name          = 'lawanTransaksi.address'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.postal')}
              name          = 'lawanTransaksi.postal'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.phone')}
              name          = 'lawanTransaksi.phone'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
            />
            <Field
              label         = 'Provinsi'
              name          = 'lawanTransaksi.provinsi'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kabupaten'
              name          = 'lawanTransaksi.kabupaten'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kecamatan'
              name          = 'lawanTransaksi.kecamatan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = 'Kelurahan'
              name          = 'lawanTransaksi.kelurahan'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.receipt.lawanTransaksi.email')}
              name          = 'lawanTransaksi.email'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
              // validate      = {validation.required}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.source').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            {this.state.showPerjanjianForm &&
            <Field
              label         = {counterpart('entities.perjanjian.title')}
              name          = 'perjanjian'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              // service       = {PerjanjianService}
              // // validate      = {validation.required}
              // params        = {{
              //   page: 1, sortBy: 'no', size: 20, sort: 'ASC',
              //   type: 'pembayaran-page-search-common',
              //   category: this.state.poParam,
              //   status: ['TEST_STATUS', 'approved']
              // }}
              options       = {this.state.perjanjianList}
              viewField     = {'no'}
              itemTemplate  = {function(d) {
                var primary = ''
                if(d.lawanTransaksi) primary = d.lawanTransaksi.npwp+" - "+d.lawanTransaksi.name;
                return {
                  primaryText: primary,
                  secondaryText: d.no,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}

              // apiPath       = 'lookup'
              valueField    = 'parent'

              onChange      = {(d)=> {
                this.handlePerjanjianChange(d)
              }}
            />
            }

            {this.state.receiptTypes.length > 0 &&
              <Field
                label         = {counterpart('entities.receipt.receiptType')}
                name          = 'status.receiptTypeParent'
                className     = 'md-cell md-cell--12'
                component     = {Searchfield}
                options       = {this.state.receiptTypes}
                // apiPath       = 'lookup'
                valueField    = 'parent'
                onChange      = {(e)=> {
                  if(e.id == '') {
                    this.props.change('status.isLast', true)
                    this.props.change('status.receiptType', '')
                  } else {
                    this.props.change('status.isLast', e.isLast)
                    this.props.change('status.receiptType', e.name)
                  }

                }}
              />
            }

            </div>

        </Card>

        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.setting').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.receipt.locationOnRoot')}
              name          = 'locationOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.locationOnRoot) &&
            <Field
              label         = {counterpart('entities.company.title')}
              name          = 'companyId'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              options       = {this.state.companies}
              itemTemplate   = {function(d) {
                return {
                  primaryText: d[this.props.viewField],
                  secondaryText: d.npwp,
                  onClick: () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
              valueField    = 'id'
              // apiPath       = {'lookup'}
            />
            }
          </div>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.receipt.itemTypeOnRoot')}
              name          = 'itemTypeOnRoot'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
            {(this.props.formData && this.props.formData.itemTypeOnRoot) &&
            <Field
              label         = {counterpart('entities.receipt.itemType')}
              name          = 'itemType'
              className     = 'md-cell md-cell--6'
              component     = {Searchfield}
              service       = {ItemTypeService}
              valueField    = 'name'
            />
            }
          </div>
          <div className='md-grid'>
            {/* <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isDiscountCalculatePerItem')}
              name          = 'summary.isDiscountCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            />

            <Field
              label         = {counterpart('entities.perjanjian.termOfCondition.isPPnCalculatePerItem')}
              name          = 'summary.isPPnCalculatePerItem'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}

            {/* <Field
              label         = {counterpart('entities.perjanjian.summary.isPpnIncluded')}
              name          = 'summary.isPpnIncluded'
              className     = 'md-cell md-cell--12'
              component     = {Switch}
            /> */}
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.item.title').toUpperCase()} />
          <Divider/>
          <DataTable baseId="simple-pagination" plain>
            <TableHeader>
              <TableRow>
                <TableColumn>No. </TableColumn>
                <TableColumn></TableColumn>
                <TableColumn>Perusahaan/Cabang</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.code').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.name').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.description').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.price').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.qty').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.perjanjian.item.ppn').toUpperCase()}</TableColumn>
                <TableColumn>{counterpart('entities.receipt.item.total').toUpperCase()}</TableColumn>

              </TableRow>
            </TableHeader>
            <TableBody>
            {Object.keys(this.props.formData.itemMap).map((key, i)=> {
                return <TableRow key={i} selectable={false}>
                  <TableColumn>{i+1}</TableColumn>
                  <TableColumn>
                    <div className="buttons__group">
                      <Button flat iconChildren="edit_outline" forceIconSize={20} onClick={()=> this.handleEditItem(this.props.formData.itemMap[key], i)}>Edit</Button>
                      <Button flat primary iconChildren="delete_outline" forceIconSize={20} onClick={()=> this.handleDeleteItem(i, this.props.formData.itemMap[key])}>Delete</Button>
                    </div>
                  </TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].info.company.name +" - "+ this.props.formData.itemMap[key].info.company.npwp}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].code}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].name}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].description}</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].price)}</TableColumn>
                  <TableColumn>{this.props.formData.itemMap[key].qty}</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].ppn)}%</TableColumn>
                  <TableColumn>{utilsService.number.format2(this.props.formData.itemMap[key].total)}</TableColumn>
                  </TableRow>
            })}
            </TableBody>
          </DataTable>
          <div className='mpk-layout' style={{padding: 8}}>
              <div className='flex'></div>
              <Button flat primary swapTheming onClick={this.handleAddItem}>{counterpart('entities.receipt.action.addItem')}</Button>
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.receipt.summary.title').toUpperCase()} />
          <Divider/>

          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.perjanjian.summary.total')}
              name          = 'summary.totalItemPrice'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              disabled
              money={','}
            />

            {!this.props.formData.summary.isDiscountCalculatePerItem && <React.Fragment>
            <Field
              label         = {counterpart('entities.receipt.summary.discountPercentage')}
              name          = 'summary.discountPercentage'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'discountPercentage')}}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.discountAmount')}
              name          = 'summary.discountAmount'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            </React.Fragment>
            }

            {!this.props.formData.summary.isPPnCalculatePerItem &&
            <React.Fragment>
              <Field
              label         = {counterpart('entities.receipt.summary.priceBeforeTax')}
              name          = 'summary.priceBeforeTax'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.receipt.summary.taxPercentage')+' (%)'}
              name          = 'summary.taxPercentage'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'taxPercentage')}}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.taxAmount')}
              name          = 'summary.taxAmount'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.receipt.summary.priceAfterTax')}
              name          = 'summary.priceAfterTax'
              className     = 'md-cell md-cell--12 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            </React.Fragment>
            }

            <Field
              label         = {counterpart('entities.receipt.summary.other1Name')}
              name          = 'summary.other1Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.other1Amount')}
              name          = 'summary.other1Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other1Amount')}}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.other2Name')}
              name          = 'summary.other2Name'
              className     = 'md-cell md-cell--3'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.other2Amount')}
              name          = 'summary.other2Amount'
              className     = 'md-cell md-cell--3 '
              component     = {TextfieldMask}
              money={','}
              onChange      = {(e, v)=> {this.calculate(v, 'other2Amount')}}
            />
            <Field
              label         = {counterpart('entities.receipt.summary.total')}
              name          = 'summary.total'
              className     = 'md-cell md-cell--6 '
              component     = {TextfieldMask}
              money={','}
              disabled
            />
            <Field
              label         = {counterpart('entities.receipt.summary.spelledOut')}
              name          = 'summary.spelledOut'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              disabled
            />
          </div>

        </Card>
        <br/>
        {this.attachmentView()}

      </div>
    )
  }

  attachmentView() {
    // if(!this.props.formData.id) return <div/>
    if(!this.props.formData.attachments) return <div/>

    return <Card>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.attachment.title').toUpperCase()} />
      <Divider/>

      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={()=> {this.setState({showUpDialog: true})}}>{counterpart('entities.attachment.add')}</Button>
      </div>

      <List>
      {this.props.formData.attachments.map((v, i) => {
          return <ListItem primaryText={v.description} secondaryText={v.path} key={i} onClick={()=> this.handleDownloadAttachment(v.id)}
            rightIcon={<FontIcon onClick={(e)=> {e.stopPropagation(); this.handleDeleteAttachment(v.id, i)}}>close</FontIcon>}
          />
        })}
      </List>

      <UploadDialog
          height={400}
          visible={this.state.showUpDialog}
          formData={{}}
          onSuccess={(e, value)=> {this.handleAttachment(e, value)}}
          onClose={() => this.setState({showUpDialog:false})}
          match={this.props.match}
        />
    </Card>
  }

  constructor(props) {
    super(props)
    this.state = {
      onProgress: false,
      isError: false,
      errorMessage: '',
      showDialog: false,
      formData: {},
      showUpDialog: false,
      documentTypes: [],
      documentTypeParam: [],
      receiptParam: [],
      poParam: [],
      receiptTypes: [],

      perjanjianList: [],
      showPerjanjianForm: true,
      warmUp: true,
      incomeTypes: [],
      coas: [],
      companies: [],
      companyMap: {}
    }
  }

  async initData() {

    await this.getDocumentType()
    let companyRes = await CompanyService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })

    if(companyRes.data) {
      var companyMap = companyRes.data.data.reduce((pv, cv) => {
        pv[cv.id] = cv
        return pv;
      }, {})
      this.setState({companies: companyRes.data.data, companyMap})
    }

     // get income types
     let incomeTypeRes = await IncomeTypeService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    })
    if(incomeTypeRes.data) this.setState({incomeTypes: incomeTypeRes.data.data})
    // get coa
    let coaRes = await CoaService.api.find({
      page: 1,
      sortBy: 'name',
      size: 90000000,
      sort: 'ASC'
    });
    if(coaRes.data) this.setState({coas: coaRes.data.data})

    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      var itemMap = {}
      var totalItemPrice = 0;
      res.data.items.forEach((v, i)=> {
        itemMap['no'+i] = v;
        if(v.ppn == 10) v.adaPPN = true;
        if(v.total) totalItemPrice += parseFloat(v.total)
      })

      res.data.itemMap = itemMap;
      res.data.summary.totalItemPrice = totalItemPrice;

      this.props.initialize(res.data);
    }
  }

  async getDocumentType() {
    var res = await DocumentTypeService.api.find({page: 1, size: 1000, sort: -1, sortBy: 'name'});
    var po = [];
    var invoice = [];
    var receipt = [];

    if(res.data) {
      var categoryParam = res.data.data.forEach((v) => {
        if(v.category == 'PO') po.push(v);
        if(v.category == 'Receipt') receipt.push(v);
        if(v.category == 'Invoice') invoice.push(v);
      })

      var documentTypeParam = receipt.map((v) => {
        return v.name
      })
      var poParam = po.map((v) => {return v.name});
      var receiptParam = receipt.map(v => v.name);

      this.setState({documentTypes: receipt, documentTypeParam: documentTypeParam, warmUp: false, receiptParam, poParam})
    }
  }

  @utilsService.Debounce(1000)
  async handlePerjanjianChange(param) {
    // console.log(param, 'sadas')
    if(!param.id) return true;
    var res = await PerjanjianService.api.findOne(param.id);
    var data = _.cloneDeep(this.props.formData);

    var receiptTypes = [];
    if(res.data.termOfCondition && res.data.isReceiptMultiLevel && !isReceiptMultiLevelPartial && res.data.receipts) {
      receiptTypes = _.unionWith(receiptTypes, res.data.receipts, (a, b) => {
        return a.name == b.name
      })
    }

    var itemMap = {}
    res.data.items.forEach((v, i)=> {

      v.info = {
        company: v.location,
        perjanjianId: param.id,
        perjanjianNo: param.no,
        perjanjianItemId: v.id
      }
      delete v.id;

      v.incomeTypes.forEach((x, i) => {
        delete x.id;
        delete x.itemId
        x.pph = v.priceNett*x.incomeType.rate/100
      })

      v.companyId = v.locationId;
      v.perjanjianId = param.id;
      v.perjanjianNo = param.no;

      // if(v.periode && v.periode.total) {
      //   if(v.periode.total > 1) {
      //     v.price = v.price/v.periode.total;
      //     v.priceNett = (v.qty*v.price)-(v.discount*v.qty*v.price/100);
      //     v.total = v.priceNett+(v.ppn*v.priceNett/100)
      //   }
      // }

      v.periode = 0;
      if(v.ppn == 10) v.adaPPN = true;
      itemMap['no'+i] = v;
      if(res.data.termOfCondition && v.isReceiptMultiLevel && res.data.termOfCondition.isReceiptMultiLevelPartial && v.receipts) {
        receiptTypes = _.unionWith(receiptTypes, v.receipts, (a, b) => {
          return a.name == b.name
        })
      }
    })

    delete res.data.lawanTransaksi.id;
    delete res.data.summary.id;

    data.itemType = res.data.itemType;
    data.itemTypeOnRoot = res.data.itemTypeOnRoot;
    data.locationOnRoot = res.data.locationOnRoot;
    data.companyId = res.data.companyId;
    data.items = res.data.items;
    data.itemMap = itemMap;
    data.lawanTransaksi = res.data.lawanTransaksi;
    data.summary = res.data.summary;
    data.summary.isDiscountCalculatePerItem = res.data.termOfCondition.isDiscountCalculatePerItem;
    data.summary.isPPnCalculatePerItem = res.data.termOfCondition.isPPnCalculatePerItem;

    this.props.initialize(data)

    if(receiptTypes.length > 0) {
      this.setState({
        receiptTypes
      })
    }

    this.calculate()
  }

  @utilsService.Debounce(500)
  async handleNpwpChange(lawanTransaksiNpwp) {
    this.setState({showPerjanjianForm: false})
    var res = await PerjanjianService.api.findAllReceiptOutstanding({lawanTransaksiNpwp});
    this.setState({ perjanjianList: res.data})
    this.setState({
      showPerjanjianForm: true
    })
  }

  @utilsService.Debounce(500)
  async calculate(v, field) {
    var summary = this.props.formData.summary;
    var totalItemPrice = 0;
    Object.keys(this.props.formData.itemMap).forEach((v, i)=> {
      if(this.props.formData.itemMap[v].total) totalItemPrice += parseFloat(this.props.formData.itemMap[v].total)
    })
    var c = {
      totalItemPrice      : utilsService.number.get(totalItemPrice),
      discountPercentage  : utilsService.number.get(summary.discountPercentage),
      discountAmount      : utilsService.number.get(summary.discountAmount),
      priceBeforeTax      : utilsService.number.get(summary.priceBeforeTax),
      taxPercentage       : utilsService.number.get(summary.taxPercentage),
      taxAmount           : utilsService.number.get(summary.taxAmount),
      priceAfterTax       : utilsService.number.get(summary.priceAfterTax),
      other1Amount        : utilsService.number.get(summary.other1Amount),
      other2Amount        : utilsService.number.get(summary.other2Amount),
      total               : utilsService.number.get(summary.total),
      spelledOut          : ""
    }
    if(field) c[field] = utilsService.number.get(v);

    c.discountAmount = c.discountPercentage*c.totalItemPrice/100;
    if(summary.isPpnIncluded) {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.priceBeforeTax = 100/(100+c.taxPercentage)*c.priceBeforeTax;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    } else {
      c.priceBeforeTax = c.totalItemPrice - c.discountAmount;
      c.taxAmount = c.taxPercentage*c.priceBeforeTax/100;
      c.priceAfterTax = c.priceBeforeTax+c.taxAmount;
    }

    c.total = c.priceAfterTax+c.other1Amount+c.other2Amount;
    // console.log(c.total, 'asdas')
    c.spelledOut = terbilang(parseInt(c.total));

    this.props.change('summary.totalItemPrice', c.totalItemPrice)
    this.props.change('summary.discountAmount', c.discountAmount)
    this.props.change('summary.priceBeforeTax', c.priceBeforeTax)
    this.props.change('summary.taxAmount', c.taxAmount)
    this.props.change('summary.priceAfterTax', c.priceAfterTax)
    this.props.change('summary.total', c.total)
    this.props.change('summary.spelledOut', c.spelledOut)
  }

  async beforeSave(value) {
    value = _.cloneDeep(value)

    if(value.attachments.length <= 0) {
      iziToast.error({title: 'Error', message: 'Attachment harus di isi!'})

      return false
    }
    var items = [];
    delete value.lawanTransaksi.id;
    Object.keys(value.itemMap).forEach((k, i)=> {
      value.itemMap[k].date = value.date;
      items.push(value.itemMap[k])
    })

    value.items = items;
    delete value.itemMap;

    return value;
  }

  async handleAttachment(value) {
    value.path = value.name;
    value.receiptId = this.props.formData.id;
    value.description = value.origin;
    if(this.props.formData.id) {
      await this.service.api.saveAttachment(this.props.formData.id, {attachments: [value]});

      iziToast.success({
        title: 'success',
        message: counterpart('entities.attachment.attachmentSuccess')
      })
      await this.initData()
    } else {
      await this.service.api.saveAttachment('11111111-1111-1111-1111-111111111111', {attachments: [value]});
      var attachments = [...this.props.formData.attachments]
      attachments.push(value)

      this.props.change('attachments', attachments)
    }
  }

  async handleDownloadAttachment(attachmentId) {
    let res = await this.service.api.downloadAttachment(this.props.formData.id, attachmentId);
    var filename = res.headers['content-disposition']
    if(!filename) filename = res.headers['authorization']
    if(filename) filename = filename.split('filename=').pop();
    if(!filename) filename = "report.xlsx"
    download(res.data, filename);
  }

  async handleDeleteAttachment(attachmentId, i) {
    var dialog = await this.context.showDialog((props, res, rej) =>({
      text: 'Apakah anda yakin akan menghapus attachment ini?'
    }))
    if(dialog) {
      if(this.props.formData.id) {
        await this.service.api.deletedAttachment(this.props.formData.id, attachmentId);

        iziToast.success({title: 'Success', message: 'Attachment telah berhasil di hapus'})
        this.initData()
      } else {
        var attachments = [...this.props.formData.attachments]
        attachments.splice(i, 1)
        this.props.change('attachments', attachments)
      }
    }
  }

  handleAddItem = (value)=> {
    // var map = this.props.formData.itemMap;
    // var length = Object.keys(this.props.formData.itemMap).length
    // this.props.dispatch(this.props.change('itemMap.no'+length, {}))
    var data = {currency: 'Rupiah', incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, info: {company: {}, percentDppPpn: 100, dppPpn: 0}, ppn: 0, discount: 0, date: this.props.formData.date}
    if(this.props.formData && this.props.formData.locationOnRoot && this.props.formData.companyId) {
      data.companyId = this.props.formData.companyId
      data.info.companyId = this.props.formData.companyId;
      data.info.company = this.state.companyMap[this.props.formData.companyId]
      data.company = this.state.companyMap[this.props.formData.companyId]
    }

    if(this.props.formData && this.props.formData.itemTypeOnRoot && this.props.formData.itemType) {
      data.itemType = this.props.formData.itemType
    }

    this.props.dispatch(initialize('ReceiptItemForm', data))
    this.setState({showDialog:true})
  }

  handleAddItemSuccess = (value)=> {
    // if(this.props.formData.id) {
    //   this.calculate();
    //   this.initData()
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    var length = Object.keys(this.props.formData.itemMap).length

    if(value.itemIndex || value.itemIndex == 0) length = value.itemIndex;
    this.props.dispatch(this.props.change('itemMap.no'+length, value))

    this.calculate()
  }

  handleDeleteItem = async (i) => {
    // if(this.props.formData.id) {
    //   var dialog = await this.context.showDialog((props, res, rej) =>({
    //     text: 'Apakah anda yakin akan menghapus item ini?'
    //   }))
    //   if(dialog) {
    //     await ReceiptItemService.api.delete(this.props.formData.itemMap['no'+i].id, this.props.formData.id);

    //     iziToast.success({title: 'Success', message: 'Item telah berhasil di hapus'})
    //     this.initData()
    //   }

    //   this.calculate();
    //   return false;
    // }

    var map = this.props.formData.itemMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('itemMap', newMap))
    this.calculate()
  }

  handleEditItem = (data, i) => {
    let newData = _.cloneDeep(data);
    var incomeTypeMap = {};
    var receiptMap = {};
    if(newData.incomeTypes) {
      newData.incomeTypes.forEach((v, i)=> {
        incomeTypeMap['no'+i] = v;
      })
    }
    // if(newData.receipts) {
    //   newData.receipts.forEach((v, i)=> {
    //     receiptMap['no'+i] = v;
    //   })
    // }
    newData.itemIndex = i;
    newData.incomeTypeMap = incomeTypeMap;
    // newData.receiptMap = receiptMap;

    this.props.dispatch(initialize('ReceiptItemForm', newData))
    this.setState({showDialog:true})
  }
}
