import ApiService from '../../../../services/api.service';

class CbsService extends ApiService {
  name  = 'cbs';
  path  = 'cbs/cbs';
  constructor() {
    super();
    this.init()
  }

  api = {
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    }
  }
}


export default new CbsService();
