import React, { Component } from 'react';
import PenyusutanAmortisasiView from './PenyusutanAmortisasi.view';
import AmortisasiView from './Amortisasi.view';

export default class PenyusutanMainView extends React.Component {

  render() {
    return <div className='mpk-layout column fill'>
      <PenyusutanAmortisasiView history={this.props.history} />

      <AmortisasiView history={this.props.history} />
    </div>
  }
}
