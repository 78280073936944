import ApiService from '../../../../services/api.service';

class KelompokHartaService extends ApiService {
  name  = 'kelompokHarta';
  path  = 'master/kelompok-harta';
  constructor() {
    super();
    this.init()
  }
}


export default new KelompokHartaService();
