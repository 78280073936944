import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './PeriodUnit.dialog.view';
import PeriodUnitService from './PeriodUnit.service';
import ListView from '../../../../components/entity/listView';
import PeriodUnitDialog from './PeriodUnit.dialog';

@connect(PeriodUnitService.stateConnectSetting(), PeriodUnitService.actionConnectSetting())
export default class PeriodUnitView extends ListView {
  service     = PeriodUnitService
  FormDialog  = PeriodUnitDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.periodUnit.name",                    value: "name"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.periodUnit.description",             value: "description"}
  ]
}
