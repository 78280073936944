import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import AppScheduleService from './AppSchedule.service';

@reduxForm({form: 'AppScheduleForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('AppScheduleForm')(state)
}))
export default class AppScheduleDialog extends DialogView {
  service     = AppScheduleService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.appSchedule.scheduleId')}
          name          = 'scheduleId'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.appSchedule.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.appSchedule.cron')}
          name          = 'cron'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.appSchedule.enabled')}
          name          = 'enabled'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
