import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../../components/form';
import FormView from '../../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import PPh21Service from './PPh21Data.service';
import uploadService from '../../../Admin/upload/upload.service';
import env from 'env';

@reduxForm({form: 'PPh21ImportForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('PPh21ImportForm')(state)
}))
export default class PPh21ImportForm extends FormView {
  service     = PPh21Service
  viewType    = 2

  titleHeader() {
    return `entities.upload.pph21Import`;
  }

  formView() {
    return (
      <Card>
        <br/>
        <div className='md-grid'>
          <div className='mpk-layout row fill'>
            <div className='flex'></div><Button onClick={() => window.open(`/images/templates/pph21_import.xlsx`,'_blank')} flat primary swapTheming>Download Template</Button>
          </div>
          <Field
            label         = {counterpart('entities.upload.file')}
            name          = 'file'
            className     = 'md-cell md-cell--12'
            component     = {Fileinput}
            // twoLines
            validate      = {validation.required}
          />
          {/* <Field
            label         = {counterpart('entities.upload.delimiter')}
            name          = 'delimiter'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[
              {id: ',', name: 'Koma'},
              {id: ';', name: 'Titik Koma'}
            ]}
            validate      = {validation.required}
          /> */}
        </div>
      </Card>
    )
  }

  async initData() {

  }

  async handleSave(value) {

    var beforeSave = await this.beforeSave(value);

    try {
      var uploadData = new FormData();
      uploadData.append('file', value.file)
      var res = await uploadService.api.upload(uploadData);

      if(res.data.name) {
        value = {
          ...value,
          ...res.data,
          delimiter: ';'
        }

        var uploadRes = this.service.api.import(value)

        iziToast.success({
          title: 'success',
          message: counterpart('entities.upload.importSuccess')
        })
      }

      console.log(res)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }
}
