import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import JenisHartaService from './JenisHarta.service';

@reduxForm({form: 'JenisHartaForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('JenisHartaForm')(state)
}))
export default class JenisHartaDialog extends DialogView {
  service     = JenisHartaService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.jenisHarta.code')}
          name          = 'code'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.jenisHarta.name')}
          name          = 'name'
          className     = 'md-cell md-cell--12'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }
}
