import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card,CardTitle, CardText, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  TabsContainer,
  Tabs,
  Tab
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch} from '../../../../components/form';
import FormView from '../../../../components/entity/form.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import CompanyService from './Company.service';
import Searchfield from '../../../../components/form/Searchfield';

@reduxForm({form: 'CompanyForm', destroyOnUnmount: true, initialValues: {isHO: false}})
@connect((state) => ({
  formData: getFormValues('CompanyForm')(state)
}))
export default class CompanyForm extends FormView {
  service     = CompanyService
  viewType    = 2;

  initialData = {
    isHO: false,
    "contact":{
      "phone":"-",
      "email":"-",
      "fax":"-"
    },
    "address":{
      "address":"-"
    },
    "kpp":{
      "name":"-",
      "kanwil":"-"
    },
    "klu":{
      "code":"-",
      "name":"-"
    },
    "info": {

    },
    "penomoran": {
      bp23: {},
      bp4a2: {},
      bp21tf: {},
      bp21f: {},
      bp21a1:{}
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.name')}
              name          = 'name'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.company.npwp')}
              name          = 'npwp'
              className     = 'md-cell md-cell--6'
              component     = {TextfieldMask}
              maskFormat    = "##.###.###.#-###-###"
              mask          = "_"
              length        = {15}
              validate      = {validation.required}
            />
            <Field
              label         = {counterpart('entities.company.alias')}
              name          = 'alias'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.code')}
              name          = 'code'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.isHO')}
              name          = 'isHO'
              className     = 'md-cell md-cell--6'
              component     = {Switch}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.company.contact.title').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.contact.phone')}
              name          = 'contact.phone'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.contact.email')}
              name          = 'contact.email'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.contact.fax')}
              name          = 'contact.fax'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />

            <Field
              label         = 'Nama Penandatangan'
              name          = 'contact.namaPenandatangan'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
            <Field
              label         = 'NPWP Penandatangan'
              name          = 'contact.npwpPenandatangan'
              className     = 'md-cell md-cell--12'
              component     = {TextfieldMask}
              maskFormat    = "##.###.###.#-###-###"
              mask          = "_"
              length        = {15}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.company.address.address').toUpperCase()} />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.address.address')}
              name          = 'address.address'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.address.urbanVillage')}
              name          = 'address.urbanVillage'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.address.subDistrict')}
              name          = 'address.subDistrict'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.address.district')}
              name          = 'address.district'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.address.province')}
              name          = 'address.province'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='KPP' />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.kpp.name')}
              name          = 'kpp.name'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.kpp.kanwil')}
              name          = 'kpp.kanwil'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='KLU' />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.klu.code')}
              name          = 'klu.code'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.klu.name')}
              name          = 'klu.name'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
          </div>
        </Card>
        <br/>
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='INFO' />
          <Divider/>
          <div className='md-grid'>
            <Field
              label         = {counterpart('entities.company.info.dateRegistered')}
              name          = 'info.dateRegistered'
              className     = 'md-cell md-cell--6'
              component     = {Datepicker}
            />
            <Field
              label         = {counterpart('entities.company.info.dateEstablished')}
              name          = 'info.dateEstablished'
              className     = 'md-cell md-cell--6'
              component     = {Datepicker}
            />
            <Field
              label         = {counterpart('entities.company.info.category')}
              name          = 'info.category'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
            <Field
              label         = {counterpart('entities.company.info.legalEntity')}
              name          = 'info.legalEntity'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
            />
          </div>
        </Card>



        {this.penomoranView()}

        {this.epptIdView()}
      </div>
    )
  }

  epptIdView() {
    if(this.props.match.params.id == 'new') return <div/>
    return <div>
      <br/>
       <Card>
       <CardTitle title='' style={{padding: '10px 16px'}} subtitle='SETTING PENGIRIMAN BP' />
          <Divider/>
        <div className='md-grid'>
          <Field
              label         = "PPh 22"
              name          = 'eppt.pph23'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: "DEFAULT",
                name: "DEFAULT"
              },{
                id: "EBUNIFIKASI",
                name: "EBUNIFIKASI"
              }]}
            />
          <Field
              label         = "PPh 23"
              name          = 'eppt.pph22'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: "EBUPOT",
                name: "EBUPOT"
              },{
                id: "EBUNIFIKASI",
                name: "EBUNIFIKASI"
              }]}
            />
          <Field
              label         = "PPh 4(a)2"
              name          = 'eppt.pph4a2'
              className     = 'md-cell md-cell--12'
              component     = {Searchfield}
              options       = {[{
                id: "DEFAULT",
                name: "DEFAULT"
              },{
                id: "EBUNIFIKASI",
                name: "EBUNIFIKASI"
              }]}
            />
            </div>
        <CardTitle title='' style={{padding: '10px 16px'}} subtitle='SETTING EPPT ID' />
        <Divider/>
            <div className='md-grid'>


          <Field
              label         = "ID Perusahaan di Eppt"
              name          = 'eppt.epptId'
              className     = 'md-cell md-cell--12'
              component     = {Textfield}
            />

              <Button flat primary swapTheming onClick={() => this._updateIdEppt()}>Update ID EPPT</Button>
            </div>

       </Card>
    </div>
  }

  async _updateIdEppt() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Update data ID perusahaan di eppt?',
      width: 500,
      initialValue: {},
      okText: 'Submit',
      text: 'Apakah anda yakin akan Update data ID perusahaan di eppt?'
    }));

    if(d) {
      await this.service.api.updateEpptId(this.props.formData.id, this.props.formData.eppt.id, this.props.formData.eppt.epptId)

      iziToast.success({message: 'ID Eppt sudah di ubah'})
    }
  }

  penomoranView() {
    if(this.props.match.params.id == 'new') return <div/>
    return <div>
       <br/>
       <Card>
       <TabsContainer panelClassName="md-grid" colored>
          <Tabs tabId="simple-tab">
            <Tab label="Penomoran BP 21 TF">
            {/* <div className='md-grid'> */}
              {/* <Field
              label         = {counterpart('entities.penomoranBp23.prefix')}
              name          = 'penomoran.bp21tf.prefix'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
              />
              <Field
              label         = {counterpart('entities.penomoranBp23.suffix')}
              name          = 'penomoran.bp21tf.suffix'
              className     = 'md-cell md-cell--6'
              component     = {Textfield}
              // validate      = {validation.required}
              /> */}
              <Field
              label         = {counterpart('entities.penomoranBp23.increment')}
              name          = 'penomoran.bp21tf.increment'
              className     = 'md-cell md-cell--12'
              // component     = {Textfield}
              validate      = {validation.maxLength7}
              component={Textfield}
              maskFormat    = "#######"
              mask          = "_"
              length        = {7}
              />
            {/* </div> */}
            </Tab>
            <Tab label="Penomoran BP 21 F">
              {/* <div className='md-grid'> */}
                {/* <Field
                label         = {counterpart('entities.penomoranBp23.prefix')}
                name          = 'penomoran.bp21f.prefix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                />
                <Field
                label         = {counterpart('entities.penomoranBp23.suffix')}
                name          = 'penomoran.bp21f.suffix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                /> */}
                <Field
                label         = {counterpart('entities.penomoranBp23.increment')}
                name          = 'penomoran.bp21f.increment'
                className     = 'md-cell md-cell--12'
                // component     = {Textfield}
                // validate      = {validation.required}
                validate      = {validation.maxLength7}
                component={Textfield}
                maskFormat    = "#######"
                mask          = "_"
                length        = {7}
                />
              {/* </div> */}
            </Tab>
            <Tab label="Penomoran BP 21 A1">
              {/* <div className='md-grid'> */}
                <Field
                label         = {counterpart('entities.penomoranBp23.increment')}
                name          = 'penomoran.bp21a1.increment'
                className     = 'md-cell md-cell--12'
                // component     = {Textfield}
                // validate      = {validation.required}
                validate      = {validation.maxLength7}
                component={Textfield}
                maskFormat    = "#######"
                mask          = "_"
                length        = {7}
                />
              {/* </div> */}
            </Tab>
            <Tab label="Penomoran BP 23">
              {/* <div className='md-grid'> */}
                <Field
                label         = {counterpart('entities.penomoranBp23.prefix')}
                name          = 'penomoran.bp23.prefix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                />
                <Field
                label         = {counterpart('entities.penomoranBp23.suffix')}
                name          = 'penomoran.bp23.suffix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                />
                <Field
                label         = {counterpart('entities.penomoranBp23.increment')}
                name          = 'penomoran.bp23.increment'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
                validate      = {validation.required}
                />
              {/* </div> */}
            </Tab>
            <Tab label="Penomoran BP 4 (2)">
              {/* <div className='md-grid'> */}
                <Field
                label         = {counterpart('entities.penomoranBp23.prefix')}
                name          = 'penomoran.bp4a2.prefix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                />
                <Field
                label         = {counterpart('entities.penomoranBp23.suffix')}
                name          = 'penomoran.bp4a2.suffix'
                className     = 'md-cell md-cell--6'
                component     = {Textfield}
                // validate      = {validation.required}
                />
                <Field
                label         = {counterpart('entities.penomoranBp23.increment')}
                name          = 'penomoran.bp4a2.increment'
                className     = 'md-cell md-cell--12'
                component     = {Textfield}
                validate      = {validation.required}
                />
              {/* </div> */}
            </Tab>
          </Tabs>
        </TabsContainer>
        </Card>
    </div>
  }

  async handleSave(value) {
    var beforeSave = await this.beforeSave(value);
    var res = null;
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        if(value.id) {
          res = await this.service.api.update(value)
          await this.service.api.bulkUpdatePenomoran(value.id, value.penomoran)
        } else {
          res = await this.service.api.save(value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      let penomoranRes = await this.service.api.getPenomoran(res.data);

      var defaultPenomoran = {
        bp23:     {prefix: '',    suffix: '', companyId: this.props.match.params.id, name: "Bukti-Potong-Pasal-23",    increment: '00000000000000'},
        bp4a2:    {prefix: '',    suffix: '', companyId: this.props.match.params.id, name: "Bukti-Potong-Pasal-4a2",     increment: '00000000000000'},
        bp21tf:   {prefix: '',    suffix: '', companyId: this.props.match.params.id, name: "Bukti-Potong-Pasal-21TidakFinal",    increment: '00000000000000'},
        bp21f:    {prefix: '',    suffix: '', companyId: this.props.match.params.id, name:  "Bukti-Potong-Pasal-21Final",   increment: '00000000000000'},
        bp21a1:   {prefix: '',    suffix: '', companyId: this.props.match.params.id, name: "Bukti-Potong-Pasal-21-a1",    increment: '0000000'},
      }

      var penomoran = penomoranRes.data.data.reduce((pv, cv) => {
        pv[this.bpMap[cv.name]] = cv
        return pv
      }, {})

      res.data.penomoran = {
        ...defaultPenomoran,
        ...penomoran
      }

      this.props.initialize(res.data);
    }
  }

  bpMap = {
    "Bukti-Potong-Pasal-23": "bp23",
    "Bukti-Potong-Pasal-4a2": "bp4a2",
    "Bukti-Potong-Pasal-21Final": "bp21f",
    "Bukti-Potong-Pasal-21TidakFinal": "bp21tf",
    "Bukti-Potong-Pasal-21-a1": "bp21a1"
  }
}
