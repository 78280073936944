import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import JenisUsahaService from './JenisUsaha.service';
import ListView from '../../../../components/entity/listView';
import JenisUsahaDialog from './JenisUsaha.dialog';

@connect(JenisUsahaService.stateConnectSetting(), JenisUsahaService.actionConnectSetting())
export default class JenisUsahaView extends ListView {
  service     = JenisUsahaService
  FormDialog  = JenisUsahaDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisUsaha.code",       value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.kelompokHarta.title",   value: "kelompokHarta.name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisUsaha.name",       value: "name",  isDefaultSort:true}

  ]
}
