import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import JenisHartaService from './JenisHarta.service';
import ListView from '../../../../components/entity/listView';
import JenisHartaDialog from './JenisHarta.dialog';

@connect(JenisHartaService.stateConnectSetting(), JenisHartaService.actionConnectSetting())
export default class JenisHartaView extends ListView {
  service     = JenisHartaService
  FormDialog  = JenisHartaDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisHarta.code",       value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisHarta.name",       value: "name",  isDefaultSort:true}
  ]
}
