import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import _ from 'lodash';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  TabsContainer,
  Tabs,
  Tab,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import KoreksiFiskalSettingService from './KoreksiFiskalSetting.service';
import CoaService from '../Coa/Coa.service';
import { KoreksiFiskalValueList, KoreksiFiskalValue } from '../../../../config/constant';

@reduxForm({form: 'KoreksiFiskalSettingDialog', destroyOnUnmount: true, initialValues: {
  coaLabaRugiKomersial: [], coaLabaRugiKomersialMap: {},
  coaKoreksiPositif: [], coaKoreksiPositifMap: {},
  coaKoreksiNegatif: [], coaKoreksiNegatifMap: {},
  coaLabaRugiFiskal: [], coaLabaRugiFiskalMap: {}
}})
@connect((state) => ({
  data: getFormValues('KoreksiFiskalSettingDialog')(state)
}))
export default class KoreksiFiskalSettingDialog extends DialogView {
  size        = 'big'
  service     = KoreksiFiskalSettingService
  initialData = {
    coaLabaRugiKomersial: [], coaLabaRugiKomersialMap: {},
    coaKoreksiPositif: [], coaKoreksiPositifMap: {},
    coaKoreksiNegatif: [], coaKoreksiNegatifMap: {},
    coaLabaRugiFiskal: [], coaLabaRugiFiskalMap: {}
  }
  formStyle   = {padding: 0, height: '100%', overflowY: 'hidden'}
  translate   = false

  titleHeader() {
    if(!this.props.data.id) {
      return 'Create Account'
    } else {
      return 'Edit Account '+this.props.data.name
    }
  }

  formView() {
    return (
      // <div style>
        <TabsContainer panelClassName="" colored>
          <Tabs activeTabIndex={this.state.currentTab} tabId="simple-tab"  onTabChange={(n, d)=> {this.setState({currentTab: n}); console.log(n, 'asdasd')}}>
            <Tab label={counterpart('entities.koreksiFiskalSetting.basic')} index={0}>
              {this.basicForm()}
            </Tab>

            <Tab label={counterpart('entities.koreksiFiskalSetting.labaRugi')} index={1} style={{overflow: 'hidden'}}>
              {this.labaRugiForm()}
            </Tab>

            <Tab label={counterpart('entities.koreksiFiskalSetting.koreksi')} index={2} style={{overflow: 'hidden'}}>
              {this.koreksiForm()}
            </Tab>
          </Tabs>
        </TabsContainer>

      // </div>

    )
  }

  basicForm() {
    return <div className='md-grid'>
      <Field
        label         = {counterpart('entities.koreksiFiskalSetting.name')}
        name          = 'name'
        className     = 'md-cell md-cell--12'
        component     = {Textfield}
        validate      = {validation.required}
      />
      <Field
        label         = {counterpart('entities.koreksiFiskalSetting.seq')}
        name          = 'seq'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money         = {','}
        // validate      = {validation.required}
      />
      <Field
        label         = {counterpart('entities.koreksiFiskalSetting.level')}
        name          = 'level'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money         ={','}
        // validate      = {validation.required}
      />
    </div>
  }

  labaRugiForm() {
    return <div style={{minHeight: 400}}>
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.valueLabaRugiKomersial')}
          name          = 'valueLabaRugiKomersial'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {KoreksiFiskalValueList}
          money={','}
        />
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.isLabaRugiKomersialEditable')}
          name          = 'isLabaRugiKomersialEditable'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          money={','}
        />
      </div>

      {this.props.data.valueLabaRugiKomersial == KoreksiFiskalValue.Cbs &&
      <React.Fragment>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.koreksiFiskalSetting.coaLabaRugiKomersial').toUpperCase()} />
      <Divider/>
      <DataTable baseId="simple-pagination" plain>
        <TableHeader>
          <TableRow>
            <TableColumn>No. </TableColumn>
            <TableColumn style={{width: '80%'}}>{counterpart('entities.coa.title').toUpperCase()}</TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.coaLabaRugiKomersialMap).map((key, i)=> {
            return <TableRow key={i} selectable={false}>
              <TableColumn>{i+1}</TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.coa.title')}
                  name          = {'coaLabaRugiKomersialMap.no'+i+'.coa'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  options       = {this.props.coaList}
                  valueField    = 'parent'
                  // viewType      = 'dialog'
                  searchField2  = 'code'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <div className='mpk-layout'>
                  <div className='flex' />
                  <Button flat primary swapTheming onClick={() => this.handleDeleteCoaLabaRugiKomersial(i)}>Delete</Button>
                </div>
              </TableColumn>
            </TableRow>
          })}
        </TableBody>
      </DataTable>
      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddCoaLabaRugiKomersial()}}>{counterpart('entities.koreksiFiskalSetting.add')}</Button>
      </div>
      </React.Fragment>
      }



      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.valueLabaRugiFiskal')}
          name          = 'valueLabaRugiFiskal'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {KoreksiFiskalValueList}
          money={','}
        />
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.isLabaRugiFiskalEditable')}
          name          = 'isLabaRugiFiskalEditable'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          money={','}
        />
      </div>

      {this.props.data.valueLabaRugiFiskal == KoreksiFiskalValue.Cbs &&
      <React.Fragment>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.koreksiFiskalSetting.coaLabaRugiFiskal').toUpperCase()} />
      <Divider/>
      <DataTable baseId="simple-pagination" plain>
        <TableHeader>
          <TableRow>
            <TableColumn>No. </TableColumn>
            <TableColumn style={{width: '80%'}}>{counterpart('entities.coa.title').toUpperCase()}</TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.coaLabaRugiFiskalMap).map((key, i)=> {
            return <TableRow key={i} selectable={false}>
              <TableColumn>{i+1}</TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.coa.title')}
                  name          = {'coaLabaRugiFiskalMap.no'+i+'.coa'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
                  options       = {this.props.coaList}
                  searchField2  = 'code'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  valueField    = 'parent'
                  // viewType      = 'dialog'
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <div className='mpk-layout'>
                  <div className='flex' />
                  <Button flat primary swapTheming onClick={() => this.handleDeleteCoaLabaRugiFiskal(i)}>Delete</Button>
                </div>
              </TableColumn>
            </TableRow>
          })}
        </TableBody>
      </DataTable>
      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddCoaLabaRugiFiskal()}}>{counterpart('entities.koreksiFiskalSetting.add')}</Button>
      </div>
      </React.Fragment>
      }


    </div>
  }

  koreksiForm() {
    return <div style={{minHeight: 400}}>
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.valueKoreksiPositif')}
          name          = 'valueKoreksiPositif'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {KoreksiFiskalValueList}
          money={','}
        />
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.isKoreksiPositifEditable')}
          name          = 'isKoreksiPositifEditable'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          money={','}
        />
      </div>

      {this.props.data.valueKoreksiPositif == KoreksiFiskalValue.Cbs &&
      <React.Fragment>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.koreksiFiskalSetting.coaKoreksiPositif').toUpperCase()} />
      <Divider/>
      <DataTable baseId="simple-pagination" plain>
        <TableHeader>
          <TableRow>
            <TableColumn>No. </TableColumn>
            <TableColumn style={{width: '80%'}}>{counterpart('entities.coa.title').toUpperCase()}</TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.coaKoreksiPositifMap).map((key, i)=> {
            return <TableRow key={i} selectable={false}>
              <TableColumn>{i+1}</TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.coa.title')}
                  name          = {'coaKoreksiPositifMap.no'+i+'.coa'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
                  options       = {this.props.coaList}
                  searchField2  = 'code'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  valueField    = 'parent'
                  // viewType      = 'dialog'
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <div className='mpk-layout'>
                  <div className='flex' />
                  <Button flat primary swapTheming onClick={() => this.handleDeleteCoaKoreksiPositif(i)}>Delete</Button>
                </div>
              </TableColumn>
            </TableRow>
          })}
        </TableBody>
      </DataTable>
      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddCoaKoreksiPositif()}}>{counterpart('entities.koreksiFiskalSetting.add')}</Button>
      </div>
      </React.Fragment>
      }

      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.valueKoreksiNegatif')}
          name          = 'valueKoreksiNegatif'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {KoreksiFiskalValueList}
          money={','}
        />
        <Field
          label         = {counterpart('entities.koreksiFiskalSetting.isKoreksiNegatifEditable')}
          name          = 'isKoreksiNegatifEditable'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          money={','}
        />
      </div>

      {this.props.data.valueKoreksiNegatif == KoreksiFiskalValue.Cbs &&
      <React.Fragment>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.koreksiFiskalSetting.coaKoreksiNegatif').toUpperCase()} />
      <Divider/>
      <DataTable baseId="simple-pagination" plain>
        <TableHeader>
          <TableRow>
            <TableColumn>No. </TableColumn>
            <TableColumn style={{width: '80%'}}>{counterpart('entities.coa.title').toUpperCase()}</TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.coaKoreksiNegatifMap).map((key, i)=> {
            return <TableRow key={i} selectable={false}>
              <TableColumn>{i+1}</TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.coa.title')}
                  name          = {'coaKoreksiNegatifMap.no'+i+'.coa'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
                  options       = {this.props.coaList}
                  searchField2  = 'code'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  valueField    = 'parent'
                  // viewType      = 'dialog'
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <div className='mpk-layout'>
                  <div className='flex' />
                  <Button flat primary swapTheming onClick={() => this.handleDeleteCoaKoreksiNegatif(i)}>Delete</Button>
                </div>
              </TableColumn>
            </TableRow>
          })}
        </TableBody>
      </DataTable>
      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddCoaKoreksiNegatif()}}>{counterpart('entities.koreksiFiskalSetting.add')}</Button>
      </div>
      </React.Fragment>
      }
    </div>
  }

  constructor(props){
    super(props);
    this.state = {
      onProgress: false,
      currentTab: 0
    };
  }

  async initData(props) {
    if(!props.formData.id) {
      this.props.initialize(this.initialData);
    } else {
      let data = props.formData;

      var coaLabaRugiKomersialMap = {}
        if(data.coaLabaRugiKomersial) {
        data.coaLabaRugiKomersial.forEach((v, i)=> {
          coaLabaRugiKomersialMap['no'+i] = v;
        })
      }
      data.coaLabaRugiKomersialMap = coaLabaRugiKomersialMap;

      var coaLabaRugiFiskalMap = {}
        if(data.coaLabaRugiFiskal) {
        data.coaLabaRugiFiskal.forEach((v, i)=> {
          coaLabaRugiFiskalMap['no'+i] = v;
        })
      }
      data.coaLabaRugiFiskalMap = coaLabaRugiFiskalMap;

      var coaKoreksiPositifMap = {}
        if(data.coaKoreksiPositif) {
        data.coaKoreksiPositif.forEach((v, i)=> {
          coaKoreksiPositifMap['no'+i] = v;
        })
      }

      data.coaKoreksiPositifMap = coaKoreksiPositifMap;

      var coaKoreksiNegatifMap = {}
        if(data.coaKoreksiNegatif) {
        data.coaKoreksiNegatif.forEach((v, i)=> {
          coaKoreksiNegatifMap['no'+i] = v;
        })
      }

      data.coaKoreksiNegatifMap = coaKoreksiNegatifMap;

      this.props.initialize(data);
    }
  }

  async handleSave(callback, value) {
    value = _.cloneDeep(this.transform(value));
    try {

      var coaLabaRugiKomersial = [];
      Object.keys(value.coaLabaRugiKomersialMap).forEach((k, i)=> {
        coaLabaRugiKomersial.push(value.coaLabaRugiKomersialMap[k].coa)
      })
      value.coaLabaRugiKomersial = coaLabaRugiKomersial;
      delete value.coaLabaRugiKomersialMap;

      var coaLabaRugiFiskal = [];
      Object.keys(value.coaLabaRugiFiskalMap).forEach((k, i)=> {
        coaLabaRugiFiskal.push(value.coaLabaRugiFiskalMap[k].coa)
      })
      value.coaLabaRugiFiskal = coaLabaRugiFiskal;
      delete value.coaLabaRugiFiskalMap;

      var coaKoreksiPositif = [];
      Object.keys(value.coaKoreksiPositifMap).forEach((k, i)=> {
        coaKoreksiPositif.push(value.coaKoreksiPositifMap[k].coa)
      })
      value.coaKoreksiPositif = coaKoreksiPositif;
      delete value.coaKoreksiPositifMap;

      var coaKoreksiNegatif = [];
      Object.keys(value.coaKoreksiNegatifMap).forEach((k, i)=> {
        coaKoreksiNegatif.push(value.coaKoreksiNegatifMap[k].coa)
      })
      value.coaKoreksiNegatif = coaKoreksiNegatif;
      delete value.coaKoreksiNegatifMap;

      this.props.onSuccess(value)
      this.props.onClose()

      callback()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  handleAddCoaLabaRugiKomersial = ()=> {
    var map = this.props.formData.coaLabaRugiKomersialMap;
    var length = Object.keys(this.props.data.coaLabaRugiKomersialMap).length
    this.props.dispatch(this.props.change('coaLabaRugiKomersialMap.no'+length, {}))
  }

  handleDeleteCoaLabaRugiKomersial = (i) => {
    var map = this.props.data.coaLabaRugiKomersialMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('coaLabaRugiKomersialMap', newMap))
  }

  handleAddCoaLabaRugiFiskal = ()=> {
    var map = this.props.formData.coaLabaRugiFiskalMap;
    var length = Object.keys(this.props.data.coaLabaRugiFiskalMap).length
    this.props.dispatch(this.props.change('coaLabaRugiFiskalMap.no'+length, {}))
  }

  handleDeleteCoaLabaRugiFiskal = (i) => {
    var map = this.props.data.coaLabaRugiFiskalMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('coaLabaRugiFiskalMap', newMap))
  }

  handleAddCoaKoreksiPositif = ()=> {
    var map = this.props.formData.coaKoreksiPositifMap;
    var length = Object.keys(this.props.data.coaKoreksiPositifMap).length
    this.props.dispatch(this.props.change('coaKoreksiPositifMap.no'+length, {}))
  }

  handleDeleteCoaKoreksiPositif = (i) => {
    var map = this.props.data.coaKoreksiPositifMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('coaKoreksiPositifMap', newMap))
  }

  handleAddCoaKoreksiNegatif = ()=> {
    var map = this.props.formData.coaKoreksiNegatifMap;
    var length = Object.keys(this.props.data.coaKoreksiNegatifMap).length
    this.props.dispatch(this.props.change('coaKoreksiNegatifMap.no'+length, {}))
  }

  handleDeleteCoaKoreksiNegatif = (i) => {
    var map = this.props.data.coaKoreksiNegatifMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('coaKoreksiNegatifMap', newMap))
  }
}
