import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import ItemTypeService from './ItemType.service';
import ListView from '../../../../components/entity/listView';
import ItemTypeDialog from './ItemType.dialog';

@connect(ItemTypeService.stateConnectSetting(), ItemTypeService.actionConnectSetting())
export default class ItemTypeView extends ListView {
  service     = ItemTypeService
  FormDialog  = ItemTypeDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.itemType.name",                    value: "name"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.itemType.description",             value: "description"}
  ]
}
