import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import JenisUsahaService from './JenisUsaha.service';
import KelompokHartaService from '../KelompokHarta/KelompokHarta.service';
import JenisHartaService from '../JenisHarta/JenisHarta.service';
import utilsService from '../../../../services/utils.service';

@reduxForm({form: 'JenisUsahaForm', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  data: getFormValues('JenisUsahaForm')(state)
}))
export default class JenisUsahaDialog extends DialogView {
  service     = JenisUsahaService

  formView() {
    return (
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.jenisHarta.title')}
          name          = 'jenisHarta'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {this.state.jenisHartaList}
          valueField    = 'parent'
          onChange      = {(e, v)=> this.handleJenisHartaChange(v)}
          // searchField   = {['name', 'aliasName', 'npwp']}
          />
        {this.state.showKelompokHarta && <Field
          label         = {counterpart('entities.kelompokHarta.title')}
          name          = 'kelompokHarta'
          className     = 'md-cell md-cell--6'
          component     = {Searchfield}
          options       = {this.state.kelompokHartaList}
          valueField    = 'parent'
          // searchField   = {['name', 'aliasName', 'npwp']}
          validate      = {validation.required}
          />}

        <Field
          label         = {counterpart('entities.jenisUsaha.code')}
          name          = 'code'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
        <Field
          label         = {counterpart('entities.jenisUsaha.name')}
          name          = 'name'
          className     = 'md-cell md-cell--6'
          component     = {Textfield}
          validate      = {validation.required}
        />
      </div>
    )
  }

  async componentDidMount() {
    this.initData(this.props);

    var jenisHartaList = await JenisHartaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 1});
    var kelompokHartaList = await KelompokHartaService.api.find({page: 1, size: 10000000, sortBy: 'name', sort: 1});
    var kelompokHartaMap = {}

    if(kelompokHartaList.data) {
      kelompokHartaMap = kelompokHartaList.data.data.reduce((pv, cv) => {
        if(!pv[cv.jenisHarta.id]) pv[cv.jenisHarta.id] = []
        pv[cv.jenisHarta.id].push(cv)
        return pv
      }, {})
    }

    this.setState({jenisHartaList: jenisHartaList.data.data, kelompokHartaMap})
  }

  constructor(props){
    super(props);
    this.state = {
      onProgress: false,
      jenisHartaList: [],
      kelompokHartaList: [],
      kelompokHartaMap: {},
      showKelompokHarta: true
    };
  }

  @utilsService.Debounce(1000)
  handleJenisHartaChange(v) {
    var kelompokHartaList = [];
    if(this.state.kelompokHartaMap[v.id]) kelompokHartaList = this.state.kelompokHartaMap[v.id]

    this.setState({showKelompokHarta: false})
    this.setState({kelompokHartaList})

    this.setState({showKelompokHarta: true})
  }
}
