import ApiService from '../../../../services/api.service';

class IncomeTypeService extends ApiService {
  name  = 'incomeType';
  path  = 'master/incomeType';
  constructor() {
    super();
    this.init()
  }
}


export default new IncomeTypeService();
