import React, { Component } from 'react';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Switch as Switchd, Paper} from 'react-md';
import { Commandbar, Table, TableSetting, Empty, DialogConfirm } from 'react-mpk';

// import FormDialog from './Item.dialog.view';
import PenyusutanAmortisasiService from './PenyusutanAmortisasi.service';
import ListView from '../../../../components/entity/listView';
import authService from '../../../../services/auth.service';
import JenisHartaService from '../JenisHarta/JenisHarta.service';
import AmortisasiService from './Amortisasi.service';
import {PenyusutanAmortisasiImportDialog} from './PenyusutanAmortisasi.import.form';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield, Fileinput} from '../../../../components/form';
import {Field, reduxForm, getFormValues} from 'redux-form';
import counterpart from 'counterpart';
import iziToast from 'izitoast';
import DefaultView from '../../../../components/entity/default.view';
import { permission } from '../../../../config/constant';
import TableSettingCustom from '../../../../components/TableSettingCustom/TableSettingCustom';

@connect((state) => ({
  ...AmortisasiService.stateConnectSetting()(state),
  approvalSetting: state.auth.approvalSetting,
  user: state.auth.user
}), AmortisasiService.actionConnectSetting())
export default class AmortisasiView extends DefaultView {
  service     = AmortisasiService
  addDialog   = false
  editDialog  = false
  jenisHarta  = ['Harta tak berwujud', 'Harta Tak Berwujud']
  rootPath    = PenyusutanAmortisasiService.name

  approvalSettingName     = 'Penyusutan Amortisasi'
  checkerPermissionName   = permission.MelakukanCheckPenyusutanAmortisasi;
  approvalPermissionName  = permission.MelakukanApprovePenyusutanAmortisasi;


  async beforeFetch(params) {
    if(!this.jenisHarta) {
      // var res = await JenisHartaService.api.find({page: 1, size: 1000000, sort: -1, sortBy: 'name'});
      // if(res.data) {
      //   this.jenisHarta = res.data.data.map((v) => {
      //     return v.name
      //   })
      // }
    }

    params.status=["draft", "checked", "approved", "reject", "TEST_STATUS", "TEST_STATUS_REFERENCE"];
    params.jenisHarta = this.jenisHarta
  }

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.perjanjian.statusWorkflow",              value: (d)=> {
      if(d.workflow.status == 'draft') return <span style={{backgroundColor: '#AAA', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'rejected') return <span style={{backgroundColor: '#ffcdd2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'approved') return <span style={{backgroundColor: '#c8e6c9', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
      if(d.workflow.status == 'checked') return <span style={{backgroundColor: '#ffe0b2', borderRadius: 4, padding: '4px 8px'}}>{d.workflow.status.toUpperCase()}</span>
    }, type: 'func'},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.penyusutanAmortisasi.masa",                  value: "masa",       isDefaultSort:true},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.penyusutanAmortisasi.tahun",                 value: "tahun"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.harta.title",                                value: "harta.name"},
    {isSortable: false,  show: false,   isSearchable:false,  label: "entities.penyusutanAmortisasi.start",                value: "start",      type: "date"},
    {isSortable: false,  show: false,   isSearchable:false,  label: "entities.penyusutanAmortisasi.end",                  value: "end",        type: "date"},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.penyusutanAmortisasi.hargaPerolehan",        value: "hargaPerolehan", type: 'number'},
    {isSortable: false,  show: true,   isSearchable:false,  label: "entities.penyusutanAmortisasi.nilaiBuku",             value: "nilaiBuku"},
    // {isSortable: true,  show: true,   isSearchable:true,  label: "entities.penyusutanAmortisasi.penyusutanTahunIni",    value: "penyusutanTahunIni"},
    {isSortable: false,  show: false,   isSearchable:false,  label: "entities.penyusutanAmortisasi.catatan",               value: "catatan"}
  ]

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Masa Perolehan'
          name='month'
          className="md-cell md-cell--12"
          component={this.ig.Searchfield}
          options       = {[
            {id: '1', name: 'Januari'},
            {id: '2', name: 'Februari'},
            {id: '3', name: 'Maret'},
            {id: '4', name: 'April'},
            {id: '5', name: 'Mei'},
            {id: '6', name: 'Juni'},
            {id: '7', name: 'Juli'},
            {id: '8', name: 'Agustus'},
            {id: '9', name: 'September'},
            {id: '10', name: 'Oktober'},
            {id: '11', name: 'November'},
            {id: '12', name: 'Desember'}
          ]}
        />
        <this.ig.Field
          label='Tahun Perolehan'
          name='year'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Nama Harta'
          name='namaHarta'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  barActions = [

    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    {
      label:'word.import',
      iconClassName:'mdi mdi-import',
      onClick:() => this.setState({showImportDialog: true})
    },
    {
      label:'word.export',
      iconClassName:'mdi mdi-export',
      onClick:() => this.handleExport()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => {
        this.setState({showDialogConfirmDeleteSelected:true})
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  view1() {
    return (
      <this.viewContainer>
        {this.commandBar()}
        <div className="flex mpk-layout mpk-padding-N all">
          {this.tableView()}
          {this.tableSetting()}

          <this.FormDialog
            height={400}
            visible={this.state.showForm}
            add={this.props.tableActions.addDataItem}
            formData={this.state.formData}
            onSuccess={()=> this.fetchData()}
            onClose={() => this.setState({showForm:false})}
            match={this.props.match}
          />

          <PenyusutanAmortisasiImportDialog
            height={400}
            visible={this.state.showImportDialog}
            add={this.props.tableActions.addDataItem}
            formData={{}}
            onSuccess={()=> {}}
            onClose={() => this.setState({showImportDialog:false})}
            match={this.props.match}
          />

          {this.dialogConfirm()}
          {this.dialogConfirmDeleteFinishFailedSelected()}
        </div>
        {this.bottomBar()}
      </this.viewContainer>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showImportDialog: false

    }
  }

  async handleExport() {
    var d = await this.context.showDialog((props, res, rej) =>({
      title: 'Export to Csv',
      width: 500,
      initialValue: {},
      okText: 'Export',
      text: (<div className='md-grid'>
        <Field
            label         = 'Tahun Pajak'
            name          = 'year'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
        <Field
            label         = {counterpart('entities.upload.delimiter')}
            name          = 'delimiter'
            className     = 'md-cell md-cell--12'
            component     = {Searchfield}
            options       = {[
              {id: ',', name: 'Koma'},
              {id: ';', name: 'Titik Koma'}
            ]}
            validate      = {validation.required}
          />
        <Field
            label         = 'Note'
            name          = 'note'
            className     = 'md-cell md-cell--12'
            component     = {Textfield}
            validate      = {validation.required}
          />
      </div>)
    }));

    if(d) {
      await PenyusutanAmortisasiService.api.export(d);
      iziToast.success({
        title: 'success',
        message: counterpart('entities.upload.exportSuccess')
      })
    }
  }
}
