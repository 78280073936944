import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import CoaDaftarNominatifBiayaPromosiService from './CoaDaftarNominatifBiayaPromosi.service';
import ListView from '../../../../components/entity/listView';
import CoaDaftarNominatifBiayaPromosiDialog from './CoaDaftarNominatifBiayaPromosi.dialog'

@connect(CoaDaftarNominatifBiayaPromosiService.stateConnectSetting(), CoaDaftarNominatifBiayaPromosiService.actionConnectSetting())
export default class CoaDaftarNominatifBiayaPromosiView extends ListView {
  service     = CoaDaftarNominatifBiayaPromosiService
  FormDialog  = CoaDaftarNominatifBiayaPromosiDialog

  columns=[
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.code",       value: "coa.code", searchField: 'code'},
    {isSortable: false,  show: true,   isSearchable:true,  label: "entities.coa.name",       value: "coa.name", searchField: 'name'}
  ]
}
