import ApiService from '../../../../services/api.service';

class PenyusutanAmortisasiService extends ApiService {
  name  = 'penyusutanAmortisasi';
  path  = 'koreksi-fiskal/penyusutan-amortisasi';
  constructor() {
    super();
    this.init()
  }

  api = {
    export: async (d) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export-all`, d);
      return res
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    updateWorkflow: async (id, data)=> {
      let res = await this.http.post(`${this.apiLocation}/${this.path}/${id}/update-workflow`, data);
      return res;
    },
    summary: async (year) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}-summary/${year}`,{}, {
        responseType: 'blob'
      })
      return res;
    }
  }
}


export default new PenyusutanAmortisasiService();
