import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './Bank.dialog.view';
import BankService from './Bank.service';
import ListView from '../../../../../components/entity/listView';
import BankDialog from './Bank.dialog';

@connect(BankService.stateConnectSetting(), BankService.actionConnectSetting())
export default class BankView extends ListView {
  service     = BankService
  FormDialog  = BankDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.bank.name",                    value: "name"},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.bank.description",             value: "description",                  className: "mpk-font-size-S"}
  ]
}
