

export const ROLE_EPPT_ADMIN = 'admin';
export const ROLE_EPPT_USER = 'user';
export const ROLE_EPPT_OWNER = 'ROLE_EPPT_OWNER';

export const LEVEL = {
  ROLE_EPPT_ADMIN: 1,
  ROLE_EPPT_OWNER: 5,
  ROLE_EPPT_USER: 10
}

export const permission = {
  //Perjanjian
  "MelihatHalamanPerjanjian"                      : "Melihat halaman perjanjian",
  "MelihatDetailPerjanjian"                       : "Melihat detail perjanjian",
  "MembuatPerjanjian"                             : "Membuat perjanjian",
  "MerubahPerjanjian"                             : "Merubah perjanjian",
  "MenghapusPerjanjian"                           : "Menghapus perjanjian",
  "MelakukanCheckPerjanjian"                      : "Melakukan check perjanjian",
  "MelakukanApprovePerjanjian"                    : "Melakukan approve perjanjian",
  "MelakukanRejectPerjanjian"                     : "Melakukan reject perjanjian",
  "MenambahkanAttachmentPerjanjian"               : "Menambahkan attachment perjanjian",
  //Receipt
  "MelihatHalamanReceipt"                         : "Melihat halaman receipt",
  "MelihatDetailReceipt"                          : "Melihat detail receipt",
  "MembuatReceipt"                                : "Membuat receipt",
  "MerubahReceipt"                                : "Merubah receipt",
  "MenghapusReceipt"                              : "Menghapus receipt",
  "MelakukanCheckReceipt"                         : "Melakukan check receipt",
  "MelakukanApproveReceipt"                       : "Melakukan approve receipt",
  "MelakukanRejectReceipt"                        : "Melakukan reject receipt",
  "MenambahkanAttachmentReceipt"                  : "Menambahkan attachment receipt",
  //Invoice
  "MelihatHalamanInvoice"                         : "Melihat halaman invoice",
  "MelihatDetailInvoice"                          : "Melihat detail invoice",
  "MembuatInvoice"                                : "Membuat invoice",
  "MerubahInvoice"                                : "Merubah invoice",
  "MenghapusInvoice"                              : "Menghapus invoice",
  "MelakukanCheckInvoice"                         : "Melakukan check invoice",
  "MelakukanApproveInvoice"                       : "Melakukan approve invoice",
  "MelakukanRejectInvoice"                        : "Melakukan reject invoice",
  "MenambahkanAttachmentInvoice"                  : "Menambahkan attachment invoice",
  //Koreksi Fiskal/penyusutanamortisasi
  "MelihatHalamanPenyusutanAmortisasi"            : "Melihat halaman penyusutan & amortisasi",
  "MelihatDetailPenyusutanAmortisasi"             : "Melihat detail penyusutan & amortisasi",
  "MembuatPenyusutanAmortisasi"                   : "Membuat penyusutan & amortisasi",
  "MerubahPenyusutanAmortisasi"                   : "Merubah penyusutan & amortisasi",
  "MenghapusPenyusutanAmortisasi"                 : "Menghapus penyusutan & amortisasi",
  "MelakukanCheckPenyusutanAmortisasi"            : "Melakukan check penyusutan & amortisasi",
  "MelakukanApprovePenyusutanAmortisasi"          : "Melakukan approve penyusutan & amortisasi",
  "MelakukanRejectPenyusutanAmortisasi"           : "Melakukan reject penyusutan & amortisasi",
  //Koreksi Fiskal/ Daftar Nominatif Biaya Promosi
  "MelihatHalamanDaftarNominatifBiayaPromosi"     : "Melihat halaman daftar nominatif biaya promosi",
  "MelihatDetailDaftarNominatifBiayaPromosi"      : "Melihat detail daftar nominatif biaya promosi",
  "MembuatDaftarNominatifBiayaPromosi"            : "Membuat daftar nominatif biaya promosi",
  "MerubahDaftarNominatifBiayaPromosi"            : "Merubah daftar nominatif biaya promosi",
  "MenghapusDaftarNominatifBiayaPromosi"          : "Menghapus daftar nominatif biaya promosi",
  "MelakukanCheckDaftarNominatifBiayaPromosi"     : "Melakukan check daftar nominatif biaya promosi",
  "MelakukanApproveDaftarNominatifBiayaPromosi"   : "Melakukan approve daftar nominatif biaya promosi",
  "MelakukanRejectDaftarNominatifBiayaPromosi"    : "Melakukan reject daftar nominatif biaya promosi",
  //Koreksi Fiskal/ Daftar Nominatif Biaya Entertain
  "MelihatHalamanDaftarNominatifBiayaEntertain"   : "Melihat halaman daftar nominatif biaya entertain",
  "MelihatDetailDaftarNominatifBiayaEntertain"    : "Melihat detail daftar nominatif biaya entertain",
  "MembuatDaftarNominatifBiayaEntertain"          : "Membuat daftar nominatif biaya entertain",
  "MerubahDaftarNominatifBiayaEntertain"          : "Merubah daftar nominatif biaya entertain",
  "MenghapusDaftarNominatifBiayaEntertain"        : "Menghapus daftar nominatif biaya entertain",
  "MelakukanCheckDaftarNominatifBiayaEntertain"   : "Melakukan check daftar nominatif biaya entertain",
  "MelakukanApproveDaftarNominatifBiayaEntertain" : "Melakukan approve daftar nominatif biaya entertain",
  "MelakukanRejectDaftarNominatifBiayaEntertain"  : "Melakukan reject daftar nominatif biaya entertain",
  //Cbs/Transaction
  "MelihatHalamanCbs"                             : "Melihat halaman cbs",
  "MelihatDetailCbs"                              : "Melihat detail cbs",
  "MembuatCbs"                                    : "Membuat cbs",
  "MerubahCbs"                                    : "Merubah cbs",
  "MenghapusCbs"                                  : "Menghapus cbs",
  "MelakukanCheckCbs"                             : "Melakukan check cbs",
  "MelakukanApproveCbs"                           : "Melakukan approve cbs",
  "MelakukanRejectCbs"                            : "Melakukan reject cbs",
  "MelakukanImportCbs"                            : "Melakukan import cbs",
  //koreksifiskal/KoreksiFiskal
  "MelihatHalamanKoreksiFiskal"                   : "Melihat halaman koreksi fiskal",
  "MelihatDetailKoreksiFiskal"                    : "Melihat detail koreksi fiskal",
  "MembuatKoreksiFiskal"                          : "Membuat koreksi fiskal",
  "MerubahKoreksiFiskal"                          : "Merubah koreksi fiskal",
  "MenghapusKoreksiFiskal"                        : "Menghapus koreksi fiskal",
  "MelakukanCheckKoreksiFiskal"                   : "Melakukan check koreksi fiskal",
  "MelakukanApproveKoreksiFiskal"                 : "Melakukan approve koreksi fiskal",
  "MelakukanRejectKoreksiFiskal"                  : "Melakukan reject koreksi fiskal",
  //logging/logimport
  "MelihatHalamanLogImport"                       : "Melihat halaman log import",
  "MelihatDetailLogImport"                        : "Melihat detail log import",
  "MembuatLogImport"                              : "Membuat log import",
  "MerubahLogImport"                              : "Merubah log import",
  "MenghapusLogImport"                            : "Menghapus log import",
  "MelakukanDownloadLogImport"                    : "Melakukan download log import",
  //pph25
  "MelihatHalamanPPh25"                           : "Melihat halaman pph25",
  "MelihatDetailPPh25"                            : "Melihat detail pph25",
  "MembuatPPh25"                                  : "Membuat pph25",
  "MerubahPPh25"                                  : "Merubah pph25",
  "MenghapusPPh25"                                : "Menghapus pph25",
  "MelakukanCheckPPh25"                           : "Melakukan check pph25",
  "MelakukanApprovePPh25"                         : "Melakukan approve pph25",
  "MelakukanRejectPPh25"                          : "Melakukan reject pph25",

  "MelihatHalamanPPh21Periode"                    : "Melihat halaman PPh 21 Periode",
  "MelihatDetailPPh21Periode"                     : "Melihat detail PPh 21 Periode",
  "MembuatPPh21Periode"                           : "Membuat PPh 21 Periode",
  "MerubahPPh21Periode"                           : "Merubah PPh 21 Periode",
  "MenghapusPPh21Periode"                         : "Menghapus PPh 21 Periode",
  "MelakukanCheckPPh21Periode"                    : "Melakukan check PPh 21 Periode",
  "MelakukanApprovePPh21Periode"                  : "Melakukan approve PPh 21 Periode",
  "MelakukanRejectPPh21Periode"                   : "Melakukan reject PPh 21 Periode"
}

export const KoreksiFiskalValue  = {
  "TidakAdaNilai"                                 : "Tidak Ada Nilai",
  "Input"                                         : "Input",
  "Penyusutan"                                    : "Penyusutan",
  "Amortisasi"                                    : "Amortisasi",
  "Penjumlahan"                                   : "Penjumlahan",
  "Cbs"                                           : "Cbs",
  "DaftarNominatifBiayaPromosiTotal"              : "Daftar Nominatif Biaya Promosi Total",
  "DaftarNominatifBiayaPromosiLengkap"            : "Daftar Nominatif Biaya Promosi Lengkap",
  "DaftarNominatifBiayaPromosiTidakLengkap"       : "Daftar Nominatif Biaya Promosi Tidak Lengkap",
  "DaftarNominatifBiayaEntertainTotal"            : "Daftar Nominatif Biaya Entertain Total",
  "DaftarNominatifBiayaEntertainLengkap"          : "Daftar Nominatif Biaya Entertain Lengkap",
  "DaftarNominatifBiayaEntertainTidakLengkap"     : "Daftar Nominatif Biaya Entertain Tidak Lengkap",
  "PenguranganKoreksiPositifDanNegatif"           : "Penjumlahan Koreksi Positif dan Negatif",
  "LabaRugiKomersialDitambahKoreksiPositifDikurangiNegatif"           : "Laba Rugi Komersial Ditambah Koreksi Positif Dikurangi Koreksi Negatif"
}

export const KoreksiFiskalValueList = Object.keys(KoreksiFiskalValue).map((k, i) => ({id: KoreksiFiskalValue[k], name: KoreksiFiskalValue[k]}))
