import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import HartaService from './Harta.service';
import ListView from '../../../../components/entity/listView';
import HartaDialog from './Harta.dialog';

@connect(HartaService.stateConnectSetting(), HartaService.actionConnectSetting())
export default class HartaView extends ListView {
  service     = HartaService
  FormDialog  = HartaDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.harta.code",           value: "code"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.jenisUsaha.title",   value: "jenisUsaha.name"},
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.harta.name",           value: "name",  isDefaultSort:true},
    {isSortable: false, show: true,   isSearchable:false, label: "entities.harta.description",    value: "description"}

  ]
}
