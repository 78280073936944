import ApiService from '../../../../services/api.service';

class CompanyService extends ApiService {
  name  = 'company';
  path  = 'master/company';
  constructor() {
    super();
    this.init()
  }

  api = {
    lookup: async () => {
      var res = await this.http.get(`${this.apiLocation}/master/company-look-up-all`)
      console.log(res)
      return {
        data: res
      }
    },
    sync: async () => {
      var res = await this.http.get(`${this.apiLocation}/master/company-sync`)
      return res;
    },
    import: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/${this.path}-import`, data);
      return res;
    },
    getPenomoran: async (data) => {
      let res = await this.http.get(`${this.apiLocation}/master/company/${data.id}/penomoran-bp`, {
        params:{page: 1, sortBy: 'name', size: 90000000, sort: 1}
      });
      return res;
    },
    bulkUpdatePenomoran: async (companyId, data) => {
      let res = await this.http.post(`${this.apiLocation}/master/company/${companyId}/penomoran-bp`, data);
      return res;
    },
    resetNomorBp: async (data) => {
      let res = await this.http.post(`${this.apiLocation}/master/company/reset-penomoran`, data);
      return res;
    },
    updateEpptId: async (id, companyEpptId, epptId) => {
      let res = await this.http.get(`${this.apiLocation}/master/company-update-eppt/${id}/${companyEpptId}/${epptId}`);
      return res;
    }


  }
}


export default new CompanyService();
