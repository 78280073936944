import React, { Component } from 'react';
import { connect } from 'react-redux';

// import FormDialog from './ItemType.dialog.view';
import ApprovalSettingService from './ApprovalSetting.service';
import ListView from '../../../../components/entity/listView';
import ApprovalSettingDialog from './ApprovalSetting.dialog';

@connect(ApprovalSettingService.stateConnectSetting(), ApprovalSettingService.actionConnectSetting())
export default class ApprovalSettingView extends ListView {
  service     = ApprovalSettingService
  FormDialog  = ApprovalSettingDialog

  columns=[
    {isSortable: true,  show: true,   isSearchable:true,  label: "entities.approvalSetting.name",       value: "name",    isDefaultSort:true},
    {isSortable: false, show: true,   isSearchable:false,  label: "entities.approvalSetting.enable",     value: "enable",  type: "boolean"}

  ]
}
