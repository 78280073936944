import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import _ from 'lodash';
import { Button, Card, CardText, CardTitle, Divider,Grid, Cell, LinearProgress, FontIcon,
  List,
  ListItem,
  TabsContainer,
  Tabs,
  Tab,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
} from 'react-md';
import counterpart from 'counterpart';
import {Textfield, TextfieldMask, validation, Datepicker, Switch, Searchfield} from '../../../../components/form';
import DialogView from '../../../../components/entity/dialog.view';
import iziToast from 'izitoast';
import download from 'downloadjs';
import InvoiceService from './Invoice.service';
import ItemUnitService from '../../Admin/ItemUnit/ItemUnit.service';
import CurrencyService from '../../Admin/Currency/Currency.service';
import CompanyService from '../../Admin/Company/Company.service';
import CoaService from '../../Admin/Coa/Coa.service';
import IncomeTypeService from '../../Admin/IncomeType/IncomeType.service';
import utilsService from '../../../../services/utils.service';
import ItemService from '../../Admin/Item/Item.service';
import ItemTypeService from '../../Admin/ItemType/ItemType.service';
import InvoiceItemService from './InvoiceItem.service';

@reduxForm({form: 'InvoiceItemForm', destroyOnUnmount: true, initialValues: {
  incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, info: {percentDppPpn: 100, dppPpn: 0}
}})
@connect((state) => ({
  data: getFormValues('InvoiceItemForm')(state)
}))
export default class InvoiceItemDialog extends DialogView {
  size        = 'huge'
  service     = InvoiceItemService
  initialData = {incomeTypes: [], incomeTypeMap: {}, isReceiptMultiLevel: false, receipts: [], receiptMap: {}, info: {percentDppPpn: 100, dppPpn: 0}}
  formStyle   = {padding: 0}

  titleHeader() {
    return `entities.invoice.item.title`;
  }

  formView() {
    return (
      <div>
        <TabsContainer panelClassName="" colored>
          <Tabs activeTabIndex={this.state.currentTab} tabId="simple-tab" mobile={false} onTabChange={(n, d)=> {this.setState({currentTab: n}); console.log(n, 'asdasd')}}>
            <Tab label={counterpart('entities.invoice.action.basicInfo')} index={0}>
              {this.basicForm()}
            </Tab>

            <Tab label={counterpart('entities.invoice.action.others')} index={1} style={{overflow: 'hidden'}}>
              {this.othersForm()}
            </Tab>
          </Tabs>
        </TabsContainer>

        {/* {this.switchForm()} */}
      </div>

    )
  }

  basicForm() {
    return ( <div className='md-grid' style={{width: '100%', overflow: 'hidden'}}>
      <Field
        label         = {counterpart('entities.receipt.item.title')}
        name          = 'itemId'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        service       = {ItemService}
        onChange      = {(data) => {
          this.handleItemChange(data)
        }}
        valueField    = 'parent'
      />
      <Field
        label         = {counterpart('entities.invoice.item.itemType')}
        name          = 'itemType'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        service       = {ItemTypeService}
        valueField    = 'name'
        disabled      = {this.props.parentData && this.props.parentData.itemTypeOnRoot && this.props.parentData.itemType }
      />
      <Field
        label         = {counterpart('entities.invoice.item.code')}
        name          = 'code'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
      />
      <Field
        label         = {counterpart('entities.invoice.item.name')}
        name          = 'name'
        className     = 'md-cell md-cell--6'
        component     = {Textfield}
        validate      = {validation.required}
      />
      <Field
        label         = {counterpart('entities.invoice.item.description')}
        name          = 'description'
        className     = 'md-cell md-cell--12'
        component     = {Textfield}
      />

      <Field
        label         = {counterpart('entities.invoice.item.unit')}
        name          = 'unit'
        className     = 'md-cell md-cell--6'
        component     = {Searchfield}
        // remoteSearch  = {true}
        service       = {ItemUnitService}
        valueField    = 'name'
        // searchField   = {['name', 'aliasName', 'npwp']}
        validate      = {validation.required}
        />
      <Field
        label         = {counterpart('entities.invoice.item.currency')}
        name          = 'currency'
        className     = 'md-cell md-cell--6'
        component     = {Searchfield}
        // remoteSearch  = {true}
        service       = {CurrencyService}
        params        = {{page: 1, sortBy: 'country', size: 9000000, sort: 'ASC'}}
        viewField     = 'currency'
        valueField    = 'currency'
        // searchField   = {['name', 'aliasName', 'npwp']}
        validate      = {validation.required}
        />
      <Field
        label         = {counterpart('entities.invoice.item.qty')}
        name          = 'qty'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money={','}
        onChange      = {(e, v)=> {this.calculatePriceNet(v, 'qty')}}
      />
      <Field
        label         = {counterpart('entities.invoice.item.price')}
        name          = 'price'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money={','}
        onChange      = {(e, v)=> {this.calculatePriceNet(v, 'price')}}
      />
      <Field
        label         = {counterpart('entities.invoice.item.discount')}
        name          = 'discount'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money={','}
        onChange      = {(e, v)=> {this.calculatePriceNet(v, 'discount')}}
      />
      <Field
        label         = {counterpart('entities.invoice.item.priceNett')}
        name          = 'priceNett'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money={','}
        disabled
      />
      <div className="md-cell md-grid md-cell--6">
        <Field
          label         = {"Ada PPN?"}
          name          = 'adaPPN'
          className     = 'md-cell md-cell--6'
          component     = {Switch}
          onChange      = {async (e, v)=> {
            if(v) {
              await this.props.change('ppn', 10)
              this.calculatePriceNet(10, 'ppn')
            } else {
              await this.props.change('ppn', 0)
              this.calculatePriceNet(0, 'ppn')
            }
          }}
        />
        <Field
          label         = {counterpart('entities.perjanjian.item.ppn')}
          name          = 'ppn'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
          // disabled      = {true}
          onChange      = {(e, v)=> {this.calculatePriceNet(v, 'ppn')}}
        />

        {this.props.data.adaPPN && <Field
          label         = {"Persentasi DPP PPN"}
          name          = 'info.percentDppPpn'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
          // disabled      = {true}
          onChange      = {(e, v)=> {this.calculatePriceNet(v, 'percentDppPpn')}}
        />}
        {this.props.data.adaPPN && <Field
          label         = {"DPP PPN"}
          name          = 'info.dppPpn'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
          // disabled      = {true}
          onChange      = {(e, v)=> {this.calculatePriceNet(v, 'dppPpn')}}
        />}
      </div>

      <Field
        label         = {counterpart('entities.invoice.item.total')}
        name          = 'total'
        className     = 'md-cell md-cell--6'
        component     = {TextfieldMask}
        money={','}
        disabled
      />

      <div className     = 'md-cell md-cell--12'>
        <p style={{fontSize: 9}}>
          <em>*Untuk Persewaan Tanah (BP 4(2)), kode di isi NOP dan keterangan di isi Alamat tanah</em>
        </p>
      </div>

    </div>)
  }

  othersForm() {
    return (<div style={{height: 600, overflowY: 'auto'}}>
    <br/>
    <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.item.locationTitle').toUpperCase()} />
    <Divider/>
    <div className='md-grid'>
      <Field
        label         = {counterpart('entities.company.title')}
        name          = 'info.company'
        className     = 'md-cell md-cell--12'
        component     = {Searchfield}
        options       = {this.props.companies}
        valueField    = 'parent'
        apiPath       = {'lookup'}
        itemTemplate   = {function(d) {
          return {
            primaryText: d[this.props.viewField],
            secondaryText: d.npwp,
            onClick: () => {
              if(!this.props.disabled){
                this.handleItemClick(d); this.hide()
              }
            }
          }
        }}
        disabled      = {this.props.parentData && this.props.parentData.locationOnRoot && this.props.parentData.companyId }
      />
    </div>

    <div>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.item.periodeTitle').toUpperCase()} />
      <Divider/>
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.invoice.item.periode.title')}
          name          = 'periode'
          className     = 'md-cell md-cell--6'
          component     = {TextfieldMask}
          money={','}
        />
      </div>
    </div>
    {/* <div>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.item.receiptTitle').toUpperCase()} />
      <Divider/>
      <div className='md-grid'>
        <Field
          label         = {counterpart('entities.invoice.item.isReceiptMultiLevel')}
          name          = 'isReceiptMultiLevel'
          className     = 'md-cell md-cell--12'
          component     = {Switch}
        />
      </div>

      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddReceipt()}}>Tambah Jenis Penghasilan</Button>
      </div>
    </div> */}

    <div>
      <CardTitle title='' style={{padding: '10px 16px'}} subtitle={counterpart('entities.invoice.item.taxTitle').toUpperCase()} />
      <Divider/>
      <div className='md-grid'>
        <Field
          label         = {'Coa Bruto'}
          name          = 'coa'
          className     = 'md-cell md-cell--12'
          component     = {Searchfield}
          // service       = {CoaService}
          options       = {this.props.coas}
          params        = {{page: 1, sortBy: 'name', size: 90000, sort: 'ASC'}}
          searchField2  = 'code'
          itemTemplate  = {function(d) {
            return {
              primaryText: d.name,
              secondaryText: d.code,
              onClick: () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
          valueField    = 'parent'
        />
      </div>

      <DataTable baseId="simple-pagination" plain>
        <TableHeader>
          <TableRow>
            <TableColumn>No. </TableColumn>
            <TableColumn style={{width: '40%'}}>{counterpart('entities.incomeType.title').toUpperCase()}</TableColumn>
            <TableColumn style={{width: '40%'}}>{'Coa Pajak'}</TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(this.props.data.incomeTypeMap).map((key, i)=> {
            return <TableRow key={i} selectable={false}>
              <TableColumn>{i+1}</TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.incomeType.title')}
                  name          = {'incomeTypeMap.no'+i+'.incomeType'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  // service       = {IncomeTypeService}
                  options       = {this.props.incomeTypes}
                  valueField    = 'parent'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.pasal+" - "+d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <Field
                  label         = {counterpart('entities.coa.title')}
                  name          = {'incomeTypeMap.no'+i+'.coaId'}
                  className     = 'md-cell md-cell--12'
                  component     = {Searchfield}
                  // remoteSearch  = {true}
                  // service       = {CoaService}
                  options       = {this.props.coas}
                  searchField2  = 'code'
                  itemTemplate  = {function(d) {
                    return {
                      primaryText: d.name,
                      secondaryText: d.code,
                      onClick: () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
                  valueField    = 'id'
                  // searchField   = {['name', 'aliasName', 'npwp']}
                />
              </TableColumn>
              <TableColumn>
                <div className='mpk-layout'>
                  <div className='flex' />
                  <Button flat primary swapTheming onClick={() => this.handleDeleteIncomeType(i)}>Hapus</Button>
                </div>
              </TableColumn>
            </TableRow>
          })}
        </TableBody>
      </DataTable>
      <div className='mpk-layout' style={{padding: 8}}>
          <div className='flex'></div>
          <Button flat primary swapTheming onClick={() => {this.handleAddIncomeType()}}>Tambah Jenis Penghasilan</Button>
      </div>
    </div>
    </div>)
  }

  constructor(props){
    super(props);
    this.state = {
      onProgress: false,
      currentTab: 0
    };
  }

  async initData(props) {
    if(!props.formData.id) {
      this.props.initialize(this.initialData);
    } else {
      let data = props.formData;

      var incomeTypeMap = {}
        if(data.incomeTypes) {
        data.incomeTypes.forEach((v, i)=> {
          incomeTypeMap['no'+i] = v;
        })
      }

      data.incomeTypeMap = incomeTypeMap;

      this.props.initialize(data);
    }
  }

  @utilsService.Debounce(500)
  async calculatePriceNet(v, field) {
    var d = this.props.data;
    var c = {
      qty         : utilsService.number.get(d.qty),
      price       : utilsService.number.get(d.price),
      discount    : utilsService.number.get(d.discount),
      priceNett   : utilsService.number.get(d.priceNett),
      ppn         : utilsService.number.get(d.ppn),
      total       : utilsService.number.get(d.total),
      percentDppPpn: utilsService.number.get(d.info.percentDppPpn),
      dppPpn      : utilsService.number.get(d.info.dppPpn)
    }
    if(field) c[field] = utilsService.number.get(v);

    c.priceNett = (c.qty*c.price)-(c.discount*c.qty*c.price/100);
    if(field != 'dppPpn') {
      c.dppPpn = c.percentDppPpn*c.priceNett/100
    }
    c.total = c.priceNett+(c.ppn*c.dppPpn/100)

    this.props.change('info.dppPpn', c.dppPpn);
    this.props.change('priceNett', c.priceNett);
    this.props.change('total', c.total)
  }

  @utilsService.Debounce(800)
  async handleItemChange(data) {
    var res = await ItemService.api.findOne(data.id)
    this.props.change('itemType', data.category)
    this.props.change('code', data.code)
    this.props.change('name', data.name)
    this.props.change('description', data.description)
    this.props.change('coa', data.coa);
    this.props.change('unit', data.unit);
    if(res.data.itemIncomeTypes && res.data.itemIncomeTypes.length > 0) {
      var incomeTypeMap = {};
      res.data.itemIncomeTypes.forEach((v, i)=> {
        incomeTypeMap['no'+i] = {
          coaId: v.coaId,
          incomeType: v.incomeType
        };
      })

      this.props.change('incomeTypeMap', incomeTypeMap)
    }
  }

  async handleSave(callback, value) {
    value = _.cloneDeep(this.transform(value));
    try {

      var incomeTypes = [];
      Object.keys(value.incomeTypeMap).forEach((k, i)=> {
        value.incomeTypeMap[k].pph = value.priceNett*value.incomeTypeMap[k].incomeType.rate/100
        incomeTypes.push(value.incomeTypeMap[k])
      })
      value.incomeTypes = incomeTypes;
      delete value.incomeTypeMap;

      // var receipts = [];
      // Object.keys(value.receiptMap).forEach((k, i)=> {
      //   receipts.push(value.receiptMap[k])
      // })

      // value.receipts = receipts;
      // delete value.receiptMap;

      // if(this.props.parentData.id) {
      //   await this.saveToServer(value)
      // }

      this.props.onSuccess(value)
      this.props.onClose()

      callback()
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message
      callback(true, msg)
    }
  }

  async saveToServer(value) {

    value.invoice = this.props.parentData;
    value.invoiceId = this.props.parentData.id

    if(value.id) {
      await this.service.api.update(value)
      this.props.change(this.props.form, value)
    } else {
      await this.service.api.save(value)
      this.props.reset(this.props.form)
    }
  }

  handleAddIncomeType = ()=> {
    var map = this.props.formData.incomeTypeMap;
    var length = Object.keys(this.props.data.incomeTypeMap).length
    this.props.dispatch(this.props.change('incomeTypeMap.no'+length, {}))
  }

  handleDeleteIncomeType = (i) => {
    var map = this.props.data.incomeTypeMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('incomeTypeMap', newMap))
  }

  handleAddReceipt = ()=> {
    var map = this.props.formData.receiptMap;
    var length = Object.keys(this.props.data.receiptMap).length
    this.props.dispatch(this.props.change('receiptMap.no'+length, {}))
  }

  handleDeleteReceipt = (i) => {
    var map = this.props.data.receiptMap;
    delete map['no'+i]

    var newMap = {}
    Object.keys(map).forEach((k, i)=> {
      newMap['no'+i] = map[k];
    })

    this.props.dispatch(this.props.change('receiptMap', newMap))
  }


}
